import React, { Component } from 'react'
import { func, shape } from 'prop-types'
import { startOfMonth, subMonths, endOfMonth } from 'date-fns'
import FileSaver from 'file-saver'
import { withRouter } from 'react-router-dom'

import ReportView from './ReportView'
import notify from '../../../../common/services/notification'
import {
  downloadFormats,
  addEmployeesToList,
  removeEmployeesFromList,
  getReportInputFromFormData,
  decodeResponse,
  isReportFormValid,
} from './utils'
import { trackReportGeneration } from '../../../../common/services/analytics'

export class Report extends Component {
  state = {
    downloadPending: false,
    formData: {
      periodSelector: {
        periodIndicator: 'lastMonth',
        startDate: startOfMonth(subMonths(Date.now(), 1)),
        endDate: endOfMonth(subMonths(Date.now(), 1)),
      },
      filters: [
        {
          key: 'PersonalData',
          label: 'Report.Filters.PersonalData.Title',
          checked: true,
          items: [
            {
              label: 'Report.Filters.PersonalData.DateOfBirth',
            },
            {
              label: 'Report.Filters.PersonalData.EmployedFrom',
            },
            {
              label: 'Report.Filters.PersonalData.NumberOfChildren',
            },
            {
              label: 'Report.Filters.PersonalData.Email',
            },
          ],
        },
        {
          key: 'Holidays',
          label: 'Report.Filters.Holidays.Title',
          checked: true,
          items: [
            {
              label: 'Report.Filters.Holidays.Total',
            },
            {
              label: 'Report.Filters.Holidays.List',
            },
          ],
        },
        {
          key: 'SickLeaves',
          label: 'Report.Filters.SickLeaves.Title',
          checked: true,
          items: [
            {
              label: 'Report.Filters.SickLeaves.Total',
            },
            {
              label: 'Report.Filters.SickLeaves.List',
            },
          ],
        },
      ],

      selectedEmployees: [],
      selectedDownloadType: downloadFormats.PDF.key,
    },
    employeeSeachValue: '',
  }

  handleChange = change => {
    this.setState(({ formData }) => ({
      formData: {
        ...formData,
        ...change,
      },
    }))
  }

  handleFilterChange = change => {
    this.setState(({ formData }) => ({
      formData: {
        ...formData,
        filters: formData.filters.map(filter => {
          if (change === filter.key) {
            return { ...filter, checked: !filter.checked }
          }
          return filter
        }),
      },
    }))
  }

  handleEmployeeSelectionChange = (employees, add) => {
    this.setState(({ formData }) => ({
      formData: {
        ...formData,
        selectedEmployees: add
          ? addEmployeesToList(employees, formData.selectedEmployees)
          : removeEmployeesFromList(employees, formData.selectedEmployees),
      },
    }))
  }

  handleEmployeeSeachValueChange = event => {
    this.props.employeesQuery.refetch({
      filter: { search: event.target.value },
    })

    this.setState({ employeeSeachValue: event.target.value })
  }

  handleSendReportByEmailClick = async () => {
    this.setState({ downloadPending: true })

    const input = getReportInputFromFormData(this.state.formData)
    const { success } = await this.sendReport(input)

    if (success) {
      trackReportGeneration()
      notify('success', 'Notification.Report.SendInEmail.Success')
    } else {
      notify('error', 'Notification.Report.SendInEmail.Failed')
    }
    this.setState({ downloadPending: false })
  }

  handleDownloadClick = async () => {
    this.setState({ downloadPending: true })
    const input = getReportInputFromFormData(this.state.formData)
    const { success, data } = await this.downloadReport(input)

    if (success) {
      const file = await decodeResponse({ data, format: input.format })
      FileSaver.saveAs(file)

      trackReportGeneration()

      notify('success', 'Notification.Report.Download.Success')
    } else {
      notify('error', 'Notification.Report.Download.Failed')
    }

    this.setState({ downloadPending: false })
  }

  downloadReport = async input => {
    try {
      const res = await this.props.downloadReport({ variables: { input } })
      return {
        success: true,
        data: res && res.data && res.data.downloadHolidayRequestsReport,
      }
    } catch (error) {
      return { success: false, error }
    }
  }

  sendReport = async input => {
    try {
      const res = await this.props.sendReportInEmail({ variables: { input } })
      return { success: true, data: res }
    } catch (error) {
      return { success: false, error }
    }
  }

  render() {
    const { formData, employeeSeachValue, downloadPending } = this.state

    const { employeesQuery: { loading, employees = [] } = {} } = this.props

    const isValid = isReportFormValid(formData)

    return (
      <ReportView
        employees={employees}
        employeeSeachValue={employeeSeachValue}
        formData={formData}
        onChange={this.handleChange}
        onEmployeeSelectionChange={this.handleEmployeeSelectionChange}
        onEmployeeSeachValueChange={this.handleEmployeeSeachValueChange}
        onSendReportByEmailClick={this.handleSendReportByEmailClick}
        onDownloadClick={this.handleDownloadClick}
        isValid={isValid}
        loading={loading}
        downloadPending={downloadPending}
        onFilterChange={this.handleFilterChange}
      />
    )
  }
}

Report.propTypes = {
  sendReportInEmail: func.isRequired,
  downloadReport: func.isRequired,
  employeesQuery: shape({}),
}

export default withRouter(Report)
