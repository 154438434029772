import React from 'react'
import { string, instanceOf, func, shape, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../components/Button'
import { H2, Text } from '../../../../components/styles/Text'
import { Spacer } from '../../../../components/styles/Spacer'
import { Form } from '../../../../components/styles/Form'
import Input from '../../../../components/Input'
import { ButtonText } from '../../../../components/styles/Button'
import { BackIcon } from '../../../../components/CompanyRegistration/styles'
import { StyledElevated, Content } from './styles'

export const ForgotPasswordView = ({
  onFormDataChange,
  onSubmit,
  error,
  formData,
  isLoading,
  onBackClick,
}) => (
  <StyledElevated mx="auto" p={20} pt={40}>
    <Spacer height={22} mb={28} alignItems="center" display="flex">
      <ButtonText onClick={() => onBackClick()} disabled={isLoading}>
        <BackIcon src="/assets/img/step-left.svg" alt="back" />
        <Text semiBold>
          <FormattedMessage id="ForgotPassword.Button.Back" />
        </Text>
      </ButtonText>
    </Spacer>

    <Content>
      <H2 textAlign="center" mb={40}>
        <FormattedMessage id="ForgotPassword.Title" />
      </H2>

      <Spacer mb={40} textAlign="center">
        <Text>
          <FormattedMessage id="ForgotPassword.Description" />
        </Text>
      </Spacer>

      <Form mb={10} onSubmit={onSubmit}>
        <Input
          id="email"
          autoFocus
          label="Input.Email.Label"
          placeholder="ForgotPassword.Email.Placeholder"
          value={formData.email}
          required
          type="email"
          onChange={event => onFormDataChange({ email: event.target.value })}
          error={error && error.email}
        />
        <Spacer textAlign="center">
          <Button
            width={252}
            primary
            type="submit"
            id="submit-button"
            loading={isLoading}
          >
            <FormattedMessage id="Button.Submit" />
          </Button>
        </Spacer>
      </Form>
    </Content>
  </StyledElevated>
)

ForgotPasswordView.propTypes = {
  formData: shape({
    email: string.isRequired,
  }),
  isLoading: bool,
  onFormDataChange: func,
  onSubmit: func,
  error: instanceOf(Object),
  onBackClick: func.isRequired,
}

export default ForgotPasswordView
