import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EmployeeChildInfoView from './EmployeeChildInfoView'
import notify from '../../../../../../common/services/notification'
import { refetchQueries } from '../../../../../../common/constants/refetchQueries'
import { EmployeeType } from '../../../../../../common/prop-types'
import { fixDate } from '../../../../../../common/utils/calendar'

class EmployeeChildInfo extends Component {
  state = {
    isChildModalOpen: false,
    isChildSectionBusy: false,
    childModalData: {
      edit: false,
      child: {
        id: '-1',
        firstName: '',
        birthDate: new Date(),
        sex: '',
        disability: false,
      },
    },
  }

  createChild = async input => {
    try {
      await this.props.createChild({
        variables: { input },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  deleteChild = async ({ id }) => {
    try {
      await this.props.deleteChild({
        variables: { id },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  updateChild = async input => {
    try {
      await this.props.updateChild({
        variables: { input },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleChildModalSubmit = async ({ id, editMode, child }) => {
    this.setState({
      isChildSectionBusy: true,
    })

    const baseInput = {
      firstName: child.firstName,
      birthDate: fixDate(child.birthDate),
      disability: child.disability,
    }

    const createInput = {
      employeeId: id,
      ...baseInput,
    }

    const updateInput = {
      id: child.id,
      ...baseInput,
    }

    if (editMode) {
      const { success } = await this.updateChild(updateInput)

      if (success) {
        notify('success', 'Notification.Child.Update.Success')
      } else {
        notify('error', 'Notification.Child.Update.Failed')
      }
    } else {
      const { success } = await this.createChild(createInput)

      if (success) {
        notify('success', 'Notification.Child.Create.Success')
      } else {
        notify('error', 'Notification.Child.Create.Failed')
      }
    }

    this.setState({ isChildSectionBusy: false, isChildModalOpen: false })
  }

  handleEditChildRequest = editChild => {
    this.setState({
      isChildModalOpen: true,
      childModalData: { edit: true, child: editChild },
    })
  }

  handleDeleteChildRequest = async ({ id }) => {
    const input = { id }
    const { success } = await this.deleteChild(input)

    if (success) {
      notify('success', 'Notification.Child.Delete.Success')
    } else {
      notify('error', 'Notification.Child.Delete.Failed')
    }

    this.setState({ isChildModalOpen: false })
  }

  render() {
    const { employee, isAdmin } = this.props
    const { isChildModalOpen, isChildSectionBusy, childModalData } = this.state

    return (
      <EmployeeChildInfoView
        isAdmin={isAdmin}
        employee={employee}
        isAddChildModalOpen={isChildModalOpen}
        isChildSectionBusy={isChildSectionBusy}
        onAddChildModalOpen={() =>
          this.setState(prevValue => ({
            ...prevValue,
            isChildModalOpen: true,
          }))
        }
        onAddChildModalClose={() =>
          this.setState(prevValue => ({
            ...prevValue,
            isChildModalOpen: false,
            childModalData: {
              edit: false,
              child: {
                id: '-1',
                firstName: '',
                birthDate: new Date(),
                sex: '',
                disability: false,
              },
            },
          }))
        }
        onChildEditRequest={child => {
          this.handleEditChildRequest(child)
        }}
        onChildDeleteRequest={child => {
          this.handleDeleteChildRequest(child)
        }}
        onAddChildModalSubmit={({ edit, childData }) => {
          this.handleChildModalSubmit({
            id: employee.id,
            editMode: edit,
            child: childData,
          })
        }}
        childModalData={childModalData}
      />
    )
  }
}

EmployeeChildInfo.propTypes = {
  employee: EmployeeType.isRequired,
  createChild: PropTypes.func.isRequired,
  deleteChild: PropTypes.func.isRequired,
  updateChild: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

export default EmployeeChildInfo
