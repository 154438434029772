import React, { useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { isWeekend } from 'date-fns'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DateType, EmployeeListType } from '../../../../common/prop-types'
import CalendarHeaderCell from './CalendarHeaderCell'
import {
  CalendarContainer,
  CalendarBody,
  EmployeeCell,
  CalendarHeader,
} from './styles'

import {
  getDaysOfSelectedMonth,
  getCalendarData,
} from '../../../../common/utils/calendar'

import UserName from '../../../../components/UserName'
import HolidayCell from './HolidayCell'
import EmptyCell from './EmptyCell'
import HolidayCreationOptions from '../../../../components/RequestHolidayButton/Options'
import HolidaySelection from './HolidaySelection/HolidaySelection'
import {
  selectIsAdmin,
  selectCurrentUserId,
} from '../../../../redux/auth/auth.selector'
import { selectActiveLanguage } from '../../../../redux/language-selector/language-selector.selector'

const renderNonWorkingDays = ({ data, employeeIndex }) =>
  data.dates.map(({ isItToday, isWorkingDay, cellId, date }, dateIndex) => {
    const isPreviousDayWorkingDay =
      data.dates[dateIndex - 1] && data.dates[dateIndex - 1].isWorkingDay

    return (
      !isWorkingDay && (
        <EmptyCell
          key={cellId}
          column={dateIndex + 2}
          row={employeeIndex + 1}
          isItToday={isItToday}
          isWorkingDay={false}
          isPreviousDayWorkingDay={isPreviousDayWorkingDay}
          isHoliday={!isWeekend(date) && !isWorkingDay}
          canCreateHoliday={false}
        />
      )
    )
  })

const renderWorkingDays = ({
  data,
  currentUserId,
  isAdmin,
  employeeIndex,
  onMouseEnter,
  onMouseDown,
  onMouseUp,
}) => {
  return data.dates.map(
    ({ isWorkingDay, isItToday, cellId, date }, dateIndex) => {
      const canCreateHoliday =
        isWorkingDay && (isAdmin || currentUserId === data.employee.id)
      const column = dateIndex + 2
      const row = employeeIndex + 1
      const isPreviousDayWorkingDay =
        data.dates[dateIndex - 1] && data.dates[dateIndex - 1].isWorkingDay
      const isPreviousDayToday =
        data.dates[dateIndex - 1] && data.dates[dateIndex - 1].isItToday
      const isPreviousDayHoliday =
        data.dates[dateIndex - 1] &&
        !isWeekend(data.dates[dateIndex - 1]) &&
        !isPreviousDayWorkingDay

      return (
        isWorkingDay && (
          <EmptyCell
            key={cellId}
            row={row}
            column={column}
            canCreateHoliday={canCreateHoliday}
            isWorkingDay
            isPreviousDayWorkingDay={isPreviousDayWorkingDay}
            isPreviousDayToday={isPreviousDayToday}
            isPreviousDayHoliday={isPreviousDayHoliday}
            isItToday={isItToday}
            onMouseDown={() =>
              onMouseDown({
                column,
                row,
                employee: data.employee,
                date,
              })
            }
            onMouseEnter={() =>
              onMouseEnter({
                column,
                date,
              })
            }
            onMouseUp={() =>
              onMouseUp({
                column,
              })
            }
          />
        )
      )
    }
  )
}
const optionsBoxWidth = 212
const optionsBoxHeight = 156

const setInitialCoords = ({ selectionRef, calendarBodyRef }) => {
  const calendarTopRowHeight = 40
  const holidaySelectionHeight = 20
  const heightOffset = calendarTopRowHeight + holidaySelectionHeight

  const calendarClientHeight = calendarBodyRef.current.clientHeight
  const calendarScrollTop = calendarBodyRef.current.scrollTop
  const holidaySelectionTop = selectionRef.current.offsetTop

  const calendarScrollWidth = calendarBodyRef.current.scrollWidth
  const holidaySelectionLeft = selectionRef.current.offsetLeft

  const optimalPlacementTop1 =
    holidaySelectionTop - calendarScrollTop + heightOffset
  const optimalPlacementTop2 = calendarClientHeight - optionsBoxHeight
  const optimalPlacementTop = Math.min(
    optimalPlacementTop1,
    optimalPlacementTop2
  )
  const adjustedPlacementTop = holidaySelectionTop + heightOffset

  const left1 = holidaySelectionLeft
  const left2 = calendarScrollWidth - optionsBoxWidth
  const x = Math.min(left1, left2)

  const minimumCalendarHeightForOptimalPlacement = 240
  const y =
    calendarClientHeight < minimumCalendarHeightForOptimalPlacement
      ? adjustedPlacementTop
      : optimalPlacementTop

  return { x, y }
}
export default function CompanyCalendar({
  employees,
  selectedDate,
  openHolidayModal,
}) {
  const currentUserId = useSelector(selectCurrentUserId)
  const isAdmin = useSelector(selectIsAdmin)
  const days = getDaysOfSelectedMonth(selectedDate)
  const calendarData = getCalendarData(employees, days)
  const calendarHeaderRef = useRef(null)
  const calendarBodyRef = useRef(null)
  const selectionRef = useRef(null)
  const { code } = useSelector(selectActiveLanguage)

  const initialSelection = { started: false, ended: false }
  const [selection, setSelection] = useState(initialSelection)
  const [showHolidayCreationDropdown, setShowHolidayCreationDropdown] =
    useState(false)
  const initialCoords = { x: null, y: null }
  const [coords, setCoords] = useState(initialCoords)

  const handleEmptyCellMouseDown = ({ row, column, employee, date }) => {
    setSelection(prev => {
      if (prev.row) {
        setShowHolidayCreationDropdown(false)
        return { started: false, ended: false }
      }
      return {
        row,
        startColumn: column,
        endColumn: column,
        started: true,
        employee,
        startDate: date,
        endDate: date,
      }
    })
  }

  const handleEmptyCellMouseEnter = ({ column, date }) => {
    setSelection(prev => {
      if (prev.started && !prev.ended) {
        if (column >= prev.endColumn) {
          return {
            ...prev,
            endColumn: column,
            endDate: date,
          }
        }
        return {
          ...prev,
          endColumn: column,
          endDate: date,
          startColumn: column,
          startDate: date,
        }
      }
      return prev
    })
  }

  const handleEmptyCellMouseUp = ({ column }) => {
    setSelection(prevSelection => {
      if (prevSelection.started && !prevSelection.ended) {
        setCoords(setInitialCoords({ selectionRef, calendarBodyRef }))
        setShowHolidayCreationDropdown(true)

        return { ...prevSelection, endColumn: column, ended: true }
      }
      setShowHolidayCreationDropdown(false)

      return prevSelection
    })
  }

  const handleRequestHoliday = requestType => {
    setShowHolidayCreationDropdown(false)
    setSelection(initialSelection)

    openHolidayModal({
      requestType,
      selectedEmployee: selection.employee,
      startDate: selection.startDate,
      endDate: selection.endDate,
    })
  }

  return (
    <CalendarContainer key={code}>
      <CalendarHeader ref={calendarHeaderRef} columns={days.length}>
        <EmployeeCell column={1} row={1} type="employee" height={40} />
        {days.map((day, i) => (
          <CalendarHeaderCell date={day} column={i + 2} key={day.getTime()} />
        ))}
      </CalendarHeader>
      <PerfectScrollbar
        style={{ height: 'inherit' }}
        containerRef={ref => {
          calendarBodyRef.current = ref
        }}
        onScrollX={container => {
          if (calendarHeaderRef.current) {
            calendarHeaderRef.current.scrollLeft = container.scrollLeft
          }
        }}
      >
        <CalendarBody columns={days.length}>
          {selection.started && (
            <HolidaySelection
              ref={selectionRef}
              row={selection.row}
              startCell={selection.startColumn}
              endCell={selection.endColumn + 1}
            />
          )}

          {calendarData.map((data, employeeIndex) => (
            <Fragment key={data.employee.id}>
              <EmployeeCell row={employeeIndex + 1} column={1}>
                <UserName
                  employee={data.employee}
                  additionalInfo={data.employee.jobTitle}
                  textLength={17}
                />
              </EmployeeCell>

              {renderWorkingDays({
                data,
                employeeIndex,
                currentUserId,
                isAdmin,
                onMouseDown: handleEmptyCellMouseDown,
                onMouseEnter: handleEmptyCellMouseEnter,
                onMouseUp: handleEmptyCellMouseUp,
              })}

              {data.employee.holidayRequests.map(holidayRequest => (
                <HolidayCell
                  selectedMonth={selectedDate}
                  key={holidayRequest.id}
                  holidayRequest={{
                    ...holidayRequest,
                    employee: data.employee,
                  }}
                  employeeIndex={employeeIndex}
                />
              ))}

              {renderNonWorkingDays({
                data,
                employeeIndex,
              })}
            </Fragment>
          ))}
        </CalendarBody>
      </PerfectScrollbar>

      {showHolidayCreationDropdown && (
        <HolidayCreationOptions
          showOptions={showHolidayCreationDropdown}
          onRequestHoliday={handleRequestHoliday}
          isAdmin={isAdmin}
          position={{ left: coords.x, top: coords.y }}
        />
      )}
    </CalendarContainer>
  )
}

CompanyCalendar.propTypes = {
  selectedDate: DateType.isRequired,
  employees: EmployeeListType.isRequired,
  openHolidayModal: PropTypes.func.isRequired,
}
