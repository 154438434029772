import {
  eachDayOfInterval,
  getMonth,
  startOfMonth,
  addWeeks,
  startOfISOWeek,
  endOfISOWeek,
} from 'date-fns'

export function weeksOfMonth(date) {
  const thisMonth = getMonth(date)

  const startOfFirstWeekInMonth = startOfISOWeek(startOfMonth(date))
  const weeks = []

  let d = startOfFirstWeekInMonth
  do {
    weeks.push(d)
    d = addWeeks(d, 1)
  } while (getMonth(d) === thisMonth)

  return weeks
}

export function daysOfWeek(date) {
  return eachDayOfInterval({
    start: startOfISOWeek(date),
    end: endOfISOWeek(date),
  })
}
