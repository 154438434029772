import gql from 'graphql-tag'

const CREATE_CHILD = gql`
  mutation createChild($input: CreateChildInput!) {
    createChild(input: $input) {
      id
      firstName
      birthDate
      sex
      disability
    }
  }
`

export default CREATE_CHILD
