import gql from 'graphql-tag'

export const holidayRequestFragments = {
  base: gql`
    fragment HolidayRequestFields on HolidayRequest {
      id
      startDate
      endDate
      status
      description
      type
      deletable
      approvable
      rejectable
      editable
      employee {
        id
        firstName
        lastName
        avatarURL
        active
      }
    }
  `,
}

const namedHolidayRequestsQuery = name => gql`
  query ${name}(
    $filter: HolidayRequestsFilter
    $orders: [HolidayRequestsOrder!] = []
    ) {
    holidayRequests(filter: $filter, orders: $orders) {
      ...HolidayRequestFields
    }
  }
  ${holidayRequestFragments.base}
`

export const QueryRequestedHolidays = namedHolidayRequestsQuery(
  'pendingHolidayRequests'
)

export const QueryRequestedSickLeaves =
  namedHolidayRequestsQuery('pendingSickLeaves')

export const QueryActiveRequests = namedHolidayRequestsQuery('activeHolidays')

export const QueryCompanyHolidayRequests =
  namedHolidayRequestsQuery('companyHolidays')

export const QueryEmployeeHolidayList = gql`
  query employeeHolidayList(
    $filter: HolidayRequestsFilter
    $orders: [HolidayRequestsOrder!] = []
  ) {
    holidayRequests(filter: $filter, orders: $orders) {
      ...HolidayRequestFields
    }
  }
  ${holidayRequestFragments.base}
`
