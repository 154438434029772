import ReactGA from 'react-ga'

export function init() {
  if (process.env.REACT_APP_GA_KEY) {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY)
  }
}

export function trackPageView(path) {
  if (process.env.REACT_APP_GA_KEY) {
    ReactGA.pageview(path)
  }
}

export function trackEvent(event) {
  if (process.env.REACT_APP_GA_KEY) {
    ReactGA.event(event)
  }
}
