import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'

import { Report } from './Report'
import {
  DownloadHolidayRequestsReport,
  SendHolidayRequestsReportInEmail,
} from '../../../../graphql/mutations/report'
import { QueryEmployeesForReport } from '../../../../graphql/queries/employees'

export default compose(
  graphql(DownloadHolidayRequestsReport, { name: 'downloadReport' }),
  graphql(SendHolidayRequestsReportInEmail, { name: 'sendReportInEmail' }),
  graphql(QueryEmployeesForReport, {
    name: 'employeesQuery',
    options: {
      variables: {
        orders: [{ orderBy: 'NAME', order: 'ASC' }],
      },
    },
  })
)(Report)
