import styled from 'styled-components/macro'

export const LoadingIndicator = styled.div`
  background-image: url('/assets/img/loading-indicator.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  pointer-events: none;
`
