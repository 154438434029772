import styled from 'styled-components/macro'

export const Container = styled.div.attrs(props => ({
  isCompanyHoliday: props.isCompanyHoliday || false,
}))`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: ${props =>
    props.isCompanyHoliday ? 'rgba(255, 196, 61, 0.2)' : ''};
  border-radius: 4px;
  padding: 12px;
  gap: 12px;
`

export const FlexColumnDirectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`
export const FlexRowDirectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const HolidayDateSection = styled.div`
  min-width: 116px;
`

export const Image = styled.img`
  cursor: pointer;
  padding: 0;
`

export const Iconwrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 0;
  gap: 16px;
`

export const HolidayMarker = styled.div.attrs(props => ({
  isCompanyHoliday: props.isCompanyHoliday || false,
}))`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${props =>
    props.isCompanyHoliday ? '#ffc43d' : '#5cccaa'};
`
