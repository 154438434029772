import styled from 'styled-components/macro'

export const Container = styled.tr`
  width: 100%;
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.07);
  height: 80px;

  &:last-of-type {
    border: none;
  }
`

export const UserNameCell = styled.td`
  vertical-align: middle;
`

export const RoleCell = styled.td`
  vertical-align: middle;
`

export const ActionCell = styled.td`
  vertical-align: middle;
  padding-left: 30px;
`

export const UserContainer = styled.div`
  display: flex;
  padding-right: 10px;
`

export const UserInfoSection = styled.div`
  grid-column: 1;
`

export const UserRoleSection = styled.div`
  grid-column: 2;
`

export const UserActionSection = styled.div`
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
`
