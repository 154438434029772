import styled from 'styled-components/macro'

export const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  ::-webkit-clear-button {
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
  }
  ::-webkit-inner-spin-button {
    display: none;
  }

  font-size: 16px;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  padding: 15px;

  &:focus {
    outline: none;
    border: 1px solid #ff9152;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
  display: ${({ hidden }) => (hidden ? 'none' : 'inherit')};
  border-color: ${({ error }) => {
    if (error) return '#F7665C'
    return '#d2d2d2'
  }};
`

export const InputLabel = styled.div`
  font-weight: bold;
  line-height: 32px;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray};
  text-align: left;
  display: flex;
  align-items: center;
`

export const InputContainer = styled.div`
  margin-bottom: 10px;
`
