import React, { useState } from 'react'
import { number } from 'prop-types'
import Tooltip from 'react-tooltip-lite'
import { useSelector } from 'react-redux'
import { startOfMonth, endOfMonth, max, min, isSameMonth } from 'date-fns'
import { HolidayRequestType, DateType } from '../../../../../common/prop-types'
import { HolidayCellContainer } from './styles'
import HolidayDetailsModal from '../../../../../components/HolidayDetailsModal'
import { localeFormat } from '../../../../../components/DateField/DateField'
import { selectActiveLanguage } from '../../../../../redux/language-selector/language-selector.selector'

const HolidayCell = ({ holidayRequest, employeeIndex, selectedMonth }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { code } = useSelector(selectActiveLanguage)

  const startDateInMonth = max([
    holidayRequest.startDate,
    startOfMonth(selectedMonth),
  ])
  const endDateInMonth = min([
    holidayRequest.endDate,
    endOfMonth(selectedMonth),
  ])

  const startCell = startDateInMonth.getDate() + 1
  const endCell = endDateInMonth.getDate() + 2

  const openFromLeft = !isSameMonth(holidayRequest.startDate, selectedMonth)
  const openFromRight = !isSameMonth(holidayRequest.endDate, selectedMonth)

  return (
    <HolidayCellContainer
      startCell={startCell}
      endCell={endCell}
      row={employeeIndex + 1}
      type={holidayRequest.type}
      status={holidayRequest.status}
      openFromLeft={openFromLeft}
      openFromRight={openFromRight}
      onClick={() => {
        setIsOpen(!isOpen)
      }}
      title={`${localeFormat(holidayRequest.startDate, code)} - ${localeFormat(
        holidayRequest.endDate,
        code
      )}`}
    >
      <Tooltip
        content={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <HolidayDetailsModal
            id="holiday-detail-modal"
            holidayRequest={holidayRequest}
            onClose={() => setIsOpen(false)}
          />
        }
        isOpen={isOpen}
        background="#fff"
        padding="0"
      >
        <div className={`inner ${isOpen ? 'active' : ''}`} />
      </Tooltip>
    </HolidayCellContainer>
  )
}

HolidayCell.propTypes = {
  holidayRequest: HolidayRequestType.isRequired,
  employeeIndex: number.isRequired,
  selectedMonth: DateType.isRequired,
}

export default HolidayCell
