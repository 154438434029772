import styled from 'styled-components/macro'
import { Elevated } from '../../../../elements'
import { dimensions } from '../../../../theme/freebees'

export const PageWrapper = styled.div`
  padding: 100px;
  display: flex;
  justify-content: center;
`

export const StyledElevated = styled(Elevated)`
  width: 520px;
  padding: 60px 80px 60px 80px;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    padding: 25px 10px;
    width: 100%;
  }
`
