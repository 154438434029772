import React from 'react'
import { string, func } from 'prop-types'

import EmployeeTableRow from './EmployeeTableRow'
import {
  Container,
  TableHeader,
  NameColumn,
  RoleColumn,
  ActionColumn,
} from './styles'
import { EmployeeListType, SortByType } from '../../../../../common/prop-types'
import { Spacer } from '../../../../../components/styles/Spacer'
import SearchField from '../../../../../components/SearchField'
import SortButton from '../../../../../components/SortButton'

export const EmployeeList = ({
  employees,
  onSortChange,
  sortBy,
  onSearchChange,
  searchValue,
}) => (
  <Container>
    <thead>
      <TableHeader>
        <NameColumn>
          <Spacer width={275}>
            <SearchField
              placeholder="Search user"
              onChange={onSearchChange}
              value={searchValue}
            />
          </Spacer>
        </NameColumn>

        <RoleColumn>
          <Spacer width={175} pl={20}>
            <SortButton
              id="sort-role"
              text="EmployeeList.Header.Role"
              sortBy={sortBy}
              sortField="ROLE"
              onSortChange={onSortChange}
            />
          </Spacer>
        </RoleColumn>
        <ActionColumn />
      </TableHeader>
    </thead>
    <tbody>
      {employees.map(employee => (
        <EmployeeTableRow key={employee.id} employee={employee} />
      ))}
    </tbody>
  </Container>
)

EmployeeList.propTypes = {
  employees: EmployeeListType,
  onSortChange: func.isRequired,
  onSearchChange: func.isRequired,
  sortBy: SortByType.isRequired,
  searchValue: string.isRequired,
}

EmployeeList.defaultProps = {}

EmployeeList.displayName = 'EmployeeList'

export default EmployeeList
