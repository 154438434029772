import React from 'react'
import PropTypes from 'prop-types'

import WithQuery from '../../../../components/WithQuery'
import RequestListModule from '../../../../components/RequestListModule'
import { QueryEmployeeHolidayList } from '../../../../graphql/queries/holidayRequests'

const RequestListModuleWithQuery = WithQuery(RequestListModule)

export default function EmployeeHolidaysModuleView({ queryVariables }) {
  return (
    <RequestListModuleWithQuery
      showWhenLoading
      query={QueryEmployeeHolidayList}
      variables={queryVariables}
    />
  )
}

EmployeeHolidaysModuleView.propTypes = {
  queryVariables: PropTypes.shape({
    filter: PropTypes.shape({}),
  }),
}
