import styled from 'styled-components/macro'
import {
  alignItems,
  background,
  border,
  boxShadow,
  display,
  flexDirection,
  grid,
  height,
  justifyContent,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  space,
  textAlign,
  width,
} from 'styled-system'

export const Spacer = styled.div`
  ${alignItems};
  ${background};
  ${border};
  ${boxShadow}
  ${display};
  ${flexDirection};
  ${grid};
  ${height};
  ${justifyContent};
  ${maxHeight};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${overflow}
  ${space};
  ${textAlign};
  ${width};
  position: relative;
`

Spacer.propTypes = {
  ...alignItems.propTypes,
  ...display.propTypes,
  ...height.propTypes,
  ...justifyContent.propTypes,
  ...space.propTypes,
  ...textAlign.propTypes,
  ...width.propTypes,
}

Spacer.displayName = 'Spacer'
