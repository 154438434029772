import React from 'react'
import styled from 'styled-components/macro'
import { string, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { EmployeeListType } from '../../../../common/prop-types'
import UserName from '../../../../components/UserName'
import SearchField from '../../../../components/SearchField'
import { Spacer } from '../../../../components/styles/Spacer'
import Checkbox from '../../../../components/Checkbox'
import { colors } from '../../../../theme/freebees'
import Loading from '../../../../components/Loading'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EmployeeSelector = ({
  activeEmployees,
  inactiveEmployees,
  selectedEmployees,
  onSearchValueChange,
  onEmployeeSelectionChange,
  searchValue,
  loading,
}) => {
  const employees = [...activeEmployees, ...inactiveEmployees]
  const employeeIds = employees.map(employee => employee.id)
  const selectedEmployeeIds = selectedEmployees.map(employee => employee.id)
  const notSelectedEmployeeIds = employeeIds.filter(
    id => !selectedEmployeeIds.includes(id)
  )

  const activeEmployeeIds = activeEmployees.map(employee => employee.id)
  const notSelectedActiveEmployeeIds = activeEmployeeIds.filter(
    id => !selectedEmployeeIds.includes(id)
  )

  return (
    <>
      <SearchField value={searchValue} onChange={onSearchValueChange} />
      <Spacer mb={32} mt={20}>
        <Checkbox
          color={colors.blueAnother}
          label={
            searchValue
              ? 'Report.EmployeeSelector.SelectCurrent'
              : 'Report.EmployeeSelector.SelectAll'
          }
          checked={notSelectedEmployeeIds.length === 0}
          boldText
          onChange={() =>
            onEmployeeSelectionChange(
              employees,
              notSelectedEmployeeIds.length !== 0
            )
          }
        />
      </Spacer>
      {inactiveEmployees.length !== 0 && (
        <Spacer mb={32} mt={20}>
          <Checkbox
            color={colors.blueAnother}
            label={
              searchValue
                ? 'Report.EmployeeSelector.SelectCurrentActive'
                : 'Report.EmployeeSelector.SelectAllActive'
            }
            checked={notSelectedActiveEmployeeIds.length === 0}
            boldText
            onChange={() =>
              onEmployeeSelectionChange(
                activeEmployees,
                notSelectedActiveEmployeeIds.length !== 0
              )
            }
          />
        </Spacer>
      )}
      {loading ? <Loading /> : null}

      {!loading && employees.length
        ? employees.map(employee => {
            const checked = !!selectedEmployees.find(e => e.id === employee.id)
            return (
              <Row key={employee.id}>
                <Checkbox
                  checked={checked}
                  color={colors.blueAnother}
                  onChange={() =>
                    onEmployeeSelectionChange([employee], !checked)
                  }
                />

                <UserName
                  suppressRedirection
                  employee={employee}
                  additionalInfo={employee.jobTitle && employee.jobTitle.name}
                  textLength={17}
                  nameColor={!employee.active ? 'grayDark' : ''}
                />
              </Row>
            )
          })
        : null}

      {!loading && !employees.length ? (
        <FormattedMessage id="Report.EmployeeSelector.NoResult" />
      ) : null}
    </>
  )
}

EmployeeSelector.propTypes = {
  activeEmployees: EmployeeListType.isRequired,
  inactiveEmployees: EmployeeListType.isRequired,
  selectedEmployees: EmployeeListType.isRequired,
  searchValue: string.isRequired,
  onSearchValueChange: func.isRequired,
  onEmployeeSelectionChange: func.isRequired,
  loading: bool,
}
export default EmployeeSelector
