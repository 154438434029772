const statusesByType = {
  APPROVED: { type: 'VACATION', statuses: ['APPROVED'] },
  REQUESTED: { type: 'VACATION', statuses: ['REQUESTED'] },
  SICK_LEAVE: { type: 'SICK_LEAVE', statuses: ['REQUESTED', 'APPROVED'] },
  PLANNED: { type: 'VACATION', statuses: ['PLANNED'] },
}

const holidayReducer = (acc, curr) =>
  acc.find(filter => filter.type === curr.type)
    ? acc.map(f =>
        f.type === curr.type
          ? { ...curr, statuses: [...f.statuses, ...curr.statuses] }
          : f
      )
    : [...acc, curr]

const createStatusCalculator = statuses => values =>
  values.map(filter => statuses[filter]).reduce(holidayReducer, [])

export const statusCalculator = createStatusCalculator(statusesByType)
