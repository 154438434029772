import React, { Component } from 'react'
import { func } from 'prop-types'

import { LoginView } from './LoginView'
import validation from '../../../../common/services/validation'
import notify from '../../../../common/services/notification'
import { AuthService } from '../../../../common/services/auth'
import { HistoryType, IntlType } from '../../../../common/prop-types'
import { trackLogin } from '../../../../common/services/analytics'
import { getCognitoMessage } from '../../../../common/constants/errorCodes'

class Login extends Component {
  state = {
    formData: {
      email: '',
      password: '',
    },
    error: null,
    isLoading: false,
  }

  isValid = validation([
    [
      'email',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
      {
        rule: 'email',
        msg: this.props.intl.formatMessage({
          id: 'NotValidEmail',
        }),
      },
    ],
    [
      'password',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
  ])

  validation = () => {
    const { error, messages } = this.isValid(this.state.formData)
    this.setState({ error: messages })
    return { error, messages }
  }

  handleFormChange = e => {
    const { name, value } = e.target
    this.setState(({ formData }) => ({
      formData: { ...formData, [name]: value },
    }))
  }

  handleFormSubmit = async e => {
    e.preventDefault()

    const { error: validationError } = this.validation()

    if (!validationError) {
      this.submitForm()
    }
  }

  submitForm = async () => {
    const { history } = this.props
    const {
      formData: { email, password },
    } = this.state

    this.setState({ isLoading: true })

    try {
      const { challengeName } = await AuthService.signIn(email, password)

      trackLogin()
      this.setState({ isLoading: false })

      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        history.push(`/change-password/${email}/${password}`)
      }
    } catch (error) {
      this.setState({ isLoading: false })

      const message = getCognitoMessage(error, 'Notification.Login.Failed')

      notify('error', message)
    }
  }

  render() {
    const { error, formData, isLoading } = this.state

    return (
      <LoginView
        error={error}
        formData={formData}
        isLoading={isLoading}
        onForgotPasswordClick={this.props.onForgotPasswordClick}
        onFormChange={this.handleFormChange}
        onFormSubmit={this.handleFormSubmit}
      />
    )
  }
}

Login.propTypes = {
  history: HistoryType.isRequired,
  onForgotPasswordClick: func.isRequired,
  intl: IntlType.isRequired,
}

export default Login
