import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { string, shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ResponsiveCurrentMonthButton } from '../../../components/styles/Button'
import {
  Header,
  TitleRow,
  Filters,
  FilterRow,
  Title,
  CalendarGrid,
} from './styles'
import {
  FunctionType,
  HolidayRequestStatusListType,
  DateType,
} from '../../../common/prop-types'
import RequestListModule from '../../../components/RequestListModule'
import RequestHolidayButton from '../../../components/RequestHolidayButton'
import SearchField from '../../../components/SearchField'
import { Text } from '../../../components/styles/Text'
import WithQuery from '../../../components/WithQuery'
import { QueryCompanyCalendar } from '../../../graphql/queries/employees'
import { QueryCompanyHolidayRequests } from '../../../graphql/queries/holidayRequests'
import SimpleDateSelector from '../../../components/SimpleDateSelector'
import { Module } from '../../../components/styles'
import { Spacer } from '../../../components/styles/Spacer'

import CalendarModule from '../components/CalendarModule'
import HolidayRequestFilter from '../components/HolidayRequestFilter'
import ResponsiveViewSwitch from '../../../components/ViewSwitch/ResponsiveViewSwitch'
import InviteBanner from '../../dashboard/components/InviteBanner'

const CalendarModuleWithQuery = WithQuery(CalendarModule)
const RequestListModuleWithQuery = WithQuery(RequestListModule)

const CalendarPageView = ({
  employeeSearchValue,
  holidayRequestFilterValue,
  onEmployeeSearchValueChange,
  onHolidayRequestFilterChange,
  onViewModeChange,
  viewMode,
  viewModes,
  companyCalendarVariables,
  selectedDate,
  onDateChange,
  onJumpCurrentMonth,
  requestListVariables,
}) => (
  <CalendarGrid>
    <InviteBanner />
    <Filters>
      <Header>
        <TitleRow>
          <Title>
            <FormattedMessage id="Calendar.Title" />
          </Title>
          <SimpleDateSelector
            date={selectedDate}
            onChange={onDateChange}
            step="month"
          />
          <ResponsiveCurrentMonthButton
            secondary
            bordered
            narrow
            color="gray"
            onClick={onJumpCurrentMonth}
          >
            <Text uppercase>
              <FormattedMessage id="Button.CurrentMonth" />
            </Text>
          </ResponsiveCurrentMonthButton>
        </TitleRow>
        <div>
          <RequestHolidayButton />
        </div>
      </Header>

      <FilterRow>
        <SearchField
          value={employeeSearchValue}
          onChange={onEmployeeSearchValueChange}
        />

        <HolidayRequestFilter
          onChange={onHolidayRequestFilterChange}
          value={holidayRequestFilterValue}
        />

        <ResponsiveViewSwitch
          viewMode={viewMode}
          viewModes={viewModes}
          onViewModeChange={onViewModeChange}
        />
      </FilterRow>
    </Filters>

    <Module overflowY="hidden">
      <Switch>
        <Route
          path="/calendar/list"
          name="RequestList"
          render={() => (
            <Spacer px={20} height="100%">
              <RequestListModuleWithQuery
                query={QueryCompanyHolidayRequests}
                variables={requestListVariables}
                showEmployee
                showStatus
                showWhenLoading
                showErrorMessage
              />
            </Spacer>
          )}
        />
        <Route
          path="/calendar/calendar"
          name="RequestCalendar"
          render={() => (
            <CalendarModuleWithQuery
              query={QueryCompanyCalendar}
              variables={companyCalendarVariables}
              date={selectedDate}
              showWhenLoading
              showErrorMessage
            />
          )}
        />

        <Redirect
          to={{
            pathname: '/calendar/calendar',
          }}
        />
      </Switch>
    </Module>
  </CalendarGrid>
)

CalendarPageView.propTypes = {
  onHolidayRequestFilterChange: FunctionType,
  onViewModeChange: FunctionType,
  onEmployeeSearchValueChange: FunctionType,
  employeeSearchValue: string.isRequired,
  viewModes: shape({ calendar: string, list: string }),
  viewMode: string,
  holidayRequestFilterValue: HolidayRequestStatusListType.isRequired,
  companyCalendarVariables: shape({}),
  requestListVariables: shape({}),
  onDateChange: FunctionType.isRequired,
  onJumpCurrentMonth: FunctionType.isRequired,
  selectedDate: DateType.isRequired,
}

export default CalendarPageView
