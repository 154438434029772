import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import SelectTagModal from '../../../../../../components/Modals/SelectTagModal'
import { H2 } from '../../../../../../components/styles'
import TagList from '../../../../../../components/TagList/TagList'
import {
  AddButton,
  TagSection,
  Title,
  TitleButtonWrapper,
  TitleWrapper,
} from './styles'

const EmployeePersonalInfoView = ({
  isTeamSelectOpen,
  onTeamSelectModalClose,
  onTeamSelectModalSubmit,
  onTeamDeselected,
  onGoToProfile,
  openTeamSelectModal,
  availableTeams,
  selectedTeams,
  isAdmin,
}) => (
  <>
    {isTeamSelectOpen && (
      <SelectTagModal
        isOpen={isTeamSelectOpen}
        onSubmit={onTeamSelectModalSubmit}
        onClose={onTeamSelectModalClose}
        onGoToProfile={onGoToProfile}
        items={availableTeams}
        selectedItems={selectedTeams}
        copy={{
          title: 'SelectTeamModal.Title',
          description: 'SelectTeamModal.Description',
        }}
      />
    )}

    <H2>
      <TitleWrapper>
        <Title>
          <FormattedMessage id="Employee.PersonalInfoTab.TeamMembership.Title" />
        </Title>

        {isAdmin && (
          <TitleButtonWrapper>
            <AddButton
              secondary
              bordered
              narrow
              orange
              className="save-button"
              type="button"
              onClick={openTeamSelectModal}
            >
              <FormattedMessage id="Employee.PersonalInfoTab.TeamMembership.Add" />
            </AddButton>
          </TitleButtonWrapper>
        )}
      </TitleWrapper>
    </H2>

    <TagSection>
      <TagList
        items={selectedTeams}
        onDelete={onTeamDeselected}
        canDelete={isAdmin}
        emptyListText="Employee.Teams.Empty"
      />
    </TagSection>
  </>
)

EmployeePersonalInfoView.propTypes = {
  isTeamSelectOpen: PropTypes.bool,
  onTeamSelectModalClose: PropTypes.func.isRequired,
  onGoToProfile: PropTypes.func.isRequired,
  onTeamSelectModalSubmit: PropTypes.func.isRequired,
  onTeamDeselected: PropTypes.func.isRequired,
  openTeamSelectModal: PropTypes.func.isRequired,
  availableTeams: PropTypes.instanceOf(Array),
  selectedTeams: PropTypes.instanceOf(Array),
  isAdmin: PropTypes.bool.isRequired,
}

export default EmployeePersonalInfoView
