import React from 'react'
import { bool, node, instanceOf } from 'prop-types'
import { Query } from '@apollo/client/react/components'
import Loading from '../Loading'
import { transformQueryData } from '../../common/utils/responseTransformer'
import EmptyListPlaceholder from '../EmptyListPlaceholder'

export default Component => {
  const Sub = ({
    query,
    variables,
    showWhenLoading,
    showErrorMessage,
    hiddenWhenLoading,
    children,

    ...props
  }) => (
    <Query query={query} variables={variables} fetchPolicy="cache-and-network">
      {response => {
        if (!showWhenLoading && response && response.networkStatus === 1) {
          if (hiddenWhenLoading) return null
          return <Loading />
        }

        if (response && response.error) {
          // eslint-disable-next-line no-console
          console.error(
            '[APOLLO_QUERY_HANDLER]\n',
            '[ERROR_MESSAGE]',
            JSON.stringify(response.error, null, 2)
          )

          return showErrorMessage ? (
            <EmptyListPlaceholder
              imageSrc="/assets/img/data-error.svg"
              textId="Error.Data.Load.Failed"
            />
          ) : null
        }

        if (response && !response.error) {
          response.data = transformQueryData(response.data)
        }

        if (response.error) {
          return null
        }

        return (
          <Component {...props} {...response}>
            {children}
          </Component>
        )
      }}
    </Query>
  )

  Sub.propTypes = {
    query: instanceOf(Object).isRequired,
    variables: instanceOf(Object),
    showWhenLoading: bool,
    hiddenWhenLoading: bool,
    showErrorMessage: bool,
    children: node,
  }

  return Sub
}
