import React from 'react'
import { arrayOf, shape, string, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Checkbox from '../Checkbox'
import { Container } from './styles'
import { Spacer } from '../styles/Spacer'
import { colors } from '../../theme/freebees'
import { Text } from '../styles'

const CheckboxGroupVertical = ({
  groupKey,
  groupLabel,
  checked,
  items,
  onChange,
}) => (
  <Container>
    <Spacer mb={25} key={`group-header-${groupKey}`}>
      <Checkbox
        label={groupLabel}
        checked={checked}
        color={colors.blueAnother}
        onChange={() => onChange(groupKey)}
      />
    </Spacer>
    {items.map(item => (
      <Spacer mb={10} ml={33} key={`group-item-${item.label}`}>
        <Text color="gray">
          <FormattedMessage id={item.label} />
        </Text>
      </Spacer>
    ))}
  </Container>
)

CheckboxGroupVertical.propTypes = {
  groupKey: string.isRequired,
  groupLabel: string.isRequired,
  items: arrayOf(
    shape({
      label: string,
    })
  ).isRequired,
  checked: bool.isRequired,
  onChange: func.isRequired,
}

export default CheckboxGroupVertical
