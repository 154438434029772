import styled from 'styled-components/macro'

export const Title = styled.div`
  display: inline-block;
  height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const TitleIndicator = styled.div`
  align-self: center;
  margin-left: 20px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 22px;

  color: #ff9152;
`

export const TitleContent = styled.div``
