import { compose } from 'redux'
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import Authenticator from './Authenticator'
import { setAuthenticated } from '../../redux/auth/auth.reducer'
import { resetApp } from '../../redux/root-reducer'
import { selectIsRegistrationInProgress } from '../../redux/auth/auth.selector'

const mapStateToProps = createStructuredSelector({
  isRegistrationInProgress: selectIsRegistrationInProgress,
})

const mapDispatchToProps = {
  setAuthenticated,
  resetApp,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Authenticator)
