import React from 'react'
import { format, isToday, isEqual, isWeekend, subDays } from 'date-fns'
import { number } from 'prop-types'
import { IntlType, DateType } from '../../../../../common/prop-types'
import { filterNotWorkingDays } from '../../../../../common/services/workdays'
import { HeaderCell, DayName, Cell } from '../styles'

const CalendarHeaderCell = ({ date, intl, column }) => {
  const dayNumber = format(date, 'd')
  const dayName = intl.formatDate(date, {
    weekday: 'short',
  })
  const isItToday = isToday(date)
  const isWorkingDay = filterNotWorkingDays(date)
  const isHoliday = !isWeekend(date) && !isWorkingDay
  const isPreviousDayWorkingDay = filterNotWorkingDays(subDays(date, 1))

  return (
    <Cell
      column={column}
      row={1}
      height={40}
      isItToday={isItToday}
      isWorkingDay={isWorkingDay}
      isPreviousDayWorkingDay={isPreviousDayWorkingDay}
      isHoliday={isHoliday}
    >
      <HeaderCell
        isItToday={isItToday}
        isWorkingDay={isWorkingDay}
        isHoliday={isHoliday}
      >
        <div className="day-number">{dayNumber}</div>
        <DayName
          className="day-name"
          isItToday={isItToday}
          isHoliday={isHoliday}
          isWorkingDay={isWorkingDay}
        >
          {dayName}
        </DayName>
      </HeaderCell>
    </Cell>
  )
}

CalendarHeaderCell.propTypes = {
  date: DateType.isRequired,
  intl: IntlType.isRequired,
  column: number.isRequired,
}

export default React.memo(CalendarHeaderCell, (prev, next) => {
  if (!isEqual(prev.date, next.date)) return false
  return true
})
