import React from 'react'
import { node } from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { isFeatureEnabled, features } from '../common/constants/feature-flags'
import { store, reduxPersistor } from './store'

export const ReduxProvider = ({ children }) =>
  isFeatureEnabled(features.persistReduxStore) ? (
    <Provider store={store}>
      <PersistGate loading={null} persistor={reduxPersistor}>
        {children}
      </PersistGate>
    </Provider>
  ) : (
    <Provider store={store}>{children}</Provider>
  )

ReduxProvider.propTypes = {
  children: node.isRequired,
}
