import React, { useState } from 'react'
import Login from '../../components/Login'
import ForgotPassword from '../../components/ForgotPassword'
import { usePageTracking } from '../../../../hooks/usePageTracking'
import { Spacer } from '../../../../components/styles/Spacer'

const steps = {
  login: 'login',
  forgotPassword: 'forgotPassword',
}

const LoginPage = () => {
  usePageTracking('Login')

  const [currentStep, setCurrentStep] = useState(steps.login)
  return (
    <Spacer>
      {currentStep === steps.login && (
        <Login
          onForgotPasswordClick={() => setCurrentStep(steps.forgotPassword)}
        />
      )}
      {currentStep === steps.forgotPassword && (
        <ForgotPassword onBackClick={() => setCurrentStep(steps.login)} />
      )}
    </Spacer>
  )
}
LoginPage.propTypes = {}

export default LoginPage
