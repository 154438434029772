import { PureComponent } from 'react'
import { node, func, bool } from 'prop-types'

import { AuthService } from '../../common/services/auth'
import client, { apolloPersistor } from '../../graphql'
import { reduxPersistor } from '../../redux/store'

class Authenticator extends PureComponent {
  state = { initialized: false }

  async componentDidMount() {
    try {
      await AuthService.currentAuthenticatedUser()
      this.props.setAuthenticated(true)
    } catch (error) {
      this.resetApp()
    }

    AuthService.subscribeToAuthEvents(async ({ payload }) => {
      switch (payload.event) {
        case 'signIn': {
          if (!this.props.isRegistrationInProgress) {
            this.props.setAuthenticated(true)
          }

          break
        }
        case 'signOut': {
          this.resetApp()

          break
        }
        default:
          break
      }
    })

    this.setState({
      initialized: true,
    })
  }

  componentWillUnmount() {
    AuthService.unsubscribeFromAuthEvents()
  }

  resetApp = () => {
    client.resetStore() // graphql: cleans in-memory cache
    apolloPersistor.purge() // graphql: purge persisted data

    reduxPersistor.purge() // redux: purge persisted state
    this.props.resetApp() // redux: reset store
  }

  render() {
    const { initialized } = this.state
    const { children } = this.props

    return initialized ? children : null
  }
}

Authenticator.propTypes = {
  children: node.isRequired,
  setAuthenticated: func.isRequired,
  resetApp: func.isRequired,
  isRegistrationInProgress: bool.isRequired,
}

export default Authenticator
