import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {
  FunctionType,
  HolidayRequestStatusListType,
} from '../../../../common/prop-types'
import { colors } from '../../../../theme/freebees'
import Checkbox from '../../../../components/Checkbox'

const defaultCheckboxes = [
  {
    label: 'Calendar.Filters.Approved',
    type: 'status',
    checked: true,
    color: colors.calendarApprovedColor,
    id: 'APPROVED',
  },
  {
    label: 'Calendar.Filters.Requested',
    type: 'status',
    checked: true,
    color: colors.calendarRequestedCheckboxColor,
    id: 'REQUESTED',
  },
  {
    label: 'Calendar.Filters.SickLeave',
    type: 'type',
    checked: true,
    color: colors.calendarSickLeaveColor,
    id: 'SICK_LEAVE',
  },
  {
    label: 'Calendar.Filters.Planned',
    type: 'status',
    checked: true,
    color: colors.calendarPlannedColor,
    id: 'PLANNED',
  },
]

export const toggleCheckbox = (checkboxes, checkbox) =>
  checkboxes.map(cb =>
    cb.id === checkbox.id ? { ...checkbox, checked: !checkbox.checked } : cb
  )

export const getActiveFilters = filters =>
  filters.filter(cb => cb.checked).map(cb => cb.id)

const getDefaultState = value =>
  defaultCheckboxes.map(cb => ({
    ...cb,
    checked: value.includes(cb.id),
  }))

const HolidayRequestFilter = ({ onChange, value }) => {
  const [checkboxes, setCheckboxes] = useState(getDefaultState(value))

  const handleChange = checkbox => {
    const updatedCheckboxes = toggleCheckbox(checkboxes, checkbox)
    const values = getActiveFilters(updatedCheckboxes)

    setCheckboxes(updatedCheckboxes)
    onChange(values)
  }

  return (
    <Container>
      {checkboxes.map(checkbox => (
        <Checkbox
          key={checkbox.id}
          id={`checkbox-${checkbox.id}`}
          label={checkbox.label}
          checked={checkbox.checked}
          color={checkbox.color}
          onChange={() => handleChange(checkbox)}
        />
      ))}
    </Container>
  )
}

HolidayRequestFilter.propTypes = {
  onChange: FunctionType.isRequired,
  value: HolidayRequestStatusListType.isRequired,
}

export default HolidayRequestFilter

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: 30px;

  @media ${({ theme }) => theme.media.mobile} {
    justify-content: center;
    margin-right: initial;
    margin-left: initial;

    > * {
      margin-top: 10px;
    }
  }
`
