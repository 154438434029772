import styled from 'styled-components/macro'

export const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;
`

export const HolidayContainer = styled.div.attrs(props => ({
  isHolidayList: props.isHolidayList || false,
}))`
  height: ${props => (props.isHolidayList ? '500px' : '')};
  overflow: ${props => (props.isHolidayList ? 'auto' : '')};
`

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  gap: 12px;
`

export const Chip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8f8;
  padding: 8px 16px;
  border-radius: 100px;
  font-family: Open Sans;
  gap: 10px;
`

export const SectionContent = styled.div``
