import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Error } from './style'

export class ErrorMessage extends Component {
  render() {
    const { error } = this.props

    return <Error>{error}</Error>
  }
}

ErrorMessage.propTypes = {
  error: PropTypes.string,
}

export default ErrorMessage
