import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc'
import { createStructuredSelector } from 'reselect'

import { connect } from 'react-redux'

import CompanyRegistration from './CompanyRegistration'
import { selectActiveLanguage } from '../../redux/language-selector/language-selector.selector'
import {
  setRegistrationInProgress,
  setAuthenticated,
} from '../../redux/auth/auth.reducer'

const mapStateToProps = createStructuredSelector({
  selectedLocale: selectActiveLanguage,
})

const mapDispatchToProps = {
  setRegistrationInProgress,
  setAuthenticated,
}

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyRegistration)
