import gql from 'graphql-tag'

const INVITE_MULTIPLE_EMPLOYEES = gql`
  mutation inviteMultipleEmployees($input: [InviteEmployeeInput!]!) {
    inviteMultipleEmployees(input: $input) {
      id
    }
  }
`

export default INVITE_MULTIPLE_EMPLOYEES
