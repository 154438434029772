import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'

import { CompanyInfo } from './CompanyInfo'
import DELETE_TEAM from '../../../../graphql/mutations/deleteTeam'
import CREATE_TEAM from '../../../../graphql/mutations/createTeam'
import { QueryCurrentCompany } from '../../../../graphql/queries/currentCompany'
import DELETE_JOB_TITLE from '../../../../graphql/mutations/deleteJobTitle'
import CREATE_JOB_TITLE from '../../../../graphql/mutations/createJobTitle'
import CREATE_COMPANY_HOLIDAY from '../../../../graphql/mutations/createCompanyHoliday'
import DELETE_COMPANY_HOLIDAY from '../../../../graphql/mutations/deleteCompanyHoliday'
import { openHolidayModal } from '../../../../redux/holiday-modal/holiday-modal.reducer'
import QueryNationalHolidays from '../../../../graphql/queries/nationalHolidays'

const mapDispatchToProps = {
  openHolidayModal,
}

export default compose(
  connect(null, mapDispatchToProps),
  graphql(DELETE_TEAM, { name: 'deleteTeam' }),
  graphql(CREATE_TEAM, { name: 'createTeam' }),
  graphql(DELETE_JOB_TITLE, { name: 'deletePosition' }),
  graphql(CREATE_JOB_TITLE, { name: 'createPosition' }),
  graphql(QueryCurrentCompany, { name: 'company' }),
  graphql(QueryNationalHolidays, { name: 'nationalHolidaysQuery' }),
  graphql(DELETE_COMPANY_HOLIDAY, { name: 'deleteCompanyHoliday' }),
  graphql(CREATE_COMPANY_HOLIDAY, { name: 'createCompanyHoliday' })
)(CompanyInfo)
