import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const TextLink = styled(Link)`
  color: ${({ theme }) => theme.colors.orange};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
