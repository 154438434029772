import styled from 'styled-components/macro'
import { StyledInput } from '../Input/styles'

export const SearchInput = styled(StyledInput)`
  width: 100%;
  padding-left: 50px;
`

export const Container = styled.div`
  position: relative;

  @media ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const Icon = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
`
