import { useGoogleLogin } from '@react-oauth/google'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import notify from '../../../../../../common/services/notification/notification'
import Button from '../../../../../../components/Button'
import ConfirmActionModal from '../../../../../../components/Modals/ConfirmActionModal'
import client from '../../../../../../graphql'
import { H2, Padding, Title, TitleContent, TitleWrapper } from './styles'
import { throwCustomError } from '../../../../../../common/constants/errorCodes'

const EmployeeGoogleConnect = ({
  connectToGoogle,
  disconnectFromGoogle,
  employeeId,
  isGoogleConnected,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDisconnectConfirmModalOpen, setIsDisconnectConfirmModalOpen] =
    React.useState(false)
  const [isConnectConfirmModalOpen, setIsConnectConfirmModalOpen] =
    React.useState(false)

  const handleConnectSubmit = () => {
    setIsConnectConfirmModalOpen(false)
    handleConnect()
  }
  const handleDisconnectSubmit = async () => {
    await disconnectEmployeeFromGoogle(employeeId)
  }

  const handleConnect = useGoogleLogin({
    flow: 'auth-code',
    scope: 'openid email profile https://www.googleapis.com/auth/calendar',
    onSuccess: async codeResponse => {
      await connectEmployeeToGoogle(codeResponse.code)
    },
    onError: () => {
      notify('error', 'GoogleAuth.LoginError')
    },
  })

  const connectEmployeeToGoogle = async code => {
    setIsLoading(true)
    try {
      const response = await connectToGoogle({
        variables: { input: { code, employeeId } },
        errorPolicy: 'all',
      })

      throwCustomError(response, 'GoogleAuth.LoginError')

      await client.reFetchObservableQueries()
      notify('success', 'GoogleAuth.LoginSuccess')
    } catch (error) {
      setIsLoading(false)
      notify('error', error.message)
    }
  }

  const disconnectEmployeeFromGoogle = async id => {
    setIsLoading(true)
    try {
      const response = await disconnectFromGoogle({
        variables: { input: { employeeId: id } },
        errorPolicy: 'all',
      })

      throwCustomError(response, 'GoogleAuth.LogoutError')

      await client.reFetchObservableQueries()

      notify('success', 'GoogleAuth.LogoutSuccess')
    } catch (error) {
      setIsLoading(false)
      notify('error', error.message)
    }
  }

  return (
    <>
      <Padding />
      <H2>
        <TitleWrapper>
          <Title>
            <TitleContent>
              <FormattedMessage id="Employee.PersonalInfoTab.GoogleCalendarSync.Title" />
            </TitleContent>
          </Title>
        </TitleWrapper>
      </H2>
      <Padding />
      <Button
        loading={isLoading}
        onClick={
          isGoogleConnected
            ? () => setIsDisconnectConfirmModalOpen(true)
            : () => setIsConnectConfirmModalOpen(true)
        }
      >
        <FormattedMessage
          id={
            isGoogleConnected
              ? 'Employee.PersonalInfoTab.GoogleCalendarUnSync'
              : 'Employee.PersonalInfoTab.GoogleCalendarSync'
          }
        />
      </Button>
      <ConfirmActionModal
        title="Employee.PersonalInfoTab.GoogleCalendarUnSync.Question"
        question="Employee.PersonalInfoTab.GoogleCalendarUnSync.Warning"
        isOpen={isDisconnectConfirmModalOpen}
        isLoading={isLoading}
        onClose={() => setIsDisconnectConfirmModalOpen(false)}
        onSubmit={handleDisconnectSubmit}
      />
      <ConfirmActionModal
        title="Employee.PersonalInfoTab.GoogleCalendarSync.Question"
        question="Employee.PersonalInfoTab.GoogleCalendarSync.Warning"
        isOpen={isConnectConfirmModalOpen}
        isLoading={isLoading}
        onClose={() => setIsConnectConfirmModalOpen(false)}
        onSubmit={handleConnectSubmit}
      />
    </>
  )
}

EmployeeGoogleConnect.propTypes = {
  connectToGoogle: PropTypes.func.isRequired,
  disconnectFromGoogle: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  isGoogleConnected: PropTypes.bool.isRequired,
}

export default EmployeeGoogleConnect
