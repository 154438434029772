import styled from 'styled-components/macro'
import { space, width } from 'styled-system'
import { dimensions } from '../../theme/freebees'

export const ModalContainer = styled.div`
  ${space};
  ${width};
  position: relative;
  background: ${({ theme }) => theme.white};
  box-shadow: ${({ shadow }) =>
    shadow ? '0px 10px 36px rgba(17, 14, 10, 0.27)' : 'none'};
  border-radius: 4px;
  max-height: 85vh;
`

export const ModalTitle = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContent = styled.div`
  padding: 0 50px;
`

export const ModalVerticalInputContainer = styled.div`
  ${space};
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    flex-direction: column;
  }
`

export const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  margin-right: 40px;
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ModalControls = styled.div`
  text-align: right;
  height: 30px;
`

export const ModalCloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
