import Auth from '@aws-amplify/auth'

import { Hub } from '@aws-amplify/core'

const init = () => {
  Auth.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: process.env.REGION,
      identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
      authenticationFlowType: process.env.REACT_APP_AUTHENTICATION_FLOW_TYPE,
    },
  })
}

const signOut = async () => Auth.signOut()

const forgotPassword = async email => Auth.forgotPassword(email)

const forgotPasswordSubmit = async (state, verificationCode, newPassword) =>
  Auth.forgotPasswordSubmit(state, verificationCode, newPassword)

const confirmSignUp = async (email, confirmationCode) =>
  Auth.confirmSignUp(email, confirmationCode)

const changePassword = async (email, oldPassword, newPassword) => {
  try {
    const user = await Auth.signIn(email, oldPassword)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword // the new password
      )
      return { success: true }
    }
    return { success: false }
  } catch (error) {
    return { success: false, error }
  }
}

const signIn = async (email, password) => Auth.signIn(email, password)
const currentAuthenticatedUser = async () => Auth.currentAuthenticatedUser()

const subscribeToAuthEvents = cb => Hub.listen('auth', cb)
const unsubscribeFromAuthEvents = () => Hub.remove('auth')

const signUp = async signupData => Auth.signUp(signupData)

const getToken = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user.signInUserSession.accessToken.jwtToken
  } catch (error) {
    return null
  }
}

export const AuthService = {
  changePassword,
  confirmSignUp,
  currentAuthenticatedUser,
  forgotPassword,
  forgotPasswordSubmit,
  getToken,
  init,
  signIn,
  signOut,
  signUp,
  subscribeToAuthEvents,
  unsubscribeFromAuthEvents,
}
