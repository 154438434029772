import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DateRangePicker from './DateRangePicker'
import { selectActiveLanguage } from '../../redux/language-selector/language-selector.selector'

const mapStateToProps = createStructuredSelector({
  activeLanguage: selectActiveLanguage,
})
export default connect(mapStateToProps)(DateRangePicker)
