import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { logger } from 'redux-logger'

import rootReducer from './root-reducer'
import { isFeatureEnabled, features } from '../common/constants/feature-flags'

const debugMode = isFeatureEnabled(features.useReduxLogger)
const persistMode = isFeatureEnabled(features.persistReduxStore)

const migrations = {
  1: state => ({
    ...state,
    languageSelector: undefined,
  }),
}

const getRootReducer = ({ persist }) =>
  persist
    ? persistReducer(
        {
          key: 'root',
          version: 1,
          storage,
          blacklist: ['holidayModal', 'companyCalendar', 'profile'],
          migrate: createMigrate(migrations, {
            debug: debugMode,
          }),
        },
        rootReducer
      )
    : rootReducer

const getMiddlewares = () => {
  const middlewares = []

  if (debugMode) {
    middlewares.push(logger)
  }

  return middlewares
}

const reducerToUse = getRootReducer({
  persist: persistMode,
})

const middlewares = getMiddlewares()

export const store = createStore(reducerToUse, applyMiddleware(...middlewares))

export const reduxPersistor = persistStore(store)
