import styled from 'styled-components/macro'
import { width, space } from 'styled-system'

const minWidth = ({ narrow = false, primary }) => {
  if (narrow) return primary ? '173px' : '110px'
  return '212px'
}

const selectColor = ({ theme, color = theme.buttons.default }) =>
  theme.buttons.variants[color] && theme.buttons.variants[color].default

const selectDarkColor = ({ theme, color = theme.buttons.default }) =>
  theme.buttons.variants[color] && theme.buttons.variants[color].dark

const backgroundColor = {
  default: ({ color, disabled, bordered, theme }) => {
    if (bordered) return theme.colors.white
    if (disabled) return theme.colors.grayLight

    return selectColor({ color, theme })
  },
  hover: ({ color, reversed, disabled, bordered, theme }) => {
    if (bordered) {
      if (disabled) return theme.colors.white
      return reversed ? selectDarkColor({ color, theme }) : theme.colors.white
    }
    if (disabled) return theme.colors.grayLight

    return selectDarkColor({ color, theme })
  },
}

const getColor = {
  default: ({ disabled, color, bordered, theme }) => {
    if (bordered) {
      if (disabled) return theme.colors.grayLight
      return selectColor({ color, theme })
    }
    return theme.colors.white
  },
  hover: ({ disabled, reversed, bordered, color, theme }) => {
    if (disabled) {
      return bordered ? theme.colors.grayLight : theme.colors.white
    }
    if (bordered)
      return reversed ? theme.colors.white : selectDarkColor({ color, theme })
    return theme.colors.white
  },
}

const boxShadow = {
  default: ({ theme }) => theme.shadow.button,
  hover: ({ disabled, theme }) =>
    disabled ? 'none' : theme.shadow.buttonHovered,
  active: ({ theme }) => theme.shadow.buttonActive,
}
const borderColor = {
  default: ({ color, disabled, theme }) =>
    disabled ? theme.colors.grayLight : selectColor({ color, theme }),
  hover: ({ color, disabled, theme }) =>
    disabled ? theme.colors.grayLight : selectDarkColor({ color, theme }),
}
const minHeight = ({ primary, secondary }) => {
  if (primary) return '52px'
  if (secondary) return '38px'
  return '52px'
}

const borderRadius = ({ primary, secondary }) => {
  if (primary) return '26px'
  if (secondary) return '4px'
  return '26px'
}

const padding = ({ narrow }) => {
  if (narrow) return '0 7px'

  return '0 40px'
}

const ButtonBase = styled.button`
  ${width};
  ${space};
  background: none;
  border: none;
  font-size: '14px';
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`

export const Button = styled(ButtonBase)`
  border-width: 1px;
  border-style: solid;
  background-color: ${backgroundColor.default};
  border-color: ${borderColor.default};
  border-radius: ${borderRadius};
  color: ${getColor.default};
  min-height: ${minHeight};
  padding: ${padding};
  min-width: ${minWidth};
  &:hover {
    box-shadow: ${boxShadow.hover};
    background-color: ${backgroundColor.hover};
    border-color: ${borderColor.hover};
    color: ${getColor.hover};
  }
  &:active {
    box-shadow: ${boxShadow.active};
  }
`

export const ButtonWithShadow = styled(Button)`
  box-shadow: ${boxShadow.hover};
`

export const CurrentMonthButton = styled(Button)`
  padding: 0 14px;
`

export const ResponsiveCurrentMonthButton = styled(CurrentMonthButton)`
  @media ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`

export const ButtonRound = styled(Button)`
  border-radius: 50%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: initial;
  width: 65px;
  height: 65px;
  padding: 0;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  }

  img {
    transition: all 0.2s;
    transform-origin: 50% 50%;
    transform: ${({ disabled, open }) => !disabled && open && 'rotate(135deg)'};
  }

  @media ${({ theme }) => theme.media.mobile} {
    width: 55px;
    height: 55px;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  button {
    margin: 0 5px;
  }
  button:first-of-type {
    margin-left: 0;
  }
  button:last-of-type {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;

    button {
      margin: initial;
      margin-top: 10px;
    }
  }
`

export const ButtonIconGroup = styled.div`
  display: flex;
  flex-direction: row;
`

export const ButtonIcon = styled(ButtonBase)`
  display: flex;
  vertical-align: center;
  justify-content: center;
  width: ${({ narrow }) => (narrow ? 'auto' : '52px')};
  height: ${({ narrow }) => (narrow ? 'auto' : '52px')};
  padding: 0;
  border: none;
  border-radius: 4px;
  background-color: ${({ selected, disabled, theme, color }) => {
    if (disabled) return theme.colors.grayLight
    if (selected) return selectColor({ theme, color })
    return theme.colors.white
  }};
  &:hover {
    background-color: ${({ selected, disabled, theme, color }) => {
      if (disabled) return theme.colors.grayLight
      if (selected) return selectDarkColor({ theme, color })
      return theme.colors.white
    }};
  }
`

export const ButtonText = styled(ButtonBase)`
  display: flex;
  align-items: center;
  padding: 0;
  color: ${selectColor};
`

export const InputButton = styled(ButtonIcon)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

export const ButtonOption = styled(ButtonBase)`
  border-radius: 4px;
  height: 38px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.orangeLightGrayish : theme.colors.white};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.orange : theme.colors.black};
`

export const ButtonWithText = styled(ButtonBase)`
  color: ${selectColor};
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily.semiBold} !important;
  font-size: '14px';
`

export const ButtonLink = styled(ButtonBase)`
  color: ${selectColor};
  text-decoration: underline;
  text-transform: none;
`
