import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Margin } from '../../../../components/styles/Margin'
import DateField from '../../../../components/DateField/DateField'
import { Text } from '../../../../components/styles/Text'
import {
  Container,
  HolidayDateSection,
  Image,
  FlexColumnDirectionWrapper,
  HolidayMarker,
  Iconwrapper,
} from './styles'

const CompanyHolidayList = ({ items, onDelete }) => {
  return items
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map(item => {
      const isExpired = new Date() > new Date(item.date)
      return (
        <Margin b key={item.id}>
          <Container isCompanyHoliday={item.deletable}>
            <HolidayMarker isCompanyHoliday={item.deletable} />
            <FlexColumnDirectionWrapper>
              <Text semiBold fontSize={16} color={isExpired ? 'gray' : 'black'}>
                <FormattedMessage
                  id={
                    item.deletable && item.description
                      ? item.description
                      : `Holiday.${item.description}`
                  }
                />
              </Text>
              <HolidayDateSection>
                <DateField date={item.date} />
              </HolidayDateSection>
            </FlexColumnDirectionWrapper>
            {item.deletable && (
              <Iconwrapper>
                <Image
                  src="/assets/icons/delete.svg"
                  id={`delete-button-${item.id}`}
                  onClick={() => onDelete(item)}
                />
              </Iconwrapper>
            )}
          </Container>
        </Margin>
      )
    })
}

export default CompanyHolidayList
