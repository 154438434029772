import styled from 'styled-components/macro'

export const Container = styled.div`
  margin-top: 35px;

  @media ${({ theme }) => theme.media.mobile} {
    margin-top: 18px;
  }
`

export const Wrapper = styled.div`
  margin: 20px 0;
`
