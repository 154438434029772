import styled from 'styled-components/macro'

export const Error = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #f7665c;
  text-align: left;
`
