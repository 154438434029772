import { FormattedMessage } from 'react-intl'
import { string } from 'prop-types'
import React from 'react'
import { TextLink } from './style'

function InlineLink({ labelTKey, path }) {
  return (
    <TextLink to={path}>
      <FormattedMessage id={labelTKey} />
    </TextLink>
  )
}

InlineLink.propTypes = {
  labelTKey: string.isRequired,
  path: string.isRequired,
}

export default InlineLink
