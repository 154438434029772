import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import CSVFileValidator from 'csv-file-validator'
import isEmail from 'validator/lib/isEmail'
import { parseISO, isValid } from 'date-fns'
import { isOver16 } from '../../../../common/utils/calculations'
import { StyledSpacer, LoadingImage, DownloadLink } from './styles'
import BulkUploadErrorDisplay from './BulkUploadErrorDisplay'
import BulkUploadSuccessDisplay from './BulkUploadSuccessDisplay'
import { IntlType } from '../../../../common/prop-types'
import Modal from '../../../../components/Modal'
import { Spacer } from '../../../../components/styles/Spacer'
import { Text } from '../../../../components/styles'
import Button from '../../../../components/Button'

export const modalStyle = {
  content: {
    width: '1000px',
    height: '750px',
    bottom: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    padding: '0px',
    border: '0px',
    boxShadow: '0px 10px 36px rgba(17, 14, 10, 0.27)',
  },
}

const isValidName = name => name.trim().length > 2

const isValidDate = dateString => isValid(parseISO(dateString))

const isBirthDateValid = dateString =>
  isValidDate(dateString) && isOver16(dateString)

class BulkUploadUsers extends Component {
  state = {
    parsing: false,
    csv: {
      ready: false,
      data: {
        success: false,
        users: [],
        errors: [],
      },
    },
  }

  csvValidatorConfig = {
    headers: [
      {
        name: this.props.intl.formatMessage({
          id: 'Input.LastName.Label',
        }),
        inputName: 'lastName',
        required: true,
        validate: lastName => isValidName(lastName),
        headerError: () => null,
        validateError: (headerName, rowNumber, columnNumber) =>
          this.getNameValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
        requiredError: (headerName, rowNumber, columnNumber) =>
          this.getRequiredValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
      },
      {
        name: this.props.intl.formatMessage({
          id: 'Input.FirstName.Label',
        }),
        inputName: 'firstName',
        required: true,
        headerError: () => null,
        validate: firstName => isValidName(firstName),
        validateError: (headerName, rowNumber, columnNumber) =>
          this.getNameValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
        requiredError: (headerName, rowNumber, columnNumber) =>
          this.getRequiredValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
      },
      {
        name: 'Email',
        inputName: 'email',
        unique: true,
        required: true,
        headerError: () => null,
        uniqueError: (headerName, rowNumber) =>
          this.getUniqueErrorMessage(headerName, rowNumber),
        validate: email => isEmail(email),
        validateError: (headerName, rowNumber, columnNumber) =>
          this.getInvalidFormalErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
        requiredError: (headerName, rowNumber, columnNumber) =>
          this.getRequiredValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
      },
      {
        name: this.props.intl.formatMessage({
          id: 'Input.BirthDate.Label',
        }),
        inputName: 'birthDate',
        required: true,
        headerError: () => null,
        validate: birthDate => isBirthDateValid(birthDate),
        validateError: (headerName, rowNumber, columnNumber) =>
          this.getInvalidFormalOrAgeErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
        requiredError: (headerName, rowNumber, columnNumber) =>
          this.getRequiredValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
      },
      {
        name: this.props.intl.formatMessage({
          id: 'Input.FirstDay.Label',
        }),
        inputName: 'hireDate',
        required: true,
        headerError: () => null,
        validate: hireDate => isValidDate(hireDate),
        validateError: (headerName, rowNumber, columnNumber) =>
          this.getInvalidFormalErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
        requiredError: (headerName, rowNumber, columnNumber) =>
          this.getRequiredValidationErrorMessage(
            headerName,
            rowNumber,
            columnNumber
          ),
      },
    ],
  }

  getRowColumnErrorIndicator = (rowNumber, columnNumber) => {
    const translatedRow = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Row',
    })

    const translatedColumn = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Column',
    })

    return `${translatedRow} ${rowNumber} / ${translatedColumn} ${columnNumber}`
  }

  getNameValidationErrorMessage = (headerName, rowNumber, columnNumber) => {
    const nameError = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Name',
    })

    const rowColumnErrorIndicator = this.getRowColumnErrorIndicator(
      rowNumber,
      columnNumber
    )

    return `${headerName} ${nameError} : ${rowColumnErrorIndicator}`
  }

  getRequiredValidationErrorMessage = (headerName, rowNumber, columnNumber) => {
    const requiredError = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Required',
    })

    const rowColumnErrorIndicator = this.getRowColumnErrorIndicator(
      rowNumber,
      columnNumber
    )

    return `${headerName} ${requiredError} : ${rowColumnErrorIndicator}`
  }

  getInvalidFormalErrorMessage = (headerName, rowNumber, columnNumber) => {
    const invalidFormat = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Format',
    })

    const rowColumnErrorIndicator = this.getRowColumnErrorIndicator(
      rowNumber,
      columnNumber
    )

    return `${headerName} ${invalidFormat} : ${rowColumnErrorIndicator}`
  }

  getUniqueErrorMessage = (headerName, rowNumber) => {
    const uniqueErrorMessage = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Unique',
    })

    const translatedRow = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Row',
    })

    return `${headerName} ${uniqueErrorMessage} : ${translatedRow} ${rowNumber}`
  }

  getInvalidFormalOrAgeErrorMessage = (headerName, rowNumber, columnNumber) => {
    const invalidAgeFormat = this.props.intl.formatMessage({
      id: 'Bulk.Upload.Parse.Error.Format.Age',
    })

    const rowColumnErrorIndicator = this.getRowColumnErrorIndicator(
      rowNumber,
      columnNumber
    )

    return `${headerName} ${invalidAgeFormat} : ${rowColumnErrorIndicator}`
  }

  handleCsvParsingFailure = invalidMessages => {
    this.setState({
      parsing: false,
      csv: {
        ready: true,
        data: { success: false, users: [], errors: invalidMessages },
      },
    })
  }

  handleCsvParsingSuccess = data => {
    const convertedEmployeeData = data.map(datum => ({
      ...datum,
      firstName: datum.firstName.trim(),
      lastName: datum.lastName.trim(),
      birthDate: new Date(datum.birthDate),
      hireDate: new Date(datum.hireDate),
      children: [],
    }))

    this.setState({
      parsing: false,
      csv: {
        ready: true,
        data: { success: true, users: convertedEmployeeData, errors: [] },
      },
    })
  }

  handleCsvData = csvData => {
    const { intl } = this.props

    const inValidMessages = csvData.inValidMessages.filter(
      item => item !== null
    )

    const noDataError = intl.formatMessage({
      id: 'Bulk.Upload.Error.No.Data',
    })
    const cannotParseError = intl.formatMessage({
      id: 'Bulk.Upload.Error.Cannot.Parse.File',
    })

    if (inValidMessages && inValidMessages.length > 0)
      this.handleCsvParsingFailure(inValidMessages)
    else if (csvData.data && csvData.data.length >= 1)
      this.handleCsvParsingSuccess(csvData.data)
    else if (csvData.data.length === 0)
      this.handleCsvParsingFailure([noDataError])
    else this.handleCsvParsingFailure([cannotParseError])
  }

  handleSelectedFiles = async event => {
    const { intl } = this.props
    const {
      target: { files },
    } = event

    if (files === undefined || files.length === 0) return

    CSVFileValidator(files[0], this.csvValidatorConfig)
      .then(csvData => {
        this.handleCsvData(csvData)
      })
      .catch(() => {
        this.handleCsvParsingFailure(
          intl.formatMessage({
            id: 'Bulk.Upload.Error.Unexpected',
          })
        )
      })
  }

  handleDisplayCsvResult = (isLoading, onSubmit) => {
    const {
      csv: {
        ready,
        data: { success, errors, users },
      },
    } = this.state

    if (ready) {
      if (success) {
        return (
          <BulkUploadSuccessDisplay
            isLoading={isLoading}
            data={users}
            onUploadUsers={() => {
              onSubmit(users)
            }}
          />
        )
      }
      return <BulkUploadErrorDisplay errorLines={errors} />
    }
    return null
  }

  handleOnFileChanged = async event => {
    this.setState({ parsing: true })

    event.stopPropagation()
    event.preventDefault()

    await this.handleSelectedFiles(event)
    this.fileUploader.value = null
  }

  render() {
    const { isOpen, onClose, onSubmit, isLoading, activeLang } = this.props
    const { parsing } = this.state
    const sampleFile =
      activeLang.code === 'en'
        ? '/assets/files/sample.csv'
        : '/assets/files/sample-hu.csv'

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleKey="Bulk.Upload.Title"
        content={() => (
          <Spacer width={550}>
            <Spacer mx={40}>
              <Spacer mt={28} height={32} textAlign="center">
                <Text>
                  <FormattedMessage id="Bulk.Upload.Hint.Main.Text" />
                </Text>
                &nbsp;
                <DownloadLink href={sampleFile}>
                  <FormattedMessage id="Bulk.Upload.Hint.Link.Text" />
                </DownloadLink>
              </Spacer>

              <StyledSpacer mt={30} height={52}>
                <input
                  type="file"
                  id="file"
                  ref={ref => {
                    this.fileUploader = ref
                  }}
                  style={{ display: 'none' }}
                  onChange={event => this.handleOnFileChanged(event)}
                />
                <Button
                  width={256}
                  primary
                  id="upload-button"
                  onClick={() => {
                    this.fileUploader.click()
                  }}
                >
                  <FormattedMessage id="Bulk.Upload.Button.Upload.Csv.Text" />
                </Button>
              </StyledSpacer>

              <Spacer mt={40} mb={40} minHeight={424} position="releative">
                {parsing ? (
                  <LoadingImage
                    src="/assets/img/loading_indicator_blue.png"
                    alt="Loading..."
                  />
                ) : (
                  this.handleDisplayCsvResult(isLoading, onSubmit)
                )}
              </Spacer>
            </Spacer>
          </Spacer>
        )}
      />
    )
  }
}

BulkUploadUsers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  intl: IntlType.isRequired,
  activeLang: PropTypes.shape({ code: PropTypes.oneOf(['en', 'hu']) }),
}

export default BulkUploadUsers
