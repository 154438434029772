import styled from 'styled-components/macro'
import { colors } from '../../../../theme/freebees'

const getHolidayIndicatorBackgroundColor = ({ holidayStatus, holidayType }) => {
  if (holidayType === 'VACATION') {
    if (holidayStatus === 'PLANNED') {
      return colors.calendarPlannedColor
    }
    if (holidayStatus === 'APPROVED') {
      return colors.calendarApprovedColor
    }
    if (holidayStatus === 'REQUESTED') {
      return colors.calendarRequestedColor
    }
    if (holidayStatus === 'REJECTED') {
      return colors.redBright
    }
  }

  if (holidayType === 'SICK_LEAVE') {
    return colors.calendarSickLeaveColor
  }

  return colors.grayLight
}

const getCellBackgroundColor = ({ theme, isWorkingDay, isItToday, type }) => {
  if (type === 'employee') return theme.colors.white

  if (!isWorkingDay) {
    return colors.calendarWeekendBackgroundColor
  }
  if (isItToday) {
    return colors.calendarTodayBackgroundColor
  }

  return theme.colors.white
}

export const CalendarCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  height: ${({ type }) => {
    if (type === 'company') return '60px'
    if (type === 'employee') return '32px'

    return '60px'
  }};
  background-color: ${getCellBackgroundColor};
  border-left: 1px solid ${({ theme }) => theme.colors.calendarBorderColor};
  position: relative;
`

export const CalendarCellInner = styled.div`
  background-color: ${getHolidayIndicatorBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% + 1px);
  height: ${({ type }) => {
    if (type === 'company') return '20px'
    if (type === 'employee') return '32px'

    return '20px'
  }};
  color: white;
  cursor: pointer;
  border-top-left-radius: ${({ firstDay }) => (firstDay ? '4px' : 'none')};
  border-bottom-left-radius: ${({ firstDay }) => (firstDay ? '4px' : 'none')};
  border-top-right-radius: ${({ lastDay }) => (lastDay ? '4px' : 'none')};
  border-bottom-right-radius: ${({ lastDay }) => (lastDay ? '4px' : 'none')};

  position: absolute;
  left: -1px;

  .target {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
  }
`

export const EmployeeContainer = styled.div`
  padding-left: 20px;
  display: flex;
  height: ${({ header }) => (header ? '40px' : '60px')};
  align-items: center;
  min-width: 242px !important;
`

export const TooltipTrigger = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`
