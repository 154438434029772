import React from 'react'
import { FormattedMessage } from 'react-intl'

import EmbedDocument from '../../../../components/EmbedDocument'
import { usePageTracking } from '../../../../hooks/usePageTracking'
import { H1 } from '../../../../components/styles'
import { LegalContainer } from '../TermsAndConditions/TermsAndConditions'

const privacyPolicyUrl =
  'https://docs.google.com/document/d/e/2PACX-1vRqqt5XHCtM5dweL04dWdIw70G0EMvWYjrzqK4r0g6cIr6cQy4T19mqgj_IvecZskwMsB5IWBRbFeUC/pub?embedded=true'

export default function PrivacyPolicy() {
  usePageTracking('PrivacyPolicy')

  return (
    <LegalContainer>
      <H1>
        <FormattedMessage id="PrivacyPolicyPage.Title" />
      </H1>
      <EmbedDocument url={privacyPolicyUrl} title="Privacy Policy" />
    </LegalContainer>
  )
}
