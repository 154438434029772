import React, { PureComponent } from 'react'
import { string, shape, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import validation from '../../../common/services/validation'
import { FormWide, InputGroup, ButtonContainer } from '../styles'
import { ModalVerticalInputContainer } from '../../../elements'
import Button from '../../Button'
import { Spacer } from '../../styles/Spacer'
import Input from '../../Input'
import Checkbox from '../../Checkbox'
import { CenterAlignedSpacer, StyledErrorText, TextLink } from './styles'
import { colors } from '../../../theme/freebees'
import { TextV as Text } from '../../styles'
import { IntlType } from '../../../common/prop-types'

class UserRegistrationForm extends PureComponent {
  state = {
    validationErrors: {},
    termsError: false,
  }

  isValid = validation([
    [
      'email',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
      {
        rule: 'email',
        msg: this.props.intl.formatMessage({
          id: 'NotValidEmail',
        }),
      },
    ],
    [
      'password',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
    [
      'firstName',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
    [
      'lastName',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
  ])

  validation = () => {
    const { error, messages } = this.isValid(this.props.formData)
    this.setState({
      validationErrors: messages,
      termsError: !this.props.formData.termsAccepted,
    })
    return { valid: !error, validationErrors: messages }
  }

  handleInputChange = change => {
    this.props.onInputChange(change)
  }

  handleSubmit = e => {
    const { valid } = this.validation()

    e.preventDefault()

    this.setState(
      {
        termsError: !this.props.formData.termsAccepted,
      },
      () => {
        if (!this.state.termsError && valid) {
          this.props.onSubmit()
        }
      }
    )
  }

  render() {
    const { formData, pending } = this.props
    const { firstName, lastName, email, password, termsAccepted } = formData
    const { validationErrors, termsError } = this.state

    return (
      <Spacer>
        <Text textAlign="center" variant="modalTitle">
          <FormattedMessage id="ComanyReg.User.Title" />
          &nbsp;:
        </Text>
        <FormWide id="user-reg-form" onSubmit={this.handleSubmit}>
          <ModalVerticalInputContainer>
            <InputGroup>
              <Input
                autoFocus
                id="last-name"
                label="Input.LastName.Label"
                required
                value={lastName}
                placeholder="Input.LastName.Placeholder"
                onChange={event =>
                  this.handleInputChange({ lastName: event.target.value })
                }
                error={validationErrors.lastName}
              />
            </InputGroup>
            <InputGroup>
              <Input
                id="first-name"
                label="Input.FirstName.Label"
                required
                value={firstName}
                placeholder="Input.FirstName.Placeholder"
                onChange={event =>
                  this.handleInputChange({ firstName: event.target.value })
                }
                error={validationErrors.firstName}
              />
            </InputGroup>
          </ModalVerticalInputContainer>

          <ModalVerticalInputContainer>
            <InputGroup>
              <Input
                id="email"
                label="Input.Email.Label"
                required
                value={email}
                type="email"
                placeholder="Input.Email.Placeholder"
                onChange={event =>
                  this.handleInputChange({ email: event.target.value })
                }
                error={validationErrors.email}
              />
            </InputGroup>

            <InputGroup>
              <Input
                id="password"
                label="Input.Password.Label"
                required
                value={password}
                type="password"
                placeholder="Input.Password.Placeholder"
                tooltip="Input.Password.Placeholder.Tooltip"
                onChange={event =>
                  this.handleInputChange({ password: event.target.value })
                }
                error={validationErrors.password}
              />
            </InputGroup>
          </ModalVerticalInputContainer>

          <CenterAlignedSpacer mb={20}>
            <Spacer display="flex" alignItems="baseline">
              <Checkbox
                checked={termsAccepted}
                color={colors.blueAnother}
                label="Term.And.Condition.Checkbox.Label"
                onChange={() => {
                  this.handleInputChange({
                    termsAccepted: !termsAccepted,
                  })
                }}
              />
              <Spacer ml={-10}>
                <Link
                  to="/terms-and-conditions"
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <TextLink>
                    <FormattedMessage id="Faq.Footer.TOS" />
                  </TextLink>
                </Link>
              </Spacer>
            </Spacer>

            {termsError ? (
              <Spacer mt={20}>
                <StyledErrorText>
                  <FormattedMessage id="Term.And.Condition.Checkbox.Error" />
                </StyledErrorText>
              </Spacer>
            ) : null}
          </CenterAlignedSpacer>

          <ButtonContainer>
            <Button
              width={256}
              onClick={this.handleSubmit}
              type="submit"
              id="finish-button"
              loading={pending}
            >
              <FormattedMessage id="ComanyReg.Button.SignUp" />
            </Button>
          </ButtonContainer>
        </FormWide>
      </Spacer>
    )
  }
}

UserRegistrationForm.propTypes = {
  onInputChange: func.isRequired,
  onSubmit: func.isRequired,
  formData: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
    password: string.isRequired,
  }).isRequired,
  pending: bool.isRequired,
  intl: IntlType.isRequired,
}
export default UserRegistrationForm
