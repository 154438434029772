import styled from 'styled-components/macro'

import TooltipLite from 'react-tooltip-lite'

export const FreeBeesTooltip = styled(TooltipLite)`
  .react-tooltip-lite {
    background: rgba(17, 17, 17, 0.7);
    border-radius: 4px;
    color: white;
    font-size: 14px;
    left: -25px;
    line-height: 20px;
    margin-right: -20px;
    padding: 12px;
    position: relative;
    width: 210px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .react-tooltip-lite-arrow {
    border-color: rgba(17, 17, 17, 0.7);
  }

  .inner {
    display: flex;
    align-items: center;
  }
`
