import { useEffect, useRef } from 'react'

function useLatest(value) {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  })

  return ref
}

export function useClickOutside(handler) {
  const ref = useRef()
  const handlerRef = useLatest(handler)

  useEffect(() => {
    function listener(event) {
      if (
        ref.current &&
        handlerRef.current &&
        !ref.current.contains(event.target)
      ) {
        handlerRef.current(event)
      }
    }
    window.addEventListener('mousedown', listener)
    window.addEventListener('touchstart', listener)

    return () => {
      window.removeEventListener('mousedown', listener)
      window.removeEventListener('touchstart', listener)
    }
  }, [ref, handlerRef])

  return ref
}
