import React from 'react'
import { arrayOf, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  CenterAlignedSpacer,
  ErrorText,
  ErrorImageIndicator,
  ErrorLine,
} from './styles'
import { Spacer } from '../../../../../components/styles/Spacer'
import { ItemListRow, Text } from '../../../../../components/styles'

const getErrorLines = errorLines =>
  errorLines.map((line, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Spacer key={index.toString()}>
      <ItemListRow>
        <ErrorLine>{line}</ErrorLine>
      </ItemListRow>
    </Spacer>
  ))

const BulkUploadErrorDisplay = ({ errorLines }) => (
  <Spacer>
    <CenterAlignedSpacer height={30}>
      <Spacer display="flex" flexDirection="column" alignItems="center">
        <Spacer display="flex" alignItems="center">
          <ErrorImageIndicator
            src="/assets/img/error-toast.png"
            alt="indicator"
          />
          <ErrorText>
            <FormattedMessage id="Bulk.Upload.Error.Text" />
          </ErrorText>
        </Spacer>
        <Text>
          <FormattedMessage id="Bulk.Upload.Error.Text.Detail" />
        </Text>
      </Spacer>
    </CenterAlignedSpacer>
    <Spacer mt={40}>{getErrorLines(errorLines)}</Spacer>

    <Spacer mt={40}>
      <Text>
        <FormattedMessage id="Bulk.Upload.Error.Text.Extra" />
      </Text>
    </Spacer>
  </Spacer>
)

BulkUploadErrorDisplay.propTypes = {
  errorLines: arrayOf(string).isRequired,
}

export default BulkUploadErrorDisplay
