import React from 'react'
import { number } from 'prop-types'
import { Overlay } from './styles'
import Loading from '../Loading/Loader'

const LoadingOverlay = ({ networkStatus }) => {
  const visible = networkStatus === 1 || networkStatus === 2
  return (
    visible && (
      <Overlay visible={visible}>
        <Loading />
      </Overlay>
    )
  )
}

LoadingOverlay.propTypes = {
  networkStatus: number.isRequired,
}

export default LoadingOverlay
