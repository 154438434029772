import styled from 'styled-components/macro'

export const Container = styled.div`
  padding: 0px;
  margin: 0px;
`
export const Title = styled.div`
  display: inline-block;
  height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;
`

export const TitleIndicator = styled.div`
  align-self: center;
  margin-left: 20px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 22px;

  color: #ff9152;
`

export const TitleContent = styled.div``

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding-top: 50px;
  padding-bottom: 50px;
`

export const LeftComponentWrapper = styled.div`
  width: 518px;
`
export const RightComponentWrapper = styled.div`
  width: 582px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 52px;
  margin: 1px 0;
  width: 100%;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
`

export const Field = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #a6a6a6;
`

export const Value = styled.div`
  min-width: 200px;
  width: 63%;
  overflow-wrap: break-word;
  line-height: 27px;

  @media ${({ theme }) => theme.media.mobile} {
    width: initial;
  }
`

export const Padding = styled.div`
  width: 100%;
  height: 30px;
`

export const TitleButtonWrapper = styled.div``

export const HorizontalLine = styled.div`
  mix-blend-mode: normal;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`

export const TagSection = styled.div`
  margin-top: 30px;
`

export const ActionIcon = styled.img`
  width: 18.33px;
  height: 18.33px;
  margin-left: 20px;
  cursor: pointer;
`
