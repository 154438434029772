import React from 'react'
import Modal from 'react-modal'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'

import App from './components/App'
import client from './graphql'
import freebeesTheme from './theme/freebees'
import { GlobalStyle } from './globalStyles'
import ErrorBoundary from './components/ErrorBoundary'
import Authenticator from './components/Authenticator'
import i18nService from './common/services/i18n'
import { ReduxProvider } from './redux/redux-wrapper'

i18nService.init()
Modal.setAppElement('body')

const Root = () => (
  <ErrorBoundary>
    <ReduxProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ThemeProvider theme={freebeesTheme}>
            <>
              <Authenticator>
                <GlobalStyle />
                <App />
              </Authenticator>
            </>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ReduxProvider>
  </ErrorBoundary>
)

export default Root
