import React from 'react'
import { HolidayRequestType, FunctionType } from '../../../common/prop-types'
import { ButtonIcon } from '../../styles/Button'
import EntityControl from '../../EntityControl'
import { refetchQueries } from '../../../common/constants/refetchQueries'
import { events } from '../../../common/services/analytics'

const RequestDelete = ({ mutate, holidayRequest }) => {
  const { status, type } = holidayRequest
  const isRequest = status === 'REQUESTED'

  const title = isRequest
    ? 'DeleteRequestModal.Title.REQUEST'
    : `DeleteRequestModal.Title.${type}`

  return (
    <EntityControl
      mutate={mutate}
      entity={holidayRequest}
      question="DeleteRequestModal.Question"
      title={title}
      refetchQueries={refetchQueries}
      eventName={events.HolidayDelete}
      renderTrigger={({ onClick, isLoading }) => (
        <ButtonIcon onClick={onClick} isLoading={isLoading} narrow>
          <img src="/assets/icons/delete.svg" alt="delete" />
        </ButtonIcon>
      )}
    />
  )
}

RequestDelete.propTypes = {
  holidayRequest: HolidayRequestType.isRequired,
  mutate: FunctionType.isRequired,
}

RequestDelete.defaultProps = {}

export default RequestDelete
