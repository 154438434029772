import React, { PureComponent } from 'react'
import styled from 'styled-components/macro'
import { display } from 'styled-system'
import { shape, bool, func, instanceOf, number } from 'prop-types'
import { HolidayRequestListType } from '../../common/prop-types'
import RequestList from '../RequestList'
import EmptyListPlaceholder from '../EmptyListPlaceholder'
import notify from '../../common/services/notification/notification'
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay'

import { ReactComponent as EmptySickLeaves } from '../../common/images/img_on_holiday.svg'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${({ theme }) => theme.media.mobile} {
    display: block;
    overflow-x: scroll;
  }
`

const EmptyListViewPlaceholder = styled(EmptySickLeaves)`
  ${display};
`

const emptyTableLabels = {
  SICK_LEAVE: 'EmployeeProfile.SickLeaveRequestList.Empty.Text',
  VACATION: 'EmployeeProfile.VacationRequestList.Empty.Text',
  default: 'EmployeeProfile.Default.Empty.Text',
}

class RequestListModule extends PureComponent {
  state = {
    sortBy: {
      field: '',
      order: '',
    },
  }

  handleSortOrder = ({ field, order }) => {
    const { refetch } = this.props

    const variables = {
      ...this.props.variables,
      orders: [
        {
          orderBy: field,
          order,
        },
      ],
    }

    this.setState(
      {
        sortBy: { field, order },
      },
      async () => {
        try {
          await refetch(variables)
        } catch (error) {
          notify('error', 'Notification.Action.Failed')
        }
      }
    )
  }

  render() {
    const {
      data: { holidayRequests = [] },
      networkStatus,
      variables,
      ...props
    } = this.props

    const { sortBy } = this.state

    const holidayType =
      variables.filter.statusesByType && variables.filter.statusesByType.type
    const emptyTranslationTitle =
      emptyTableLabels[holidayType] || emptyTableLabels.default

    const showEmptyListIndicator = !holidayRequests.length
    return (
      <Container>
        <RequestList
          id="request-list"
          holidayRequests={holidayRequests}
          showStatus
          showHeader={holidayRequests.length > 0}
          onSortChange={o => this.handleSortOrder(o)}
          sortBy={sortBy}
          {...props}
        />
        {showEmptyListIndicator && (
          <EmptyListPlaceholder
            textId={emptyTranslationTitle}
            svg={EmptyListViewPlaceholder}
          />
        )}
        <LoadingOverlay networkStatus={networkStatus} />
      </Container>
    )
  }
}

RequestListModule.propTypes = {
  data: shape({
    holidayRequests: HolidayRequestListType,
  }),
  showEmployee: bool,
  refetch: func,
  networkStatus: number,
  variables: instanceOf(Object),
}

RequestListModule.defaultProps = {
  data: {
    holidayRequests: [],
  },
}

export default RequestListModule
