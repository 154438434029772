import gql from 'graphql-tag'

const UPDATE_CHILD = gql`
  mutation updateChild($input: UpdateChildInput!) {
    updateChild(input: $input) {
      id
    }
  }
`
export default UPDATE_CHILD
