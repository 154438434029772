import React, { Component } from 'react'

import { shape, node } from 'prop-types'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <>{this.props.children}</>
  }
}

ScrollToTop.propTypes = {
  location: shape({}),
  children: node,
}

export default ScrollToTop
