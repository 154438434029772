import styled from 'styled-components/macro'

export const HorizontalLine = styled.div`
  mix-blend-mode: normal;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`
export const Wrapper = styled.div`
  padding-top: 0;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
`

export const Section = styled.div``
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SearchInput = styled.input`
  margin-top: 39px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 52px;
`
