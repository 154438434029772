import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'validator/lib/isEmpty'
import { FormattedMessage } from 'react-intl'
import { ChildType } from '../../../common/prop-types'
import { ButtonGroup, ButtonText } from '../../styles/Button'
import { CheckboxSection } from './styles'
import Button from '../../Button'
import Checkbox from '../../Checkbox'
import Input from '../../Input'
import { StyledInput, InputLabel } from '../../Input/styles'
import { Spacer } from '../../styles/Spacer'
import { getChildAgeSelectionRules } from '../../../common/services/dateSelectionRules'
import { colors } from '../../../theme/freebees'
import Modal from '../../Modal'
import { ActionIcon } from '../../../modules/profile/components/EmployeeProfile/EmployeePersonalInfoTab/EmployeeChildInfo/styles'

class ChildModal extends Component {
  state = {
    preloaded: false,
    child: this.props.data.child,
  }

  isChildDataValid = child =>
    !isEmpty(child.firstName.trim()) && !isEmpty(child.birthDate.toString())

  handleClose = onClose => {
    this.setState({
      preloaded: false,
      child: {
        id: '-1',
        firstName: '',
        birthDate: new Date(),
        sex: '',
        disability: false,
      },
    })
    onClose()
  }

  handleSubmit = (editMode, onSubmit) => {
    if (this.isChildDataValid(this.state.child)) {
      onSubmit({ edit: editMode, childData: this.state.child })
    }
  }

  handleDelete = child => {
    this.props.onDelete(child)
  }

  preloadData = data => {
    if (!this.state.preloaded && data.edit) {
      this.setState({
        preloaded: true,
        child: {
          id: data.child.id,
          firstName: data.child.firstName,
          birthDate: data.child.birthDate,
          sex: data.child.sex,
          disability: data.child.disability,
        },
      })
    }
  }

  render() {
    const { isOpen, onClose, onSubmit, parent, data, loading } = this.props
    const { child } = this.state

    this.preloadData(data)

    const rules = getChildAgeSelectionRules()

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleKey={data.edit ? 'ChildModal.Edit.Title' : 'ChildModal.Title'}
        content={() => (
          <Spacer>
            <Input
              label="ChildModal.Name"
              id="name-input"
              placeholder="ChildModal.Name.Placeholder"
              value={child.firstName}
              onChange={event =>
                this.setState({
                  child: {
                    ...child,
                    firstName: event.target.value,
                  },
                })
              }
            />
            <Input
              id="birth-date"
              type="date"
              dateFormatCalendar="MMMM"
              label="ChildModal.DateOfBirth"
              showYearDropdown
              scrollableYearDropdown
              maxDate={rules.maxDate}
              minDate={rules.minDate}
              selected={child.birthDate}
              yearDropdownItemNumber={100}
              onChange={day => {
                this.setState({
                  child: {
                    ...child,
                    birthDate: day,
                  },
                })
              }}
            />

            <InputLabel>
              <FormattedMessage id="ChildModal.Parent" />
            </InputLabel>
            <StyledInput disabled placeholder={parent} />

            <CheckboxSection>
              <Checkbox
                label="ChildModal.DisabilityText"
                checked={child.disability}
                color={colors.blueAnother}
                onChange={() =>
                  this.setState(prevValue => ({
                    child: {
                      ...child,
                      disability: !prevValue.child.disability,
                    },
                  }))
                }
              />
            </CheckboxSection>

            <ButtonGroup>
              <Button
                primary
                bordered
                narrow
                id="close-modal"
                type="button"
                onClick={() => this.handleClose(onClose)}
              >
                <FormattedMessage id="Button.Cancel" />
              </Button>
              <Button
                primary
                narrow
                disabled={!this.isChildDataValid(child)}
                onClick={() => this.handleSubmit(data.edit, onSubmit)}
                id="submit-button"
                type="button"
                loading={loading}
              >
                {data.edit ? (
                  <FormattedMessage id="Button.Update" />
                ) : (
                  <FormattedMessage id="Button.Add" />
                )}
              </Button>
            </ButtonGroup>
            {data.edit && (
              <Spacer textAlign="center" mt={10}>
                <ButtonText
                  onClick={() => this.handleDelete(child)}
                  disabled={loading}
                  color="red"
                  mx="auto"
                >
                  <ActionIcon
                    src="/assets/icons/user-deactivate-red.svg"
                    alt="remove"
                  />
                  <FormattedMessage id="Button.RemoveChild" />
                </ButtonText>
              </Spacer>
            )}
          </Spacer>
        )}
      />
    )
  }
}

ChildModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  parent: PropTypes.string,
  data: PropTypes.shape({
    edit: PropTypes.bool,
    child: ChildType,
  }),
  loading: PropTypes.bool,
}

ChildModal.defaultProps = {
  parent: '',
  data: {
    edit: false,
    child: {
      id: '-1',
      firstName: '',
      birthDate: new Date(),
      sex: '',
      disability: false,
    },
  },
}

export default ChildModal
