import { createSelector } from 'reselect'

const selectAuth = state => state.auth
const getFullUserName = user => `${user.lastName} ${user.firstName}`

export const selectCurrentUserData = createSelector(
  [selectAuth],
  auth => auth.data
)

export const selectIsCurrentUserDataLoaded = createSelector(
  [selectAuth],
  auth => !!auth.data
)

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  auth => auth.isAuthenticated
)

export const selectIsRegistrationInProgress = createSelector(
  [selectAuth],
  auth => auth.isRegistrationInProgress
)

export const selectIsTutorialShown = createSelector([selectAuth], auth =>
  Boolean(auth.isTutorialShown)
)

export const selectIsAdmin = createSelector(
  [selectAuth],
  auth => auth.data && auth.data.isAdmin
)

export const selectCurrentUserName = createSelector([selectAuth], auth =>
  auth.data ? getFullUserName(auth.data) : ''
)

export const selectCurrentUserProfileImage = createSelector(
  [selectAuth],
  auth => (auth.data ? auth.data.avatarURL : '')
)

export const selectCurrentUserId = createSelector([selectAuth], auth =>
  auth.data ? auth.data.id : null
)

export const selectCurrentUserLocale = createSelector([selectAuth], auth =>
  auth.data ? auth.data.selectedLang : null
)
