import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonGroup } from '../../styles/Button'
import { TextArea } from '../../styles/TextArea'
import { Padding } from '../../styles/Padding'
import Button from '../../Button'
import Modal from '../../Modal'
import { Spacer } from '../../styles/Spacer'

const ConfirmActionModal = ({
  cancelButtonText,
  hasComment,
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  question,
  subject,
  submitButtonText,
  title,
}) => {
  const [message, setMessage] = useState('')

  const handleSubmit = () => {
    onSubmit({ message, subject })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      titleKey={title}
      content={() => (
        <Spacer textAlign="center" w={520}>
          <Padding v>
            <FormattedMessage id={question} />
          </Padding>

          {hasComment && (
            <Padding v>
              <TextArea
                value={message}
                onChange={event => setMessage(event.target.value)}
              />
            </Padding>
          )}

          <ButtonGroup>
            <Button
              narrow
              bordered
              reversed
              id="close-modal"
              type="button"
              onClick={onClose}
              primary
            >
              <FormattedMessage id={cancelButtonText} />
            </Button>
            <Button
              narrow
              primary
              loading={isLoading}
              onClick={handleSubmit}
              id="submit-button"
              type="button"
            >
              <FormattedMessage id={submitButtonText} />
            </Button>
          </ButtonGroup>
        </Spacer>
      )}
    />
  )
}

ConfirmActionModal.propTypes = {
  isOpen: PropTypes.bool,
  hasComment: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  question: PropTypes.string,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  subject: PropTypes.instanceOf(Object),
}

ConfirmActionModal.defaultProps = {
  isOpen: false,
  hasComment: false,
  submitButtonText: 'Button.Yes',
  cancelButtonText: 'Button.No',
}

export default ConfirmActionModal
