import React from 'react'
import PropTypes from 'prop-types'
import { Container, UserNameCell, RoleCell, ActionCell } from './style'
import UserName from '../../../../../../components/UserName'
import { Spacer } from '../../../../../../components/styles/Spacer'
import EmployeeControl from '../../../../../../components/EmployeeControl'
import EmployeeRoleEditor from '../../../../../../components/EmployeeControl/EmployeeRoleEditor'

export const EmployeeTableRow = ({ employee }) => (
  <Container>
    <UserNameCell>
      <Spacer width={275}>
        <UserName
          employee={employee}
          additionalInfo={employee.jobTitle ? employee.jobTitle.name : ''}
        />
      </Spacer>
    </UserNameCell>

    <RoleCell>
      <EmployeeRoleEditor employee={employee} hiddenWhenLoading />
    </RoleCell>

    <ActionCell>
      <EmployeeControl employee={employee} />
    </ActionCell>
  </Container>
)

EmployeeTableRow.propTypes = {
  employee: PropTypes.instanceOf(Object).isRequired,
}

export default EmployeeTableRow
