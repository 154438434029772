import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  UserNameAdminContainer,
  UserNameContainer,
  ProfileImageContainer,
  UserInfoContainer,
  DisplayDataContainer,
} from './styles'
import { EmployeeType, IntlType } from '../../common/prop-types'
import { Text } from '../styles/Text'
import ProfileImage from '../ProfileImage'
import { cutText } from '../../common/utils/text'
import { selectIsAdmin } from '../../redux/auth/auth.selector'
import Tooltip from '../Tooltip'

const injectUsernameData = (isAdmin, suppressRedirection, employee, innerTag) =>
  isAdmin ? (
    <UserNameAdminContainer
      disableclick={suppressRedirection.toString()}
      to={`/profile/${employee.id}`}
      onClick={e => {
        if (suppressRedirection) e.preventDefault()
      }}
    >
      {innerTag}
    </UserNameAdminContainer>
  ) : (
    <UserNameContainer>{innerTag}</UserNameContainer>
  )

export const UserName = ({
  employee,
  additionalInfo,
  suppressRedirection,
  textLength,
  nameColor,
  intl,
}) => {
  const isAdmin = useSelector(selectIsAdmin)
  const name = intl.formatMessage(
    { id: 'Employee.Name' },
    { lastName: employee.lastName, firstName: employee.firstName }
  )
  const displayData = (
    <DisplayDataContainer minWidth={[100, 222]}>
      <ProfileImageContainer>
        <ProfileImage src={employee.avatarURL} />
      </ProfileImageContainer>

      <UserInfoContainer>
        {name.length > textLength ? (
          <Tooltip content={name} hoverDelay={500}>
            <Text fontSize={16} color={nameColor}>
              {cutText(name, textLength)}
            </Text>
          </Tooltip>
        ) : (
          <Text fontSize={16} color={nameColor}>
            {cutText(name, textLength)}
          </Text>
        )}

        {additionalInfo && (
          <Text fontSize={14} color="grayLight" title={additionalInfo}>
            {cutText(additionalInfo, 25)}
          </Text>
        )}
      </UserInfoContainer>
    </DisplayDataContainer>
  )
  return injectUsernameData(isAdmin, suppressRedirection, employee, displayData)
}

UserName.propTypes = {
  employee: EmployeeType.isRequired,
  additionalInfo: PropTypes.string,
  suppressRedirection: PropTypes.bool,
  nameColor: PropTypes.string,
  intl: IntlType.isRequired,
}

UserName.defaultProps = {
  suppressRedirection: false,
  textLength: 19,
}

export default UserName
