import { createSelector } from 'reselect'

const selectLanguageSelector = state => state.languageSelector

export const selectActiveLanguage = createSelector(
  [selectLanguageSelector],
  lang => lang.locales.find(locale => locale.code === lang.selectedLocale)
)

export const selectLocales = createSelector(
  [selectLanguageSelector],
  lang => lang.locales
)
