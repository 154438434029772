import gql from 'graphql-tag'

const REJECT_HOLIDAY_REQUEST = gql`
  mutation rejectHolidayRequest($input: RejectHolidayRequestInput!) {
    rejectHolidayRequest(input: $input) {
      id
    }
  }
`

export default REJECT_HOLIDAY_REQUEST
