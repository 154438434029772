import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: 'visible';
`
export const Image = styled.img`
  float: right;
  height: 12px;
  width: 12px;
  padding-left: 0;
`
