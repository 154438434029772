import { compose } from 'redux'

import { graphql } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'

import AddEmployeeModal from './AddEmployeeModal'
import INVITE_EMPLOYEE from '../../../../graphql/mutations/inviteEmployee'
import INVITE_MULTIPLE_EMPLOYEES from '../../../../graphql/mutations/inviteMultipleEmployees'
import { QueryCurrentCompany } from '../../../../graphql/queries/currentCompany'

export default compose(
  graphql(INVITE_EMPLOYEE, { name: 'InviteEmployee' }),
  graphql(INVITE_MULTIPLE_EMPLOYEES, { name: 'InviteMultipleEmployees' }),
  graphql(QueryCurrentCompany, { name: 'CurrentCompany' }),
  injectIntl
)(AddEmployeeModal)
