import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'

import EmployeeNameEditor from './EmployeeNameEditor'
import UPDATE_EMPLOYEE from '../../../../graphql/mutations/updateEmployee'

export default compose(
  injectIntl,
  graphql(UPDATE_EMPLOYEE, { name: 'UpdateEmployee' })
)(EmployeeNameEditor)
