import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: space-between;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`

export const Col = styled.div`
  flex: 1;
`

export const Padding = styled.div`
  flex: 0 1 15px;
`
