import React, { Component } from 'react'
import { startOfYear, addMonths } from 'date-fns'
import Month from './Month'

import { Calendar } from './style'
import { DateType, HolidayRequestListType } from '../../../../common/prop-types'

export class EmployeeCalendar extends Component {
  getMonthRange = date => {
    const result = startOfYear(date)
    return Array(12)
      .fill(null)
      .map((val, i) => addMonths(result, i))
  }

  render() {
    const { date } = this.props

    const months = this.getMonthRange(date)
    return (
      <Calendar>
        {months.map(month => (
          <Month
            key={`month-${month}`}
            date={month}
            holidayRequests={this.props.holidayRequests}
          />
        ))}
      </Calendar>
    )
  }
}

EmployeeCalendar.propTypes = {
  date: DateType.isRequired,
  holidayRequests: HolidayRequestListType.isRequired,
}

export default EmployeeCalendar
