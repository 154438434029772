import React from 'react'
import PropTypes from 'prop-types'

import { injectIntl } from 'react-intl'
import { Week } from './Week'

import {
  WeekContainer,
  MonthHeader,
  MonthContainer,
  WeekHeaderCell,
} from './style'
import { daysOfWeek, weeksOfMonth } from '../../../../common/utils/dateUtils'
import { HolidayRequestListType, IntlType } from '../../../../common/prop-types'

const renderHeader = (date, intl) => (
  <MonthHeader key="header">
    {intl.formatDate(date, {
      month: 'long',
    })}
  </MonthHeader>
)

const renderWeekHeader = (date, intl) => (
  <WeekContainer>
    {daysOfWeek(date).map(weekday => (
      <WeekHeaderCell key={`weekday-header-${weekday.toUTCString()}`}>
        {intl.formatDate(weekday, {
          weekday: 'short',
        })}
      </WeekHeaderCell>
    ))}
  </WeekContainer>
)

const Month = ({ date, holidayRequests, intl }) => {
  const weeks = weeksOfMonth(date)
  return (
    <MonthContainer>
      {renderHeader(date, intl)}
      {renderWeekHeader(date, intl)}
      {weeks.map(week => (
        <Week
          key={`week-${week.toUTCString()}`}
          date={week}
          month={date}
          holidayRequests={holidayRequests}
        />
      ))}
    </MonthContainer>
  )
}

Month.propTypes = {
  date: PropTypes.instanceOf(Object),
  holidayRequests: HolidayRequestListType.isRequired,
  intl: IntlType.isRequired,
}

export default injectIntl(Month)
