import React from 'react'
import { func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Tabs, Tab } from './styles'
import { TabListType, TabType } from '../../../../common/prop-types'

const TabMenu = ({ tabs, active, onChange }) => (
  <Tabs>
    {tabs.map(tab => (
      <Tab
        key={tab.id}
        id={tab.id}
        active={active.id === tab.id}
        onClick={() => onChange(tab)}
      >
        <FormattedMessage id={tab.title} />
      </Tab>
    ))}
  </Tabs>
)

TabMenu.propTypes = {
  tabs: TabListType.isRequired,
  active: TabType.isRequired,
  onChange: func.isRequired,
}

export default TabMenu
