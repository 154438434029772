import React from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components/macro'

import ProfileImage from '../../../../components/ProfileImage'
import { Spacer } from '../../../../components/styles/Spacer'
import { cutText } from '../../../../common/utils/text'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  padding: 0 20px;
  background-color: #ededed;
  align-items: center;
  margin: 5px;
  border-radius: 4px;
  cursor: default;
`

const RemoveIcon = styled.img`
  cursor: pointer;
`

const EmployeeTag = ({ avatarURL, showImage, name, onRemove, id }) => (
  <Container>
    {showImage && (
      <Spacer mr={14}>
        <ProfileImage src={avatarURL} />
      </Spacer>
    )}
    {cutText(name, 22)}
    <Spacer ml={14}>
      <RemoveIcon
        src="assets/img/closeIcon.svg"
        onClick={() => onRemove({ id })}
      />
    </Spacer>
  </Container>
)

EmployeeTag.propTypes = {
  avatarURL: string,
  name: string.isRequired,
  id: string,
  onRemove: func.isRequired,
  showImage: bool,
}

export default EmployeeTag
