import * as googleAnalytics from './google-analytics'
import * as sentry from './sentry'

export function init() {
  googleAnalytics.init()
  sentry.init()
}

export function setCurrentUser(user) {
  sentry.configureScope({
    id: user.id,
    email: user.email,
    username: `${user.firstName} ${user.lastName}`,
  })
}

export function trackEvent(eventName) {
  googleAnalytics.trackEvent({ category: 'Interaction', action: eventName })
}

export function trackPageView() {
  const fullPath = window.location.pathname + window.location.search

  googleAnalytics.trackPageView(fullPath)
}

export function trackHolidayCreation() {
  trackEvent(events.HolidayCreate)
}

export function trackHolidayUpdate() {
  trackEvent(events.HolidayUpdate)
}

export function trackReportGeneration() {
  trackEvent(events.ReportGenerate)
}

export function trackLogin() {
  trackEvent(events.Login)
}

export function trackLogout() {
  trackEvent(events.Logout)
}

export function trackRegistration() {
  trackEvent(events.Registration)
}

export const events = {
  PageView: 'PageView',
  HolidayUpdate: 'HolidayUpdate',
  HolidayCreate: 'HolidayCreate',
  HolidayApprove: 'HolidayApprove',
  HolidayDelete: 'HolidayDelete',
  HolidayReject: 'HolidayReject',
  HolidayConvert: 'HolidayConvert',
  ReportGenerate: 'ReportGenerate',
  Login: 'Login',
  Logout: 'Logout',
  Registration: 'Registration',
}
