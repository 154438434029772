import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { createStructuredSelector } from 'reselect'
import { CompanyEditableField } from './CompanyEditableField'

import UPDATE_COMPANY from '../../../../graphql/mutations/updateCompany'
import { selectCompany } from '../../../../redux/company/company.selector'

const mapStateToProps = createStructuredSelector({
  company: selectCompany,
})

export default compose(
  connect(mapStateToProps, null),
  graphql(UPDATE_COMPANY, { name: 'UpdateCompany' })
)(CompanyEditableField)
