import React from 'react'
import { arrayOf, shape, string, func, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Tag from '../Tag'

const TagList = ({
  items,
  emptyListText,
  onDelete,
  onSelectionChanged,
  canDelete,
}) => {
  if (!items.length) {
    return emptyListText ? <FormattedMessage id={emptyListText} /> : null
  }

  return items.map(item => (
    <Tag
      key={item.id}
      data={item}
      onDelete={onDelete}
      onSelectionChanged={onSelectionChanged}
      canDelete={canDelete}
    />
  ))
}

TagList.propTypes = {
  items: arrayOf(
    shape({
      name: string.isRequired,
      id: string.isRequired,
    })
  ),
  onDelete: func,
  canDelete: bool,
  emptyListText: string,
  onSelectionChanged: func,
}

export default TagList
