import { createSelector } from 'reselect'

export const selectCompany = state => state.company.data
export const selectCompanyName = createSelector([selectCompany], company =>
  company ? company.name : null
)
export const selectCompanyEmployees = createSelector([selectCompany], company =>
  company ? company.employees : null
)

export const selectActiveEmployees = createSelector([selectCompany], company =>
  company ? company.activeEmployees : null
)

export const selectAreEmployeesAdded = createSelector(
  [selectCompany],
  company => (company ? company.employees.length > 1 : false)
)

export const selectCompanyRoles = createSelector([selectCompany], company =>
  company ? company.roles : []
)
