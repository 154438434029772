import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DropdownList from './DropdownList'
import {
  selectCurrentUserName,
  selectCurrentUserProfileImage,
} from '../../redux/auth/auth.selector'

const mapStateToProps = createStructuredSelector({
  name: selectCurrentUserName,
  profileImage: selectCurrentUserProfileImage,
})

export default connect(mapStateToProps, null)(DropdownList)
