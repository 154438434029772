import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Container = styled.div`
  text-align: center;
  padding: 20px;
  height: 100%;
`

export default function EmbedDocument({ url, title }) {
  return (
    <Container>
      <iframe
        title={title}
        style={{
          height: '100%',
          border: 'none',
          width: '100%',
          maxWidth: '900px',
        }}
        src={url}
      />
    </Container>
  )
}

EmbedDocument.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
