import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { subMonths, startOfMonth, endOfMonth } from 'date-fns'
import { DateType } from '../../../../common/prop-types'
import { Spacer } from '../../../../components/styles/Spacer'
import ButtonSelector from '../../../../components/ButtonSelector'
import Input from '../../../../components/Input'

const getLastMonthDateRange = () => {
  const todayInLastLonth = subMonths(Date.now(), 1)
  return {
    startDate: startOfMonth(todayInLastLonth),
    endDate: endOfMonth(todayInLastLonth),
  }
}

const getThisMonthDateRange = () => ({
  startDate: startOfMonth(Date.now()),
  endDate: endOfMonth(Date.now()),
})

const getDateRangeByPeriod = ({ periodIndicator, startDate, endDate }) => {
  switch (periodIndicator) {
    case 'lastMonth':
      return getLastMonthDateRange()
    case 'thisMonth':
      return getThisMonthDateRange()
    default:
      return { startDate, endDate }
  }
}

const handleDateChange = (startDate, endDate, onSelectionChanged) => {
  onSelectionChanged({
    periodIndicator: 'customPeriod',
    startDate,
    endDate,
  })
}

const handlePeriodChange = (data, periodIndicator, onSelectionChanged) => {
  const dateRange = getDateRangeByPeriod({
    ...data,
    periodIndicator,
  })

  return onSelectionChanged({
    periodIndicator,
    ...dateRange,
  })
}

const ReportPeriodSelector = ({ data, onSelectionChanged }) => {
  const displayDate = getDateRangeByPeriod(data)

  useEffect(() => {
    // handlePeriodChange(data, data.periodIndicator, onSelectionChanged)
  })

  return (
    <>
      <Spacer pb={30}>
        <ButtonSelector
          id="period-type-selector"
          options={[
            {
              value: 'Report.DateSelector.LastMonth',
              key: 'lastMonth',
            },
            { key: 'thisMonth', value: 'Report.DateSelector.ThisMonth' },
            { key: 'customPeriod', value: 'Report.DateSelector.CustomPeriod' },
          ]}
          value={data.periodIndicator}
          width="150px"
          onChange={value => {
            handlePeriodChange(data, value, onSelectionChanged)
          }}
        />
      </Spacer>
      <Spacer>
        <Input
          type="date-range"
          id="period-date-range"
          value={{
            startDate: displayDate.startDate,
            endDate: displayDate.endDate,
          }}
          onChange={({ startDate, endDate }) => {
            handleDateChange(startDate, endDate, onSelectionChanged)
          }}
        />
      </Spacer>
    </>
  )
}

ReportPeriodSelector.propTypes = {
  data: PropTypes.shape({
    periodIndicator: PropTypes.oneOf(['lastMonth', 'thisMonth', 'customPeriod'])
      .isRequired,
    startDate: PropTypes.oneOfType([DateType, PropTypes.string]),
    endDate: PropTypes.oneOfType([DateType, PropTypes.string]),
  }),
  onSelectionChanged: PropTypes.func.isRequired,
}

export default ReportPeriodSelector
