import React from 'react'
import { shape, string } from 'prop-types'

import ResetPassword from '../../components/ResetPassword'
import { usePageTracking } from '../../../../hooks/usePageTracking'
import { Spacer } from '../../../../components/styles/Spacer'

const ResetPasswordPage = ({ match }) => {
  const {
    params: { code, email },
  } = match

  usePageTracking('ResetPassword')

  return (
    <Spacer my={100}>
      <ResetPassword code={code} email={email} />
    </Spacer>
  )
}
ResetPasswordPage.propTypes = {
  match: shape({ params: shape({ code: string, email: string }) }).isRequired,
}

export default ResetPasswordPage
