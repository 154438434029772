import styled from 'styled-components/macro'
import { Text } from '../../../../components/styles/Text'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  @media ${({ theme }) => theme.media.mobile} {
    flex: 0.5;
  }
`

export const CounterNumber = styled(Text)`
  padding-right: 15px;
  padding-left: 26px;
  color: black;
  font-size: 36px;

  @media ${({ theme }) => theme.media.mobile} {
    padding-left: initial;
  }
`

export const CounterTitle = styled(Text)`
  width: 90px;
  line-height: 16px;
  color: gray;
`
