export const refetchQueries = [
  'pendingHolidayRequests',
  'pendingSickLeaves',
  'companyCalendar',
  'currentCompany',
  'employeeCalendar',
  'employeeHolidayList',
  'currentEmployee',
  'activeEmployees',
  'inactiveEmployees',
  'employeeProfile',
  'activeHolidays',
  'employeesForReport',
]
