import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { withRouter } from 'react-router-dom'
import UserDataLoader from './UserDataLoader'

import { QueryCurrentEmployee } from '../../graphql/queries/currentEmployee'
import QueryNationalHolidays from '../../graphql/queries/nationalHolidays'
import { QueryCompensationDays } from '../../graphql/queries/compensationDays'
import { QueryCurrentCompany } from '../../graphql/queries/currentCompany'
import { setCompanyData } from '../../redux/company/company.reducer'
import { setUserData } from '../../redux/auth/auth.reducer'

const mapDispatchToProps = {
  setCompanyData,
  setUserData,
}

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  graphql(QueryCurrentEmployee, {
    name: 'employeeQuery',
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(QueryNationalHolidays, {
    name: 'nationalHolidaysQuery',
  }),
  graphql(QueryCompensationDays, {
    name: 'compensationDaysQuery',
  }),
  graphql(QueryCurrentCompany, {
    name: 'currentCompanyQuery',
  })
)(UserDataLoader)
