import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PrivateRouteComponent from './PrivateRoute'

import CompanyPage from './modules/company/pages/Company'

import DashboardPage from './modules/dashboard/pages'

import CalendarPage from './modules/calendar/pages'

import Login from './modules/auth/pages/Login'
import ChangePassword from './modules/auth/pages/ChangePassword'
import ResetPasswordPage from './modules/auth/pages/ResetPassword'

import ProfilePage from './modules/profile/pages'

import ReportPage from './modules/report/pages'

import Landing from './modules/public/pages/Landing'
import TermsAndConditions from './modules/public/pages/TermsAndConditions'
import PricingPage from './modules/public/pages/Pricing'
import PrivacyPolicy from './modules/public/pages/PrivacyPolicy'

import useRedirectTo from './hooks/useRedirectTo'

const loginRoute = <Route path="/login" name="Login" component={Login} exact />

const changePasswordRoute = (
  <Route
    path="/change-password/:email/:code"
    name="ChangePassword"
    exact
    component={ChangePassword}
  />
)

const PricingRoute = (
  <Route path="/pricing" name="Pricing" exact component={PricingPage} />
)

const resetPassword = (
  <Route
    path="/reset-password/:email/:code"
    name="ResetPassword"
    component={ResetPasswordPage}
  />
)
const faqRoute = (
  <Route
    path="/faq"
    name="Faq"
    component={() => {
      useRedirectTo('https://t.ly/6ADbl')
      return null
    }}
    exact
  />
)

const productDemoRoute = (
  <Route
    path="/product-demo"
    name="Faq"
    component={() => {
      useRedirectTo(
        'https://calendly.com/levente-varga-dina/freebees-product-demo'
      )
      return null
    }}
    exact
  />
)

const termsAndConditionsRoute = (
  <Route
    path="/terms-and-conditions"
    name="TermsAndConditions"
    component={TermsAndConditions}
    exact
  />
)

const PrivacyPolicyRoute = (
  <Route
    path="/privacy-policy"
    name="PrivacyPolicy"
    component={PrivacyPolicy}
    exact
  />
)

const ladingPageRoute = (
  <Route path="/" name="LandingPage" component={Landing} exact />
)

const employeeProfileRoute = (
  <PrivateRouteComponent
    path="/profile/:id"
    name="EmployeeProfile"
    component={ProfilePage}
  />
)

const myProfileRoute = (
  <PrivateRouteComponent path="/me" name="MyProfile" component={ProfilePage} />
)

const calendarRoute = (
  <PrivateRouteComponent
    path="/calendar"
    name="Calendar"
    component={CalendarPage}
  />
)

const reportRoute = (
  <PrivateRouteComponent
    onlyAdmin
    path="/report"
    name="Report"
    component={ReportPage}
  />
)

const dashboardRoute = (
  <PrivateRouteComponent
    onlyAdmin
    path="/dashboard"
    name="Dashboard"
    component={DashboardPage}
  />
)

const companyRoute = (
  <PrivateRouteComponent
    onlyAdmin
    path="/company"
    name="Company"
    component={CompanyPage}
  />
)

export const PublicRoutes = () => (
  <Switch>
    {loginRoute}
    {changePasswordRoute}
    {resetPassword}
    {faqRoute}
    {termsAndConditionsRoute}
    {ladingPageRoute}
    {PricingRoute}
    {PrivacyPolicyRoute}

    <Redirect
      to={{
        pathname: '/',
      }}
    />
  </Switch>
)

export const EmployeeRoutes = () => (
  <Switch>
    {myProfileRoute}
    {calendarRoute}
    {faqRoute}
    {termsAndConditionsRoute}
    {PrivacyPolicyRoute}

    <Redirect
      to={{
        pathname: '/calendar',
      }}
    />
  </Switch>
)

export const AdminRoutes = () => (
  <Switch>
    {myProfileRoute}
    {employeeProfileRoute}
    {companyRoute}
    {calendarRoute}
    {reportRoute}
    {dashboardRoute}
    {faqRoute}
    {productDemoRoute}
    {termsAndConditionsRoute}
    {PrivacyPolicyRoute}

    <Redirect
      to={{
        pathname: '/dashboard',
      }}
    />
  </Switch>
)
