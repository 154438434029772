import styled from 'styled-components/macro'

const color = ({ theme, isWorkingDay, isToday }) => {
  if (!isWorkingDay) return theme.colors.orangeLight
  if (isToday) return theme.colors.grayVeryDark

  return theme.colors.grayVeryDark
}

const opacity = ({ isDifferentMonth }) => {
  if (isDifferentMonth) return 0.25

  return 1
}

const backgroundColor = ({ theme, isToday }) => {
  if (isToday) return theme.colors.grayVeryLight
  return theme.colors.white
}

export const Calendar = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.media.mobile} {
    justify-content: center;
  }
`

export const MonthHeader = styled.header`
  text-align: center;
  size: 22px;
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
`
export const MonthContainer = styled.div`
  padding: 20px;
`

export const WeekContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`

export const Container = styled.div`
  display: flex;
`

export const WeekDay = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin: 5px 0;
  color: ${color};
  background-color: ${backgroundColor};
  cursor: default;
  border-radius: 50%;
  opacity: ${opacity};
`

export const WeekHeaderCell = styled.div`
  width: 32px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.gray};
  background-color: ${backgroundColor};
  cursor: default;
  text-transform: uppercase;
`
