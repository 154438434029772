import styled from 'styled-components/macro'

const getSize = ({ theme, sm, lg, xlg }) => {
  if (sm) return theme.paddings.small
  if (lg) return theme.paddings.large
  if (xlg) return theme.paddings.xlarge
  return theme.paddings.default
}
const paddingTop = ({ v, t, sm, lg, xlg, theme }) =>
  v || t ? getSize({ theme, sm, lg, xlg }) : 0
const paddingBottom = ({ v, b, sm, lg, xlg, theme }) =>
  v || b ? getSize({ theme, sm, lg, xlg }) : 0
const paddingLeft = ({ h, l, sm, lg, xlg, theme }) =>
  h || l ? getSize({ theme, sm, lg, xlg }) : 0
const paddingRight = ({ h, r, sm, lg, xlg, theme }) =>
  h || r ? getSize({ theme, sm, lg, xlg }) : 0

export const Padding = styled.div`
  padding-top: ${paddingTop};
  padding-bottom: ${paddingBottom};
  padding-left: ${paddingLeft};
  padding-right: ${paddingRight};
`
