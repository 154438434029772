import gql from 'graphql-tag'

export const QueryCompensationDays = gql`
  query compensationDays {
    compensationDays {
      id
      date
    }
  }
`
