import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'

import EmployeeTeamMembership from './EmployeeTeamMembership'

import { QueryCurrentCompany } from '../../../../../../graphql/queries/currentCompany'
import ASSIGN_TEAMS from '../../../../../../graphql/mutations/assignTeams'

export default compose(
  withRouter,
  graphql(QueryCurrentCompany, { name: 'CurrentCompany' }),
  graphql(ASSIGN_TEAMS, { name: 'assignTeams' })
)(EmployeeTeamMembership)
