import gql from 'graphql-tag'
import { employeeFragments } from './employees'

export const QueryCurrentCompany = gql`
  query currentCompany {
    currentCompany {
      id
      name
      email
      roles {
        id
        name
      }
      jobTitles {
        id
        name
      }
      teams {
        id
        name
      }
      companyHolidays {
        id
        date
        description
        deletable
      }
      employees {
        ...BaseEmployeeFields
        ...HolidaysBreakdownFields
      }
    }
  }
  ${employeeFragments.base}
  ${employeeFragments.holidaysBreakdowns}
`
