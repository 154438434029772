import gql from 'graphql-tag'

const CREATE_JOB_TITLE = gql`
  mutation createJobTitle($input: CreateJobTitleInput!) {
    createJobTitle(input: $input) {
      name
    }
  }
`

export default CREATE_JOB_TITLE
