import styled from 'styled-components/macro'
import { borderTop, borderBottom, display, position, left } from 'styled-system'

export const HeaderItem = styled.th`
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
  vertical-align: middle;
`

export const TableCell = styled.td`
  ${display};
  ${position};
  ${left};

  @media ${({ theme }) => theme.media.mobile} {
    white-space: nowrap;
    padding-right: 20px;
  }

  vertical-align: middle;
  width: 410px;
`

export const TableRow = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
  height: 60px;

  &:last-of-type {
    border-bottom: none;
  }
  &:first-of-type {
    border-top: none;
  }

  ${display};
  ${borderTop};
  ${borderBottom};
`

TableRow.defaultProps = {
  bg: 'white',
}

export const Table = styled.table`
  width: 100%;
  overflow-y: scroll;
`

export const TableHeader = styled.thead`
  height: 60px;
`
