import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'

import EmployeeBaseInfo from './EmployeeBaseInfo'

import UPDATE_EMPLOYEE from '../../../../../../graphql/mutations/updateEmployee'

export default compose(
  injectIntl,
  graphql(UPDATE_EMPLOYEE, { name: 'UpdateEmployee' })
)(EmployeeBaseInfo)
