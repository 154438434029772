import React, { Component } from 'react'
import { func } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { EmployeeType, IntlType } from '../../../../common/prop-types'
import notify from '../../../../common/services/notification'
import { refetchQueries } from '../../../../common/constants/refetchQueries'
import { Container, Input, EmployeeForm, EmployeeName } from './styles'
import { cutText } from '../../../../common/utils/text'
import { ButtonGroup } from '../../../../components/styles/Button'
import Button from '../../../../components/Button'
import { EditImageBig } from '../../../../components/EditableField/style'

class EmployeeNameEditor extends Component {
  state = {
    isLoading: false,
    editing: false,
    formData: {
      firstName: this.props.employee.firstName,
      lastName: this.props.employee.lastName,
    },
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employee !== this.props.employee) {
      this.setState(({ formData }) => ({
        formData: {
          ...formData,
          firstName: this.props.employee.firstName,
          lastName: this.props.employee.lastName,
        },
      }))
    }
  }

  handleValueChange = async event => {
    const { name, value } = event.target

    this.setState(({ formData }) => ({
      formData: { ...formData, [name]: value },
    }))
  }

  handleSavePressed = async e => {
    e.preventDefault()

    this.setState({ isLoading: true })

    const { success } = await this.updateEmployee({
      firstName: this.state.formData.firstName,
      lastName: this.state.formData.lastName,
    })

    if (success) {
      notify('success', 'Notification.Employee.Update.Success')
    } else {
      notify('error', 'Notification.Employee.Update.Failed')
    }

    this.setState({ isLoading: false, editing: false })
    this.props.onEditModeChange({ editing: false })
  }

  handleEditPressed = () => {
    this.setState({ editing: true })
    this.props.onEditModeChange({ editing: true })
  }

  handleCancelPressed = () => {
    this.setState({
      editing: false,
      formData: {
        firstName: this.props.employee.firstName,
        lastName: this.props.employee.lastName,
      },
    })
    this.props.onEditModeChange({ editing: false })
  }

  updateEmployee = async input => {
    // eslint-disable-next-line prefer-destructuring
    const id = this.props.employee.id

    try {
      await this.props.UpdateEmployee({
        variables: { input: { ...input, id } },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  render() {
    const { employee, intl } = this.props
    const { formData, editing } = this.state

    if (!employee) return null

    const { isLoading } = this.state
    const isValid =
      formData.firstName &&
      formData.lastName &&
      (formData.firstName !== employee.firstName ||
        formData.lastName !== employee.lastName)

    return (
      <Container>
        {editing ? (
          <EmployeeForm
            name="employee-name"
            onSubmit={event => {
              if (isValid) this.handleSavePressed(event)
            }}
          >
            <Input
              autoFocus
              hideErrors
              id="employee-editable-text-field"
              placeholder={intl.formatMessage({
                id: 'Input.EditLastName.Placeholder',
              })}
              name="lastName"
              isLoading={isLoading}
              value={formData.lastName}
              onChange={this.handleValueChange}
            />
            <Input
              hideErrors
              id="employee-editable-text-field"
              isLoading={isLoading}
              value={formData.firstName}
              placeholder={intl.formatMessage({
                id: 'Input.EditFirstName.Placeholder',
              })}
              name="firstName"
              onChange={this.handleValueChange}
            />
            <ButtonGroup className="button-group">
              <Button
                narrow
                secondary
                orange
                className="save-button"
                type="submit"
                loading={this.state.isLoading}
                disabled={!isValid}
              >
                <FormattedMessage id="Button.Save" />
              </Button>
              <Button
                narrow
                secondary
                bordered
                reversed
                onClick={this.handleCancelPressed}
                className="cancel-button"
              >
                <FormattedMessage id="Button.Cancel" />
              </Button>
            </ButtonGroup>
          </EmployeeForm>
        ) : (
          <>
            <EmployeeName>
              {cutText(`${employee.lastName} ${employee.firstName}`, 25)}
            </EmployeeName>
            <EditImageBig
              src="/assets/icons/edit.svg"
              alt="Edit"
              className="edit-button"
              onClick={this.handleEditPressed}
            />
          </>
        )}
      </Container>
    )
  }
}

EmployeeNameEditor.propTypes = {
  UpdateEmployee: func.isRequired,
  employee: EmployeeType.isRequired,
  onEditModeChange: func,
  intl: IntlType.isRequired,
}

export default EmployeeNameEditor
