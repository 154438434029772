import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Text } from '../styles/Text'
import { PlaceholderImage, Container } from './styles'
import { SvgType } from '../../common/prop-types'
import { Spacer } from '../styles/Spacer'

const EmptyListPlaceholder = ({ textId, imageSrc, svg: Svg }) => (
  <Container>
    {imageSrc && typeof imageSrc === 'string' && (
      <PlaceholderImage
        className="placeholder-image"
        src={imageSrc}
        alt="empty"
      />
    )}
    {Svg && typeof Svg === 'object' && <Svg display={['none', 'block']} />}
    {textId && (
      <Spacer mt={20}>
        <Text fontSize={18} color="gray">
          <FormattedMessage id={textId} />
        </Text>
      </Spacer>
    )}
  </Container>
)

EmptyListPlaceholder.propTypes = {
  textId: PropTypes.string,
  imageSrc: PropTypes.string,
  svg: SvgType,
}

export default EmptyListPlaceholder
