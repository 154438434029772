import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { OptionContainer } from './styles'
import { ButtonWithShadow } from '../styles/Button'

const Options = ({ isAdmin, onRequestHoliday, showOptions, position }) => (
  <OptionContainer visible={showOptions} style={position}>
    <ButtonWithShadow
      id="request-vacation-button"
      secondary
      color="gray"
      onClick={() => onRequestHoliday('VACATION')}
    >
      <FormattedMessage
        id={
          isAdmin
            ? 'RequestHoliday.Admin.DropDown.Vacation'
            : 'RequestHoliday.DropDown.Vacation'
        }
      />
    </ButtonWithShadow>
    <ButtonWithShadow
      id="request-sickleave-button"
      secondary
      color="gray"
      onClick={() => onRequestHoliday('SICK_LEAVE')}
    >
      <FormattedMessage
        id={
          isAdmin
            ? 'RequestHoliday.Admin.DropDown.SickLeave'
            : 'RequestHoliday.DropDown.SickLeave'
        }
      />
    </ButtonWithShadow>
    <ButtonWithShadow
      height={100}
      id="request-plan-button"
      secondary
      color="gray"
      onClick={() => onRequestHoliday('PLANNED')}
    >
      <FormattedMessage
        id={
          isAdmin
            ? 'RequestHoliday.Admin.DropDown.Plan'
            : 'RequestHoliday.DropDown.Plan'
        }
      />
    </ButtonWithShadow>
  </OptionContainer>
)

Options.propTypes = {
  isAdmin: PropTypes.bool,
  onRequestHoliday: PropTypes.func,
  showOptions: PropTypes.bool,
  position: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
}

export default Options
