import React, { Component } from 'react'
import { func } from 'prop-types'
import { EmployeeType, IntlType } from '../../../common/prop-types'

import SelectorInput from '../../SelectorInput'
import ConfirmActionModal from '../../Modals/ConfirmActionModal'
import notify from '../../../common/services/notification'
import { refetchQueries } from '../../../common/constants/refetchQueries'

class EmployeeStatusEditor extends Component {
  state = {
    isOpen: false,
    selectedStatus: null,
    isLoading: false,
  }

  userStatuses = [
    {
      value: 'active',
      label: this.props.intl.formatMessage({ id: 'Employee.Status.Active' }),
    },
    {
      value: 'deactivated',
      label: this.props.intl.formatMessage({ id: 'Employee.Status.Inactive' }),
    },
  ]

  handleStatusChange = async () => {
    const { employee } = this.props
    const { selectedStatus } = this.state

    this.setState({ isLoading: true })

    const { success } = await this.changeStatus({
      employee,
      status: selectedStatus,
    })

    if (success) {
      notify('success', 'Notification.Action.Success')
    } else {
      notify('error', 'Notification.Action.Failed')
    }

    this.setState({
      isOpen: false,
      selectedStatus: null,
      isLoading: false,
    })
  }

  closeConfirmationModal = () => {
    this.setState({
      isOpen: false,
      selectedStatus: null,
    })
  }

  changeStatus = async ({ employee, status } = {}) => {
    const { ActivateEmployee, DeactivateEmployee } = this.props

    try {
      let mutate
      if (status.value === 'active') mutate = ActivateEmployee
      if (status.value === 'deactivated') mutate = DeactivateEmployee

      await mutate({
        variables: { id: employee.id },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleSelectorChange = selected => {
    this.setState({
      selectedStatus: selected,
      isOpen: true,
    })
  }

  render() {
    const { employee } = this.props
    const { isOpen, selectedStatus, isLoading } = this.state

    const employeeStatus = this.userStatuses.find(
      status => status.value === (employee.active ? 'active' : 'deactivated')
    )

    const value = selectedStatus || employeeStatus

    return (
      <>
        {isOpen && (
          <ConfirmActionModal
            isOpen={isOpen}
            onSubmit={this.handleStatusChange}
            onClose={this.closeConfirmationModal}
            question="Employee.ChangeStatus.Question"
            title="Employee.ChangeStatus.Title"
            isLoading={isLoading}
          />
        )}
        <SelectorInput
          id="roleCell"
          options={this.userStatuses}
          value={value}
          withBorder={false}
          onChange={this.handleSelectorChange}
          selectorStyle="roleSelector"
          isSearchable={false}
        />
      </>
    )
  }
}

EmployeeStatusEditor.propTypes = {
  employee: EmployeeType.isRequired,
  ActivateEmployee: func.isRequired,
  DeactivateEmployee: func.isRequired,
  intl: IntlType.isRequired,
}

export default EmployeeStatusEditor
