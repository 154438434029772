import gql from 'graphql-tag'

const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
    }
  }
`
export default UPDATE_EMPLOYEE
