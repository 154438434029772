import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  selectedDate: new Date(),
}

export const SELECT_DATE = 'SELECT_DATE'

export const { selectDate } = createActions({}, SELECT_DATE)

export default handleActions(
  {
    [SELECT_DATE]: (state, { payload }) => ({
      ...state,
      selectedDate: payload,
    }),
  },
  defaultState
)
