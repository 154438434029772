import React from 'react'
import { bool, string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  CheckboxText,
  Label,
} from './styles'

const Checkbox = ({ checked, label, color, boldText, ...props }) => (
  <Label>
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} type="checkbox" />
      <StyledCheckbox checked={checked} color={color}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    {label && (
      <CheckboxText boldText={boldText}>
        <FormattedMessage id={label} />
      </CheckboxText>
    )}
  </Label>
)

Checkbox.propTypes = {
  checked: bool,
  boldText: bool,
  label: string,
  color: string,
}

export default Checkbox
