import React from 'react'
import { string, func, shape, arrayOf } from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { InputContainer } from '../styles'
import { InputButton } from '../../styles/Button'
import { TextV as Text } from '../../styles/Text'
import Input from '../../Input'
import { Spacer } from '../../styles/Spacer'
import { StyledForm } from './styles'

const CompanyNameFormView = ({
  companyName,
  onChange,
  onSubmit,
  validationErrors,
}) => (
  <Spacer>
    <Text textAlign="center" variant="modalTitle">
      <FormattedMessage id="ComanyReg.CompanyName.Title" />
    </Text>

    <Spacer textAlign="center" my={[10, 20]}>
      <Text color="grayAnother" variant="modalSubTitle">
        <FormattedMessage id="ComanyReg.CompanyName.SubTitle" />
      </Text>
    </Spacer>
    <StyledForm
      mx="auto"
      id="company-name-form"
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <InputContainer>
        <Input
          id="company-name"
          value={companyName}
          maxLength={50}
          placeholder="ComanyReg.CompanyName.Placeholder"
          onChange={event => onChange({ companyName: event.target.value })}
          error={validationErrors.companyName}
          style={{ width: '291px', marginBottom: '60px' }}
          hideErrors
        />

        <InputButton
          type="submit"
          color="orange"
          selected
          onClick={onSubmit}
          disabled={!companyName}
        >
          <img src="/assets/img/right-arrow.svg" alt="next" />
        </InputButton>
      </InputContainer>
    </StyledForm>
  </Spacer>
)

CompanyNameFormView.propTypes = {
  companyName: string.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  validationErrors: shape({ companyName: arrayOf(string) }),
}
export default CompanyNameFormView
