import React from 'react'
import { string } from 'prop-types'

import { Form } from '../styles'
import { FunctionType } from '../../../common/prop-types'
import { VerificationInput } from '../../../elements'
import { Button } from '../../styles/Button'
import { H2, Text } from '../../styles/Text'
import { Spacer } from '../../styles/Spacer'

const EmailVerificationFormView = ({
  confirmationCode,
  onChange,
  onSubmit,
}) => (
  <Spacer>
    <H2>Success!</H2>
    <Spacer textAlign="center" my={20}>
      <Text color="grayAnother" fontSize={20}>
        An e-mail has landed in your inbox. Enter the 4-digit code you find
        there:
      </Text>
    </Spacer>
    <Form id="email-verification-form" onSubmit={onSubmit} autoComplete="off">
      <VerificationInput
        id="code-input"
        maxLength={6}
        minLength={6}
        fontSize={6}
        value={confirmationCode}
        onChange={event => onChange({ confirmationCode: event.target.value })}
      />
      <Button type="submit" disabled={!confirmationCode}>
        SUBMIT AND ENTER THE APP
      </Button>
    </Form>
  </Spacer>
)

EmailVerificationFormView.propTypes = {
  confirmationCode: string.isRequired,
  onSubmit: FunctionType.isRequired,
  onChange: FunctionType.isRequired,
}
export default EmailVerificationFormView
