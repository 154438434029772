import styled from 'styled-components/macro'

const getSize = ({ theme, sm, lg }) => {
  if (sm) return theme.margins.small
  if (lg) return theme.margins.large
  return theme.margins.default
}
const marginTop = ({ v, t, sm, lg, theme }) =>
  v || t ? getSize({ theme, sm, lg }) : 0
const marginBottom = ({ v, b, sm, lg, theme }) =>
  v || b ? getSize({ theme, sm, lg }) : 0
const marginLeft = ({ h, l, sm, lg, theme }) =>
  h || l ? getSize({ theme, sm, lg }) : 0
const marginRight = ({ h, right, sm, lg, theme }) =>
  h || right ? getSize({ theme, sm, lg }) : 0

export const Margin = styled.div`
  margin-top: ${marginTop};
  margin-bottom: ${marginBottom};
  margin-left: ${marginLeft};
  margin-right: ${marginRight};
`
