import styled from 'styled-components/macro'
import { space, display } from 'styled-system'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 30px 30px 0px;
`

export const TutorialSteps = styled.ol`
  ${space};

  list-style: decimal;
`

export const Step = styled.li`
  line-height: 1.6;

  &::marker {
    font-size: 2.6rem;
    color: ${({ theme }) => theme.colors.orange};
  }
`

export const TutorialContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 0px 0px 20px;
`

export const TutorialTextContent = styled.div`
  width: 460px;
`

export const Image = styled.img`
  ${display};

  width: 200px;
  padding: 20px;
`

export const ModalCloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
`
