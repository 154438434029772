import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { refetchQueries } from '../../../../../../common/constants/refetchQueries'
import { EmployeeType, IntlType } from '../../../../../../common/prop-types'
import {
  getEmployeeAgeSelectionRules,
  getHireDateSelectionRules,
} from '../../../../../../common/services/dateSelectionRules'
import notify from '../../../../../../common/services/notification'
import validation from '../../../../../../common/services/validation'
import { fixDate } from '../../../../../../common/utils/calendar'
import EmployeeBaseInfoView from './EmployeeBaseInfoView'

const birthDateRules = getEmployeeAgeSelectionRules()
const hireDateRules = getHireDateSelectionRules()

class EmployeeBaseInfo extends Component {
  state = {
    editing: false,
    isLoading: false,
    formData: {
      email: this.props.employee.email,
      sex: this.props.employee.sex,
      birthDate: this.props.employee.birthDate
        ? new Date(this.props.employee.birthDate)
        : birthDateRules.defaultDate,
      hireDate: this.props.employee.hireDate
        ? new Date(this.props.employee.hireDate)
        : hireDateRules.defaultDate,
    },
    errors: null,
  }

  handleEditModeChange = () => {
    this.setState(({ editing }) => ({ editing: !editing }))
  }

  handleFormDataChange = change => {
    this.setState(({ formData }) => ({
      formData: { ...formData, ...change },
      errors: null,
    }))
  }

  handleCancel = () => {
    this.resetFormStates()
  }

  handleSave = async () => {
    const { formData } = this.state
    const { email, sex, birthDate, hireDate } = formData
    const { error: hasErrors, messages } = validation([
      [
        'hireDate',
        {
          rule: 'at-least-16',
          msg: this.props.intl.formatMessage({
            id: 'Employee.Errors.TooYoungToWork',
          }),
        },
      ],
      [
        'email',
        {
          rule: 'email',
          msg: this.props.intl.formatMessage({
            id: 'NotValidEmail',
          }),
        },
      ],
    ])(formData)

    if (hasErrors) {
      this.setState({ errors: messages })
      return
    }

    const input = {
      id: this.props.employee.id,
      email,
      sex,
      birthDate: birthDate ? fixDate(birthDate) : null,
      hireDate: hireDate ? fixDate(hireDate) : null,
    }

    this.setState({ isLoading: true })

    const { success, error } = await this.updateEmployee(input)

    if (success) {
      this.handleUpdateSuccess()
    } else {
      this.handleUpdateError(error)
      this.resetFormStates()
    }
  }

  updateEmployee = async input => {
    try {
      await this.props.UpdateEmployee({
        variables: { input },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleUpdateSuccess = () => {
    notify('success', 'Notification.Employee.Update.Success')
  }

  handleUpdateError = error => {
    const {
      graphQLErrors: [
        {
          errorInfo: { code },
        },
      ],
    } = error

    if (code === 'Notification.Employee.Update.EmailAlreadyTaken') {
      notify('error', code)
    } else {
      notify('error', 'Notification.Employee.Update.Failed')
    }
  }

  resetFormStates = () => {
    this.setState({
      editing: false,
      isLoading: false,
      formData: {
        email: this.props.employee.email,
        sex: this.props.employee.sex,
        birthDate: this.props.employee.birthDate
          ? new Date(this.props.employee.birthDate)
          : birthDateRules.defaultDate,
        hireDate: this.props.employee.hireDate
          ? new Date(this.props.employee.hireDate)
          : hireDateRules.defaultDate,
      },
      errors: null,
    })
  }

  render() {
    const { employee, isAdmin } = this.props
    const { editing, formData, isLoading, errors } = this.state

    return (
      <EmployeeBaseInfoView
        editing={editing}
        isLoading={isLoading}
        employee={employee}
        formData={formData}
        errors={errors}
        onEditModeChange={this.handleEditModeChange}
        onSave={this.handleSave}
        onFormDataChange={this.handleFormDataChange}
        onCancel={this.handleCancel}
        isAdmin={isAdmin}
        intl={this.props.intl}
      />
    )
  }
}

EmployeeBaseInfo.propTypes = {
  employee: EmployeeType.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  UpdateEmployee: PropTypes.func.isRequired,
  intl: IntlType.isRequired,
}

export default EmployeeBaseInfo
