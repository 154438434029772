import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { minWidth } from 'styled-system'

export const UserNameContainer = styled.div``

export const UserNameAdminContainer = styled(Link)`
  cursor: ${props =>
    `${props.disableclick === 'true' ? 'default' : 'pointer'};`};
  text-decoration: none;
`

export const DisplayDataContainer = styled.div`
  display: flex;
  min-width: 222px;
  height: 60px;
  padding: 8px 0;
  align-items: center;
  line-height: 1.6rem;

  ${minWidth}
`

export const ProfileImageContainer = styled.div`
  margin-right: 14px;
`

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
