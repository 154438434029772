import React, { Component } from 'react'
import { string } from 'prop-types'

import { ResetPasswordView } from './ResetPasswordView'
import notify from '../../../../common/services/notification'
import { AuthService } from '../../../../common/services/auth'
import { getCognitoMessage } from '../../../../common/constants/errorCodes'

class ResetPassword extends Component {
  state = {
    formData: {
      password: '',
      repeatedPassword: '',
      email: this.props.email,
    },
    error: null,
    isLoading: false,
  }

  componentDidMount() {
    if (!this.props.email || !this.props.code) {
      notify('error', 'Notification.ResetPassword.URLIsNotVaild')
    }
  }

  handleFormChange = change => {
    this.setState(({ formData }) => ({ formData: { ...formData, ...change } }))
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { code, email } = this.props
    const {
      formData: { password },
    } = this.state

    this.setState({ isLoading: true })

    try {
      await AuthService.forgotPasswordSubmit(email, code, password)
      notify('success', 'Notification.ResetPassword.Submit.Success')
      await AuthService.signIn(email, password)
    } catch (error) {
      if (error && error.code === 'InvalidParameterException') {
        error.code = 'InvalidPasswordException'
      }
      const message = getCognitoMessage(
        error,
        'Notification.ResetPassword.Submit.Failed'
      )
      notify('error', message)
    }

    this.setState({ isLoading: false })
  }

  render() {
    const { error, formData, isLoading } = this.state

    const disabled =
      (error &&
        (error.password.length > 0 || error.repeatedPassword.length > 0)) ||
      formData.password !== formData.repeatedPassword
    return (
      <ResetPasswordView
        onSubmit={this.handleSubmit}
        error={error}
        isLoading={isLoading}
        disabled={disabled}
        formData={formData}
        onFormDataChange={this.handleFormChange}
      />
    )
  }
}

ResetPassword.propTypes = {
  code: string.isRequired,
  email: string.isRequired,
}

export default ResetPassword
