import styled from 'styled-components/macro'

export const CallbackElement = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 10px 0 10px 21px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1f1f1f;
  &:hover,
  &:active {
    text-decoration: none;
    background: #eeeeee;
  }
`
