import React from 'react'
import { string, instanceOf, func, shape, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { StyledElevated } from './styles'
import { H2, Text } from '../../../../components/styles/Text'
import { Spacer } from '../../../../components/styles/Spacer'
import { Form } from '../../../../components/styles/Form'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'

export const ResetPasswordView = ({
  onFormDataChange,
  onSubmit,
  error,
  formData,
  isLoading,
  disabled,
}) => (
  <StyledElevated>
    <H2 textAlign="center" mb={40}>
      <FormattedMessage id="ResetPassword.Title" />
    </H2>

    <Spacer mb={20} px={40} textAlign="center">
      <Text>
        <FormattedMessage id="ResetPassword.Description" />
      </Text>
    </Spacer>
    <Form mb={10} onSubmit={onSubmit}>
      <Input
        hidden
        id="username"
        value={formData.email}
        autoComplete="username"
        onChange={() => null}
      />
      <Input
        autoFocus
        required
        id="password"
        tooltip="Input.Password.Placeholder.Tooltip"
        label="Input.NewPassword.Label"
        placeholder="Input.NewPassword.Placeholder"
        value={formData.password}
        type="password"
        autoComplete="new-password"
        onChange={event => onFormDataChange({ password: event.target.value })}
        error={error && error.password}
      />
      <Input
        required
        id="password-repeat"
        label="Input.RepeatPassword.Label"
        placeholder="Input.RepeatPassword.Placeholder"
        autoComplete="repeat-password"
        value={formData.repeatedPassword}
        type="password"
        onChange={event =>
          onFormDataChange({ repeatedPassword: event.target.value })
        }
        error={error && error.repeatedPassword}
      />

      <Spacer textAlign="center">
        <Button
          width={252}
          primary
          id="submit-button"
          disabled={disabled}
          loading={isLoading}
        >
          <FormattedMessage id="Button.Done" />
        </Button>
      </Spacer>
    </Form>
  </StyledElevated>
)

ResetPasswordView.propTypes = {
  formData: shape({
    repeatedPassword: string.isRequired,
    password: string.isRequired,
  }),
  isLoading: bool,
  disabled: bool,
  onFormDataChange: func,
  onSubmit: func,
  error: instanceOf(Object),
}

export default ResetPasswordView
