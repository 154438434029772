import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
  MenuItems,
  Container,
  Content,
  Logo,
  MenuItem,
  MobileContent,
  HamburgerMenu,
  MobileDropdown,
  MobileMenuItems,
  MobileMenuItem,
  MobileRightContent,
  ExternalLink,
  MobileExternalLink,
} from './styles'

import Button from '../Button'
import LanguageSelector from '../LanguageSelector'
import { Spacer } from '../styles/Spacer'
import { HistoryType } from '../../common/prop-types'
import UserDropdownList from '../UserDropdownList'
import LogoutButton from '../LogoutButton/LogoutButton'
import {
  selectIsAuthenticated,
  selectIsAdmin,
  selectIsCurrentUserDataLoaded,
} from '../../redux/auth/auth.selector'

const MenuItemTypes = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
}

const AppHeader = ({ history }) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const isCurrentUserDataLoaded = useSelector(selectIsCurrentUserDataLoaded)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isAdmin = useSelector(selectIsAdmin)

  let menuItems = [
    {
      order: 0,
      id: 'try-now',
      title: 'UserNavigationBar.Item.TryNow',
      link: '/#try-now',
      visible: !isCurrentUserDataLoaded,
    },
    {
      order: 20,
      id: 'faq',
      title: 'UserNavigationBar.Item.Faq',
      link: '/faq',
      visible: !isCurrentUserDataLoaded,
    },
    {
      order: 10,
      id: 'why-freebees',
      title: 'UserNavigationBar.Item.Why.Freebees',
      link: '/#why-freebees',
      visible: !isCurrentUserDataLoaded,
    },
    {
      order: 15,
      id: 'pricing',
      title: 'UserNavigationBar.Item.Pricing',
      link: '/pricing',
      visible: !isCurrentUserDataLoaded,
    },
    {
      id: 'calendar',
      order: 30,
      title: 'UserNavigationBar.Item.Calendar',
      link: '/calendar',
      visible: isCurrentUserDataLoaded,
    },
  ]

  if (isCurrentUserDataLoaded) {
    menuItems = [
      ...menuItems,
      {
        order: 20,
        id: 'dasboard',
        title: 'UserNavigationBar.Item.Dashboard',
        link: '/dashboard',
        visible: isAdmin,
      },
      {
        order: 40,
        id: 'company',
        title: 'UserNavigationBar.Item.Company',
        link: '/company',
        visible: isAdmin,
      },
      {
        order: 50,
        id: 'report',
        title: 'UserNavigationBar.Item.NewReport',
        link: '/report',
        visible: isAdmin,
      },
      {
        order: 60,
        id: 'profile',
        title: 'UserNavigationBar.Item.MyTimeOff',
        link: `/me`,
        visible: true,
      },
    ]
  }

  const menuItemsToShow = menuItems
    .filter(item => item.visible)
    .sort((a, b) => a.order - b.order)

  return (
    <Container>
      <MobileContent>
        <Logo to="/" onClickCapture={() => setIsMobileMenuOpened(false)} />
        <MobileRightContent>
          <Spacer mx={20}>
            <LanguageSelector />
          </Spacer>
          <HamburgerMenu
            onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
          />
        </MobileRightContent>
        <MobileDropdown open={isMobileMenuOpened}>
          {isMobileMenuOpened && (
            <>
              <MobileMenuItems>
                {menuItemsToShow.map(item => {
                  if (item.type === MenuItemTypes.EXTERNAL) {
                    return (
                      <MobileExternalLink href={item.link} key={item.id}>
                        <FormattedMessage id={item.title} />
                      </MobileExternalLink>
                    )
                  }
                  return (
                    <MobileMenuItem
                      key={item.id}
                      mt={20}
                      onClickCapture={() => setIsMobileMenuOpened(false)}
                      smooth
                      to={item.link}
                      activeClassName="active"
                    >
                      <FormattedMessage id={item.title} />
                    </MobileMenuItem>
                  )
                })}
                {isCurrentUserDataLoaded ? (
                  <Spacer mt={20}>
                    <LogoutButton />
                  </Spacer>
                ) : (
                  <Spacer mt={20}>
                    <Button
                      secondary
                      color="orange"
                      narrow
                      to="/login"
                      loading={isAuthenticated && !isCurrentUserDataLoaded}
                      onClick={() => {
                        history.push('/login')
                        setIsMobileMenuOpened(false)
                      }}
                    >
                      <FormattedMessage id="UserNavigationBar.Item.SignIn" />
                    </Button>
                  </Spacer>
                )}
              </MobileMenuItems>
            </>
          )}
        </MobileDropdown>
      </MobileContent>
      <Content>
        <Logo to="/" />
        <MenuItems>
          <>
            {menuItemsToShow.map(item => {
              if (item.type === MenuItemTypes.EXTERNAL) {
                return (
                  <ExternalLink href={item.link} key={item.id}>
                    <FormattedMessage id={item.title} />
                  </ExternalLink>
                )
              }

              return (
                <MenuItem
                  key={item.id}
                  mx={20}
                  smooth
                  to={item.link}
                  activeClassName="active"
                  isActive={(match, location) => {
                    return (
                      location.pathname + location.hash === item.link ||
                      (match && match.url === item.link)
                    )
                  }}
                >
                  <FormattedMessage id={item.title} />
                </MenuItem>
              )
            })}
          </>
          {isCurrentUserDataLoaded ? (
            <>
              <Spacer mx={20}>
                <LanguageSelector />
              </Spacer>

              <Spacer ml={20}>
                <UserDropdownList />
              </Spacer>
            </>
          ) : (
            <>
              <Spacer mx={20}>
                <LanguageSelector />
              </Spacer>

              <Spacer ml={20}>
                <Button
                  secondary
                  color="orange"
                  narrow
                  to="/login"
                  onClick={() => {
                    history.push('/login')
                  }}
                >
                  <FormattedMessage id="UserNavigationBar.Item.SignIn" />
                </Button>
              </Spacer>
            </>
          )}
        </MenuItems>
      </Content>
    </Container>
  )
}

AppHeader.propTypes = {
  history: HistoryType.isRequired,
}

export default AppHeader
