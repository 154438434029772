import { getYear } from 'date-fns'

export const isOver16 = bornOn => {
  const now = new Date()
  const then = new Date(bornOn)

  return now - then > 504910816000
}

const currentYear = getYear(new Date())

const getHolidaysBreakdownsForYear = (
  holidaysBreakdowns = [],
  year = currentYear
) => holidaysBreakdowns.find(breakdown => breakdown.year === year)

export const calculateHolidayCountersForYear = (
  employee,
  year = currentYear
) => {
  const {
    ageHolidaysCounter,
    assignedHolidaysCounter,
    availableHolidaysCounter,
    baseHolidaysCounter,
    birthsHolidaysCounter,
    childrenHolidaysCounter,
    extraHolidaysCounter,
    plannedHolidaysCounter,
    sickLeavesCounter,
    unaccountedHolidaysCounter,
  } = getHolidaysBreakdownsForYear(employee.holidaysBreakdowns, year)

  return {
    ageHolidays: ageHolidaysCounter,
    allowedHolidays: assignedHolidaysCounter + extraHolidaysCounter,
    assignedHolidays: assignedHolidaysCounter,
    availableHolidays: availableHolidaysCounter,
    baseHolidays: baseHolidaysCounter + ageHolidaysCounter,
    birthsHolidays: birthsHolidaysCounter,
    childrenHolidays: childrenHolidaysCounter,
    compensatoryHolidays: childrenHolidaysCounter + birthsHolidaysCounter,
    extraHolidays: extraHolidaysCounter,
    plannedHolidays: plannedHolidaysCounter,
    sickLeaves: sickLeavesCounter,
    unaccountedHolidays: unaccountedHolidaysCounter,
  }
}

export const uniqueElementsBy = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some(x => fn(v, x))) acc.push(v)
    return acc
  }, [])
