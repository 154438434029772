import { createActions, handleActions } from 'redux-actions'
import { localeConfig as locales } from '../../common/services/i18n/i18n.service'

const defaultState = {
  selectedLocale: 'hu',
  locales,
}

export const SELECT_LOCALE = 'SELECT_LOCALE'

export const { selectLocale } = createActions({}, SELECT_LOCALE)

export default handleActions(
  {
    [SELECT_LOCALE]: (state, { payload }) => ({
      ...state,
      selectedLocale: payload && payload.toLowerCase(),
    }),
  },
  defaultState
)
