import React, { Component } from 'react'
import { string, instanceOf, func, shape, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { ModalVerticalInputContainer, InputGroup } from '../../elements'
import Button from '../Button'
import { H2, Text } from '../styles/Text'
import { Form } from '../styles/Form'
import Input from '../Input'
import { Spacer } from '../styles/Spacer'
import Checkbox from '../Checkbox'
import {
  CenterAlignedSpacer,
  StyledErrorText,
  TextLink,
  StyledElevated,
} from './styles'
import { colors } from '../../theme/freebees'

export class ChangePasswordView extends Component {
  render() {
    const { onSubmit, error, onFormChange, formData, isLoading, email } =
      this.props

    return (
      <Spacer>
        <StyledElevated mx="auto" pt={60} pb={40} px={80} my={100}>
          <H2 textAlign="center">
            <FormattedMessage id="ChangePassword.Title" />
          </H2>

          <Spacer textAlign="center" my={40}>
            <img src="/assets/img/hello-bee.png" alt="hello" />
          </Spacer>

          <Spacer textAlign="center" mb={40}>
            <Text>
              <FormattedMessage id="ChangePassword.Description" />
            </Text>
          </Spacer>

          <Form mb={10} onSubmit={onSubmit}>
            <Input hidden id="username" value={email} onChange={() => null} />
            <ModalVerticalInputContainer>
              <InputGroup block>
                <Input
                  autoFocus
                  id="new-password"
                  name="password"
                  label="Input.NewPassword.Label"
                  required
                  type="password"
                  placeholder="Input.NewPassword.Placeholder"
                  value={formData.password}
                  onChange={onFormChange}
                  error={error && error.password}
                />
              </InputGroup>
              <InputGroup block>
                <Input
                  id="repeat-password"
                  name="repeatedPassword"
                  label="Input.RepeatPassword.Label"
                  required
                  type="password"
                  placeholder="Input.RepeatPassword.Placeholder"
                  value={formData.repeatedPassword}
                  onChange={onFormChange}
                  error={error && error.repeatedPassword}
                />
              </InputGroup>
            </ModalVerticalInputContainer>

            <CenterAlignedSpacer mb={20}>
              <Spacer display="flex" alignItems="baseline">
                <Checkbox
                  checked={formData.termAndCondition}
                  label="Term.And.Condition.Checkbox.Label"
                  name="termAndCondition"
                  color={colors.blueAnother}
                  onChange={() =>
                    onFormChange({
                      target: {
                        name: 'termAndCondition',
                        value: !formData.termAndCondition,
                      },
                    })
                  }
                />
                <Spacer ml={-10}>
                  <Link
                    to="/terms-and-conditions"
                    style={{ textDecoration: 'none' }}
                  >
                    <TextLink>
                      <FormattedMessage id="Term.And.Condition.Checkbox.Link" />
                    </TextLink>
                  </Link>
                </Spacer>
              </Spacer>

              {formData.termAndConditionError ? (
                <Spacer mt={20}>
                  <StyledErrorText>
                    <FormattedMessage id="Term.And.Condition.Checkbox.Error" />
                  </StyledErrorText>
                </Spacer>
              ) : null}
            </CenterAlignedSpacer>
            <Spacer textAlign="center">
              <Button
                primary
                width={252}
                id="submit-button"
                loading={isLoading}
              >
                <FormattedMessage id="Button.Done" />
              </Button>
            </Spacer>
          </Form>
        </StyledElevated>
      </Spacer>
    )
  }
}

ChangePasswordView.propTypes = {
  formData: shape({
    password: string.isRequired,
    repeatedPassword: string.isRequired,
  }),
  isLoading: bool,
  onFormChange: func,
  onSubmit: func,
  email: string,
  error: instanceOf(Object),
}

export default ChangePasswordView
