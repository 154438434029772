/* eslint-disable prefer-destructuring */
export const colors = {
  black: '#000',
  blueAnother: '#5F8CE1',
  blueLightGrayish: '#E7EEFB',
  blueModerate: '#3261BA',
  blueVerySoft: '#AFC5F0',
  calendarBorderColor: 'rgba(0, 0, 0, 0.07)',
  calendarBorderColorDark: '#DCDCDC',
  calendarTodayBackgroundColor: 'rgba(255, 211, 186, 0.92)',
  calendarWeekendBackgroundColor: 'rgba(235, 235, 235, 0.92)',
  calendarHolidayBackgroundColor: 'rgba(206, 240, 230, 0.92)',
  calendarHolidayTodayBackgroundColor: 'rgba(255, 242, 235, 0.8)',
  calendarCellSelectedBackgroundColor: '#F57831',
  calendarApprovedColor: '#5F8CE1',
  calendarSickLeaveColor: '#F7665C',
  calendarRequestedColor: 'rgba(255, 145, 82, 0.9)',
  calendarRequestedCheckboxColor: 'rgba(255, 145, 82, 1)',
  calendarPlannedColor: 'rgb(178, 198, 237)',
  checkboxText: '#2E2E2E',
  gray: '#A6A6A6',
  calendarHeaderGray: '#8C8C8C',
  calendarHeaderOrange: '#FF620A',
  calendarHeaderGreen: '#02A071',
  inputBorderGray: '#D2D2D2',
  grayAnother: '#585858',
  grayDark: '#797979',
  grayLight: '#CECECE',
  grayLightSeparatorLine: 'rgba(0,0,0,0.1)',
  grayVeryDark: '#111111',
  grayVeryLight: '#EEEEEE',
  greenLime: '#5CCCAA',
  greenLimeDark: '#4eb294',
  greenVeryLight: '#ddf9f1',
  orange: '#F17835',
  orangeBright: '#F17836',
  orangeLight: '#FF9152',
  orangeLightGrayish: '#fdf4ee',
  orangeVeryPale: '#FFECE1',
  redBright: '#E84539',
  redDark: '#a8332a',
  redLightGrayish: '#FDDFDD',
  rejectRed: '#F7665C',
  strongCyan: '#00a3d7',
  white: '#fff',
  menuTextColor: '#0b0b0b',
  modalShadowColor: 'rgba(17, 14, 10, 0.27)',
  infoColor: 'rgba(245, 148, 108, 0.15)',
}

const breakpoints = ['550px', '1024px', '1240px']

const media = {
  mobile: `(max-width: ${breakpoints[0]})`,
  mediumDesktop: `(max-width: ${breakpoints[2]})`,
}

const zIndex = {
  background: -1,
  header: 2,
  modal: 1,
}

const sizes = {
  default: '20px',
  small: '11px',
  large: '40px',
}

const paddings = {
  default: '20px',
  small: '11px',
  large: '40px',
  xlarge: '80px',
}
const margins = {
  default: '20px',
  small: '10px',
  large: '40px',
}

export const dimensions = {
  pageWidth: 1240,
}

const shadows = {
  modal: `0px 10px 36px ${colors.modalShadowColor}`,
  footer: '0px 2px 14px rgba(0, 0, 0, 0.16)',
}

const fontFamily = {
  default: 'Open Sans, sans-serif',
  semiBold: 'Open Sans SemiBold, sans-serif',
  bold: 'Open Sans Bold, sans-serif',
}

const fontSizes = []

const space = [0, 5, 10, 20, 40, 45]

space.xsmall = space[1]
space.small = space[2]
space.medium = space[3]
space.large = space[4]

export default {
  zIndex,
  colors,
  buttons: {
    variants: {
      orange: { default: colors.orangeLight, dark: colors.orange },
      lime: { default: colors.greenLime, dark: colors.greenLimeDark },
      red: { default: colors.rejectRed, dark: colors.rejectRed },
      gray: { default: colors.grayDark, dark: colors.grayDark },
      orangePale: {
        default: colors.orangeVeryPale,
        dark: colors.orangeVeryPale,
      },
    },
    default: 'orange',
  },
  shadow: {
    button: '0px 7px 16px rgba(0, 0, 0, 0.15)',
    buttonHovered: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    buttonActive: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  fontFamily,
  dimensions,
  sizes,
  paddings,
  margins,
  space,
  fontSizes,
  shadows,
  breakpoints,
  media,
}
