import React from 'react'
import { string } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import 'react-toastify/dist/ReactToastify.css'
import './toastStyles.css'

import { Image, ToastContainer, Text } from './style'

const Toast = ({ messageId, type }) => (
  <ToastContainer type={type}>
    <Image
      src={`/assets/img/${type}-toast.png`}
      alt="indicator"
      type="success"
    />
    <Text type={type}>
      <FormattedMessage id={messageId} />
    </Text>
  </ToastContainer>
)

Toast.propTypes = {
  messageId: string.isRequired,
  type: string.isRequired,
}

export default Toast
