import React from 'react'
import { string, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { ButtonOption } from '../styles/Button'
import { Container } from './styles'
import { OptionTypeList } from '../../common/prop-types'

const ButtonSelector = ({ options, value, onChange, width }) => (
  <Container>
    {options.map(option => (
      <ButtonOption
        key={option.key}
        id={option.key}
        secondary
        narrow
        selected={value === option.key}
        onClick={() => onChange(option.key)}
        width={width}
      >
        <FormattedMessage id={option.value} />
      </ButtonOption>
    ))}
  </Container>
)

ButtonSelector.propTypes = {
  options: OptionTypeList,
  value: string,
  onChange: func.isRequired,
  width: string,
}

ButtonSelector.defaultProps = {
  options: [],
  width: '126px',
}

export default ButtonSelector
