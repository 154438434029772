import { createSelector } from 'reselect'

const selectHolidayModal = state => state.holidayModal

export const selectIsHolidayModalOpen = createSelector(
  [selectHolidayModal],
  holidayModal => holidayModal.isOpen
)

export const selectIsAddUserModalOpen = createSelector(
  [selectHolidayModal],
  holidayModal => holidayModal.isAdUserModalOpen
)

export const selectHolidayModalRequestType = createSelector(
  [selectHolidayModal],
  holidayModal => holidayModal.requestType
)
export const selectHolidayModalSelectedEmployee = createSelector(
  [selectHolidayModal],
  holidayModal => holidayModal.selectedEmployee
)

export const selectHolidayModalData = createSelector(
  [selectHolidayModal],
  holidayModal => ({
    selectedEmployee: holidayModal.selectedEmployee,
    requestType: holidayModal.requestType,
    startDate: holidayModal.startDate,
    endDate: holidayModal.endDate,
    holidayRequest: holidayModal.holidayRequest,
    isEmployeeSelectable: holidayModal.isEmployeeSelectable,
  })
)
