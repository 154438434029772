import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'

import BulkUploadUsers from './BulkUploadUsers'
import { selectActiveLanguage } from '../../../../redux/language-selector/language-selector.selector'

const mapStateToProps = createStructuredSelector({
  activeLang: selectActiveLanguage,
})

export default compose(injectIntl, connect(mapStateToProps))(BulkUploadUsers)
