import React from 'react'
import { toast } from 'react-toastify'
import Toast from '../../../components/Toast'

export default function notify(type, message) {
  const toastProps = {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    closeButton: false,
    autoClose: 8000,
    draggable: false,
    toastId: 'singleToast',
    delay: 1000,
  }

  toast.dismiss()

  switch (type) {
    case 'info':
      toast.info(message, {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      break
    case 'success':
      toast(<Toast type="success" messageId={message} />, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'styled_toast success-toast',
        ...toastProps,
      })
      break
    case 'warning':
      toast.warn(message, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      break
    case 'error':
      toastProps.autoClose = false
      toast(<Toast type="error" messageId={message} />, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'styled_toast error-toast',
        ...toastProps,
      })
      break
    default:
      toast(message)
  }
}
