import styled from 'styled-components/macro'

import { InputGroup as InputGroupElement } from '../../elements/Inputs'
import { dimensions } from '../../theme/freebees'

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;

  input {
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`

export const Form = styled.form`
  width: 343px;
  margin: 0 auto;
`

export const FormWide = styled(Form)`
  width: 534px;
  margin: 40px 23px 0;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    width: 100%;
    margin: 0;
  }
`

export const Title = styled.div`
  line-height: 36px;
  font-size: 28px;
  text-align: center;
  width: 342px;
  margin: 0 auto;
`
export const InputGroup = styled(InputGroupElement)`
  width: 100%;
`

export const PageBackground = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.background};
  background-image: url('/assets/img/bees-bg-image.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  transition: opacity 0.5s;
`

export const ButtonContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`

export const BackIcon = styled.img`
  width: 8px;
  margin-right: 20px;
`
