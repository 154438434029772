import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'

import EmployeeRoleEditor from './EmployeeRoleEditor'
import UPDATE_EMPLOYEE_ROLE from '../../../graphql/mutations/updateEmployeeRole'
import { selectCompanyRoles } from '../../../redux/company/company.selector'

const mapStateToProps = createStructuredSelector({
  roles: selectCompanyRoles,
})

export default compose(
  injectIntl,
  connect(mapStateToProps, null),
  graphql(UPDATE_EMPLOYEE_ROLE)
)(EmployeeRoleEditor)
