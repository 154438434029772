import styled from 'styled-components/macro'

export const TextArea = styled.textarea`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 52px;
  max-height: ${props => `${props.maxHeight}px`};
  width: 100%;
  height: 82px;
  padding: 15px;
  resize: vertical;
  font-size: 16px;

  &:focus {
    outline-width: 0;
    border: 1px solid #ff9152;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`
