import React, { Component } from 'react'
import { func } from 'prop-types'

import { ForgotPasswordView } from './ForgotPasswordView'
import validation from '../../../../common/services/validation'
import notify from '../../../../common/services/notification'
import { AuthService } from '../../../../common/services/auth'
import { getCognitoMessage } from '../../../../common/constants/errorCodes'
import { IntlType } from '../../../../common/prop-types'

class ForgotPassword extends Component {
  state = {
    formData: {
      email: '',
    },
    error: null,
    isLoading: false,
  }

  isValid = validation([
    [
      'email',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
      {
        rule: 'email',
        msg: this.props.intl.formatMessage({
          id: 'NotValidEmail',
        }),
      },
    ],
  ])

  validation = () => {
    const { error, messages } = this.isValid(this.state.formData)
    this.setState({ error: messages })
    return { error }
  }

  handleFormChange = change => {
    this.setState(({ formData }) => ({ formData: { ...formData, ...change } }))
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { error: validationError } = this.validation()

    if (!validationError) {
      const {
        formData: { email },
      } = this.state

      this.setState({ isLoading: true })

      try {
        await AuthService.forgotPassword(email)
        notify('success', 'Notification.ForgotPassword.Submit.Success')
        this.props.onBackClick()
      } catch (error) {
        const message = getCognitoMessage(
          error,
          'Notification.ForgotPassword.Failed'
        )

        notify('error', message)
      }

      this.setState({ isLoading: false })
    }
  }

  render() {
    const { error, formData, isLoading } = this.state

    return (
      <ForgotPasswordView
        onSubmit={this.handleSubmit}
        error={error}
        isLoading={isLoading}
        formData={formData}
        onFormDataChange={this.handleFormChange}
        onBackClick={this.props.onBackClick}
      />
    )
  }
}

ForgotPassword.propTypes = {
  onBackClick: func.isRequired,
  intl: IntlType.isRequired,
}

export default ForgotPassword
