import styled from 'styled-components'

export const InviteLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.colors.orange};

  &:hover {
    text-decoration: underline;
  }
`

export const Banner = styled.div`
  font-size: 12px;
  background: ${p => p.theme.colors.orangeVeryPale};
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${p => p.theme.zIndex.modal};

  @media ${({ theme }) => theme.media.mediumDesktop} {
    top: 52px;
  }

  & > a {
    margin-left: 1em;
  }
`
