import React from 'react'
import styled from 'styled-components/macro'
import { FormattedMessage } from 'react-intl'

import { shape, func, string, bool } from 'prop-types'

import ReportPeriodSelector from '../ReportPeriodSelector'

import { EmployeeListType } from '../../../../common/prop-types'
import Button from '../../../../components/Button'
import { Spacer } from '../../../../components/styles/Spacer'
import {
  H1,
  GridColumnWrapper,
  Module,
  H2,
  Separator,
} from '../../../../components/styles'
import EmployeeSelector from '../EmployeeSelector'
import SelectedEmployees from '../SelectedEmployees'
import ContentFilterFieldSelector from '../ContentFilterFieldSelector'
import ReportDownloadSection from '../ReportDownloadSection'
import { ButtonGroup } from '../../../../components/styles/Button'

export const DownloadIcon = styled.img`
  width: 24px;
  height: 24px;
`

export const StyledButton = styled(Button)`
  padding-right: 25px;
  padding-left: 25px;
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 900px;
  grid-column-gap: 40px;
`

const ReportView = ({
  formData,
  onChange,
  onEmployeeSelectionChange,
  employeeSeachValue,
  onEmployeeSeachValueChange,
  onSendReportByEmailClick,
  onDownloadClick,
  isValid,
  downloadPending,
  onFilterChange,
  employees,
  loading,
}) => {
  const activeEmployees = employees.filter(employee => employee.active)
  const inactiveEmployees = employees.filter(employee => !employee.active)

  return (
    <>
      <Spacer mt={45} mb={40}>
        <H1>
          <FormattedMessage id="Report.Title" />
        </H1>
      </Spacer>

      <Grid>
        <GridColumnWrapper column={1}>
          <Module height={864} style={{ overflow: 'auto' }}>
            <Spacer p={20}>
              <EmployeeSelector
                loading={loading}
                activeEmployees={activeEmployees}
                inactiveEmployees={inactiveEmployees}
                searchValue={employeeSeachValue}
                onSearchValueChange={onEmployeeSeachValueChange}
                onEmployeeSelectionChange={onEmployeeSelectionChange}
                selectedEmployees={formData.selectedEmployees}
                allSelected={formData.selectAllEmployees}
              />
            </Spacer>
          </Module>
        </GridColumnWrapper>
        <GridColumnWrapper column={2}>
          <Module>
            <Spacer px={40} pt={40} pb={30}>
              <H2 mb={23}>
                <FormattedMessage id="Report.SelectedEmployees.Title" />
              </H2>
              <SelectedEmployees
                employees={formData.selectedEmployees}
                onRemove={employee =>
                  onEmployeeSelectionChange([employee], false)
                }
                onUncheckAllSelected={e => onEmployeeSelectionChange(e, false)}
                allSelected={
                  formData.selectedEmployees.length === employees.length
                }
                allActiveSelected={
                  formData.selectedEmployees.every(
                    employee => employee.active
                  ) &&
                  formData.selectedEmployees.length === activeEmployees.length
                }
              />
            </Spacer>

            <Separator />

            <Spacer px={40} pt={40} pb={29} width={530}>
              <H2 mb={40}>
                <FormattedMessage id="Report.Period.Title" />
              </H2>
              <ReportPeriodSelector
                data={formData.periodSelector}
                onSelectionChanged={change =>
                  onChange({ periodSelector: change })
                }
              />
            </Spacer>

            <Separator />

            <Spacer px={40} pt={40} pb={14}>
              <H2 mb={30}>
                <FormattedMessage id="Report.Filters.Title" />
              </H2>
              <ContentFilterFieldSelector
                filterGroups={formData.filters}
                onChange={onFilterChange}
              />
            </Spacer>
          </Module>
          <Spacer
            my={40}
            mx={0}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ReportDownloadSection
              selectedDownloadType={formData.selectedDownloadType}
              onSelectionChanged={change =>
                onChange({ selectedDownloadType: change })
              }
            />
            <ButtonGroup>
              <StyledButton
                primary
                id="download-report"
                type="button"
                disabled={!isValid || downloadPending}
                onClick={onDownloadClick}
              >
                <Spacer display="flex" alignItems="center">
                  <Spacer mr={7}>
                    <DownloadIcon
                      src="assets/icons/download.svg"
                      alt="download"
                    />
                  </Spacer>
                  <FormattedMessage id="Report.Button.Download" />
                </Spacer>
              </StyledButton>
              <StyledButton
                primary
                bordered
                loading={false}
                narrow
                type="button"
                id="send-report-by-email"
                onClick={onSendReportByEmailClick}
                disabled={!isValid || downloadPending}
              >
                <FormattedMessage id="Report.Button.Send.By.Email" />
              </StyledButton>
            </ButtonGroup>
          </Spacer>
        </GridColumnWrapper>
      </Grid>
    </>
  )
}
ReportView.propTypes = {
  employeeSeachValue: string.isRequired,
  formData: shape({ periodSelector: shape({}) }),
  isValid: bool.isRequired,
  downloadPending: bool.isRequired,
  onChange: func.isRequired,
  onDownloadClick: func.isRequired,
  onEmployeeSeachValueChange: func.isRequired,
  onEmployeeSelectionChange: func.isRequired,
  onSendReportByEmailClick: func.isRequired,
  onFilterChange: func.isRequired,
  employees: EmployeeListType,
  loading: bool,
}
export default ReportView
