import React, { Component } from 'react'
import { shape, string } from 'prop-types'
import { startOfMonth, endOfMonth } from 'date-fns'
import CalendarPageView from './CalendarView'
import { HistoryType } from '../../../common/prop-types'
import { statusCalculator } from '../../../common/utils/holidayRequest'
import { trackPageView } from '../../../common/services/analytics'
import { fixDate } from '../../../common/utils/calendar'

const viewModes = {
  calendar: 'calendar',
  list: 'list',
}

class CalendarPage extends Component {
  state = {
    viewMode:
      this.props.location.pathname === '/calendar/list'
        ? viewModes.list
        : viewModes.calendar,
    employeeSearchValue: '',
    holidayRequestFilterValue: [
      'APPROVED',
      'REQUESTED',
      'PLANNED',
      'SICK_LEAVE',
    ],
    selectedDate: new Date(),
  }

  componentDidMount() {
    trackPageView({ page: 'Calendar' })
  }

  handleViewModeChange = mode => {
    this.setState({ viewMode: mode })
    this.props.history.push(`/calendar/${mode}`)
  }

  handleHolidayRequestFilterChange = filters => {
    this.setState({ holidayRequestFilterValue: filters })
  }

  handleEmployeeSearchValueChange = event => {
    this.setState({ employeeSearchValue: event.target.value })
  }

  handleDateChange = ({ date }) => {
    this.setState({
      selectedDate: date,
    })
  }

  handleJumpCurrentMonth = () => {
    this.setState({
      selectedDate: new Date(),
    })
  }

  render() {
    const {
      viewMode,
      employeeSearchValue,
      holidayRequestFilterValue,
      selectedDate,
    } = this.state

    const employeesFilter = {
      search: employeeSearchValue,
      active: true,
    }

    const holidayRequestsFilter = {
      statusesByType: statusCalculator(holidayRequestFilterValue),
      intervalStart: fixDate(startOfMonth(selectedDate)),
      intervalEnd: fixDate(endOfMonth(selectedDate)),
    }

    const companyCalendarVariables = {
      employeesFilter,
      employeeOrders: [{ orderBy: 'NAME', order: 'ASC' }],
      holidayRequestsFilter,
    }

    const requestListVariables = {
      filter: {
        search: employeeSearchValue,

        statusesByType: statusCalculator(holidayRequestFilterValue),
        intervalStart: fixDate(startOfMonth(selectedDate)),
        intervalEnd: fixDate(endOfMonth(selectedDate)),
        activeEmployees: true,
      },

      orders: [{ orderBy: 'START_DATE', order: 'ASC' }],
    }

    const props = {
      onHolidayRequestFilterChange: this.handleHolidayRequestFilterChange,
      onViewModeChange: this.handleViewModeChange,
      onEmployeeSearchValueChange: this.handleEmployeeSearchValueChange,
      viewMode,
      employeeSearchValue,
      holidayRequestFilterValue,
      viewModes,
      companyCalendarVariables,
      requestListVariables,
      selectedDate,
      onJumpCurrentMonth: this.handleJumpCurrentMonth,
      onDateChange: this.handleDateChange,
    }

    return <CalendarPageView {...props} />
  }
}

CalendarPage.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  history: HistoryType.isRequired,
}

export default CalendarPage
