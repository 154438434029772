import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import {
  selectIsAdmin,
  selectIsTutorialShown,
} from '../../redux/auth/auth.selector'
import { setShowTutorial } from '../../redux/auth/auth.reducer'
import UserDropdownList from './UserDropdownList'

const mapStateToProps = createStructuredSelector({
  isTutorialShown: selectIsTutorialShown,
  isAdmin: selectIsAdmin,
})

const mapDispatchToProps = {
  setShowTutorial,
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserDropdownList)
