import styled from 'styled-components/macro'

const getHolidayIndicatorBackgroundColor = ({ theme, type, status }) => {
  if (type === 'VACATION') {
    if (status === 'PLANNED') {
      return theme.colors.calendarPlannedColor
    }
    if (status === 'APPROVED') {
      return theme.colors.calendarApprovedColor
    }
    if (status === 'REQUESTED') {
      return theme.colors.calendarRequestedColor
    }
    if (status === 'REJECTED') {
      return theme.colors.redBright
    }
  }

  if (type === 'SICK_LEAVE') {
    return theme.colors.calendarSickLeaveColor
  }

  if (type === 'SELECTION') {
    return theme.colors.calendarRequestedColor
  }

  return theme.colors.grayLight
}

export const HolidayCellContainer = styled.div`
  grid-column-start: ${({ startCell }) => startCell};
  grid-column-end: ${({ endCell }) => endCell};
  grid-row-start: ${({ row }) => row};
  grid-row-end: span 1;
  align-self: center;

  .inner {
    height: 20px;
    background-color: ${getHolidayIndicatorBackgroundColor};

    border-top-left-radius: ${({ openFromLeft }) =>
      openFromLeft ? 'none' : '4px'};
    border-bottom-left-radius: ${({ openFromLeft }) =>
      openFromLeft ? 'none' : '4px'};
    border-top-right-radius: ${({ openFromRight }) =>
      openFromRight ? 'none' : '4px'};
    border-bottom-right-radius: ${({ openFromRight }) =>
      openFromRight ? 'none' : '4px'};
    cursor: pointer;
    transition: all 0.25s;

    &:hover,
    &.active {
      box-shadow: 5px 5px 5px ${({ theme }) => theme.colors.modalShadowColor};
      transform: scale(1.01);
      position: relative;
      z-index: 1;
    }

    &:active {
      box-shadow: none;
    }
  }
`
