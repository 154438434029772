const isAdmin = ({ role }) => role && role.name.toLowerCase() === 'admin'

const isEmployee = ({ role }) => !role || role.name.toLowerCase() === 'user'

const transformChildren = (chinldren = []) =>
  chinldren.map(child => ({
    ...child,
    birthDate: new Date(child.birthDate),
  }))

export const transformCompanyHolidays = (chs = []) =>
  chs.map(ch => ({
    ...ch,
    date: new Date(ch.date),
  }))

export const transformHolidayRequest = (hr = {}) => ({
  ...hr,
  startDate: new Date(hr.startDate),
  endDate: new Date(hr.endDate),
  statusChangeDate: new Date(hr.statusChangeDate),
})

export const transformHolidayRequestList = (hrs = []) =>
  hrs.map(hr => transformHolidayRequest(hr))

export const transformEmployee = (e = {}) => ({
  ...e,
  holidayRequests: transformHolidayRequestList(e.holidayRequests),
  isAdmin: isAdmin(e),
  isEmployee: isEmployee(e),
  children: transformChildren(e.children),
  teams: e.teams,
})

const filterActiveEmployees = (employeesList = []) =>
  employeesList.filter(employee => employee.active)

export const transformEmployeeList = (el = []) =>
  el.map(e => transformEmployee(e))

export const transformCompanyData = (
  companyData = { holidayRequests: [], employees: [] }
) => ({
  ...companyData,
  activeEmployees: transformEmployeeList(
    filterActiveEmployees(companyData.employees)
  ),
  holidayRequests: transformHolidayRequestList(companyData.holidayRequests),
  employees: transformEmployeeList(companyData.employees),
  companyHolidays: transformCompanyHolidays(companyData.companyHolidays),
})

export const transformQueryData = data => {
  const transformedData = {}

  if (data && data.holidayRequests) {
    transformedData.holidayRequests = transformHolidayRequestList(
      data.holidayRequests
    )
  }
  if (data && data.employees) {
    transformedData.employees = transformEmployeeList(data.employees)
  }
  if (data && data.currentCompany) {
    transformedData.currentCompany = transformCompanyData(data.currentCompany)
  }

  if (data && data.currentEmployee) {
    transformedData.currentEmployee = transformEmployee(data.currentEmployee)
  }

  return transformedData
}
