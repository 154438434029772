import React from 'react'
import * as Sentry from '@sentry/browser'

import { node } from 'prop-types'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorInfo: null,
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)
    this.setState({
      errorInfo,
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
        </div>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
}

export default ErrorBoundary
