import React from 'react'
import { shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  FunctionType,
  HolidayRequestStatusListType,
  DateType,
  HolidayRequestTypeType,
} from '../../../../common/prop-types'
import SimpleDateSelector from '../../../../components/SimpleDateSelector'
import { Header } from './styles'
import { H2 } from '../../../../components/styles/Text'
import WithQuery from '../../../../components/WithQuery'
import { QueryActiveRequests } from '../../../../graphql/queries/holidayRequests'
import RequestListModule from '../../../../components/RequestListModule'

const RequestListModuleWithQuery = WithQuery(RequestListModule)

const headerTitleTranslations = {
  SICK_LEAVE: 'Dashboard.ActiveSickLeaves.Title',
  VACATION: 'Dashboard.ActiveHolidays.Title',
  default: 'Dashboard.ActiveHolidays.Title',
}

export default function OnHolidayModuleView({
  onDateChange,
  onHolidayVariables,
  selectedDate,
}) {
  const requestType =
    onHolidayVariables.filter.statusesByType &&
    onHolidayVariables.filter.statusesByType.type

  const titleTranslationId =
    headerTitleTranslations[requestType] || headerTitleTranslations.default

  return (
    <>
      <Header flexDirection={['column', 'row']} mb={[0, 20]}>
        <H2>
          <FormattedMessage id={titleTranslationId} />
        </H2>
        <SimpleDateSelector
          onChange={onDateChange}
          date={selectedDate}
          step="day"
        />
      </Header>

      <RequestListModuleWithQuery
        query={QueryActiveRequests}
        variables={onHolidayVariables}
        showWhenLoading
        showErrorMessage
        onHolidayMode
        showEmployee
        showStatus={false}
      />
    </>
  )
}

OnHolidayModuleView.propTypes = {
  onDateChange: FunctionType.isRequired,
  onHolidayVariables: shape({
    filter: shape({
      statusesByType: shape({
        type: HolidayRequestTypeType.isRequired,
        statuses: HolidayRequestStatusListType.isRequired,
      }).isRequired,
      intervalStart: DateType.isRequired,
      intervalEnd: DateType.isRequired,
    }).isRequired,
  }).isRequired,
  selectedDate: DateType.isRequired,
}
