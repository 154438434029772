import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import CREATE_COMPANY from '../../graphql/mutations/createCompany'
import { GqlClientType, HistoryType } from '../../common/prop-types'
import notify from '../../common/services/notification'
import { AuthService } from '../../common/services/auth'
import CompanyRegistrationView from './CompanyRegistrationView'
import { trackRegistration } from '../../common/services/analytics'
import { getCognitoMessage } from '../../common/constants/errorCodes'

const createCompanyRegRequestData = (
  companyFormData,
  userFormData,
  selectedLang = ''
) => {
  const { companyName: name } = companyFormData
  const { firstName, lastName, email } = userFormData
  return {
    name,
    employee: {
      firstName,
      lastName,
      email,
      selectedLang:
        selectedLang && selectedLang.toUpperCase
          ? selectedLang.toUpperCase()
          : 'hu',
    },
  }
}

const createSignUpRequestData = ({ email, firstName, password }, { code }) => ({
  username: email,
  password,
  attributes: {
    email,
    'custom:language': code.toUpperCase(),
    'custom:firstName': firstName,
  },
})

const createSignInRequestData = ({ email, password }) => ({
  email,
  password,
})

export const steps = {
  companyName: { name: 'companyName', showBack: false, nextStep: 'userReg' },
  userReg: {
    name: 'userReg',
    showBack: true,
    nextStep: 'emailVerification',
    prevStep: 'companyName',
  },
  emailVerification: {
    name: 'emailVerification',
    showBack: true,
    prevStep: 'userReg',
  },
}

class CompanyRegistration extends PureComponent {
  state = {
    pending: false,
    currentStep: steps.companyName,
    companyFormData: {
      companyName: '',
    },
    userFormData: {
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      termsAccepted: false,
    },
    verificationFormData: {
      confirmationCode: '',
    },
  }

  handleBack = currentStep => {
    if (currentStep.prevStep) {
      this.setState({ currentStep: steps[currentStep.prevStep] })
    }
  }

  handleCompanyDataSubmit = ({ companyName }) => {
    this.setState(({ companyFormData }) => ({
      currentStep: steps.userReg,
      companyFormData: { ...companyFormData, companyName },
    }))
  }

  handleCompanyDataChange = change => {
    this.setState(({ companyFormData }) => ({
      companyFormData: { ...companyFormData, ...change },
    }))
  }

  handleUserRegistrationChange = change => {
    this.setState(({ userFormData }) => ({
      userFormData: { ...userFormData, ...change },
    }))
  }

  handleUserRegistrationSubmit = async () => {
    const { history, selectedLocale } = this.props
    const { companyFormData, userFormData } = this.state

    const signupData = createSignUpRequestData(userFormData, selectedLocale)
    const signInData = createSignInRequestData(userFormData)
    const companyData = createCompanyRegRequestData(
      companyFormData,
      userFormData,
      selectedLocale.code
    )

    try {
      this.setState({ pending: true })

      this.props.setRegistrationInProgress(true)

      await Promise.all([
        await this.signUpUser(signupData),
        await this.signInUser(signInData),
        await this.createCompany(companyData),
      ])
      this.props.setAuthenticated(true)
      this.props.setRegistrationInProgress(false)

      trackRegistration()

      history.push('/dashboard')
    } catch (error) {
      if (error && error.code === 'InvalidParameterException') {
        error.code = 'InvalidPasswordException'
      }
      const message = getCognitoMessage(error, 'Notification.Login.Failed')
      notify('error', message)
    }
    this.setState({ pending: false })
  }

  signInUser = async ({ email, password } = {}) => {
    await AuthService.signIn(email, password)
  }

  signUpUser = async signupData => {
    await AuthService.signUp(signupData)
  }

  createCompany = async data => {
    const { client } = this.props

    await client.mutate({
      mutation: CREATE_COMPANY,
      variables: { input: data },
    })
  }

  handleEmailVerificationSubmit = async () => {
    const { history } = this.props
    const {
      verificationFormData: { confirmationCode },
      userFormData: { email },
    } = this.state

    try {
      await AuthService.confirmSignUp(email, confirmationCode)
      history.push('/dashboard')
    } catch (error) {
      const message = getCognitoMessage(
        error,
        'Notification.EmailVerification.Failed'
      )
      notify('error', message)
    }
  }

  handleEmailVerificationChange = change => {
    this.setState(({ verificationFormData }) => ({
      verificationFormData: { ...verificationFormData, ...change },
    }))
  }

  render() {
    const {
      companyFormData,
      currentStep,
      userFormData,
      verificationFormData,
      pending,
    } = this.state

    const props = {
      companyFormData,
      currentStep,
      onBackPress: this.handleBack,
      onCompanyDataSubmit: this.handleCompanyDataSubmit,
      onCompanyDataChange: this.handleCompanyDataChange,
      onVerificationCodeSubmit: this.handleEmailVerificationSubmit,
      onVerificationCodeChange: this.handleEmailVerificationChange,
      onUserRegistrationSubmit: this.handleUserRegistrationSubmit,
      onUserRegistrationChange: this.handleUserRegistrationChange,
      pending,
      steps,
      userFormData,
      verificationFormData,
    }

    return <CompanyRegistrationView {...props} />
  }
}
CompanyRegistration.propTypes = {
  client: GqlClientType.isRequired,
  history: HistoryType.isRequired,
  selectedLocale: PropTypes.shape({ code: PropTypes.string.isRequired }),
  setRegistrationInProgress: PropTypes.func.isRequired,
  setAuthenticated: PropTypes.func.isRequired,
}

CompanyRegistration.defaultProps = {
  selectedLocale: { code: 'EN' },
}

export default CompanyRegistration
