import styled from 'styled-components/macro'

export const ModalCloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 35px;
  width: 12px;
  height: 12px;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media ${({ theme }) => theme.media.mobile} {
    justify-content: center;
  }
`
