import { func, shape, string } from 'prop-types'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  EmployeeType,
  TabListType,
  TabType,
} from '../../../../common/prop-types'
import { Module } from '../../../../components/styles'
import { Spacer } from '../../../../components/styles/Spacer'
import EmployeeProfileInfo from '../EmployeeProfileInfo'
import TabMenu from '../TabMenu'
import EmployeePersonalInfoTab from './EmployeePersonalInfoTab'
import EmployeeTimeOff from './EmployeeTimeOffTab'

const EmployeeProfileView = ({
  activeTab,
  onTabChange,
  tabs,
  employee,
  redirectUrl,
  match,
}) => {
  if (!employee) {
    return 'no such employee'
  }

  return (
    <Spacer mb={100}>
      <EmployeeProfileInfo employee={employee} />
      <Module>
        <TabMenu
          id="tab-menu"
          active={activeTab}
          tabs={tabs}
          onChange={tab => onTabChange(tab)}
        />

        <Switch>
          <Route
            path={`${match.path}/timeoff`}
            name="Timeoff"
            component={() => (
              <EmployeeTimeOff id="employee-time-off" employee={employee} />
            )}
          />
          <Route
            path={`${match.path}/info`}
            name="calendar"
            component={() => (
              <EmployeePersonalInfoTab
                id="employee-personal-info"
                employee={employee}
              />
            )}
          />
          <Redirect
            to={{
              pathname: redirectUrl,
            }}
          />
        </Switch>
      </Module>
    </Spacer>
  )
}

EmployeeProfileView.propTypes = {
  activeTab: TabType.isRequired,
  onTabChange: func.isRequired,
  tabs: TabListType.isRequired,
  employee: EmployeeType.isRequired,
  redirectUrl: string.isRequired,
  match: shape({}),
}

EmployeeProfileView.displayName = 'EmployeeProfileView'

export default EmployeeProfileView
