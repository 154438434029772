import { func } from 'prop-types'
import React, { Component } from 'react'

import { HorizontalLine } from './style'
import { GqlDataType } from '../../../../common/prop-types'
import AddTagModal from '../AddTagModal'
import CompanyDictionaryEditor from '../CompanyDictionaryEditor'
import CompanyHolidayList from '../CompanyHolidayList'
import { Padding } from '../../../../components/styles'
import TagList from '../../../../components/TagList'
import compileHolidayList from './compileHolidayList'

const copy = {
  companyTeams: {
    title: 'CompanyTeams.Title',
    createModal: {
      title: 'CompanyTeams.Create.Title',
      currentListLabel: 'CompanyTeams.Create.CurrentItemsLabel',
      inputPlaceholder: 'CompanyTeams.Create.InputPlaceholder',
      newItemLabel: 'CompanyTeams.Create.NewItemLabel',
    },
    deleteModal: {
      question: 'CompanyTeams.Delete.Question',
      title: 'CompanyTeams.Delete.Title',
    },
  },
  companyPositions: {
    title: 'CompanyPositions.Title',
    createModal: {
      title: 'CompanyPositions.Create.Title',
      currentListLabel: 'CompanyPositions.Create.CurrentItemsLabel',
      inputPlaceholder: 'CompanyPositions.Create.InputPlaceholder',
      newItemLabel: 'CompanyPositions.Create.NewItemLabel',
    },
    deleteModal: {
      question: 'CompanyPositions.Delete.Question',
      title: 'CompanyPositions.Delete.Title',
    },
  },
  companyHolidays: {
    title: 'CompanyHolidays.Title',
    createModal: {
      title: 'CompanyHolidays.Create.Title',
      currentListLabel: 'CompanyHolidays.Create.CurrentItemsLabel',
      inputPlaceholder: 'CompanyHolidays.Create.InputPlaceholder',
      newItemLabel: 'CompanyHolidays.Create.NewItemLabel',
    },
    deleteModal: {
      question: 'CompanyHolidays.Delete.Question',
      title: 'CompanyHolidays.Delete.Title',
    },
  },
}

export class CompanyInfo extends Component {
  render() {
    const {
      company: {
        currentCompany: {
          teams = [],
          jobTitles = [],
          companyHolidays = [],
        } = {},
        refetch,
      },
      deleteTeam,
      deletePosition,
      createTeam,
      createPosition,
      deleteCompanyHoliday,
      createCompanyHoliday,
      nationalHolidaysQuery,
    } = this.props

    const { nationalHolidays } = nationalHolidaysQuery

    return (
      <>
        <Padding v h lg>
          <CompanyDictionaryEditor
            id="company-dictionary-editor"
            items={compileHolidayList(companyHolidays, nationalHolidays)}
            isHolidaySection
            copy={copy.companyHolidays}
            deleteItem={deleteCompanyHoliday}
            createItem={createCompanyHoliday}
            updateItems={refetch}
            renderList={CompanyHolidayList}
            emptyListTextId="CompanyProfile.CompanyHolidays.Empty"
            onAddItemClick={() => {
              this.props.openHolidayModal({
                requestType: 'COMPANY_HOLIDAY',
                selectedEmployee: null,
                startDate: new Date(),
                endDate: new Date(),
              })
            }}
          />
        </Padding>

        <HorizontalLine />

        <Padding v h lg>
          <CompanyDictionaryEditor
            items={jobTitles}
            copy={copy.companyPositions}
            isHolidaySection={false}
            updateItems={refetch}
            deleteItem={deletePosition}
            createItem={createPosition}
            renderList={TagList}
            RenderAddItemModal={AddTagModal}
            emptyListTextId="CompanyProfile.Positions.Empty"
          />
        </Padding>

        <HorizontalLine />

        <Padding v h lg>
          <CompanyDictionaryEditor
            items={teams}
            isHolidaySection={false}
            copy={copy.companyTeams}
            deleteItem={deleteTeam}
            createItem={createTeam}
            updateItems={refetch}
            renderList={TagList}
            RenderAddItemModal={AddTagModal}
            emptyListTextId="CompanyProfile.Teams.Empty"
          />
        </Padding>
      </>
    )
  }
}

CompanyInfo.propTypes = {
  company: GqlDataType.isRequired,
  deleteTeam: func.isRequired,
  deletePosition: func.isRequired,
  createTeam: func.isRequired,
  createPosition: func.isRequired,
  deleteCompanyHoliday: func.isRequired,
  createCompanyHoliday: func.isRequired,
  openHolidayModal: func.isRequired,
  nationalHolidaysQuery: GqlDataType.isRequired,
}

export default CompanyInfo
