import styled from 'styled-components/macro'

export const ItemListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`
