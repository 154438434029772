import React from 'react'
import { func, string, shape, bool, arrayOf } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import {
  ModalFooter,
  ModalVerticalInputContainer,
  InputGroup,
} from '../../../../elements'
import ChildEditor from './ChildEditor'
import { ChildListType, DateType } from '../../../../common/prop-types'
import { ChildEditorContainer } from './styles'
import BulkUploadUsers from '../BulkUploadUsers'
import {
  getEmployeeAgeSelectionRules,
  getHireDateSelectionRules,
} from '../../../../common/services/dateSelectionRules'
import Modal from '../../../../components/Modal'
import { Spacer } from '../../../../components/styles/Spacer'
import { TextLink, Text, H2 } from '../../../../components/styles'
import Input from '../../../../components/Input'
import { ButtonGroup } from '../../../../components/styles/Button'
import Button from '../../../../components/Button'

const AddEmployeeModalView = ({
  formData,
  isLoading,
  isOpen,
  isBulkUploadOpen,
  jobTitles,
  filterOptions,
  onAddChild,
  onChangeChild,
  onClose,
  onRemoveChild,
  onSubmit,
  onValueChange,
  onBulkUploadOpen,
  onBulkUploadClose,
  onBulkUploadSubmit,
  validationErrors,
  childValidationErrors,
}) => {
  const employeeAgeRules = getEmployeeAgeSelectionRules()
  const hireDateRules = getHireDateSelectionRules(formData.birthDate)
  const maxNumberOfHireDate =
    hireDateRules.maxDate.getFullYear() - hireDateRules.minDate.getFullYear()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      titleKey="AddEmployeeModal.Title"
      content={() => (
        <Spacer width={720}>
          <BulkUploadUsers
            isOpen={isBulkUploadOpen}
            onClose={onBulkUploadClose}
            onSubmit={onBulkUploadSubmit}
            isLoading={isLoading}
          />
          <Spacer textAlign="center" mb={30}>
            <TextLink onClick={onBulkUploadOpen}>
              <FormattedMessage id="AddEmployeeModal.Bulk" />
            </TextLink>
          </Spacer>

          <ModalVerticalInputContainer mb={10}>
            <InputGroup block>
              <Input
                label="Input.LastName.Label"
                id="last-name"
                placeholder="Input.LastName.Placeholder"
                value={formData.lastName}
                onChange={event =>
                  onValueChange({ lastName: event.target.value })
                }
                error={validationErrors.lastName}
              />
            </InputGroup>
            <InputGroup block>
              <Input
                label="Input.FirstName.Label"
                id="first-name"
                placeholder="Input.FirstName.Placeholder"
                value={formData.firstName}
                onChange={event =>
                  onValueChange({ firstName: event.target.value })
                }
                error={validationErrors.firstName}
              />
            </InputGroup>
          </ModalVerticalInputContainer>

          <ModalVerticalInputContainer mb={10}>
            <InputGroup block>
              <Input
                label="Input.Email.Label"
                id="email"
                placeholder="Input.Email.Placeholder"
                value={formData.email}
                onChange={event => onValueChange({ email: event.target.value })}
                error={validationErrors.email}
              />
            </InputGroup>

            <InputGroup block>
              <Input
                label="Input.Position.Label"
                id="position"
                type="selector"
                options={jobTitles}
                defaultValue={jobTitles[0]}
                filterOption={filterOptions}
                isSearchable={false}
                placeholder="Input.Position.Placeholder"
                value={formData.jobTitle}
                onChange={jobTitle => onValueChange({ jobTitle })}
                selectorStyle="addEmployee"
              />
            </InputGroup>
          </ModalVerticalInputContainer>

          <ModalVerticalInputContainer mb={10}>
            <InputGroup block>
              <Input
                id="birth-date-input"
                label="Input.BirthDate.Label"
                type="date"
                showYearDropdown
                scrollableYearDropdown
                dateFormatCalendar="MMMM"
                showDisabledMonthNavigation
                selected={formData.birthDate}
                yearDropdownItemNumber={100}
                maxDate={employeeAgeRules.maxDate}
                minDate={employeeAgeRules.minDate}
                onChange={day => {
                  onValueChange({ birthDate: day })
                }}
              />
            </InputGroup>
            <InputGroup block>
              <Input
                id="first-workday-date"
                type="date"
                label="Input.FirstDay.Label"
                showYearDropdown
                scrollableYearDropdown
                dateFormatCalendar="MMMM"
                showDisabledMonthNavigation
                maxDate={hireDateRules.maxDate}
                minDate={hireDateRules.minDate}
                yearDropdownItemNumber={maxNumberOfHireDate}
                selected={formData.hireDate}
                onChange={day => {
                  onValueChange({ hireDate: day })
                }}
                error={validationErrors.hireDate}
              />
            </InputGroup>
          </ModalVerticalInputContainer>

          <ChildEditorContainer>
            <Spacer mt={40}>
              <H2 style={{ textAlign: 'center' }}>
                <FormattedMessage id="AddEmployeeModal.Children.Title" />
              </H2>
              <ChildEditor
                kids={formData.children}
                onChange={onChangeChild}
                onAdd={onAddChild}
                onRemove={onRemoveChild}
                childValidationErrors={childValidationErrors}
              />
            </Spacer>
          </ChildEditorContainer>

          <ModalFooter>
            <Spacer mt={40} mb={30} textAlign="center">
              <Text>
                <FormattedMessage id="AddEmployeeModal.Footer.Note" />
              </Text>
            </Spacer>
            <Spacer>
              <ButtonGroup>
                <Button primary bordered onClick={onClose}>
                  <FormattedMessage id="Button.Cancel" />
                </Button>
                <Button onClick={onSubmit} loading={isLoading}>
                  <FormattedMessage id="Button.AddUser" />
                </Button>
              </ButtonGroup>
            </Spacer>
          </ModalFooter>
        </Spacer>
      )}
    />
  )
}

AddEmployeeModalView.propTypes = {
  isOpen: bool,
  isBulkUploadOpen: bool,
  isLoading: bool,
  filterOptions: func,
  formData: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
    birthDate: DateType.isRequired,
    hireDate: DateType.isRequired,
    children: ChildListType.isRequired,
  }).isRequired,
  jobTitles: arrayOf(shape({})),
  validationErrors: shape({}),
  childValidationErrors: arrayOf(shape({})),
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  onValueChange: func.isRequired,
  onAddChild: func.isRequired,
  onRemoveChild: func.isRequired,
  onChangeChild: func.isRequired,
  onBulkUploadOpen: func.isRequired,
  onBulkUploadClose: func.isRequired,
  onBulkUploadSubmit: func.isRequired,
}

export default AddEmployeeModalView
