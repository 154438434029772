import styled from 'styled-components/macro'
import { display } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.25px;
  margin: 50px auto 20px;
  width: 100%;
`
export const PlaceholderImage = styled.img`
  ${display};
  width: 240px;
  margin: 20px 0;
`
