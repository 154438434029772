import React from 'react'
import { arrayOf, shape, string, func, bool } from 'prop-types'
import { Column } from './styles'
import { Spacer } from '../../../../components/styles/Spacer'
import CheckboxGroupVertical from '../../../../components/CheckboxGroupVertical'
import { IntlType } from '../../../../common/prop-types'

const ContentFilterFieldSelector = ({ intl, filterGroups, onChange }) => (
  <Spacer display="flex">
    {filterGroups.map(group => (
      <Column key={`group-${group.key}`}>
        <CheckboxGroupVertical
          intl={intl}
          groupKey={group.key}
          groupLabel={group.label}
          items={group.items}
          onChange={onChange}
          checked={group.checked}
        />
      </Column>
    ))}
  </Spacer>
)

ContentFilterFieldSelector.propTypes = {
  intl: IntlType.isRequired,
  filterGroups: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
      items: arrayOf(
        shape({
          key: string,
          label: string,
          checked: bool,
        }).isRequired
      ),
    })
  ),
  onChange: func.isRequired,
}

export default ContentFilterFieldSelector
