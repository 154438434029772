import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'
import EmployeeHolidaysModule from '../../EmployeeHolidaysModule'
import EmployeeTimeOffHeader from '../../EmployeeTimeOffHeader'
import { EmployeeType } from '../../../../../common/prop-types'
import EmployeeCalendar from '../../EmployeeCalendar'
import { Text } from '../../../../../components/styles'
import { RightControls } from '../../../../../elements'
import ViewSwith from '../../../../../components/ViewSwitch'
import RequestHolidayButton from '../../../../../components/RequestHolidayButton'
import SimpleDateSelector from '../../../../../components/SimpleDateSelector'
import { selectDate } from '../../../../../redux/profile/profile.reducer'
import { selectSelectedDate } from '../../../../../redux/profile/profile.selector'
import { ResponsiveCurrentMonthButton } from '../../../../../components/styles/Button'
import { Container, EmployeeTimeOffContainer } from './style'

export default function EmployeeTimeOffView({
  viewMode,
  viewModes,
  onViewModeChange,
  employee,
  match,
}) {
  const selectedDate = useSelector(selectSelectedDate)
  const dispatch = useDispatch()

  return (
    <Container>
      <EmployeeTimeOffContainer>
        <EmployeeTimeOffHeader employee={employee} />

        <RightControls>
          <div className="control">
            <ViewSwith
              viewMode={viewMode}
              viewModes={viewModes}
              onViewModeChange={onViewModeChange}
            />
          </div>
          <div className="control">
            <RequestHolidayButton employee={employee} />
          </div>
        </RightControls>
      </EmployeeTimeOffContainer>

      <RightControls>
        <div className="control">
          <SimpleDateSelector
            onChange={({ date }) => dispatch(selectDate(date))}
            step={viewMode === 'calendar' ? 'year' : 'month'}
            date={selectedDate}
          />
        </div>
        <div className="control">
          <ResponsiveCurrentMonthButton
            secondary
            bordered
            narrow
            color="gray"
            onClick={() => dispatch(selectDate(new Date()))}
          >
            <Text uppercase>
              <FormattedMessage
                id={
                  viewMode === 'calendar'
                    ? 'Button.CurrentYear'
                    : 'Button.CurrentMonth'
                }
              />
            </Text>
          </ResponsiveCurrentMonthButton>
        </div>
      </RightControls>

      <Switch>
        <Route
          path={`${match.path}/calendar`}
          name="employee-calendar"
          component={() => (
            <EmployeeCalendar
              date={selectedDate}
              holidayRequests={employee.holidayRequests}
            />
          )}
        />
        <Route
          path={`${match.path}/list`}
          name="employee-holidays"
          component={() => <EmployeeHolidaysModule employeeId={employee.id} />}
        />
        <Redirect
          to={{
            pathname: `${match.path}/calendar`,
          }}
        />
      </Switch>
    </Container>
  )
}

EmployeeTimeOffView.propTypes = {
  viewMode: PropTypes.string.isRequired,
  viewModes: PropTypes.shape({
    calendar: PropTypes.string,
    list: PropTypes.string,
  }),
  onViewModeChange: PropTypes.func.isRequired,
  employee: EmployeeType.isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }),
}

EmployeeTimeOffView.displayName = 'EmployeeTimeOffView'
