import React, { Component } from 'react'
import { func } from 'prop-types'
import {
  EmployeeType,
  IntlType,
  RoleListType,
} from '../../../common/prop-types'

import ConfirmActionModal from '../../Modals/ConfirmActionModal'
import notify from '../../../common/services/notification/notification'
import { refetchQueries } from '../../../common/constants/refetchQueries'
import { throwCustomError } from '../../../common/constants/errorCodes'
import Input from '../../Input'

class EmployeeRoleEditor extends Component {
  state = {
    isOpen: false,
    selectedRole: null,
    isLoading: false,
  }

  handleRoleChange = async () => {
    const { employee } = this.props
    const { selectedRole } = this.state

    this.setState({ isLoading: true })

    const { success, error } = await this.changeRole({
      employee,
      role: selectedRole,
    })

    if (success) {
      notify('success', 'Notification.Role.Change.Success')
    } else {
      notify('error', error.message)
    }

    this.setState({
      isOpen: false,
      selectedRole: null,
      isLoading: false,
    })
  }

  closeConfirmationModal = () => {
    this.setState({
      isOpen: false,
      selectedRole: null,
    })
  }

  changeRole = async ({ employee, role } = {}) => {
    const { mutate } = this.props

    try {
      const input = {
        employeeId: employee.id,
        roleId: role.value,
      }

      const result = await mutate({
        variables: { input },
        refetchQueries,
        errorPolicy: 'all',
      })

      throwCustomError(result, 'Notification.Role.Change.Failed')

      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleSelectorChange = selected => {
    const { employee } = this.props

    if (selected.value !== employee.role.id) {
      this.setState({
        selectedRole: selected,
        isOpen: true,
      })
    }
  }

  render() {
    const { employee, intl, roles } = this.props
    const { isOpen, selectedRole, isLoading } = this.state

    const options = roles.map(role => ({
      value: role.id,
      label: intl.formatMessage({ id: `User.Roles.{${role.name}}` }),
    }))

    const employeeRole = options.find(o => o.value === employee.role.id)

    const value = selectedRole || employeeRole

    return (
      <>
        {isOpen && (
          <ConfirmActionModal
            isOpen={isOpen}
            onSubmit={this.handleRoleChange}
            onClose={this.closeConfirmationModal}
            question={intl.formatMessage(
              {
                id: 'EmployeeRole.Change.Modal.Question',
              },
              {
                name: `${employee.lastName} ${employee.firstName}`,
                role: selectedRole.label,
              }
            )}
            title={intl.formatMessage({
              id: 'EmployeeRole.Change.Modal.Title',
            })}
            isLoading={isLoading}
          />
        )}
        <Input
          type="selector"
          id="roleCell"
          options={options}
          value={value}
          withBorder={false}
          onChange={this.handleSelectorChange}
          selectorStyle="roleSelector"
          isSearchable={false}
          hideErrors
        />
      </>
    )
  }
}

EmployeeRoleEditor.propTypes = {
  employee: EmployeeType.isRequired,
  mutate: func.isRequired,
  intl: IntlType.isRequired,
  roles: RoleListType.isRequired,
}

export default EmployeeRoleEditor
