import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CallbackElement } from './styles'

const DropDownCallbackLink = ({ title, callback, show = true }) => {
  if (!show) {
    return null
  }

  return (
    <CallbackElement onClick={callback}>
      <FormattedMessage id={title} />
    </CallbackElement>
  )
}

DropDownCallbackLink.propTypes = {
  title: PropTypes.string,
  callback: PropTypes.func,
  show: PropTypes.bool,
}

export default DropDownCallbackLink
