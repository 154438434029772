import { compose } from 'redux'
import { connect } from 'react-redux'

import CompanyCalendar from './CompanyCalendar'
import { openHolidayModal } from '../../../../redux/holiday-modal/holiday-modal.reducer'

const mapDispatchToProps = {
  openHolidayModal,
}

export default compose(connect(null, mapDispatchToProps))(CompanyCalendar)
