import React from 'react'
import { bool, node } from 'prop-types'
import { Button as StyledButton } from '../styles/Button'
import { LoadingIndicator } from '../styles/LoadingIndicator'

const Button = ({ loading, disabled, children, ...props }) => (
  <StyledButton {...props} disabled={disabled}>
    {loading ? <LoadingIndicator /> : children}
  </StyledButton>
)

Button.propTypes = {
  loading: bool,
  disabled: bool,
  children: node,
}

export default Button
