import {
  addDays,
  eachDayOfInterval,
  endOfYear,
  getYear,
  isSameDay,
  isSameYear,
  isWeekend,
  max,
  min,
  startOfYear,
} from 'date-fns'
import { calculateHolidayCountersForYear } from '../utils/calculations'

let companyHolidays = []
let nationalHolidays = []
let compensationDays = []

const isItWorkingDay = ({
  isItWeekend,
  nationalHoliday,
  compensationDay,
  companyHoliday,
}) =>
  ((!isItWeekend && !nationalHoliday) || !!compensationDay) && !companyHoliday

export const setCompanyHolidays = holidays => {
  companyHolidays = holidays
}
export const setNationalHolidays = holidays => {
  nationalHolidays = holidays
}
export const setCompensationDays = holidays => {
  compensationDays = holidays
}

export const filterNotWorkingDays = date => {
  const isItWeekend = isWeekend(date)
  const nationalHoliday = nationalHolidays.find(hd => isSameDay(hd.date, date))
  const compensationDay = compensationDays.find(hd => isSameDay(hd.date, date))
  const companyHoliday = companyHolidays.find(hd => isSameDay(hd.date, date))
  return isItWorkingDay({
    isItWeekend,
    nationalHoliday,
    compensationDay,
    companyHoliday,
  })
}

export function getStartOfClosestWorkingDay(dateToCheck) {
  let workingDay = dateToCheck
  while (!filterNotWorkingDays(workingDay)) {
    workingDay = addDays(workingDay, 1)
  }
  return workingDay
}

export const getWorkdaysInYear = () =>
  eachDayOfInterval({
    start: startOfYear(new Date()),
    end: endOfYear(new Date()),
  }).filter(date => !filterNotWorkingDays(date))

const getWorkdaysInRage = ({ startDate, endDate }) =>
  eachDayOfInterval({ start: startDate, end: endDate }).filter(
    filterNotWorkingDays
  ).length

const getDistinctYears = years => [...new Set(years)]

const getPartialHolidayForYear = ({
  holidayRequest: { startDate, endDate },
  year,
}) => {
  const yearAsDate = new Date(year, 1)
  const hasPartInGivenYear =
    isSameYear(yearAsDate, startDate) || isSameYear(yearAsDate, endDate)

  if (!hasPartInGivenYear) {
    return null
  }

  return {
    startDate: max([startDate, startOfYear(yearAsDate)]),
    endDate: min([endDate, endOfYear(yearAsDate)]),
  }
}

const getRemainingHolidaysByYear = ({
  holidayYears,
  holidayRequest,
  owner,
  originalHolidayRequest,
}) =>
  holidayYears.map(year => {
    const partialHoliday = getPartialHolidayForYear({ holidayRequest, year })
    const partialOriginalHolidayRequest =
      originalHolidayRequest &&
      getPartialHolidayForYear({
        holidayRequest: originalHolidayRequest,
        year,
      })

    const { availableHolidays } = calculateHolidayCountersForYear(owner, year)

    const originalWorkdays = partialOriginalHolidayRequest
      ? getWorkdaysInRage(partialOriginalHolidayRequest)
      : 0

    const newWorkdays = getWorkdaysInRage(partialHoliday)

    const remainingDays = availableHolidays + originalWorkdays - newWorkdays

    return {
      year,
      availableHolidays,
      remainingDays,
    }
  })

export const calculateRemainingHolidaysPerYear = ({
  owner,
  holidayRequest,
  originalHolidayRequest,
}) => {
  const holidayYears = getDistinctYears([
    getYear(holidayRequest.startDate),
    getYear(holidayRequest.endDate),
  ])

  return getRemainingHolidaysByYear({
    holidayYears,
    holidayRequest,
    owner,
    originalHolidayRequest,
  })
}
