import { Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

import styled from 'styled-components/macro'
import { space } from 'styled-system'
import { dimensions } from '../../theme/freebees'

const mobileWidth = 1240

export const Container = styled.div`
  z-index: ${p => p.theme.zIndex.header};
  position: fixed;
  width: 100%;
  top: 0px;

  display: grid;

  grid-template-columns: auto ${mobileWidth}px auto;
  grid-template-rows: auto;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.16);

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    grid-template-columns: auto;
    width: 100vw;
  }
`

export const Content = styled.div`
  grid-column: 2;
  display: grid;
  height: 60px;
  grid-template-columns: 120px auto;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    display: none;
  }
`

export const Logo = styled(Link)`
  grid-column: 1;
  background-image: url('/assets/img/FreebeesOrange.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
`

export const MenuItems = styled.div`
  justify-self: end;
  align-self: center;
  display: flex;
  align-items: center;
`

export const MenuItem = styled(NavHashLink)`
  ${space}
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.menuTextColor};
  font-weight: bold;

  &.active {
    color: ${({ theme }) => theme.colors.orange};
  }
`

export const ExternalLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.menuTextColor};
  font-weight: bold;
`

export const MobileContent = styled.div`
  grid-column: 1;
  display: grid;

  grid-template-columns: 120px auto 120px;

  align-content: center;

  padding: 10px 10px;

  @media screen and (min-width: ${mobileWidth}px) {
    display: none;
  }
`
export const MobileRightContent = styled.div`
  grid-column: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export const HamburgerMenu = styled.div`
  width: 30px;
  height: 30px;
  background-image: url('/assets/icons/mobile-menu.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  align-self: center;
  justify-self: end;
  cursor: pointer;
`

export const MobileDropdown = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  transform: ${({ open }) => (open ? 'scaleY(1)' : 'scaleY(0)')};
`

export const MobileMenuItems = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MobileMenuItem = styled(MenuItem)`
  font-size: '18px';
  text-align: center;

  &.active {
    color: ${({ theme }) => theme.colors.orange};
  }
`

export const MobileExternalLink = styled(ExternalLink)`
  font-size: '18px';
  text-align: center;
  margin-top: 20px;
`
