import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { shape, string } from 'prop-types'

import EmployeeProfile from '../components/EmployeeProfile'
import WithQuery from '../../../components/WithQuery'
import { QueryEmployeeProfile } from '../../../graphql/queries/employees'
import { selectCurrentUserId } from '../../../redux/auth/auth.selector'
import { usePageTracking } from '../../../hooks/usePageTracking'

const EmployeeProfileWithQuery = WithQuery(EmployeeProfile)

const ProfilePage = ({
  match: {
    params: { id },
    path,
  },
}) => {
  usePageTracking('EmployeeProfile')
  const currentUserId = useSelector(selectCurrentUserId)

  const employeeIds = path === '/me' ? currentUserId : id
  return (
    <EmployeeProfileWithQuery
      query={QueryEmployeeProfile}
      showErrorMessage
      showWhenLoading
      variables={{
        filter: {
          employeeIds,
        },
      }}
    />
  )
}

ProfilePage.propTypes = {
  match: shape({ params: shape({ id: string }) }).isRequired,
}

export default memo(ProfilePage)
