import styled from 'styled-components/macro'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
  // margin-top: -9px;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: ${({ checked, color = 'salmon' }) =>
    checked ? color : 'white'};
  border-radius: 2px;
  border: 1px solid
    ${({ checked, color = 'salmon', theme }) =>
      checked ? color : theme.colors.grayLight};
  transition: all 150ms;
  cursor: pointer;
  /* ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.grayVeryLight};
  }
  ${HiddenCheckbox}:hover + & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.grayVeryLight};
  } */

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

export const CheckboxText = styled.span`
  color: ${({ theme, boldText }) =>
    boldText ? theme.colors.grayVeryDark : theme.colors.checkboxText};
  padding-left: 15px;
  font-weight: ${({ boldText }) => (boldText ? 600 : 'normal')};
  font-size: ${({ boldText }) => (boldText ? '16px' : '14px')};
  line-height: 22px;
`

export const Label = styled.label`
  margin: 0 15px 0 0;
`
