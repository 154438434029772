import { withRouter } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import AddNewUsersModal from './AddNewUsersModal'
import { selectCompanyEmployees } from '../../../../redux/company/company.selector'

const mapStateToProps = createStructuredSelector({
  employees: selectCompanyEmployees,
})

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(AddNewUsersModal)
