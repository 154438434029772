import React, { PureComponent } from 'react'
import { string, shape } from 'prop-types'
import { FunctionType } from '../../../common/prop-types'
import EmailVerificationFormView from './EmailVerificationFormView'
import validation from '../../../common/services/validation'

class EmailVerificationForm extends PureComponent {
  state = {
    error: null,
    valid: false,
  }

  isValid = validation([['confirmationCode', 'required']])

  validation = () => {
    const { error, messages } = this.isValid(this.state)
    this.setState({ valid: !error, error: messages })
  }

  handleInputChange = change => {
    const { onInputChange } = this.props
    this.setState(change, () => this.validation())

    onInputChange(change)
  }

  handleSubmit = async () => {
    const { onSubmit } = this.props
    const { code } = this.state

    onSubmit({ code })
  }

  render() {
    const { formData } = this.props
    const { confirmationCode } = formData

    const props = {
      confirmationCode,
      onSubmit: this.handleSubmit,
      onChange: this.handleInputChange,
    }
    return <EmailVerificationFormView {...props} />
  }
}

EmailVerificationForm.propTypes = {
  onSubmit: FunctionType.isRequired,
  onInputChange: FunctionType.isRequired,
  formData: shape({
    confirmationCode: string.isRequired,
  }).isRequired,
}
export default EmailVerificationForm
