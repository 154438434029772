import styled from 'styled-components/macro'
import { colors } from '../../theme/freebees'

export const Container = styled.div`
  padding: 0px 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  height: 52px;
  display: inline-flex;
  border-radius: 4px;

  background: ${props =>
    `${props.selected ? colors.blueLightGrayish : colors.grayVeryLight};`}

  cursor: ${props => `${props.selectable ? 'pointer' : 'default'}`};
`

export const Text = styled.div`
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 35px;
`

export const CloseImage = styled.img`
  margin-left: 15px;
  align-self: center;
  cursor: pointer;
  width: 10px;
  height: 10px;
`
export const CheckImage = styled.img`
  align-self: center;
  cursor: pointer;
  width: 15px;
  height: 9px;
  margin-right: 9px;
`
