import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonGroup } from '../../styles/Button'

import { Description } from './styles'
import Button from '../../Button'
import TagList from '../../TagList/TagList'
import Modal from '../../Modal'
import { Spacer } from '../../styles/Spacer'

export class SelectTagModal extends Component {
  state = {
    selectedItems: this.props.selectedItems,
  }

  prepareItems = items => {
    const { selectedItems } = this.state

    return items.map(item =>
      selectedItems.some(selectedItem => selectedItem.id === item.id)
        ? { ...item, selected: true }
        : item
    )
  }

  handleSelectionChange = item => {
    const { selectedItems } = this.state

    const newList = selectedItems.some(
      selectedItem => selectedItem.id === item.id
    )
      ? selectedItems.filter(selectedItem => selectedItem.id !== item.id)
      : [...selectedItems, item]

    this.setState({ selectedItems: newList })
  }

  render() {
    const { onClose, copy, isOpen, onSubmit, items, onGoToProfile } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleKey={copy.title}
        content={() => (
          <Spacer maxWidth={500}>
            <Description>
              {items.length ? (
                <FormattedMessage id={copy.description} />
              ) : (
                <FormattedMessage id="Employee.AddToTeamModal.NoTeams" />
              )}
            </Description>

            <Spacer mt={22} mb={30}>
              <TagList
                items={this.prepareItems(items)}
                onSelectionChanged={this.handleSelectionChange}
              />
            </Spacer>

            <ButtonGroup>
              <Button
                primary
                bordered
                narrow
                id="close-modal"
                type="button"
                onClick={onClose}
              >
                <FormattedMessage id="Button.Cancel" />
              </Button>
              {items.length ? (
                <Button
                  primary
                  narrow
                  onClick={() => {
                    onSubmit(this.state.selectedItems)
                  }}
                  id="submit-button"
                  type="button"
                >
                  <FormattedMessage id="Button.Apply" />
                </Button>
              ) : (
                <Button
                  primary
                  onClick={onGoToProfile}
                  id="submit-button"
                  type="button"
                >
                  <FormattedMessage id="Button.GoToCompanyProfile" />
                </Button>
              )}
            </ButtonGroup>
          </Spacer>
        )}
      />
    )
  }
}

SelectTagModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onGoToProfile: PropTypes.func.isRequired,
  copy: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  items: PropTypes.instanceOf(Array),
  selectedItems: PropTypes.instanceOf(Array),
}

export default SelectTagModal
