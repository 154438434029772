import styled from 'styled-components/macro'
import Button from '../../../../../../components/Button'

export const Title = styled.div`
  display: inline-block;
  height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`

export const TitleButtonWrapper = styled.div`
  display: flex;
`

export const AddButton = styled(Button)`
  width: 161px;
  height: 42px;
  padding: 0px;
  min-width: 91px;
  align-self: center;

  @media ${({ theme }) => theme.media.mobile} {
    width: initial;
    margin-top: 10px;
    padding: 0 10px;
  }
`

export const TagSection = styled.div`
  margin-top: 30px;
`
