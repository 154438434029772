import React, { Component } from 'react'
import { oneOf, oneOfType, string, func, bool, number } from 'prop-types'

import { FormattedMessage } from 'react-intl'
import {
  Container,
  SmallValue,
  SmallInput,
  BigInput,
  EditImageBig,
  EditImageSmall,
} from './style'
import Button from '../Button'
import { ButtonGroup } from '../styles/Button'
import { H1 } from '../styles'

export class EditableField extends Component {
  state = {
    isEditing: false,
    value: this.props.value,
  }

  handleSave = async () => {
    const { onChange } = this.props

    await onChange(this.state.value)
    this.setState({ isEditing: false })
  }

  handleEdit = () => {
    this.setState({ isEditing: true })
  }

  handleInputChange = value => {
    this.setState({ value })
  }

  handleCancel = () => {
    this.setState({ isEditing: false, value: this.props.value })
  }

  render() {
    const { isEditing, value } = this.state
    const { type, value: initialValue } = this.props

    const isValidNumber = () => {
      const intValue = parseInt(value, 10)
      const isNotEmpty = !!value || value === 0
      const isDifferent = intValue !== initialValue
      const { isNegativeAllowed } = this.props
      const isNotNegative = intValue >= 0

      return isNotEmpty && isDifferent && (isNegativeAllowed || isNotNegative)
    }

    const isValidText = () => {
      const isNotEmpty = !!value
      const isDifferent = value !== initialValue

      return isNotEmpty && isDifferent
    }

    const isValid = () => {
      const isNumericField = this.props.type === 'number'

      return isNumericField ? isValidNumber() : isValidText()
    }

    return isEditing ? (
      <Container>
        {this.props.big ? (
          <BigInput
            className="editable-input"
            value={value}
            type={type}
            onChange={event => this.handleInputChange(event.target.value)}
            maxLength={this.props.maxLength}
          />
        ) : (
          <SmallInput
            className="editable-input"
            value={value}
            type={type}
            onChange={event => this.handleInputChange(event.target.value)}
            maxLength={this.props.maxLength}
            min={this.props.isNegativeAllowed ? null : 0}
          />
        )}

        <ButtonGroup>
          <Button
            secondary
            orange
            className="save-button"
            type="button"
            onClick={this.handleSave}
            loading={this.props.isLoading}
            disabled={!isValid()}
            style={{
              width: '91px',
              height: '42px',
              padding: '0px',
              border: '0px',
              minWidth: '91px',
            }}
          >
            <FormattedMessage id="Button.Save" />
          </Button>
          <Button
            secondary
            bordered
            reversed
            style={{
              width: '91px',
              height: '42px',
              padding: '0px',
              minWidth: '91px',
            }}
            onClick={this.handleCancel}
            className="cancel-button"
          >
            <FormattedMessage id="Button.Cancel" />
          </Button>
        </ButtonGroup>
      </Container>
    ) : (
      <Container>
        {this.props.big ? (
          <H1>{this.props.value}</H1>
        ) : (
          <SmallValue>{this.props.value}</SmallValue>
        )}

        {this.props.big ? (
          <EditImageBig
            src="/assets/icons/edit.svg"
            alt="Edit"
            className="edit-button"
            onClick={this.handleEdit}
          />
        ) : (
          <EditImageSmall
            src="/assets/icons/edit.svg"
            alt="Edit"
            className="edit-button"
            onClick={this.handleEdit}
          />
        )}
      </Container>
    )
  }
}

EditableField.propTypes = {
  value: oneOfType([string, number]),
  onChange: func.isRequired,
  big: bool,
  isLoading: bool,
  type: oneOf(['text', 'number']),
  maxLength: number,
  isNegativeAllowed: bool,
}

EditableField.defaultProps = {
  type: 'text',
}

export default EditableField
