import React from 'react'
import MadeItModalContent from './MadeItModal.content'
import Modal from '../../../../components/Modal'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'

const MadeItModal = () => {
  const [isMadeIdModalDismissed, setIsMadeIdModalDismissed] = useLocalStorage(
    'MIM_ClosedBySubmission',
    false
  )

  return (
    !isMadeIdModalDismissed && (
      <Modal
        isOpen={!isMadeIdModalDismissed}
        onClose={() => setIsMadeIdModalDismissed(true)}
        titleKey="MadeIt.Title"
        content={() => (
          <MadeItModalContent submit={() => setIsMadeIdModalDismissed(true)} />
        )}
      />
    )
  )
}

export default MadeItModal
