import gql from 'graphql-tag'

const QueryNationalHolidays = gql`
  query nationalHolidays {
    nationalHolidays {
      id
      date
      name
    }
  }
`

export default QueryNationalHolidays
