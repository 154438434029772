import gql from 'graphql-tag'

const UPDATE_SELECTED_LANGUAGE = gql`
  mutation updateEmployeeSelectedLang(
    $input: UpdateEmployeeSelectedLangInput!
  ) {
    updateEmployeeSelectedLang(input: $input)
  }
`
export default UPDATE_SELECTED_LANGUAGE
