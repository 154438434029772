import React, { Component } from 'react'

import { endOfDay, startOfDay } from 'date-fns'
import OnHolidayModuleView from './OnHolidayModuleView'
import { fixTimezoneOffset } from '../../../../common/utils/calendar'
import { HolidayRequestTypeType } from '../../../../common/prop-types'

class OnHolidayModule extends Component {
  state = {
    selectedDate: new Date(),
  }

  handleDateChange = ({ date }) => {
    this.setState({
      selectedDate: date,
    })
  }

  render() {
    const { selectedDate } = this.state
    const { holidayRequestType } = this.props

    const onHolidayVariables = {
      filter: {
        statusesByType: {
          type: holidayRequestType,
          statuses: ['APPROVED'],
        },
        intervalStart: fixTimezoneOffset(startOfDay(selectedDate)),
        intervalEnd: fixTimezoneOffset(endOfDay(selectedDate)),
      },
    }

    const props = {
      selectedDate,
      onHolidayVariables,
      onDateChange: this.handleDateChange,
    }

    return <OnHolidayModuleView {...props} />
  }
}

OnHolidayModule.propTypes = {
  holidayRequestType: HolidayRequestTypeType.isRequired,
}

export default OnHolidayModule
