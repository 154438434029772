import gql from 'graphql-tag'

export const SendHolidayRequestsReportInEmail = gql`
  mutation sendHolidayRequestsReportInEmail(
    $input: CreateHolidayRequestsReportInput!
  ) {
    sendHolidayRequestsReportInEmail(input: $input)
  }
`

export const DownloadHolidayRequestsReport = gql`
  mutation downloadHolidayRequestsReport(
    $input: CreateHolidayRequestsReportInput!
  ) {
    downloadHolidayRequestsReport(input: $input)
  }
`
