import React from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as Spinner } from './spinner.svg'

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Loading = () => (
  <LoadingContainer>
    <Spinner />
  </LoadingContainer>
)

export default Loading
