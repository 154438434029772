import styled from 'styled-components/macro'
import { H1 } from '../../../../components/styles'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const EmployeeForm = styled.form`
  display: flex;
  margin-bottom: 0;

  input {
    margin-right: 10px;
    margin-bottom: 0;
  }

  input + .button-group {
    margin-left: 10px;
  }

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    margin-bottom: 20px;

    input {
      margin-bottom: 10px;
    }

    input + .button-group {
      margin-left: 0;
    }
  }
`

export const Input = styled.input`
  width: 202px;
  height: 52px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 15px;
  outline: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.orange};
  }
`

export const EmployeeName = styled(H1)`
  @media ${({ theme }) => theme.media.mobile} {
    font-size: 22px;
    word-break: break-all;
  }
`
