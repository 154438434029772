import gql from 'graphql-tag'

const UPDATE_COMPANY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
    }
  }
`
export default UPDATE_COMPANY
