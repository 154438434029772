import React from 'react'
import LandingView from './LandingView'
import { usePageTracking } from '../../../../hooks/usePageTracking'

const LandingPage = () => {
  usePageTracking('Landing')

  return <LandingView />
}

LandingPage.propTypes = {}

export default LandingPage
