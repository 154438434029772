import React from 'react'
import { HolidayRequestType, FunctionType } from '../../../common/prop-types'
import EntityControl from '../../EntityControl'
import { refetchQueries } from '../../../common/constants/refetchQueries'
import { events } from '../../../common/services/analytics'

const RequestStatusTransform = ({ mutate, holidayRequest, renderTrigger }) =>
  holidayRequest.status === 'PLANNED' ? (
    <EntityControl
      mutate={mutate}
      entity={holidayRequest}
      refetchQueries={refetchQueries}
      suppressConfirmation
      renderTrigger={renderTrigger}
      eventName={events.HolidayConvert}
    />
  ) : null

RequestStatusTransform.propTypes = {
  holidayRequest: HolidayRequestType.isRequired,
  mutate: FunctionType.isRequired,
  renderTrigger: FunctionType.isRequired,
}

export default RequestStatusTransform
