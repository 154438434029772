import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  data: null,
}

export const SET_COMPANY_DATA = 'SET_COMPANY_DATA'

export const { setCompanyData } = createActions({}, SET_COMPANY_DATA)

export default handleActions(
  {
    [setCompanyData]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  defaultState
)
