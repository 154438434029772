import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  Container,
  Title,
  ChildInfoTitle,
  TitleWrapper,
  TitleContent,
  TitleIndicator,
  TitleButtonWrapper,
  ActionIcon,
  AddButton,
} from './styles'
import { Padding } from '../EmployeeBaseInfo/styles'
import { EmployeeType } from '../../../../../../common/prop-types'
import ChildModal from '../../../../../../components/Modals/ChildModal'
import { H2 } from '../../../../../../components/styles'
import { localeFormat } from '../../../../../../components/DateField/DateField'
import { Spacer } from '../../../../../../components/styles/Spacer'
import { selectActiveLanguage } from '../../../../../../redux/language-selector/language-selector.selector'

const EmployeeChildInfoView = ({
  employee,
  isAdmin,

  isAddChildModalOpen,
  onAddChildModalOpen,
  onAddChildModalClose,
  onChildEditRequest,
  onChildDeleteRequest,
  onAddChildModalSubmit,
  isChildSectionBusy,
  childModalData,
}) => {
  const { code } = useSelector(selectActiveLanguage)
  return (
    <Container>
      {isAddChildModalOpen && (
        <ChildModal
          loading={isChildSectionBusy}
          isOpen={isAddChildModalOpen}
          onClose={onAddChildModalClose}
          onDelete={onChildDeleteRequest}
          onSubmit={onAddChildModalSubmit}
          parent={`${employee.firstName} ${employee.lastName}`}
          data={childModalData}
        />
      )}
      <>
        <H2>
          <TitleWrapper>
            <Title>
              <TitleContent>
                <FormattedMessage id="Employee.PersonalInfoTab.Children.Title" />
              </TitleContent>
              <TitleIndicator>{employee.children.length}</TitleIndicator>
            </Title>

            {isAdmin && (
              <TitleButtonWrapper>
                <AddButton
                  secondary
                  bordered
                  narrow
                  loading={isChildSectionBusy}
                  orange
                  className="save-button"
                  type="button"
                  onClick={onAddChildModalOpen}
                >
                  <FormattedMessage id="Employee.PersonalInfoTab.Children.Add" />
                </AddButton>
              </TitleButtonWrapper>
            )}
          </TitleWrapper>
        </H2>
        <Padding />
        {employee.children.map(child => (
          <ChildInfoTitle key={child.id}>
            <div>
              <FormattedMessage
                id="Employee.PersonalInfoTab.ChildInfo"
                values={{
                  name: child.firstName,
                  birthDate: localeFormat(child.birthDate, code),
                }}
              />
            </div>
            {isAdmin && (
              <Spacer ml={20} alignItems="center" display="flex">
                <ActionIcon
                  src="/assets/icons/edit.svg"
                  alt="edit"
                  onClick={() => onChildEditRequest(child)}
                />
              </Spacer>
            )}
          </ChildInfoTitle>
        ))}
      </>
    </Container>
  )
}

EmployeeChildInfoView.propTypes = {
  employee: EmployeeType.isRequired,

  isAddChildModalOpen: PropTypes.bool,
  onAddChildModalOpen: PropTypes.func.isRequired,
  onAddChildModalClose: PropTypes.func.isRequired,
  onAddChildModalSubmit: PropTypes.func.isRequired,

  onChildEditRequest: PropTypes.func.isRequired,
  onChildDeleteRequest: PropTypes.func.isRequired,
  childModalData: PropTypes.shape({}).isRequired,
  isChildSectionBusy: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
}

export default injectIntl(EmployeeChildInfoView)
