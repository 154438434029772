import React from 'react'
import { shape, string, func, number } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Spacer } from '../../../../components/styles/Spacer'
import { Header } from '../OnHolidayModule/styles'
import { H2, HolidayCounter } from '../../../../components/styles'
import RequestList from '../../../../components/RequestList'
import EmptyListPlaceholder from '../../../../components/EmptyListPlaceholder'
import LoadingOverlay from '../../../../components/LoadingOverlay'
import { HolidayRequestListType, SvgType } from '../../../../common/prop-types'

const PendingRequestModule = ({
  data: { holidayRequests = [] },
  titleId,
  placeholderImage,
  placeholderTextId,
  renderStatusControl,
  networkStatus,
}) => {
  const showEmtyListIndicator = !holidayRequests.length

  return (
    <Spacer p={[10, 40]}>
      <Header>
        <H2 mb={[10, 20]}>
          <FormattedMessage id={titleId} />
          {holidayRequests && (
            <HolidayCounter>{holidayRequests.length}</HolidayCounter>
          )}
        </H2>
      </Header>
      <RequestList
        id="request-list"
        holidayRequests={holidayRequests}
        showEmployee
        showStatus={false}
        dashboardMode
        renderStatusControl={renderStatusControl}
      />
      {showEmtyListIndicator && (
        <EmptyListPlaceholder
          svg={placeholderImage}
          textId={placeholderTextId}
        />
      )}
      <LoadingOverlay networkStatus={networkStatus} />
    </Spacer>
  )
}

PendingRequestModule.propTypes = {
  data: shape({
    holidayRequests: HolidayRequestListType,
  }),
  titleId: string,
  placeholderImage: SvgType,
  placeholderTextId: string,
  renderStatusControl: func,
  networkStatus: number,
}

PendingRequestModule.defaultProps = {
  data: { holidayRequests: [] },
}

export default PendingRequestModule
