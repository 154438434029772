import React from 'react'
import { string, instanceOf, func, shape, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonLink } from '../../../../components/styles/Button'
import Button from '../../../../components/Button'
import { H2 } from '../../../../components/styles/Text'
import { Spacer } from '../../../../components/styles/Spacer'
import { Form } from '../../../../components/styles/Form'
import Input from '../../../../components/Input'
import { StyledElevated } from './styles'

export const LoginView = ({
  error,
  formData,
  isLoading,
  onForgotPasswordClick,
  onFormChange,
  onFormSubmit,
}) => (
  <Spacer pt={40}>
    <StyledElevated mx="auto">
      <H2 textAlign="center" mb={40}>
        <FormattedMessage id="Login.Title" />
      </H2>

      <Form onSubmit={onFormSubmit}>
        <Input
          autoFocus
          error={error && error.email}
          id="email"
          label="Input.Email.Label"
          name="email"
          onChange={onFormChange}
          placeholder="Input.Email.Placeholder"
          required
          type="email"
          autoComplete="username"
          value={formData.email}
        />
        <Input
          error={error && error.password}
          id="password"
          label="Input.Password.Label"
          name="password"
          onChange={onFormChange}
          placeholder="Input.Password.Placeholder"
          required
          type="password"
          autoComplete="current-password"
          value={formData.password}
        />
        <Spacer textAlign="center" mt={10}>
          <Button
            width={252}
            primary
            type="submit"
            id="submit-button"
            loading={isLoading}
          >
            <FormattedMessage id="Button.Login" />
          </Button>

          <ButtonLink
            mt={10}
            primary
            id="forgot-password-button"
            type="button"
            onClick={onForgotPasswordClick}
          >
            <FormattedMessage id="Button.ForgotPassword" />
          </ButtonLink>
        </Spacer>
      </Form>
    </StyledElevated>
  </Spacer>
)

LoginView.propTypes = {
  error: instanceOf(Object),
  formData: shape({
    email: string.isRequired,
    password: string.isRequired,
  }),
  isLoading: bool,
  onForgotPasswordClick: func,
  onFormChange: func,
  onFormSubmit: func,
}

export default LoginView
