import styled from 'styled-components/macro'
import { flexDirection, justifyContent } from 'styled-system'

export const Container = styled.div`
  ${flexDirection};
  ${justifyContent};
  display: flex;
  align-items: center;
`
export const Control = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: ${({ type }) => {
    if (type === 'button') return '5px'
    if (type === 'icon') return '5px'
    return '10px'
  }};
`
