import * as Sentry from '@sentry/browser'

import packageJson from '../../../../package.json'

export const init = () => {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: packageJson.version,
    })
  }
}

export function configureScope({ email, id, username }) {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id,
        email,
        username,
      })
    })
  }
}
