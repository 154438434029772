import React from 'react'
import { bool, func } from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { TableCell, TableRow } from './styles'
import UserName from '../UserName'
import { HolidayRequestListType } from '../../common/prop-types'
import RequestStatusControl from '../RequestStatusControl'
import DateField from '../DateField'
import { Text } from '../styles/Text'
import { cutText } from '../../common/utils/text'
import { localeFormat } from '../DateField/DateField'
import { selectActiveLanguage } from '../../redux/language-selector/language-selector.selector'

const RequestListBody = ({
  holidayRequests,
  showEmployee,
  showStartDate,
  showEndDate,
  showType,
  showDescription,
  showStatusControl,
  showStatus,
  showDelete,
  dashboardMode,
  renderStatusControl,
}) => {
  const { code } = useSelector(selectActiveLanguage)

  return (
    <tbody>
      {holidayRequests.map(request => {
        const employeeAdditionalInfo = dashboardMode
          ? `${localeFormat(request.startDate, code)} - ${localeFormat(
              request.endDate,
              code
            )}`
          : request.employee.jobTitle
        return (
          <React.Fragment key={request.id}>
            <TableRow borderBottom={['none']}>
              {showEmployee && (
                <TableCell>
                  <UserName
                    additionalInfo={employeeAdditionalInfo}
                    employee={request.employee}
                  />
                </TableCell>
              )}
              {showStartDate && (
                <TableCell textAlign={['right']}>
                  <DateField date={request.startDate} />
                </TableCell>
              )}
              {showEndDate && (
                <TableCell textAlign={['right']}>
                  <DateField date={request.endDate} />
                </TableCell>
              )}
              {showType && (
                <TableCell>
                  <FormattedMessage
                    id={`HolidayRequest.Type.${request.type}`}
                  />
                </TableCell>
              )}
              {showDescription && (
                <TableCell>
                  <Text title={request.description}>
                    {cutText(request.description, 15)}
                  </Text>
                </TableCell>
              )}

              {showStatus && (
                <TableCell>
                  <Text title={request.description}>
                    <FormattedMessage
                      id={`HolidayRequest.Status.${request.status}`}
                    />
                  </Text>
                </TableCell>
              )}

              {showStatusControl && (
                <TableCell display={['none', 'table-cell']}>
                  {renderStatusControl ? (
                    renderStatusControl({ holidayRequest: request })
                  ) : (
                    <RequestStatusControl
                      holidayRequest={request}
                      iconsFirst={dashboardMode}
                      showApprove
                      showDelete={showDelete}
                      showEdit
                      showReject
                      showTransform
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
            {showStatusControl && (
              <TableRow display={['table-row', 'none']} borderTop={['none']}>
                <TableCell position="sticky" left={0}>
                  {renderStatusControl ? (
                    renderStatusControl({ holidayRequest: request })
                  ) : (
                    <RequestStatusControl
                      holidayRequest={request}
                      iconsFirst={dashboardMode}
                      showApprove
                      showDelete={showDelete}
                      showEdit
                      showReject
                      showTransform
                    />
                  )}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        )
      })}
    </tbody>
  )
}

RequestListBody.propTypes = {
  holidayRequests: HolidayRequestListType.isRequired,
  renderStatusControl: func,
  showEmployee: bool,
  dashboardMode: bool,
  showStartDate: bool,
  showEndDate: bool,
  showType: bool,
  showDescription: bool,
  showStatus: bool,
  showStatusControl: bool,
  showDelete: bool,
}

export default RequestListBody
