import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { eachDayOfInterval } from 'date-fns'
import AddHolidayModalView from './AddHolidayModalView'
import {
  FunctionType,
  HolidayRequestType,
  EmployeeType,
  EmployeeListType,
} from '../../../common/prop-types'
import validation from '../../../common/services/validation'
import notify from '../../../common/services/notification/notification'
import UserName from '../../UserName'
import { refetchQueries } from '../../../common/constants/refetchQueries'
import { fixDate } from '../../../common/utils/calendar'
import { getHolidayRequestDateSelectionRules } from '../../../common/services/dateSelectionRules'
import {
  trackHolidayCreation,
  trackHolidayUpdate,
} from '../../../common/services/analytics'
import { throwCustomError } from '../../../common/constants/errorCodes'
import { calculateRemainingHolidaysPerYear } from '../../../common/services/workdays'
import { sortAlphabeticallyByLanguage } from '../../../common/utils/text'

class AddHolidayModal extends Component {
  isValid = validation([['employee', 'required']])

  constructor(props) {
    super(props)
    const { modalData, currentUserData, activeEmployees } = this.props

    const dateSelectionRules = getHolidayRequestDateSelectionRules({
      holidayRequest: modalData.holidayRequest,
      employee: currentUserData,
      requestType: modalData.requestType,
    })

    this.state = {
      formData: {
        employee: this.getEmployee(),
        startDate: modalData.startDate || dateSelectionRules.startDate,
        endDate: modalData.endDate || dateSelectionRules.endDate,
        comment: modalData.holidayRequest
          ? modalData.holidayRequest.description
          : '',
      },
      isLoading: false,
      isConverting: false,
      valid: false,
      viewConfig: this.getViewConfig(),
      alphabeticallySortedEmployees: sortAlphabeticallyByLanguage(
        activeEmployees,
        'hu'
      ),
    }
  }

  componentDidMount() {
    this.setState(({ formData }) => ({
      formData: { ...formData, employee: this.getEmployee() },
    }))
    this.validation()
  }

  getEmployee() {
    const {
      currentUserData,
      modalData: { holidayRequest, selectedEmployee },
      activeEmployees,
    } = this.props
    let employeeId

    if (holidayRequest) {
      employeeId = holidayRequest.employee.id
    } else {
      employeeId = selectedEmployee ? selectedEmployee.id : currentUserData.id
    }

    const employee = activeEmployees.find(e => e.id === employeeId)
    return {
      value: employee,
      label: (
        <UserName employee={employee} suppressRedirection textLength={30} />
      ),
    }
  }

  getViewConfig() {
    const {
      modalData: { holidayRequest, requestType, isEmployeeSelectable },
      currentUserData,
    } = this.props

    const createSickLeaveTitle = currentUserData.isAdmin
      ? 'RequestHoliday.Admin.Create.Title.SickLeave'
      : 'RequestHoliday.Create.Title.SickLeave'
    const createVacationTitle = currentUserData.isAdmin
      ? 'RequestHoliday.Admin.Create.Title.Vacation'
      : 'RequestHoliday.Create.Title.Vacation'
    const createPlannedTitle = currentUserData.isAdmin
      ? 'RequestHoliday.Admin.Create.Title.Plan'
      : 'RequestHoliday.Create.Title.Plan'

    const rules = getHolidayRequestDateSelectionRules({
      employee: currentUserData,
      holidayRequest,
      requestType,
    })

    const viewConfigs = {
      VACATION: {
        title: createVacationTitle,
        updateTitle: 'RequestHoliday.Update.Title.Vacation',
        showEmployeeSelector: currentUserData.isAdmin,
        isEmployeeSelectable: !!(
          isEmployeeSelectable && currentUserData.isAdmin
        ),
        showDaysLeft: true,
        type: 'VACATION',
        status: 'REQUESTED',
        minDate: rules.minDate,
        maxDate: rules.maxDate,
      },
      SICK_LEAVE: {
        title: createSickLeaveTitle,
        updateTitle: 'RequestHoliday.Update.Title.SickLeave',
        showEmployeeSelector: currentUserData.isAdmin,
        isEmployeeSelectable: !!(
          isEmployeeSelectable && currentUserData.isAdmin
        ),
        showDaysLeft: false,
        type: 'SICK_LEAVE',
        status: 'REQUESTED',
        minDate: rules.minDate,
        maxDate: rules.maxDate,
      },
      PLANNED: {
        title: createPlannedTitle,
        updateTitle: 'RequestHoliday.Update.Title.Plan',
        showEmployeeSelector: false,
        showDaysLeft: true,
        type: 'VACATION',
        status: 'PLANNED',
        minDate: rules.minDate,
        maxDate: rules.maxDate,
      },
      COMPANY_HOLIDAY: {
        title: 'RequestHoliday.Create.Title.CompanyHoliday',
        updateTitle: 'RequestHoliday.Update.Title.CompanyHoliday',
        showEmployeeSelector: false,
        showDaysLeft: false,
        minDate: rules.minDate,
        maxDate: rules.maxDate,
        type: 'COMPANY_HOLIDAY',
      },
    }

    const viewConfig = viewConfigs[requestType]

    if (holidayRequest) viewConfig.title = viewConfig.updateTitle

    return viewConfig
  }

  validation = () => {
    const { error } = this.isValid(this.state.formData)
    this.setState({ valid: !error })
  }

  handleValueChange = change => {
    this.setState(({ formData }) => ({
      formData: { ...formData, ...change },
    }))
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true })
    const { success, message } = this.props.modalData.holidayRequest
      ? await this.updateHoliday()
      : await this.createHoliday()

    this.setState({ isLoading: false })

    if (success) {
      notify('success', message)
      this.props.onClose()
    } else {
      notify('error', message)
    }
  }

  handleConvert = async () => {
    this.setState({ isConverting: true })
    const { success, message } = await this.updateHoliday({ convert: true })

    this.setState({ isConverting: false })

    if (success) {
      notify('success', message)
    } else {
      notify('error', message)
    }

    this.props.onClose()
  }

  createHoliday = async () => {
    const {
      CreateHoliday,
      CreateCompanyHoliday,
      modalData: { requestType },
      currentUserData,
    } = this.props
    const {
      formData: { employee: formEmployee, startDate, endDate, comment },
      viewConfig,
    } = this.state

    const fixedTimeZoneRange = eachDayOfInterval({
      start: startDate,
      end: endDate,
    }).map(day => fixDate(day))

    const employee = currentUserData.isAdmin
      ? formEmployee.value
      : currentUserData

    try {
      const mutation =
        requestType === 'COMPANY_HOLIDAY' ? CreateCompanyHoliday : CreateHoliday

      const variables =
        requestType === 'COMPANY_HOLIDAY'
          ? {
              dates: fixedTimeZoneRange,
              description: comment,
            }
          : {
              startDate: fixDate(startDate),
              endDate: fixDate(endDate),
              status: viewConfig.status,
              description: comment || '',
              type: viewConfig.type,
              employeeId: employee.id,
            }

      const response = await mutation({
        variables: { input: variables },
        refetchQueries,
        errorPolicy: 'all',
      })

      throwCustomError(response, 'Notification.Holiday.Create.Failed')

      trackHolidayCreation()
      return { success: true, message: 'Notification.Holiday.Create.Success' }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

  updateHoliday = async ({ convert = false } = {}) => {
    const {
      UpdateHoliday,
      modalData: { holidayRequest },
    } = this.props

    const {
      formData: { startDate, endDate, comment },
    } = this.state

    try {
      const variables = {
        id: holidayRequest.id,
        startDate: fixDate(startDate),
        endDate: fixDate(endDate),
        description: comment || '',
      }

      if (convert) {
        variables.status = 'REQUESTED'
      }

      const response = await UpdateHoliday({
        variables: { input: variables },
        refetchQueries,
        errorPolicy: 'all',
      })

      throwCustomError(response, 'Notification.Holiday.Create.Failed')

      trackHolidayUpdate()

      return { success: true, message: 'Notification.Holiday.Update.Success' }
    } catch (error) {
      return { success: false, message: error.message }
    }
  }

  render() {
    const {
      onClose,
      isOpen,
      modalData: { holidayRequest },
      currentUserData,
    } = this.props

    const {
      formData,
      valid,
      isLoading,
      viewConfig,
      isConverting,
      alphabeticallySortedEmployees,
    } = this.state

    const listedEmployees = alphabeticallySortedEmployees.map(employee => ({
      value: employee,
      label: (
        <UserName employee={employee} suppressRedirection textLength={30} />
      ),
    }))

    const submitButtonText = `${
      currentUserData.isAdmin ? 'RequestHoliday.Admin' : 'RequestHoliday'
    }.${holidayRequest ? 'Update' : 'Create'}.SubmitButtonText.{${
      viewConfig.type
    }}`

    const convertable = holidayRequest && holidayRequest.status === 'PLANNED'

    const remainingHolidays = viewConfig.showDaysLeft
      ? calculateRemainingHolidaysPerYear({
          owner: formData.employee.value,
          holidayRequest: formData,
          originalHolidayRequest: holidayRequest,
        })
      : []

    const hasEnoughHolidays = remainingHolidays.reduce(
      (acc, curr) => acc && curr.remainingDays >= 0,
      true
    )

    const submitButtonDisabled =
      !valid ||
      isConverting ||
      (!hasEnoughHolidays &&
        viewConfig.type === 'VACATION' &&
        ['REQUESTED', 'PLANNED'].includes(viewConfig.status))

    const props = {
      convertable,
      employees: listedEmployees,
      formData,
      holidayRequest,
      isConverting,
      isLoading,
      isOpen,
      remainingHolidays,
      onClose,
      onConvert: this.handleConvert,
      onSubmit: this.handleSubmit,
      onValueChange: this.handleValueChange,
      submitButtonText,
      viewConfig,
      isAdmin: currentUserData.isAdmin,
      submitButtonDisabled,
    }

    return <AddHolidayModalView {...props} />
  }
}

AddHolidayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: FunctionType.isRequired,

  CreateHoliday: FunctionType.isRequired,
  CreateCompanyHoliday: FunctionType.isRequired,
  UpdateHoliday: FunctionType.isRequired,

  activeEmployees: EmployeeListType,
  currentUserData: EmployeeType.isRequired,
  modalData: PropTypes.shape({
    holidayRequest: HolidayRequestType,
    isEmployeeSelectable: PropTypes.bool,
    requestType: PropTypes.oneOf([
      'VACATION',
      'SICK_LEAVE',
      'PLANNED',
      'COMPANY_HOLIDAY',
    ]),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    selectedEmployee: EmployeeType,
  }),
  activeLanguage: PropTypes.shape({ code: PropTypes.string }),
}

AddHolidayModal.defaultProps = {
  activeEmployees: [],
}

export default AddHolidayModal
