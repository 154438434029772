import React from 'react'
import { bool } from 'prop-types'
import { format, isWeekend, isPast, isToday, isSameMonth } from 'date-fns'

import { WeekDay } from './style'
import { HolidayRequestType, DateType } from '../../../../common/prop-types'
import CalendarBodyCell from './CalendarBodyCell'

export const Day = ({
  date,
  month,
  holiday,
  firstDay,
  lastDay,
  isWorkingDay,
}) => {
  const showDay = isSameMonth(date, month)
  const showBodyCell = showDay && holiday && isWorkingDay

  const showDate = showDay && !showBodyCell
  return (
    <WeekDay
      isWeekend={isWeekend(date)}
      isWorkingDay={isWorkingDay}
      isDifferentMonth={isPast(date) || !isSameMonth(date, month)}
      isToday={isToday(date)}
    >
      {showBodyCell && (
        <CalendarBodyCell
          holidayRequest={holiday}
          firstDay={firstDay}
          lastDay={lastDay}
          isItToday={isToday(date)}
          isWorkingDay={isWorkingDay}
          type="employee"
          date={date}
        >
          {format(date, 'd')}
        </CalendarBodyCell>
      )}
      {showDate && format(date, 'd')}
    </WeekDay>
  )
}

Day.propTypes = {
  date: DateType.isRequired,
  month: DateType.isRequired,
  holiday: HolidayRequestType,
  firstDay: bool,
  lastDay: bool,
  isWorkingDay: bool,
}

export default Day
