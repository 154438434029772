import gql from 'graphql-tag'

const UPDATE_HOLIDAY_REQUEST = gql`
  mutation updateHolidayRequest($input: UpdateHolidayRequestInput!) {
    updateHolidayRequest(input: $input) {
      id
    }
  }
`

export default UPDATE_HOLIDAY_REQUEST
