import React from 'react'
import ReactDOM from 'react-dom'
import dotenv from 'dotenv'

import Root from './Root'
import * as AnalyticsService from './common/services/analytics'
import { AuthService } from './common/services/auth'

import { printAppInfo } from './common/services/app-info'

printAppInfo()

dotenv.config()

AuthService.init()
AnalyticsService.init()

ReactDOM.render(<Root />, document.getElementById('root'))
