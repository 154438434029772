import styled from 'styled-components/macro'

export const FlexContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-items: center;
`
export const CenterImage = styled.img`
  width: 50%;
  align-self: center;
`

export const DescriptionText = styled.div`
  font-style: normal;
  font-weight: 600;
  line-height: 3.4rem;
  font-size: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayVeryDark};
`

export const DetailText = styled.div`
  padding: 2rem;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  line-height: 3rem;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.grayVeryDark};
`
