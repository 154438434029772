import React from 'react'
import { func, string, shape } from 'prop-types'
import { ButtonIconGroup, ButtonIcon } from '../styles/Button'

const ViewSwitch = ({ onViewModeChange, viewMode, viewModes, ...rest }) => (
  <ButtonIconGroup {...rest}>
    <ButtonIcon
      color="orangePale"
      id="b1"
      onClick={() => {
        if (viewMode !== viewModes.list) onViewModeChange(viewModes.list)
      }}
      selected={viewMode === viewModes.list}
    >
      {viewMode === viewModes.list ? (
        <img src="/assets/img/icon-list.svg" alt="list" />
      ) : (
        <img src="/assets/img/icon-list-gray.svg" alt="calendar" />
      )}
    </ButtonIcon>
    <ButtonIcon
      color="orangePale"
      id="b2"
      onClick={() => {
        if (viewMode !== viewModes.calendar)
          onViewModeChange(viewModes.calendar)
      }}
      selected={viewMode === viewModes.calendar}
    >
      {viewMode === viewModes.calendar ? (
        <img src="/assets/icons/calendar.svg" alt="calendar" />
      ) : (
        <img src="/assets/icons/calendar-gray.svg" alt="calendar" />
      )}
    </ButtonIcon>
  </ButtonIconGroup>
)

ViewSwitch.propTypes = {
  onViewModeChange: func.isRequired,
  viewMode: string.isRequired,
  viewModes: shape({}),
}

export default ViewSwitch
