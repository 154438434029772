export const cutText = (text = '', maxLength = 10) => {
  if (!text) return ''
  return text.length < maxLength ? text : `${text.slice(0, maxLength)}...`
}

export const sortAlphabetically = list => {
  list.sort((a, b) => {
    const nameA = a.lastName.toUpperCase()
    const nameB = b.lastName.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export const sortAlphabeticallyByLanguage = (list, language) => {
  const mapLanguageToLocale = {
    hu: (nameA, nameB) =>
      Intl.Collator('hu').compare(nameA.lastName, nameB.lastName),
    en: (nameA, nameB) =>
      Intl.Collator('hu').compare(nameA.firstName, nameB.firstName),
  }

  const compareMethod = mapLanguageToLocale[language] ?? mapLanguageToLocale.hu

  return list.sort(compareMethod)
}
