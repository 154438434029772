import packageJson from '../../../package.json'

export const printAppInfo = () => {
  // eslint-disable-next-line no-console
  console.info(
    `%c FreeBees 🐝 %c v${packageJson.version}/${process.env.REACT_APP_ENVIRONMENT}`,
    'background: #F57831; color: #fff; font-weight: bold; padding: 5px; border-radius: 4px;',
    'background: #fff; color: #000;'
  )
}

export const getAppVersion = () => packageJson.version
