import React, { Component } from 'react'
import { func, bool } from 'prop-types'

import AddEmployeeModalView from './AddEmployeeModalView'
import { ApolloDataType, IntlType } from '../../../../common/prop-types'
import validation from '../../../../common/services/validation'
import notify from '../../../../common/services/notification/notification'
import { refetchQueries } from '../../../../common/constants/refetchQueries'
import { fixDate } from '../../../../common/utils/calendar'
import {
  getChildAgeSelectionRules,
  getEmployeeAgeSelectionRules,
} from '../../../../common/services/dateSelectionRules'
import { throwCustomError } from '../../../../common/constants/errorCodes'

let c = 0
const getNexdChildId = () => {
  c += 1
  return `child-${c}`
}

const getVariablesFromFormData = ({
  email,
  firstName,
  lastName,
  hireDate,
  birthDate,
  jobTitle,
  children = [],
}) => {
  const variables = {
    email,
    firstName,
    lastName,
    birthDate,
    hireDate: fixDate(hireDate),
    children: children.map(child => ({
      birthDate: fixDate(child.birthDate),
      firstName: child.firstName,
      sex: child.sex,
    })),
  }
  if (jobTitle && jobTitle.value && jobTitle.value.id) {
    variables.jobTitleId = jobTitle.value.id
  }
  return variables
}

class AddEmployeeModal extends Component {
  isValid = validation([
    [
      'firstName',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
    [
      'lastName',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
    [
      'email',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
      {
        rule: 'email',
        msg: this.props.intl.formatMessage({
          id: 'NotValidEmail',
        }),
      },
    ],
    [
      'hireDate',
      {
        rule: 'at-least-16',
        msg: this.props.intl.formatMessage({
          id: 'Employee.Errors.TooYoungToWork',
        }),
      },
    ],
  ])

  isChildValid = validation([
    [
      'firstName',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
  ])

  constructor(props) {
    super(props)
    const rules = getEmployeeAgeSelectionRules()

    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: null,
        hireDate: new Date(),
        birthDate: rules.defaultDate,
        children: [],
      },
      isLoading: false,
      isBulkUploadOpen: false,
      validationErrors: {},
      childValidationErrors: [],
    }
  }

  validation = () => {
    const { formData } = this.state
    const { error, messages: validationErrors } = this.isValid(formData)
    const childValidationErrors = formData.children.map(child => {
      const { messages: childMessages } = this.isChildValid(child)
      return childMessages
    })

    const childErrors = formData.children.map(child => {
      const { error: childError } = this.isChildValid(child)
      return childError
    })

    const isValid = !(error || childErrors.filter(x => x).length)

    this.setState({
      validationErrors,
      childValidationErrors,
    })

    return { isValid }
  }

  handleValueChange = change => {
    this.setState(({ formData }) => ({ formData: { ...formData, ...change } }))
  }

  handleSubmit = async () => {
    const { isValid } = this.validation()

    if (isValid) {
      this.setState({ isLoading: true })

      const { success, error } = await this.inviteEmployee()

      if (success) {
        notify('success', 'Notification.Invite.Employee.Success')
        this.props.onSuccess()
      } else {
        notify('error', error.message)
      }
      this.setState({ isLoading: false })
    }
  }

  inviteEmployee = async () => {
    const { InviteEmployee } = this.props

    try {
      const variables = getVariablesFromFormData(this.state.formData)
      const result = await InviteEmployee({
        variables: { input: variables },
        refetchQueries,
        errorPolicy: 'all',
      })

      throwCustomError(result, 'Notification.Invite.Employee.Failed')

      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleAddChild = () => {
    const rules = getChildAgeSelectionRules()
    this.setState(({ formData }) => ({
      formData: {
        ...formData,
        children: formData.children.concat([
          {
            id: getNexdChildId(),
            firstName: '',
            lastName: '',
            birthDate: rules.defaultDate,
          },
        ]),
      },
    }))
  }

  handleRemoveChild = id => {
    this.setState(({ formData }) => ({
      formData: {
        ...formData,
        children: formData.children.filter(child => child.id !== id),
      },
    }))
  }

  handleChangeChild = (id, change) => {
    this.setState(({ formData }) => ({
      formData: {
        ...formData,
        children: formData.children.map(child => {
          if (child.id === id) return { ...child, ...change }
          return child
        }),
      },
    }))
  }

  handleOpenBulkUpload = () => {
    this.setState({ isBulkUploadOpen: true })
  }

  handleCloseBulkUpload = () => {
    this.setState({ isBulkUploadOpen: false })
  }

  handleBulkUploadSubmit = async users => {
    this.setState({ isLoading: true })

    const { success, errors } = await this.inviteMultipleEmployees(users)

    if (success) {
      notify('success', 'Notification.Invite.Multiple.Employees.Success')
      this.props.onSuccess()
    } else {
      const {
        graphQLErrors: [
          {
            errorInfo: {
              code,
              //        details: { takenEmails: emailsWithError },
            },
          },
        ],
      } = errors

      if (code === 'MultipleEmployeesInvitation.AlreadyTakenEmails') {
        notify('error', 'MultipleEmployeesInvitation.AlreadyTakenEmails')
      } else notify('error', 'Notification.Invite.Multiple.Employees.Failed')
    }

    this.setState({ isLoading: false })
  }

  inviteMultipleEmployees = async input => {
    const { InviteMultipleEmployees } = this.props

    const fixedDateEmployees = input.map(employee => ({
      ...employee,
      birthDate: fixDate(employee.birthDate),
      hireDate: fixDate(employee.hireDate),
    }))

    try {
      await InviteMultipleEmployees({
        variables: { input: fixedDateEmployees },
        refetchQueries,
      })
      return { success: true }
    } catch (errors) {
      return { success: false, errors }
    }
  }

  render() {
    const {
      onClose,
      isOpen,
      CurrentCompany: { currentCompany: { jobTitles = [] } = {} },
    } = this.props

    const {
      formData,
      isLoading,
      isBulkUploadOpen,
      validationErrors,
      childValidationErrors,
    } = this.state

    const filterOptions = (jobs, input) => {
      if (input) {
        return jobs.value === jobs[0].value
      }
      return true
    }

    let jobs = jobTitles.map(j => ({
      value: j,
      label: j.name,
    }))

    jobs = [
      {
        value: 'custom',
        label: '-',
      },
      ...jobs,
    ]

    const props = {
      isOpen,
      onClose,
      filterOptions,
      onValueChange: this.handleValueChange,
      onAddChild: this.handleAddChild,
      onRemoveChild: this.handleRemoveChild,
      onChangeChild: this.handleChangeChild,
      jobTitles: jobs,
      validationErrors,
      childValidationErrors,
      formData,
      onSubmit: this.handleSubmit,
      isLoading,
      isBulkUploadOpen,
      onBulkUploadOpen: this.handleOpenBulkUpload,
      onBulkUploadClose: this.handleCloseBulkUpload,
      onBulkUploadSubmit: this.handleBulkUploadSubmit,
    }

    return <AddEmployeeModalView id="view" {...props} />
  }
}

AddEmployeeModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  CurrentCompany: ApolloDataType.isRequired,
  InviteEmployee: func.isRequired,
  InviteMultipleEmployees: func.isRequired,
  onSuccess: func.isRequired,
  intl: IntlType.isRequired,
}

export default AddEmployeeModal
