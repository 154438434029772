import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HolidayRequestType, FunctionType } from '../../../common/prop-types'
import { Button } from '../../styles/Button'
import EntityControl from '../../EntityControl'
import { refetchQueries } from '../../../common/constants/refetchQueries'
import { events } from '../../../common/services/analytics'

const RequestStatusReject = ({ mutate, holidayRequest }) => (
  <EntityControl
    mutate={mutate}
    entity={holidayRequest}
    question="RejectRequestModal.Question"
    title="RejectRequestModal.Title"
    hasComment
    useInput
    refetchQueries={refetchQueries}
    eventName={events.HolidayReject}
    renderTrigger={({ onClick, isLoading }) => (
      <Button
        secondary
        color="red"
        bordered
        reversed
        narrow
        id="reject-request-button"
        onClick={onClick}
        isLoading={isLoading}
      >
        <FormattedMessage id="Button.RejectRequest" />
      </Button>
    )}
  />
)

RequestStatusReject.propTypes = {
  holidayRequest: HolidayRequestType.isRequired,
  mutate: FunctionType.isRequired,
}

export default RequestStatusReject
