import { GoogleOAuthProvider } from '@react-oauth/google'
import React from 'react'
import { useSelector } from 'react-redux'
import { EmployeeType } from '../../../../../common/prop-types'
import {
  selectCurrentUserId,
  selectIsAdmin,
} from '../../../../../redux/auth/auth.selector'
import DaysOff from './DaysOff'
import EmployeeBaseInfo from './EmployeeBaseInfo'
import EmployeeChildInfo from './EmployeeChildInfo'
import EmployeeTeamMembership from './EmployeeTeamMembership'
import EmployeeGoogleConnect from './EmployeeGoogleConnect'
import { HorizontalLine, Section, Subsection } from './styles'

const EmployeePersonalInfoTab = ({ employee }) => {
  const isAdmin = useSelector(selectIsAdmin)
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
  const currentUserId = useSelector(selectCurrentUserId)
  const isEmployeeCurrentUser = currentUserId === employee.id

  return (
    <div>
      <Section>
        <Subsection>
          <EmployeeBaseInfo employee={employee} isAdmin={isAdmin} />
        </Subsection>
        <Subsection>
          <EmployeeChildInfo employee={employee} isAdmin={isAdmin} />
          {isEmployeeCurrentUser && (
            <GoogleOAuthProvider clientId={googleClientId}>
              <EmployeeGoogleConnect
                employeeId={employee.id}
                isGoogleConnected={employee.isGoogleConnected}
              />
            </GoogleOAuthProvider>
          )}
        </Subsection>
      </Section>

      <HorizontalLine />

      <Section>
        <Subsection>
          <EmployeeTeamMembership employee={employee} isAdmin={isAdmin} />
        </Subsection>
        <Subsection>
          <DaysOff employee={employee} isAdmin={isAdmin} />
        </Subsection>
      </Section>
    </div>
  )
}

EmployeePersonalInfoTab.propTypes = {
  employee: EmployeeType.isRequired,
}

export default EmployeePersonalInfoTab
