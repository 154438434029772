import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { RedirectElement } from './styles'

const DropDownRedirectLink = ({ title, to }) => (
  <RedirectElement to={to}>
    <FormattedMessage id={title} />
  </RedirectElement>
)

DropDownRedirectLink.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
}

export default DropDownRedirectLink
