import styled from 'styled-components/macro'

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: 20px;

  @media ${({ theme }) => theme.media.mobile} {
    align-items: flex-start;
  }
`

export const Image = styled.img`
  float: left;
  width: 80px;
  height: 80px;
`

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin: 0 20px;
`

export const EmployeeProfileInfoContainer = styled.div`
  display: flex;
  margin-bottom: 35px;
  margin-top: 45px;

  @media ${({ theme }) => theme.media.mobile} {
    margin: 20px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  height: 52px;
  position: relative;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    height: initial;
  }
`
