import styled from 'styled-components/macro'
import ViewSwitch from './ViewSwitch'

const ResponsiveViewSwitch = styled(ViewSwitch)`
  @media ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`

export default ResponsiveViewSwitch
