import React, { Component } from 'react'
import { string } from 'prop-types'

import { ChangePasswordView } from './ChangePasswordView'
import validation from '../../common/services/validation'
import notify from '../../common/services/notification'
import { AuthService } from '../../common/services/auth'
import { getCognitoMessage } from '../../common/constants/errorCodes'
import { IntlType } from '../../common/prop-types'

class ChangePassword extends Component {
  state = {
    formData: {
      password: '',
      repeatedPassword: '',
      termAndCondition: false,
      termAndConditionError: false,
    },
    error: null,
    isLoading: false,
  }

  isValid = validation([
    [
      'password',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
    [
      'repeatedPassword',
      {
        rule: 'required',
        msg: this.props.intl.formatMessage({
          id: 'RequiredField',
        }),
      },
    ],
  ])

  validation = () => {
    const result = this.isValid(this.state.formData)
    let { error } = result
    const { messages } = result

    if (this.state.formData.password !== this.state.formData.repeatedPassword) {
      error = true
      messages.password = [
        ...messages.password,
        this.props.intl.formatMessage({
          id: 'ChangePassword.Validation.Match.Error',
        }),
      ]
      messages.repeatedPassword = [
        ...messages.repeatedPassword,
        this.props.intl.formatMessage({
          id: 'ChangePassword.Validation.Match.Error',
        }),
      ]
    }

    this.setState({ error: messages })

    return { error, messages }
  }

  validateTermAndCondition = () => {
    const {
      formData: { termAndCondition },
    } = this.state

    this.setState(({ formData }) => ({
      formData: { ...formData, termAndConditionError: !termAndCondition },
    }))

    return termAndCondition
  }

  handleFormChange = event => {
    const { name, value } = event.target
    this.setState(({ formData }) => ({
      formData: { ...formData, [name]: value },
    }))
  }

  handleSubmit = async e => {
    e.preventDefault()

    const { error: validationError } = this.validation()

    if (!validationError) {
      const { email, code } = this.props
      const termsAccepted = this.validateTermAndCondition()

      if (!termsAccepted) return

      this.setState({ isLoading: true })

      const {
        formData: { password },
      } = this.state

      const { success, error } = await this.changePassword(
        email,
        code,
        password
      )

      if (success) {
        notify('success', 'Notification.Password.Changed')
        await AuthService.signIn(email, password)
      } else {
        const message = getCognitoMessage(
          error,
          'Notification.Password.Change.Failed'
        )

        notify('error', message)
      }

      this.setState({ isLoading: false })
    }
  }

  changePassword = async (email, oldPassword, newPassword) => {
    try {
      return AuthService.changePassword(email, oldPassword, newPassword)
    } catch (error) {
      return { success: false, error }
    }
  }

  render() {
    const { error, formData, isLoading } = this.state
    const { email } = this.props

    return (
      <ChangePasswordView
        onSubmit={this.handleSubmit}
        formData={formData}
        email={email}
        error={error}
        isLoading={isLoading}
        onFormChange={this.handleFormChange}
      />
    )
  }
}

ChangePassword.propTypes = {
  code: string.isRequired,
  email: string.isRequired,
  intl: IntlType.isRequired,
}

export default ChangePassword
