import { compose } from 'redux'
import { connect } from 'react-redux'

import { graphql } from '@apollo/client/react/hoc'
import { createStructuredSelector } from 'reselect'

import EmployeeProfileInfo from './EmployeeProfileInfo'

import { QueryCurrentCompany } from '../../../../graphql/queries/currentCompany'
import ASSIGN_JOBTITLE from '../../../../graphql/mutations/assignJobTitle'
import { selectIsAdmin } from '../../../../redux/auth/auth.selector'

const mapStateToProps = createStructuredSelector({
  isAdmin: selectIsAdmin,
})

export default compose(
  connect(mapStateToProps, null),
  graphql(QueryCurrentCompany, { name: 'CurrentCompany' }),
  graphql(ASSIGN_JOBTITLE, { name: 'AssignJobTitle' })
)(EmployeeProfileInfo)
