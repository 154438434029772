import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { ModalFooter, ModalVerticalInputContainer } from '../../../elements'

import { ButtonGroup, ButtonText } from '../../styles/Button'
import Button from '../../Button'
import {
  AddHolidayViewConfigType,
  EmployeeType,
  DateType,
} from '../../../common/prop-types'
import Input from '../../Input'
import RemainingHolidaysIndicator from '../../RemainingHolidaysIndicator'
import { Spacer } from '../../styles/Spacer'
import Modal from '../../Modal'
import { Container, Wrapper } from './styles'

const AddHolidayModalView = ({
  isOpen,
  formData,
  employees,
  onClose,
  onValueChange,
  onSubmit,
  viewConfig,
  isLoading,
  submitButtonText,
  onConvert,
  isConverting,
  convertable,
  isAdmin,
  remainingHolidays,
  submitButtonDisabled,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    titleKey={viewConfig.title}
    content={() => (
      <Container>
        {viewConfig.showEmployeeSelector && (
          <Input
            id="emplyee"
            type="selector"
            options={employees}
            disabled={!viewConfig.isEmployeeSelectable}
            value={formData.employee}
            onChange={employee => {
              onValueChange({ employee })
            }}
            label="Input.Employee.Label"
            isSearchable={false}
            selectorStyle="addEmployee"
          />
        )}

        <ModalVerticalInputContainer>
          <Input
            type="date-range"
            onlyWorkDays
            id="holiday"
            value={{
              startDate: formData.startDate,
              endDate: formData.endDate,
            }}
            onChange={onValueChange}
            minDate={viewConfig.minDate}
            maxDate={viewConfig.maxDate}
          />
        </ModalVerticalInputContainer>

        {viewConfig.showDaysLeft &&
          remainingHolidays.map(counters => (
            <RemainingHolidaysIndicator
              key={counters.year}
              holidayCounters={counters}
            />
          ))}

        <Wrapper>
          <Input
            type="text-area"
            label="Input.Comment.Label"
            value={formData.comment || ''}
            maxHeight={82}
            onChange={event => onValueChange({ comment: event.target.value })}
            hideErrors
          />
        </Wrapper>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose} narrow primary reversed bordered>
              <FormattedMessage id="Button.Cancel" />
            </Button>
            <Button
              onClick={onSubmit}
              narrow
              primary
              disabled={submitButtonDisabled}
              loading={isLoading}
            >
              <FormattedMessage id={submitButtonText} />
            </Button>
          </ButtonGroup>

          {convertable && (
            <Spacer display="flex" justifyContent="center" mt={40}>
              <ButtonText onClick={onConvert} disabled={isConverting}>
                <FormattedMessage
                  id={`Button.TransformRequest${isAdmin ? '.Admin' : ''}`}
                />
              </ButtonText>
            </Spacer>
          )}
        </ModalFooter>
      </Container>
    )}
  />
)

AddHolidayModalView.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  convertable: PropTypes.bool,
  isConverting: PropTypes.bool,
  formData: PropTypes.shape({
    employeeId: PropTypes.string,
    startDate: DateType.isRequired,
    endDate: DateType.isRequired,
    comment: PropTypes.string,
    employee: PropTypes.shape({
      value: EmployeeType.isRequired,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onConvert: PropTypes.func.isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      value: EmployeeType,
      label: PropTypes.node,
    })
  ),
  viewConfig: AddHolidayViewConfigType.isRequired,
  submitButtonDisabled: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  remainingHolidays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default AddHolidayModalView
