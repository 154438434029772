import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

export default function HolidayIndicator({ data }) {
  if (!data.holidayRequests.length) {
    return null
  }

  return (
    <Container>
      <FormattedMessage id="OnHoliday.Title" />
    </Container>
  )
}

HolidayIndicator.propTypes = {
  data: PropTypes.shape({
    holidayRequests: PropTypes.array.isRequired,
  }).isRequired,
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 28px;
  mix-blend-mode: normal;
  border-radius: 4px;
  background: #e7eefb;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  color: #5f8ce1;

  margin-left: 25px;

  @media ${({ theme }) => theme.media.mobile} {
    margin-left: 0;
    margin-top: 20px;
  }
`
