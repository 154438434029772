import gql from 'graphql-tag'

const APPROVE_HOLIDAY_REQUEST = gql`
  mutation approveHolidayRequest($id: ID!) {
    approveHolidayRequest(id: $id) {
      id
    }
  }
`

export default APPROVE_HOLIDAY_REQUEST
