import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HolidayRequestType, FunctionType } from '../../../common/prop-types'
import { Button } from '../../styles/Button'
import EntityControl from '../../EntityControl'
import { refetchQueries } from '../../../common/constants/refetchQueries'
import { events } from '../../../common/services/analytics'

const RequestStatusApprove = ({ mutate, holidayRequest }) => (
  <EntityControl
    mutate={mutate}
    entity={holidayRequest}
    question="ApproveRequestModal.Question"
    title="ApproveRequestModal.Title"
    refetchQueries={refetchQueries}
    suppressConfirmation
    eventName={events.HolidayApprove}
    renderTrigger={({ onClick }) => (
      <Button
        secondary
        narrow
        color="lime"
        id="approve-request-button"
        onClick={onClick}
      >
        <FormattedMessage id="Button.ApproveRequest" />
      </Button>
    )}
  />
)

RequestStatusApprove.propTypes = {
  holidayRequest: HolidayRequestType.isRequired,
  mutate: FunctionType.isRequired,
}

export default RequestStatusApprove
