import styled from 'styled-components/macro'
import { colors } from '../../../../../theme/freebees'
import { Spacer } from '../../../../../components/styles/Spacer'
import { Text } from '../../../../../components/styles'

export const CenterAlignedSpacer = styled(Spacer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ErrorText = styled(Text)`
  color: ${colors.rejectRed};
`

export const ErrorImageIndicator = styled.img`
  padding-right: 11px;
  height: 10px;
`

export const ErrorLine = styled(Text)`
  line-height: 30px;
  padding: 5px 0;
`
