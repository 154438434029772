import styled from 'styled-components/macro'

export const Container = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const TableHeader = styled.tr`
  text-align: left;
`

export const UserInfoSection = styled.div`
  grid-column: 1;
`

export const UserRoleSection = styled.div`
  grid-column: 2;
  display: flex;
  align-items: flex-end;
`

export const ImageColumn = styled.th`
  width: 0px;
`
export const NameColumn = styled.th``
export const RoleColumn = styled.th`
  vertical-align: middle;
`

export const ActionColumn = styled.th`
  width: 80px;
`

export const TableBody = styled.div``

export const SearchInput = styled.input`
  margin-top: 39px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 52px;
`

export const HorizontalLine = styled.div`
  mix-blend-mode: normal;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`

export const NameCell = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 32px;
  text-transform: uppercase;

  color: #ed7335;
`
