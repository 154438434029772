import React from 'react'
import { string } from 'prop-types'
import { SearchInput, Container, Icon } from './styles'
import { FunctionType, IntlType } from '../../common/prop-types'

const SearchField = ({ onChange, value, intl }) => (
  <Container>
    <Icon src="/assets/img/search-icon.svg" alt="search" />
    <SearchInput
      placeholder={intl.formatMessage({ id: 'Input.Search.Placeholder' })}
      id="search-field"
      onChange={onChange}
      value={value}
    />
  </Container>
)

SearchField.propTypes = {
  onChange: FunctionType,
  value: string,
  intl: IntlType.isRequired,
}

export default SearchField
