import { addLocaleData } from 'react-intl'
import { registerLocale } from 'react-datepicker'

import en from 'date-fns/locale/en-GB'
import hu from 'date-fns/locale/hu'

import enLocaleData from 'react-intl/locale-data/en'
import huLocaleData from 'react-intl/locale-data/hu'

import enUS from '../../../lang/en-US.json'
import huHU from '../../../lang/hu-HU.json'

export const localeConfig = [
  {
    code: 'en',
    displayName: 'EN',
    messages: enUS,
    localeData: enLocaleData,
    calendarLocaleData: en,
  },
  {
    code: 'hu',
    displayName: 'HU',
    messages: { ...enUS, ...huHU },
    localeData: huLocaleData,
    calendarLocaleData: hu,
  },
]

const init = () => {
  localeConfig.forEach(lang => {
    addLocaleData(lang.localeData)
    registerLocale(lang.code, lang.calendarLocaleData)
  })
}

const getMessages = code =>
  localeConfig.find(config => config.code === code).messages

export default {
  init,
  getMessages,
}
