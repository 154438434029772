import styled from 'styled-components/macro'
import { dimensions } from '../../theme/freebees'

export const DropdownWrapper = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  z-index: ${p => p.theme.zIndex.modal};
`

export const DropdownTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
export const DropdownTitleText = styled.a`
  color: #282828;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
  ::hover {
    background-color: blue;
  }
`

export const DropdownList = styled.div`
  position: absolute;
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin: 0px;
  min-width: 160px;
  top: 50px;
  right: 0;
  font-size: 1.4rem;
  display: ${({ show }) => (show ? 'block' : 'none')};

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    left: -60px;
  }
`

export const DropdownListItem = styled.a`
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
`

export const DropDownToggleIcon = styled.img`
  width: 8px;
  height: 5px;
  margin-left: 10px;
`

export const ProfileImageWrapper = styled.div``

export const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`
