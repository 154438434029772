import React from 'react'
import { func, arrayOf, shape } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { InputGroup } from '../../../../../elements'
import { ChildListType } from '../../../../../common/prop-types'
import {
  VerticalInputContainer,
  RemoveButtonContainer,
  RemoveChildIcon,
} from './styles'
import { getChildAgeSelectionRules } from '../../../../../common/services/dateSelectionRules'
import { Spacer } from '../../../../../components/styles/Spacer'
import { Padding } from '../../../../../components/styles'
import Input from '../../../../../components/Input'
import { InputLabel } from '../../../../../components/Input/styles'
import { ButtonWithText } from '../../../../../components/styles/Button'

const ChildEditorView = ({
  kids,
  onChange,
  onAdd,
  onRemove,
  childValidationErrors,
}) => {
  const childBirthdaySelectorRules = getChildAgeSelectionRules()
  const maxNumberOfChildYears =
    childBirthdaySelectorRules.maxDate.getFullYear() -
    childBirthdaySelectorRules.minDate.getFullYear()
  return (
    <Spacer mt={25} mb={20}>
      {kids.map((child, index) => (
        <Padding v key={child.id}>
          <VerticalInputContainer>
            <InputGroup block>
              <Input
                label="Input.FirstName.Label"
                id="first-name"
                value={child.firstName}
                onChange={event =>
                  onChange(child.id, { firstName: event.target.value })
                }
                error={
                  childValidationErrors &&
                  childValidationErrors[index] &&
                  childValidationErrors[index].firstName
                }
              />
            </InputGroup>
            <InputGroup block>
              <InputLabel>
                <FormattedMessage id="Input.BirthDate.Label" />
              </InputLabel>
              <Input
                type="date"
                id="birth-date"
                dateFormatCalendar="MMMM"
                maxDate={childBirthdaySelectorRules.maxDate}
                minDate={childBirthdaySelectorRules.minDate}
                scrollableYearDropdown
                selected={child.birthDate}
                showDisabledMonthNavigation
                yearDropdownItemNumber={maxNumberOfChildYears}
                showYearDropdown
                onChange={day => {
                  onChange(child.id, {
                    birthDate: day,
                  })
                }}
              />
            </InputGroup>
          </VerticalInputContainer>
          <RemoveButtonContainer>
            <Padding t sm>
              <ButtonWithText onClick={() => onRemove(child.id)}>
                <RemoveChildIcon
                  src="/assets/icons/user-deactivate-orange.svg"
                  alt="remove-child"
                />
                <FormattedMessage id="Button.RemoveChild" />
              </ButtonWithText>
            </Padding>
          </RemoveButtonContainer>
        </Padding>
      ))}

      <ButtonWithText onClick={onAdd}>
        <FormattedMessage id="Button.AddChild" />
      </ButtonWithText>
    </Spacer>
  )
}
ChildEditorView.propTypes = {
  kids: ChildListType,
  onChange: func.isRequired,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  childValidationErrors: arrayOf(shape({})),
}

ChildEditorView.defaultProps = {
  kids: [],
}

export default ChildEditorView
