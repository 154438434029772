import React from 'react'
import { bool, func } from 'prop-types'

import SortButton from '../SortButton'

import { HeaderItem, TableHeader } from './styles'
import { SortByType } from '../../common/prop-types'

const RequestListHeader = ({
  onSortChange,
  showEmployee,
  showStartDate,
  showEndDate,
  showType,
  showDescription,
  showStatusControl,
  sortable,
  sortBy,
  showStatus,
}) => (
  <TableHeader>
    <tr>
      {showEmployee && <th> </th>}

      {showStartDate && (
        <HeaderItem textAlign={['right']}>
          <SortButton
            id="sort-start-date"
            onSortChange={onSortChange}
            sortable={sortable}
            sortBy={sortBy}
            sortField="START_DATE"
            text="HolidayList.Header.StartDate"
          />
        </HeaderItem>
      )}

      {showEndDate && (
        <HeaderItem textAlign={['right']}>
          <SortButton
            id="sort-end-date"
            onSortChange={onSortChange}
            sortable={sortable}
            sortBy={sortBy}
            sortField="END_DATE"
            text="HolidayList.Header.EndDate"
          />
        </HeaderItem>
      )}

      {showType && (
        <HeaderItem>
          <SortButton
            id="sort-type"
            onSortChange={onSortChange}
            sortable={sortable}
            sortBy={sortBy}
            sortField="TYPE"
            text="HolidayList.Header.Type"
          />
        </HeaderItem>
      )}

      {showDescription && (
        <HeaderItem>
          <SortButton
            id="sort-description"
            onSortChange={onSortChange}
            sortable={false}
            sortBy={sortBy}
            sortField="DESCRIPTION"
            text="HolidayList.Header.Description"
          />
        </HeaderItem>
      )}

      {showStatus && (
        <HeaderItem>
          <SortButton
            id="sort-status"
            onSortChange={onSortChange}
            sortable={sortable}
            sortBy={sortBy}
            sortField="STATUS"
            text="HolidayList.Header.Status"
          />
        </HeaderItem>
      )}

      {showStatusControl && <HeaderItem />}
    </tr>
  </TableHeader>
)
RequestListHeader.propTypes = {
  onSortChange: func.isRequired,
  showEmployee: bool,
  sortBy: SortByType.isRequired,
  showStartDate: bool,
  showEndDate: bool,
  showType: bool,
  showDescription: bool,
  showStatusControl: bool,
  sortable: bool,
  showStatus: bool,
}

export default RequestListHeader
