import styled from 'styled-components/macro'

const getSize = ({ size }) => {
  if (size === 'large') return '75px'
  if (size === 'small') return '30px'

  return '30px'
}

export const Image = styled.img`
  border-radius: 50%;
  width: ${getSize};
  height: ${getSize};
`
