import React, { PureComponent } from 'react'
import { bool, oneOf, node } from 'prop-types'

import { CalendarCell } from '../styles'
import { HolidayRequestType } from '../../../../../common/prop-types'
import CalendarBodyCellInner from './CalendarBodyCellInner'

class CalendarBodyCell extends PureComponent {
  render() {
    const {
      holidayRequest,
      firstDay,
      lastDay,
      type,
      children,
      isItToday,
      isWorkingDay,
    } = this.props

    const empty = !holidayRequest

    return (
      <CalendarCell
        isWorkingDay={isWorkingDay}
        isItToday={isItToday}
        type={type}
      >
        {empty ? null : (
          <CalendarBodyCellInner
            holiday={holidayRequest}
            firstDay={firstDay}
            lastDay={lastDay}
            type={type}
          >
            {children}
          </CalendarBodyCellInner>
        )}
      </CalendarCell>
    )
  }
}
CalendarBodyCell.propTypes = {
  holidayRequest: HolidayRequestType,
  firstDay: bool,
  lastDay: bool,
  type: oneOf(['employee', 'company']),
  children: node,
  isItToday: bool,
  isWorkingDay: bool,
}

export default CalendarBodyCell
