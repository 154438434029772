import gql from 'graphql-tag'
import { employeeFragments } from './employees'

export const QueryCurrentEmployee = gql`
  query currentEmployee {
    currentEmployee {
      ...BaseEmployeeFields
      ...RoleFields
      ...TeamsFields
      ...HolidaysBreakdownFields
    }
  }
  ${employeeFragments.base}
  ${employeeFragments.holidaysBreakdowns}
  ${employeeFragments.role}
  ${employeeFragments.teams}
`
