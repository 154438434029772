import gql from 'graphql-tag'

const CREATE_HOLIDAY_REQUEST = gql`
  mutation createHolidayRequest($input: CreateHolidayRequestInput!) {
    createHolidayRequest(input: $input) {
      id
    }
  }
`

export default CREATE_HOLIDAY_REQUEST
