import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'
import { ButtonRound } from '../styles/Button'
import { EmployeeType } from '../../common/prop-types'
import Options from './Options'
import { useClickOutside } from '../../hooks/useClickOutside'

export default function RequestHolidayButton({
  currentUserData,
  openHolidayModal,
  employee,
}) {
  const [showOptions, setShowOptions] = useState(false)
  const ref = useClickOutside(() => setShowOptions(false))

  function toggleMenu() {
    setShowOptions(previous => !previous)
  }

  function handleRequestHoliday(requestType) {
    openHolidayModal({
      requestType,
      selectedEmployee: employee || currentUserData,
      isEmployeeSelectable: !employee,
    })
  }

  return (
    <Container ref={ref}>
      <ButtonRound onClick={toggleMenu} open={showOptions}>
        <img src="/assets/img/plus.svg" alt="+" />
      </ButtonRound>

      <Options
        showOptions={showOptions}
        onRequestHoliday={handleRequestHoliday}
        isAdmin={currentUserData.isAdmin}
        position={{ right: 0 }}
      />
    </Container>
  )
}

RequestHolidayButton.propTypes = {
  openHolidayModal: PropTypes.func.isRequired,
  employee: EmployeeType,
  currentUserData: EmployeeType.isRequired,
}
