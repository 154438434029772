import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { AuthService } from '../../common/services/auth'
import notify from '../../common/services/notification'
import { trackLogout } from '../../common/services/analytics'
import Button from '../Button'

class LogoutButton extends Component {
  signOut = async () => {
    try {
      await AuthService.signOut()
      trackLogout()

      notify('success', 'Notification.LogOut.Successful')
    } catch (error) {
      notify('error', error.message)
    }
  }

  render() {
    return (
      <Button secondary color="orange" onClick={this.signOut}>
        <FormattedMessage id="UserDropDown.SignOut" />
      </Button>
    )
  }
}

LogoutButton.propTypes = {}

export default LogoutButton
