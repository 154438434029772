import styled from 'styled-components/macro'
import {
  space,
  textAlign,
  lineHeight,
  fontSize,
  fontWeight,
  variant,
} from 'styled-system'

const textTransform = ({ uppercase }) => {
  return uppercase ? 'uppercase' : 'none'
}

export const selectColor = ({ theme, color = theme.colors.grayVeryDark }) => {
  return theme.colors[color] || theme.colors.grayVeryDark
}

const fontFamily = ({ theme, bold, semiBold }) => {
  if (bold) return theme.fontFamily.bold
  if (semiBold) return theme.fontFamily.semiBold

  return theme.fontFamily.default
}

export const H1 = styled.h1`
  ${textAlign};
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  font-size: 3.6rem;
`

export const H2 = styled.h2`
  ${space};
  ${textAlign};
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  font-size: 2.6rem;
  color: ${selectColor};
`

const TextBase = styled.span`
  ${fontWeight}
  ${fontSize};
  font-style: normal;
  line-height: 22px;
  text-decoration: none;
`

export const Text = styled(TextBase)`
  ${space};
  ${textAlign};
  ${lineHeight};
  font-family: ${fontFamily} !important;
  color: ${selectColor};
  text-transform: ${textTransform};
`

export const TextV = styled(Text)(
  {},
  variant({
    variants: {
      pageTitle: {
        fontSize: [20, 40],
        lineHeight: ['30px', '60px'],
        fontWeight: 600,
      },
      title: {
        fontSize: [19, 38],
        lineHeight: ['28px', '54px'],
        fontWeight: 600,
      },
      modalTitle: {
        fontSize: [20, 28],
        lineHeight: ['28px', '35px'],
      },
      modalSubTitle: {
        fontSize: [15, 20],
      },
      sectionTitle: {
        fontSize: [16, 22],
        lineHeight: ['20px', '30px'],
        fontWeight: 600,
      },
      description: {
        fontSize: [12, 16],
        lineHeight: ['16px', '20px'],
      },
      small: {
        fontSize: [8, 12],
      },
    },
  })
)

export const TextLink = styled(TextBase)`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.orangeLight};
  position: relative;
  &:after {
    content: '';

    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;

    border-width: 0 0 1px;
    border-style: solid;
  }
`

export const TextInfo = styled(Text)`
  background: ${({ warning, theme }) =>
    warning ? theme.colors.redLightGrayish : theme.colors.infoColor};
  border-radius: 4px;
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  padding: 8px;
`

export const TableHeaderText = styled(TextBase)`
  font-style: normal;
  font-weight: bold;
  font-size: '12px';
  line-height: 32px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray};
`

export const HolidayCounter = styled(TextBase)`
  font-weight: 600;
  font-size: '28px';
  color: ${({ theme }) => theme.colors.orange};
  margin: 0 20px;
`

export const FooterBoldText = styled(TextBase)`
  font-size: '18px';
  line-height: 30px;
  color: ${({ theme }) => theme.colors.grayVeryDark};
`
