import gql from 'graphql-tag'

const CREATE_COMPANY = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
    }
  }
`

export default CREATE_COMPANY
