import React from 'react'
import PropTypes from 'prop-types'

import { Cell } from '../styles'

const EmptyCell = props => (
  <Cell {...props}>
    <div className="inner" role="presentation" />
  </Cell>
)

EmptyCell.propTypes = {
  column: PropTypes.number.isRequired,
  row: PropTypes.number.isRequired,
  isItToday: PropTypes.bool.isRequired,
  canCreateHoliday: PropTypes.bool.isRequired,
  isWorkingDay: PropTypes.bool.isRequired,
  isPreviousDayWorkingDay: PropTypes.bool,
}

export default React.memo(EmptyCell)
