import gql from 'graphql-tag'

const CREATE_TEAM = gql`
  mutation createTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      name
    }
  }
`

export default CREATE_TEAM
