import gql from 'graphql-tag'

const CREATE_COMPANY_HOLIDAY = gql`
  mutation createCompanyHoliday($input: CreateCompanyHolidaysInput!) {
    createCompanyHoliday(input: $input) {
      id
      date
    }
  }
`

export default CREATE_COMPANY_HOLIDAY
