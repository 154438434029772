import styled from 'styled-components/macro'

export const ModalHeaderWrapper = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`

export const ModalCloseIcon = styled.img`
  margin-top: 31px;
  margin-right: 30px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`
export const TitleWrapper = styled.div`
  height: 43px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentWrapper = styled.div`
  padding-bottom: 40px;
  overflow: hidden;
`

export const TagsSection = styled.div`
  margin: 20px 80px 40px;
`
export const InputSection = styled.div`
  margin-top: 40px;
  margin-left: 80px;
  margin-right: 80px;
  height: 73px;
`

export const CheckboxSection = styled.div`
  margin: 20px 0;
  text-align: center;
`
