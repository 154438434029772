import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HorizontalLine, TitleContainer, Wrapper } from './styles'

import {
  QueryActiveEmployees,
  QueryInactiveEmployees,
} from '../../../../graphql/queries/employees'
import EmployeeList from '../EmployeeListModule/EmployeeList'
import WithQuery from '../../../../components/WithQuery'
import EmployeeListModule from '../EmployeeListModule'
import { Padding, H2 } from '../../../../components/styles'
import CreateEmployeeButton from '../CreateEmployeeButton'

const EmployeeListModuleWithQuery = WithQuery(EmployeeListModule)

export const EmployeeInfo = () => (
  <div>
    <Wrapper>
      <Padding v lg>
        <TitleContainer>
          <H2>
            <FormattedMessage id="CompanyProfile.ActiveUsers.Title" />
          </H2>
          <CreateEmployeeButton />
        </TitleContainer>
      </Padding>

      <EmployeeListModuleWithQuery
        query={QueryActiveEmployees}
        variables={{
          filter: {
            active: true,
          },
          orders: [{ orderBy: 'NAME', order: 'ASC' }],
        }}
        showWhenLoading
        showErrorMessage
        renderEmployees={({
          searchValue,
          employees,
          onSortChange,
          onSearchChange,
          sortBy,
        }) => (
          <EmployeeList
            searchValue={searchValue}
            employees={employees}
            onSortChange={onSortChange}
            onSearchChange={onSearchChange}
            sortBy={sortBy}
          />
        )}
      />
    </Wrapper>

    <HorizontalLine />
    <Padding h b lg>
      <Padding v lg>
        <TitleContainer>
          <H2>
            <FormattedMessage id="CompanyProfile.InactiveUsers.Title" />
          </H2>
        </TitleContainer>
      </Padding>

      <EmployeeListModuleWithQuery
        query={QueryInactiveEmployees}
        showWhenLoading
        showErrorMessage
        variables={{
          filter: {
            active: false,
          },
          orders: [{ orderBy: 'NAME', order: 'ASC' }],
        }}
        renderEmployees={({
          searchValue,
          employees,
          onSortChange,
          onSearchChange,
          sortBy,
        }) => (
          <EmployeeList
            searchValue={searchValue}
            employees={employees}
            onSortChange={onSortChange}
            onSearchChange={onSearchChange}
            sortBy={sortBy}
          />
        )}
      />
    </Padding>
  </div>
)

EmployeeInfo.propTypes = {}

export default EmployeeInfo
