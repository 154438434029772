import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import RequestHolidayButton from './RequestHolidayButton'
import { openHolidayModal } from '../../redux/holiday-modal/holiday-modal.reducer'
import { selectCurrentUserData } from '../../redux/auth/auth.selector'

const mapStateToProps = createStructuredSelector({
  currentUserData: selectCurrentUserData,
})

const mapsDispatchToProps = {
  openHolidayModal,
}

export default compose(connect(mapStateToProps, mapsDispatchToProps))(
  RequestHolidayButton
)
