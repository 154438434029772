import styled from 'styled-components/macro'

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`

export const Tab = styled.div`
  display: flex;
  height: 70px;
  margin: 0 15px;
  padding: 0 10px;
  border-bottom: ${({ theme, active }) =>
    `4px solid ${active ? theme.colors.orangeLight : 'transparent'}`};
  border-top: 14px solid transparent;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
  color: ${({ theme, active }) =>
    active ? theme.colors.grayVeryDark : theme.colors.grayLight};
  font-size: 2rem;
  letter-spacing: -0.25px;
`
