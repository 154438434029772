import React from 'react'
import PropTypes from 'prop-types'
import AddHolidayModal from './AddHolidayModal'

const Wrapper = props =>
  props.isHolidayModalOpen ? (
    <AddHolidayModal
      {...props}
      isOpen={props.isHolidayModalOpen}
      onClose={props.closeHolidayModal}
    />
  ) : null

Wrapper.propTypes = {
  isHolidayModalOpen: PropTypes.bool,
  closeHolidayModal: PropTypes.func,
}

export default Wrapper
