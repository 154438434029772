import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'

import EmployeeChildInfo from './EmployeeChildInfo'

import CREATE_CHILD from '../../../../../../graphql/mutations/createChild'
import DELETE_CHILD from '../../../../../../graphql/mutations/deleteChild'
import UPDATE_CHILD from '../../../../../../graphql/mutations/updateChild'

export default compose(
  graphql(CREATE_CHILD, { name: 'createChild' }),
  graphql(DELETE_CHILD, { name: 'deleteChild' }),
  graphql(UPDATE_CHILD, { name: 'updateChild' })
)(EmployeeChildInfo)
