import styled from 'styled-components/macro'
import { height, maxHeight } from 'styled-system'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 600px 600px;
  grid-column-gap: 40px;

  @media ${({ theme }) => theme.media.mobile} {
    display: block;
  }
`

export const GridColumnWrapper = styled.div`
  grid-column: ${({ column = 1 }) => column};
  ${height};
  ${maxHeight};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${height};

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`
