import React from 'react'
import { func, string, bool } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { ReactComponent as CloseIcon } from '../../common/images/close.svg'

import {
  ModalContainer,
  ModalControls,
  ModalHeader,
  ModalTitle,
  ModalContent,
  CustomReactModal,
} from './styles'

const styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    overflow: 'scroll',
    padding: 0,
  },
}

const Modal = ({ content, isOpen, onClose, titleKey }) => {
  return (
    <CustomReactModal isOpen={isOpen} onRequestClose={onClose} style={styles}>
      <ModalContainer shadow>
        <ModalControls>
          <CloseIcon onClick={onClose} />
        </ModalControls>

        {titleKey && (
          <ModalHeader>
            <ModalTitle>
              <FormattedMessage id={titleKey} />
            </ModalTitle>
          </ModalHeader>
        )}

        <ModalContent>{content()}</ModalContent>
      </ModalContainer>
    </CustomReactModal>
  )
}
Modal.propTypes = {
  content: func.isRequired,
  onClose: func,
  titleKey: string,
  isOpen: bool,
}

Modal.defaultProps = {
  onClose: () => null,
  isOpen: false,
}

export default Modal
