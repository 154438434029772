import gql from 'graphql-tag'

const CONNECT_TO_GOOGLE = gql`
  mutation connectEmployeeToGoogle($input: ConnectEmployeeToGoogleInput!) {
    connectEmployeeToGoogle(input: $input) {
      message
    }
  }
`

export default CONNECT_TO_GOOGLE
