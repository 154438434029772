import React from 'react'
import { shape, string, func, bool } from 'prop-types'
import { Container, Text, CloseImage, CheckImage } from './styles'
import { cutText } from '../../common/utils/text'

const Tag = ({ data, onDelete, onSelectionChanged, canDelete }) => (
  <Container
    id="select-tag"
    selectable={!!onSelectionChanged}
    selected={onSelectionChanged ? data.selected : true}
    onClick={onSelectionChanged ? () => onSelectionChanged({ ...data }) : null}
  >
    {data.selected && onSelectionChanged ? (
      <CheckImage src="/img/checkIcon.svg" id="checked-tag-img" />
    ) : null}
    <Text>{cutText(data.name, 19)}</Text>
    {canDelete && onDelete ? (
      <CloseImage
        src="/img/closeIcon.svg"
        id="delete-tag"
        onClick={() => onDelete(data)}
      />
    ) : null}
  </Container>
)

Tag.propTypes = {
  data: shape({
    name: string.isRequired,
    id: string.isRequired,
    selected: bool,
  }),
  onDelete: func,
  onSelectionChanged: func,
  canDelete: bool,
}

export default Tag
