import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'
import { createStructuredSelector } from 'reselect'
import Button from '../../../../components/Button'
import AddEmployeeModal from '../AddEmployeeModal'
import { selectIsAddUserModalOpen } from '../../../../redux/holiday-modal/holiday-modal.selector'
import {
  closeAddUserModal,
  openAddUserModal,
} from '../../../../redux/holiday-modal/holiday-modal.reducer'

const CreateEmployeeButton = ({
  isAddUserModalOpen,
  onCloseAddUserModal,
  onOpenAddUserModal,
}) => {
  const handleClose = () => {
    onCloseAddUserModal()
  }

  const handleButtonClick = () => {
    onOpenAddUserModal()
  }

  return (
    <div>
      <Button
        id="open-modal-button"
        secondary
        bordered
        narrow
        reversed
        onClick={handleButtonClick}
      >
        <FormattedMessage id="Button.AddUser" />
      </Button>

      {isAddUserModalOpen && (
        <AddEmployeeModal
          id="add-amployee-modal"
          isOpen={isAddUserModalOpen}
          onSuccess={handleClose}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

CreateEmployeeButton.propTypes = {
  onCloseAddUserModal: func.isRequired,
  onOpenAddUserModal: func.isRequired,
  isAddUserModalOpen: bool.isRequired,
}

const mapStateToProps = createStructuredSelector({
  isAddUserModalOpen: selectIsAddUserModalOpen,
})

const mapDispatchToProps = {
  onCloseAddUserModal: closeAddUserModal,
  onOpenAddUserModal: openAddUserModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmployeeButton)
