import React from 'react'
import { FormattedMessage, FormattedDate } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { NavHashLink } from 'react-router-hash-link'

import { H1, Text } from '../../../../components/styles'
import { Spacer } from '../../../../components/styles/Spacer'
import Button from '../../../../components/Button'

import { ReactComponent as SpecialPlanIcon } from '../../../../common/images/special-plan.svg'
import { dimensions } from '../../../../theme/freebees'
import { ReactComponent as Waves } from '../../../../common/images/pricing-waves.svg'
import { ReactComponent as CheckMark } from '../../../../common/images/checked.svg'

const PricingContainer = styled.div`
  width: 285px;
  height: 312px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background: #ffffff;
  margin: 0 20px;
  cursor: default;
  padding: 30px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .waves {
    position: absolute;
    right: 0;
    top: 37px;
  }
`

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    flex-direction: column;
    align-items: center;
    ${PricingContainer} {
      margin: 20px 0;
    }
  }
`

const PlanHeader = ({ title }) => (
  <div>
    <Spacer mb={10}>
      <Text fontSize={22} fontWeight={600}>
        <FormattedMessage id={title} />
      </Text>
    </Spacer>
    <Text color="calendarSickLeaveColor" uppercase fontSize={12} bold>
      <FormattedMessage id="Pricing.Plan.PerMonth" />
    </Text>
  </div>
)

PlanHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

const PlanPrice = () => (
  <Spacer>
    <Spacer mb={10}>
      <Text color="calendarApprovedColor" fontSize={46}>
        <FormattedMessage id="Pricing.Plan.Standard.Price" />
      </Text>
    </Spacer>
    <Spacer mb={10}>
      <Text color="calendarApprovedColor" fontSize={12} uppercase bold>
        <FormattedMessage id="Pricing.Plan.FtPerUser" />
      </Text>
    </Spacer>
    <Text fontSize={14}>
      <Spacer mr={10} display="inline-block">
        <CheckMark />
      </Spacer>
      <FormattedMessage id="Pricing.Plan.AllFeaturesIncluded" />
    </Text>
  </Spacer>
)

const SpecialPlanPrice = () => (
  <div>
    <SpecialPlanIcon />
    <Spacer mt={10}>
      <Text fontSize={14}>
        <FormattedMessage id="Pricing.Plan.Special.For" />
      </Text>
    </Spacer>
    <div>
      <Text fontSize={18} fontWeight={600}>
        <FormattedMessage id="Pricing.Plan.Special.NUsers" />
      </Text>
    </div>
  </div>
)

const trialAvailableFrom = new Date(2019, 11, 1)
const signUpUntil = new Date(2019, 10, 30)
const endOfYear = new Date(2019, 11, 31)

function PricingPageContent() {
  const isTrialAvailable = new Date() > trialAvailableFrom
  return (
    <Spacer textAlign="center" px={['sm', 0]} pt={40}>
      <Spacer mb={160} textAlign="center">
        <Spacer mb={25}>
          <H1>
            <FormattedMessage id="Pricing.Title" />
          </H1>
        </Spacer>

        <Spacer mb={40}>
          {isTrialAvailable ? (
            <Text uppercase color="redBright" size="s22">
              <FormattedMessage id="Pricing.Trial" />
            </Text>
          ) : (
            <>
              <Text>
                <FormattedMessage
                  id="Pricing.Descriptiona"
                  defaultMessage="Sign up till {endOfIntroductionPeriod} and get a free trial till {endOfYear}"
                  values={{
                    endOfIntroductionPeriod: (
                      <FormattedDate
                        value={signUpUntil}
                        year="numeric"
                        month="long"
                        day="numeric"
                      />
                    ),
                    endOfYear: (
                      <Text color="redBright">
                        <FormattedDate
                          value={endOfYear}
                          year="numeric"
                          month="long"
                          day="numeric"
                        />
                      </Text>
                    ),
                  }}
                />
              </Text>
            </>
          )}
        </Spacer>

        <PlansContainer>
          <PricingContainer>
            <Waves className="waves" />
            <PlanHeader title="Pricing.Plan.Standard.Title" />

            <PlanPrice />

            <NavHashLink to="/#try-now">
              <Button primary rounded>
                <FormattedMessage id="Pricing.Plan.Standard.Button" />
              </Button>
            </NavHashLink>
          </PricingContainer>

          <PricingContainer>
            <Waves className="waves" />
            <PlanHeader title="Pricing.Plan.Special.Title" />

            <SpecialPlanPrice />

            <a href="mailto:hello@freebees.io">
              <Button primary rounded bordered>
                <FormattedMessage id="Pricing.Plan.Special.Button" />
              </Button>
            </a>
          </PricingContainer>
        </PlansContainer>
      </Spacer>
    </Spacer>
  )
}

export default PricingPageContent
