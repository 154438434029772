import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

export const RedirectElement = styled(NavLink)`
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 10px 0 10px 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #1f1f1f;
  &:hover,
  &:active {
    text-decoration: none;
    background: #eeeeee;
    color: ${({ theme }) => theme.colors.orange};
  }
`
