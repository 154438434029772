import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Container, Image, SortIconContainer, StyledText } from './styles'
import { SortByType } from '../../common/prop-types'

export const SortButton = ({
  sortable = true,
  text,
  sortField,
  onSortChange,
  sortBy,
}) => {
  const sortActive = sortBy.field === sortField
  const ascending = sortActive && sortBy.order === 'ASC'

  return sortable ? (
    <Container
      id="sort-button"
      onClick={() =>
        onSortChange({
          field: sortField,
          order: sortBy.order === 'ASC' ? 'DESC' : 'ASC',
        })
      }
    >
      <SortIconContainer active={sortActive}>
        <Image
          id="sort-icon-asc"
          active={sortActive && ascending}
          src="/assets/img/sort-up.svg"
        />
        <Image
          id="sort-icon-desc"
          active={sortActive && !ascending}
          src="/assets/img/sort-down.svg"
        />
      </SortIconContainer>
      <StyledText
        color={sortBy.field === sortField ? 'grayDark' : 'gray'}
        uppercase
      >
        <FormattedMessage id={text} />
      </StyledText>
    </Container>
  ) : (
    <StyledText color="gray" uppercase>
      <FormattedMessage id={text} />
    </StyledText>
  )
}

SortButton.propTypes = {
  text: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sortBy: SortByType.isRequired,
  sortable: PropTypes.bool,
}

export default SortButton
