import React, { PureComponent } from 'react'
import { bool, oneOf, node } from 'prop-types'
import styled from 'styled-components/macro'
import Tooltip from 'react-tooltip-lite'
import { CalendarCellInner, TooltipTrigger } from '../styles'
import { HolidayRequestType } from '../../../../../common/prop-types'
import HolidayDetailsModal from '../../../../../components/HolidayDetailsModal'

const Overlay = styled.div`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 600;
`

class CalendarBodyCellInner extends PureComponent {
  state = { tipOpen: false }

  closeTip = () => {
    this.setState({
      tipOpen: false,
    })
  }

  toggleTip = () => {
    this.setState(({ tipOpen }) => ({
      tipOpen: !tipOpen,
    }))
  }

  render() {
    const { holiday, firstDay, lastDay, type, children } = this.props
    const { tipOpen } = this.state

    const tooltipContent = holiday && (
      <>
        <HolidayDetailsModal
          id="holiday-detail-modal"
          holidayRequest={holiday}
          onClose={this.closeTip}
        />
      </>
    )

    return (
      <CalendarCellInner
        holidayType={holiday.type}
        holidayStatus={holiday.status}
        firstDay={firstDay}
        lastDay={lastDay}
        type={type}
      >
        {tipOpen && <Overlay onClick={this.closeTip} />}
        <Tooltip
          content={tooltipContent}
          className="target"
          tipContentClassName=""
          tagName="div"
          isOpen={tipOpen}
          background="#fff"
          padding="0"
        >
          <TooltipTrigger onClick={this.toggleTip}>{children}</TooltipTrigger>
        </Tooltip>
      </CalendarCellInner>
    )
  }
}
CalendarBodyCellInner.propTypes = {
  holiday: HolidayRequestType,
  firstDay: bool,
  lastDay: bool,
  type: oneOf(['employee', 'company']),
  children: node,
}

export default CalendarBodyCellInner
