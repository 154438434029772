import React, { Component } from 'react'
import { shape, oneOf } from 'prop-types'
import EmployeeTimeOffTabView from './EmployeeTimeOffTabView'
import { EmployeeType, HistoryType } from '../../../../../common/prop-types'

const viewModes = {
  calendar: 'calendar',
  list: 'list',
}

export class EmployeeTimeOffTab extends Component {
  state = {}

  handleViewModeChange = mode => {
    this.props.history.push(`${this.props.match.url}/${mode}`)
  }

  render() {
    const viewMode = this.props.location.pathname.includes('/list')
      ? 'list'
      : 'calendar'

    return (
      <EmployeeTimeOffTabView
        viewMode={viewMode}
        viewModes={viewModes}
        match={this.props.match}
        onViewModeChange={a => this.handleViewModeChange(a)}
        employee={this.props.employee}
      />
    )
  }
}

EmployeeTimeOffTab.propTypes = {
  employee: EmployeeType.isRequired,
  history: HistoryType.isRequired,
  match: shape({ params: shape({ viewMode: oneOf(['list', 'calendar']) }) })
    .isRequired,
  location: shape({}),
}

export default EmployeeTimeOffTab
