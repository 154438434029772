import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import {
  FlexContainer,
  CenterImage,
  DescriptionText,
  DetailText,
} from './styles'
import Button from '../../../../components/Button'

function MadeItModalContent({ submit }) {
  return (
    <FlexContainer>
      <CenterImage src="/img/freebeesHello.svg" alt="Hello" />

      <DescriptionText>
        <FormattedMessage id="MadeIt.Description.Text" />
      </DescriptionText>
      <DetailText>
        <FormattedMessage id="MadeIt.Detail.Text" />
        <br />
        <FormattedMessage id="MadeIt.Detail.Enjoy" />
      </DetailText>

      <Button id="submit-button" onClick={submit}>
        <FormattedMessage id="MadeIt.Submit.Text" />
      </Button>
    </FlexContainer>
  )
}

MadeItModalContent.propTypes = {
  submit: PropTypes.func.isRequired,
}

export default MadeItModalContent
