import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { GridContainer, CenterImage, SubmitButton } from './styles'
import Modal from '../../../../components/Modal'
import { EmployeeListType } from '../../../../common/prop-types'

const localStorageName = 'ANUM_ClosedBySubmission'

class AddNewUsersModal extends Component {
  state = {
    isOpen: true,
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }

  onSubmit = () => {
    const { history } = this.props

    localStorage.setItem(localStorageName, true)

    history.push('Company')
    this.setState({ isOpen: false })
  }

  checkIfClosedBySubmission = () =>
    JSON.parse(localStorage.getItem(localStorageName)) === true

  render() {
    const IsClosedBySubmission = this.checkIfClosedBySubmission()

    const moreEmployeesNeeded =
      this.props.employees && !this.props.employees.length

    const showModal =
      this.state.isOpen && moreEmployeesNeeded && !IsClosedBySubmission

    return (
      showModal && (
        <Modal
          isOpen={showModal}
          onClose={this.onClose}
          titleKey="AddNewUserModal.Title"
          content={() => (
            <GridContainer>
              <CenterImage src="/assets/img/cool-bee.png" alt="Hello" />
              <SubmitButton id="submit-button" onClick={() => this.onSubmit()}>
                <FormattedMessage id="AddNewUserModal.SubmitButton" />
              </SubmitButton>
            </GridContainer>
          )}
        />
      )
    )
  }
}

AddNewUsersModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  employees: EmployeeListType,
}

export default AddNewUsersModal
