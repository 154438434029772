import React from 'react'
import { shape, number } from 'prop-types'
import styled from 'styled-components/macro'

import CompanyCalendar from '../CompanyCalendar'
import { EmployeeListType, DateType } from '../../../../common/prop-types'
import EmptyListPlaceholder from '../../../../components/EmptyListPlaceholder'
import LoadingOverlay from '../../../../components/LoadingOverlay'

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const CalendarModule = ({ data: { employees = [] }, networkStatus, date }) => {
  const showEmtyListIndicator = networkStatus === 7 && !employees.length
  return (
    <Container>
      {employees.length ? (
        <CompanyCalendar employees={employees} selectedDate={date} />
      ) : null}
      {showEmtyListIndicator && (
        <EmptyListPlaceholder
          imageSrc="/assets/img/cool-bee.png"
          textId="CalendarPage.Calendar.Empty"
        />
      )}
      <LoadingOverlay networkStatus={networkStatus} />
    </Container>
  )
}

CalendarModule.propTypes = {
  data: shape({
    employees: EmployeeListType,
  }).isRequired,
  date: DateType.isRequired,
  networkStatus: number,
}

export default CalendarModule
