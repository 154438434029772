import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import CONNECT_TO_GOOGLE from '../../../../../../graphql/mutations/connectToGoogle'
import DISCONNECT_FROM_GOOGLE from '../../../../../../graphql/mutations/disconnectFromGoogle'
import EmployeeGoogleConnect from './EmployeeGoogleConnect'

export default compose(
  graphql(CONNECT_TO_GOOGLE, { name: 'connectToGoogle' }),
  graphql(DISCONNECT_FROM_GOOGLE, { name: 'disconnectFromGoogle' })
)(EmployeeGoogleConnect)
