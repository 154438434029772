import React from 'react'
import { bool, func } from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import RequestListHeader from './RequestListHeader'
import RequestListBody from './RequestListBody'
import { HolidayRequestListType, SortByType } from '../../common/prop-types'
import { Table } from './styles'

const RequestList = ({
  showEmployee,
  dashboardMode,
  onHolidayMode,
  holidayRequests,
  onSortChange,
  showStatus,
  renderStatusControl,
  sortBy,
  showHeader,
}) => {
  const showStartDate = !dashboardMode
  const showEndDate = !dashboardMode
  const showType = !dashboardMode && !onHolidayMode
  const showDescription = !dashboardMode && !onHolidayMode
  const showStatusControl = !onHolidayMode
  const iconsFirst = dashboardMode
  const showDelete = !dashboardMode
  const sortable = !onHolidayMode

  return (
    <PerfectScrollbar style={{ transform: 'translateX(20px)' }}>
      <Table>
        {!dashboardMode && showHeader ? (
          <RequestListHeader
            onSortChange={onSortChange}
            showEmployee={showEmployee}
            showStartDate={showStartDate}
            showEndDate={showEndDate}
            showType={showType}
            showDescription={showDescription}
            showStatusControl={showStatusControl}
            showStatus={showStatus}
            sortBy={sortBy}
            sortable={sortable}
          />
        ) : null}
        <RequestListBody
          holidayRequests={holidayRequests}
          showEmployee={showEmployee}
          showStartDate={showStartDate}
          showEndDate={showEndDate}
          showType={showType}
          showDescription={showDescription}
          showStatusControl={showStatusControl}
          dashboardMode={dashboardMode}
          onHolidayMode={onHolidayMode}
          showStatus={showStatus}
          showDelete={showDelete}
          iconsFirst={iconsFirst}
          renderStatusControl={renderStatusControl}
        />
      </Table>
    </PerfectScrollbar>
  )
}

RequestList.propTypes = {
  holidayRequests: HolidayRequestListType.isRequired,
  renderStatusControl: func,
  showEmployee: bool,
  dashboardMode: bool,
  onHolidayMode: bool,
  showHeader: bool,
  showStatus: bool,
  onSortChange: func,
  sortBy: SortByType,
}

RequestList.defaultProps = { showHeader: true }

export default RequestList
