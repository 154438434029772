import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import Loader from './components/Loading'
import {
  selectIsAuthenticated,
  selectIsAdmin,
  selectIsCurrentUserDataLoaded,
} from './redux/auth/auth.selector'

export const PrivateRoute = ({ component: Component, onlyAdmin, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isAdmin = useSelector(selectIsAdmin)
  const currentUserDataLoaded = useSelector(selectIsCurrentUserDataLoaded)

  const renderComponent = props => {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    }

    if (!currentUserDataLoaded) {
      return <Loader />
    }

    const allowed = onlyAdmin ? isAdmin : true

    if (!allowed) {
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    }

    return <Component {...props} />
  }

  return <Route {...rest} render={renderComponent} />
}

PrivateRoute.propTypes = {
  history: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  component: PropTypes.instanceOf(Object),
  onlyAdmin: PropTypes.bool,
}

export default PrivateRoute
