import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  isAdUserModalOpen: false,
  isOpen: false,
  requestType: 'VACATION',
  holidayRequest: null,
  selectedEmployee: null,
  startDate: null,
  endDate: null,
}

export const OPEN_HOLIDAY_MODAL = 'OPEN_HOLIDAY_MODAL'
export const CLOSE_HOLIDAY_MODAL = 'CLOSE_HOLIDAY_MODAL'
export const OPEN_ADD_USER_MODAL = 'OPEN_ADD_USER_MODAL'
export const CLOSE_ADD_USER_MODAL = 'CLOSE_ADD_USER_MODAL'

export const {
  closeHolidayModal,
  openHolidayModal,
  openAddUserModal,
  closeAddUserModal,
} = createActions(
  {},
  CLOSE_HOLIDAY_MODAL,
  OPEN_HOLIDAY_MODAL,
  OPEN_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL
)

export default handleActions(
  {
    [OPEN_HOLIDAY_MODAL]: (state, { payload }) => ({
      ...state,
      isOpen: true,
      ...payload,
    }),
    [CLOSE_HOLIDAY_MODAL]: () => defaultState,
    [OPEN_ADD_USER_MODAL]: state => ({
      ...state,
      isAdUserModalOpen: true,
    }),
    [CLOSE_ADD_USER_MODAL]: () => defaultState,
  },
  defaultState
)
