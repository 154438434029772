import { combineReducers } from 'redux'
import languageSelector from './language-selector/language-selector.reducer'
import auth from './auth/auth.reducer'
import holiday from './holiday-modal/holiday-modal.reducer'
import company from './company/company.reducer'
import profile from './profile/profile.reducer'

const RESET_APP = 'RESET_APP'
export const resetApp = () => ({ type: RESET_APP })

const appReducer = combineReducers({
  auth,
  languageSelector,
  holidayModal: holiday,
  company,
  profile,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    // eslint-disable-next-line no-param-reassign
    state = { languageSelector: state.languageSelector }
  }

  return appReducer(state, action)
}

export default rootReducer
