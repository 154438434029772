import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { Locale } from './style'
import DropdownList from '../DropdownList'
import { CallbackElement } from '../DropdownList/DropDownCallbackLink/styles'
import {
  selectActiveLanguage,
  selectLocales,
} from '../../redux/language-selector/language-selector.selector'
import { selectIsAuthenticated } from '../../redux/auth/auth.selector'

import { selectLocale } from '../../redux/language-selector/language-selector.reducer'
import UPDATE_SELECTED_LANGUAGE from '../../graphql/mutations/updateEmployeeSelectedLang'

export default function LanguageSelector() {
  const selectedLocale = useSelector(selectActiveLanguage)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const locales = useSelector(selectLocales)
  const dispatch = useDispatch()
  const [updateEmployeeSelectedLang] = useMutation(UPDATE_SELECTED_LANGUAGE)

  async function updateSelectedLanguage(input) {
    try {
      await updateEmployeeSelectedLang({
        variables: {
          input: {
            ...input,
          },
        },
      })
      return {
        success: true,
      }
    } catch (error) {
      return {
        success: false,
        error,
      }
    }
  }

  function handleLanguageChange(locale) {
    if (locale.code !== selectedLocale.code) {
      dispatch(selectLocale(locale.code))

      if (isAuthenticated) {
        updateSelectedLanguage({
          selectedLang: locale.code.toUpperCase(),
        })
      }
    }
  }

  function itemRenderer() {
    return locales.map(locale => (
      <CallbackElement
        key={locale.code}
        onClick={() => handleLanguageChange(locale)}
      >
        {locale.displayName}
      </CallbackElement>
    ))
  }

  return (
    <DropdownList
      HeaderRenderer={() => <Locale>{selectedLocale.displayName}</Locale>}
      ItemRenderer={itemRenderer}
    />
  )
}
