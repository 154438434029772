import React from 'react'
import { bool } from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'
import {
  EmployeeListType,
  FunctionType,
  IntlType,
} from '../../../../common/prop-types'
import { Text } from '../../../../components/styles/Text'

import EmployeeTag from './EmployeeTag'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 180px;
`

const SelectedEmployees = ({
  employees,
  onRemove,
  allSelected,
  allActiveSelected,
  intl,
  onUncheckAllSelected,
}) => {
  if (allSelected) {
    return (
      <Container>
        <EmployeeTag
          name={intl.formatMessage({
            id: 'Report.SelectedEmployees.AllSelected',
          })}
          onRemove={() => onUncheckAllSelected(employees)}
        />
      </Container>
    )
  }
  if (allActiveSelected) {
    return (
      <Container>
        <EmployeeTag
          name={intl.formatMessage({
            id: 'Report.SelectedEmployees.AllActiveSelected',
          })}
          onRemove={() => onUncheckAllSelected(employees)}
        />
      </Container>
    )
  }
  return employees.length ? (
    <Container>
      {employees
        .sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1
          }
          if (a.lastName > b.lastName) {
            return 1
          }
          return 0
        })
        .map(employee => (
          <EmployeeTag
            name={`${employee.lastName} ${employee.firstName}`}
            key={employee.id}
            avatarURL={employee.avatarURL}
            onRemove={onRemove}
            id={employee.id}
            showImage
          />
        ))}
    </Container>
  ) : (
    <Text fontSize={14} height="32">
      <FormattedMessage id="Report.SelectedEmployees.Empty" />
    </Text>
  )
}

SelectedEmployees.propTypes = {
  employees: EmployeeListType.isRequired,
  onRemove: FunctionType.isRequired,
  onUncheckAllSelected: FunctionType.isRequired,
  allSelected: bool,
  allActiveSelected: bool,
  intl: IntlType.isRequired,
}
export default SelectedEmployees
