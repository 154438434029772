import styled from 'styled-components/macro'

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Subsection = styled.div`
  flex: 1;
  flex-basis: 400px;
  margin: 50px 40px;
`

export const HorizontalLine = styled.div`
  mix-blend-mode: normal;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`
