import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { EmployeeType } from '../../../../../../common/prop-types'
import { Title, TitleContent, TitleIndicator } from './styles'
import { calculateHolidayCountersForYear } from '../../../../../../common/utils/calculations'
import { H2, Text } from '../../../../../../components/styles'
import EmployeeEditableField from '../../../EmployeeEditableField'
import Tooltip from '../../../../../../components/Tooltip'

export const Container = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 25px;
  line-height: 25px;
  align-items: center;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Col = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  position: relative;

  @media ${({ theme }) => theme.media.mobile} {
    width: inherit;
  }
`

const DaysOff = ({ employee, isAdmin }) => {
  const counters = calculateHolidayCountersForYear(employee)
  return (
    <Container>
      <H2>
        <Title>
          <TitleContent>
            <FormattedMessage id="DaysOff.DaysOff" />
          </TitleContent>
          <TitleIndicator>{counters.availableHolidays}</TitleIndicator>
        </Title>
      </H2>

      <Wrapper>
        <Col>
          <Text fontSize={16} color="grayLight">
            <FormattedMessage id="DaysOff.Base" />
          </Text>
        </Col>
        <Col>
          <Text fontSize={16}>{counters.baseHolidays}</Text>
        </Col>
      </Wrapper>

      <Wrapper>
        <Col>
          <Text fontSize={16} color="grayLight">
            <FormattedMessage id="DaysOff.Compensatory" />
          </Text>
        </Col>
        <Col>
          <Text fontSize={16}>{counters.compensatoryHolidays}</Text>
        </Col>
      </Wrapper>

      <Wrapper>
        <Col>
          <Text fontSize={16} color="grayLight">
            <FormattedMessage id="DaysOff.ExtraDays" />
          </Text>
        </Col>
        <Col>
          {isAdmin ? (
            <EmployeeEditableField
              type="number"
              field="extraHolidaysCounter"
              value={counters.extraHolidays}
              employee={employee}
              isNegativeAllowed={false}
            />
          ) : (
            counters.extraHolidays
          )}
        </Col>
      </Wrapper>

      <Wrapper>
        <Col>
          <Tooltip
            content="DaysOff.UnaccountedHolidays.Tooltip"
            translate
            styles={{
              position: 'relative',
              left: '-25px',
              marginRight: '-20px',
              cursor: 'pointer',
            }}
          >
            <img src="/assets/icons/info.svg" alt="info" />
          </Tooltip>
          <Text fontSize={16} color="grayLight">
            <FormattedMessage id="DaysOff.UnaccountedHolidays" />
          </Text>
        </Col>
        <Col>
          {isAdmin ? (
            <EmployeeEditableField
              type="number"
              field="unaccountedHolidaysCounter"
              employee={employee}
              value={counters.unaccountedHolidays}
              isNegativeAllowed={false}
            />
          ) : (
            counters.unaccountedHolidays
          )}
        </Col>
      </Wrapper>
    </Container>
  )
}

DaysOff.propTypes = {
  employee: EmployeeType.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}
export default DaysOff
