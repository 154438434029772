import styled from 'styled-components/macro'

import { Spacer } from '../styles/Spacer'
import { Text } from '../styles/Text'
import { colors, dimensions } from '../../theme/freebees'
import { Elevated } from '../../elements'

export const PageWrapper = styled.div`
  padding: 100px;
  display: flex;
  justify-content: center;
`

export const CenterAlignedSpacer = styled(Spacer)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledErrorText = styled(Text)`
  color: ${colors.rejectRed};
`

export const TextLink = styled(Text)`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: ${colors.orangeLight};
  display: flex;
  align-items: center;
`

export const StyledElevated = styled(Elevated)`
  width: 620px;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    padding: 25px 10px;
    margin-top: 10px;
    width: 100%;
  }
`
