import React, { Component } from 'react'
import { func, string } from 'prop-types'
import EditableField from '../../../../components/EditableField'
import { CompanyType } from '../../../../common/prop-types'
import notify from '../../../../common/services/notification'
import { refetchQueries } from '../../../../common/constants/refetchQueries'

export class CompanyEditableField extends Component {
  state = {
    isLoading: false,
  }

  handleValueChange = async value => {
    const { field } = this.props
    const variables = { [field]: value }

    this.setState({ isLoading: true })
    const { success } = await this.updateCompany(variables)

    if (success) {
      notify('success', 'Notification.Company.Updated')
    } else {
      notify('error', 'Notification.Updated.Failed')
    }

    this.setState({ isLoading: false })
  }

  updateCompany = async input => {
    try {
      await this.props.UpdateCompany({
        variables: { input },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  render() {
    const { company, field, ...otherProps } = this.props

    const value = company && company[field]

    if (!value) return null

    return (
      <EditableField
        id="editable-text-field"
        isLoading={this.state.isLoading}
        value={value}
        onChange={v => this.handleValueChange(v)}
        {...otherProps}
      />
    )
  }
}

CompanyEditableField.propTypes = {
  UpdateCompany: func.isRequired,
  company: CompanyType.isRequired,
  field: string.isRequired,
}

export default CompanyEditableField
