import React from 'react'

import DashboardPageContent from './Dashboard.content'
import { usePageTracking } from '../../../hooks/usePageTracking'

const DashboardPage = () => {
  usePageTracking('Dashboard')

  return <DashboardPageContent />
}

export default DashboardPage
