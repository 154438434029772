import { graphql } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import EmployeeDectivate from './EmployeeDeactivate'
import DEACTIVATE_EMPLOYEE from '../../../graphql/mutations/deactivateEmployee'

export default compose(
  injectIntl,
  graphql(DEACTIVATE_EMPLOYEE)
)(EmployeeDectivate)
