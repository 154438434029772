import { uniqueElementsBy } from '../../../../common/utils/calculations'
import { fixTimezoneOffset } from '../../../../common/utils/calendar'

export const downloadFormats = {
  PDF: { key: 'PDF', mimeType: 'application/pdf', ext: 'pdf' },
  CSV: { key: 'CSV', mimeType: 'text/csv', ext: 'csv' },
  XLS: { key: 'XLS', mimeType: 'application/vnd.ms-excel', ext: 'xls' },
}

export const urltoFile = async (url, filename, mimeType) => {
  try {
    const data = await fetch(url)
    const buffer = await data.arrayBuffer()
    const file = new File([buffer], filename, { type: mimeType })
    return file
  } catch (error) {
    return null
  }
}

export const decodeResponse = async ({ data, format }) => {
  const meta = downloadFormats[format]
  const url = `data:${meta.mimeType};base64,${data}`
  const fileName = `report.${meta.ext}`

  const file = await urltoFile(url, fileName, meta)
  return file
}

export const getReportInputFromFormData = ({
  selectedDownloadType,
  periodSelector,
  selectedEmployees,
  filters,
}) => {
  const includeSickLeaves = !!filters.find(
    filter => filter.key === 'SickLeaves' && filter.checked
  )
  const includeHolidays = !!filters.find(
    filter => filter.key === 'Holidays' && filter.checked
  )
  const includePersonalInfo = !!filters.find(
    filter => filter.key === 'PersonalData' && filter.checked
  )
  return {
    format: selectedDownloadType,
    intervalStart: fixTimezoneOffset(periodSelector.startDate),
    intervalEnd: fixTimezoneOffset(periodSelector.endDate),
    employeeIds: selectedEmployees.map(e => Number(e.id)),
    settings: {
      includeSickLeaves,
      includeHolidays,
      includePersonalInfo,
      personalInfo: {
        includeBirthDate: includePersonalInfo,
        includeHireDate: includePersonalInfo,
        includeNumberOfChildren: includePersonalInfo,
        includeEmail: includePersonalInfo,
      },
    },
  }
}

export const removeEmployeesFromList = (employees, current) =>
  current.filter(employee => !employees.map(e => e.id).includes(employee.id))

export const addEmployeesToList = (employees, current) =>
  uniqueElementsBy([...current, ...employees], (a, b) => a.id === b.id)

const isSelectedFiltersValid = formData => {
  const minMandatoryKeys = ['SickLeaves', 'Holidays']
  return (
    formData.filters.filter(
      item => minMandatoryKeys.includes(item.key) && item.checked
    ).length >= 1
  )
}

export const isReportFormValid = formData =>
  !!(
    formData.selectedEmployees.length &&
    formData.periodSelector.startDate &&
    formData.periodSelector.endDate &&
    formData.selectedDownloadType
  ) && isSelectedFiltersValid(formData)
