import gql from 'graphql-tag'

const INVITE_EMPLOYEE = gql`
  mutation inviteEmployee($input: InviteEmployeeInput!) {
    inviteEmployee(input: $input) {
      id
    }
  }
`

export default INVITE_EMPLOYEE
