import { oneOfType, string } from 'prop-types'
import { useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns'
import en from 'date-fns/locale/en-GB'
import hu from 'date-fns/locale/hu'

import { DateType } from '../../common/prop-types'
import { selectActiveLanguage } from '../../redux/language-selector/language-selector.selector'

const formats = {
  hu: 'yyyy. MMM d.',
  en: 'd MMM yyyy',
}

const locales = {
  hu,
  en,
}

export const getDateFormat = (code = 'en') => formats[code]
export const getLocale = (code = 'en') => locales[code]

export const localeFormat = (date, code = 'en') => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date
  return format(parsedDate, getDateFormat(code), { locale: getLocale(code) })
}

const DateField = ({ date }) => {
  const { code } = useSelector(selectActiveLanguage)

  return localeFormat(date, code)
}

DateField.propTypes = {
  date: oneOfType([string, DateType]).isRequired,
}

export default DateField
