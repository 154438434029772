import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Prompt } from 'react-router-dom'
import { Title, Row, Field, Value, Padding } from './styles'
import {
  EmployeeType,
  DateType,
  IntlType,
} from '../../../../../../common/prop-types'
import {
  getEmployeeAgeSelectionRules,
  getHireDateSelectionRules,
} from '../../../../../../common/services/dateSelectionRules'
import DateField from '../../../../../../components/DateField'
import { H2, Margin } from '../../../../../../components/styles'
import {
  ButtonGroup,
  ButtonIcon,
} from '../../../../../../components/styles/Button'
import Button from '../../../../../../components/Button'
import ButtonSelector from '../../../../../../components/ButtonSelector'
import Input from '../../../../../../components/Input'
import EmployeeRoleEditor from '../../../../../../components/EmployeeControl/EmployeeRoleEditor'
import EmployeeStatusEditor from '../../../../../../components/EmployeeControl/EmployeeStatusEditor'

const birthDateRules = getEmployeeAgeSelectionRules()

const EmployeePersonalInfoView = ({
  editing,
  employee,
  formData,
  errors,
  onEditModeChange,
  onFormDataChange,
  onSave,
  onCancel,
  isLoading,
  isAdmin,
  intl,
}) => {
  const sex = employee.sex ? (
    <FormattedMessage id={`Gender.${employee.sex}`} />
  ) : (
    '-'
  )
  const birthDate = employee.birthDate ? (
    <DateField date={employee.birthDate} />
  ) : (
    '-'
  )
  const hireDate = employee.hireDate ? (
    <DateField date={employee.hireDate} />
  ) : (
    '-'
  )

  const hireDateRules = getHireDateSelectionRules(formData.birthDate)

  return (
    <>
      <Prompt
        when={editing}
        message={() =>
          `${intl.formatMessage({
            id: 'Employee.PersonalInfoTab.AreYouSureToLeave.Title',
          })}\n${intl.formatMessage({
            id: 'Employee.PersonalInfoTab.AreYouSureToLeave.Description',
          })}`
        }
      />
      <H2>
        <Title>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.Title" />

          {isAdmin && (
            <Margin l>
              {editing ? (
                <ButtonGroup>
                  <Button
                    secondary
                    orange
                    className="save-button"
                    type="button"
                    onClick={onSave}
                    loading={isLoading}
                    style={{
                      width: '91px',
                      height: '42px',
                      padding: '0px',
                      border: '0px',
                      minWidth: '91px',
                    }}
                  >
                    <FormattedMessage id="Button.Save" />
                  </Button>
                  <Button
                    secondary
                    bordered
                    reversed
                    style={{
                      width: '91px',
                      height: '42px',
                      padding: '0px',
                      minWidth: '91px',
                    }}
                    onClick={onCancel}
                    className="cancel-button"
                  >
                    <FormattedMessage id="Button.Cancel" />
                  </Button>
                </ButtonGroup>
              ) : (
                <ButtonIcon onClick={onEditModeChange}>
                  <img src="/assets/icons/edit.svg" alt="calendar" />
                </ButtonIcon>
              )}
            </Margin>
          )}
        </Title>
      </H2>
      <Padding />
      <Row>
        <Field>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.Email" />
        </Field>
        <Value>
          {editing ? (
            <Input
              type="email"
              hideErrors={!errors || !errors.email}
              error={errors && errors.email}
              id="email"
              value={formData.email}
              onChange={event =>
                onFormDataChange({ email: event.target.value })
              }
            />
          ) : (
            employee.email
          )}
        </Value>
      </Row>
      <Row>
        <Field>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.Gender" />
        </Field>
        <Value>
          {editing ? (
            <ButtonSelector
              id="genre-selector"
              options={[
                {
                  value: 'Gender.FEMALE',
                  key: 'FEMALE',
                },
                { value: 'Gender.MALE', key: 'MALE' },
              ]}
              value={formData.sex}
              onChange={value => onFormDataChange({ sex: value })}
            />
          ) : (
            sex
          )}
        </Value>
      </Row>
      <Row>
        <Field>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.BirthDate" />
        </Field>
        <Value>
          {editing ? (
            <Input
              type="date"
              hideErrors
              id="birth-date"
              maxDate={birthDateRules.maxDate}
              minDate={birthDateRules.minDate}
              selected={formData.birthDate}
              showYearDropdown
              scrollableYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={100}
              showDisabledMonthNavigation
              onChange={date => onFormDataChange({ birthDate: date })}
            />
          ) : (
            birthDate
          )}
        </Value>
      </Row>
      <Row>
        <Field>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.EmployedFrom" />
        </Field>
        <Value>
          {isAdmin && editing ? (
            <Input
              type="date"
              hideErrors={!errors || !errors.hireDate}
              error={errors && errors.hireDate}
              id="hire-date"
              maxDate={hireDateRules.maxDate}
              minDate={hireDateRules.minDate}
              selected={formData.hireDate}
              showYearDropdown
              scrollableYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={100}
              showDisabledMonthNavigation
              onChange={date => onFormDataChange({ hireDate: date })}
            />
          ) : (
            hireDate
          )}
        </Value>
      </Row>

      <Padding />

      <Row>
        <Field>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.Role" />
        </Field>
        <Value>
          {isAdmin && editing ? (
            <EmployeeRoleEditor employee={employee} />
          ) : (
            <FormattedMessage id={`User.Roles.{${employee.role.name}}`} />
          )}
        </Value>
      </Row>
      <Row>
        <Field>
          <FormattedMessage id="Employee.PersonalInfoTab.PersonalInfo.Status" />
        </Field>
        <Value>
          {isAdmin && editing ? (
            <EmployeeStatusEditor employee={employee} />
          ) : (
            <FormattedMessage
              id={`Employee.Status.${employee.active ? 'Active' : 'Inactive'}`}
            />
          )}
        </Value>
      </Row>
    </>
  )
}

EmployeePersonalInfoView.propTypes = {
  editing: PropTypes.bool,
  employee: EmployeeType.isRequired,
  errors: PropTypes.shape({
    hireDate: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
  }),
  formData: PropTypes.shape({
    email: PropTypes.string,
    sex: PropTypes.string,
    birthDate: DateType,
    hireDate: DateType,
  }),
  onEditModeChange: PropTypes.func.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  intl: IntlType.isRequired,
}

export default EmployeePersonalInfoView
