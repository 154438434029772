import React, { Component } from 'react'
import { func, shape, string, arrayOf, bool } from 'prop-types'
import ConfirmActionModal from '../Modals/ConfirmActionModal'
import notify from '../../common/services/notification'
import { trackEvent } from '../../common/services/analytics'
import { throwCustomError } from '../../common/constants/errorCodes'

export class EntityContol extends Component {
  state = {
    isModalOpen: false,
    isLoading: false,
  }

  openConfirmationModal = () => {
    this.setState({ isModalOpen: true })
  }

  handleSubmit = async ({ message }) => {
    await this.handleRequestConfirmed({ message })
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  handleRequestConfirmed = async ({ message }) => {
    const { mutate, entity, refetchQueries, hasComment, useInput, eventName } =
      this.props
    try {
      this.setState({ isLoading: true })
      let variables = { id: entity.id }

      if (hasComment) {
        variables.rejectionReason = message
      }

      if (useInput) {
        variables = { input: variables }
      }

      trackEvent(eventName, { id: entity.id })

      const response = await mutate({
        variables,
        refetchQueries,
        errorPolicy: 'all',
      })

      throwCustomError(response, 'Notification.Action.Failed')

      notify('success', 'Notification.Action.Success')
    } catch (error) {
      notify('error', error.message)
    }

    this.setState({ isModalOpen: false, isLoading: false })
  }

  render() {
    const { isModalOpen, isLoading } = this.state
    const { renderTrigger, suppressConfirmation, ...otherProps } = this.props
    return (
      <div>
        {isModalOpen && (
          <ConfirmActionModal
            isOpen={isModalOpen}
            onSubmit={({ message }) => this.handleSubmit({ message })}
            onClose={() => this.handleCloseModal()}
            isLoading={isLoading}
            {...otherProps}
          />
        )}
        {renderTrigger({
          id: 'render-trigger',
          onClick: () => {
            if (suppressConfirmation) this.handleRequestConfirmed('')
            else this.openConfirmationModal()
          },
          isLoading,
        })}
      </div>
    )
  }
}

EntityContol.propTypes = {
  entity: shape({
    id: string.isRequired,
  }),
  renderTrigger: func.isRequired,
  mutate: func.isRequired,
  refetchQueries: arrayOf(string),
  hasComment: bool,
  useInput: bool,
  suppressConfirmation: bool,
  eventName: string,
}

EntityContol.defaultProps = {
  refetchQueries: [],
  suppressConfirmation: false,
}

export default EntityContol
