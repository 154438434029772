export const features = {
  persistReduxStore: 'persistReduxStore',
  persistApolloCache: 'persistApolloCache',
  useReduxLogger: 'useReduxLogger',
}

const featureFlags = {
  [features.persistReduxStore]: true,
  [features.useReduxLogger]: [`dev`, `staging`, `release`].includes(
    process.env.REACT_APP_ENVIRONMENT
  ),
  [features.persistApolloCache]: true,
}

export const isFeatureEnabled = key => {
  return featureFlags[key]
}
