import React from 'react'
import styled from 'styled-components/macro'
import { FormattedMessage } from 'react-intl'
import { usePageTracking } from '../../../../hooks/usePageTracking'
import EmbedDocument from '../../../../components/EmbedDocument'
import { H1 } from '../../../../components/styles'
import { Spacer } from '../../../../components/styles/Spacer'

export const LegalContainer = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  height: calc(100vh - 60px);
`

const TermsAndConditionsUrl =
  'https://docs.google.com/document/d/e/2PACX-1vQAaj9RC3--QnmFqCnXt8IlzOOoTLYyQZspuay5kng6Oz1eowBrGlDmLCGo3f6cV8OgM-X-TNKSQcDm/pub?embedded=true'

export default function TermsAndConditions() {
  usePageTracking('TermsAndConditions')

  return (
    <LegalContainer>
      <H1>
        <FormattedMessage id="TermsAndConditionsPage.Title" />
      </H1>
      <Spacer height="100%">
        <EmbedDocument
          url={TermsAndConditionsUrl}
          title="Terms and Conditions"
        />
      </Spacer>
    </LegalContainer>
  )
}
