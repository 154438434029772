import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { selectCompanyName } from '../../../../redux/company/company.selector'
import { selectCurrentUserData } from '../../../../redux/auth/auth.selector'
import { Row, H1, Reveal, H2 } from '../../../../components/styles'

export const PageHeader = () => {
  const companyName = useSelector(selectCompanyName)
  const me = useSelector(selectCurrentUserData)

  return (
    <Row>
      <H1>
        <FormattedMessage
          id="Dashboard.Title"
          values={{ name: me.firstName }}
        />
      </H1>
      <Reveal ready={!!companyName}>
        <H2>{companyName}</H2>
      </Reveal>
    </Row>
  )
}

PageHeader.propTypes = {}
