import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { isBefore, isAfter, isEqual } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { DateType } from '../../common/prop-types'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

import { Col, Padding, Container } from './styles'
import { InputLabel } from '../Input/styles'
import { getWorkdaysInYear } from '../../common/services/workdays'
import { getDateFormat } from '../DateField/DateField'

export default class DateRangePicker extends React.Component {
  handleChangeStart = date => {
    if (
      isBefore(date, this.props.value.endDate) ||
      isEqual(date, this.props.value.endDate)
    ) {
      this.props.onChange({
        startDate: date,
        endDate: this.props.value.endDate,
      })
    } else if (isAfter(date, this.props.value.endDate)) {
      this.props.onChange({
        startDate: date,
        endDate: date,
      })
    }
  }

  handleChangeEnd = date => {
    if (
      isAfter(date, this.props.value.startDate) ||
      isEqual(date, this.props.value.startDate)
    ) {
      this.props.onChange({
        startDate: this.props.value.startDate,
        endDate: date,
      })
    }
  }

  render() {
    const { activeLanguage } = this.props
    const dateFormat = getDateFormat(activeLanguage.code)

    return (
      <Container>
        <Col>
          <InputLabel>
            <FormattedMessage id="Input.From.Label" />
          </InputLabel>
          <DatePicker
            id="startDate"
            selected={this.props.value.startDate}
            selectsStart
            startDate={this.props.value.startDate}
            endDate={this.props.value.endDate}
            onChange={date => this.handleChangeStart(date)}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            scrollableYearDropdown
            popperPlacement="bottom"
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={100}
            locale={this.props.locale}
            dateFormat={dateFormat}
            excludeDates={this.props.onlyWorkDays ? getWorkdaysInYear() : null}
          />
        </Col>
        <Padding />
        <Col>
          <InputLabel>
            <FormattedMessage id="Input.To.Label" />
          </InputLabel>
          <DatePicker
            id="endDate"
            selected={this.props.value.endDate}
            selectsEnd
            startDate={this.props.value.startDate}
            endDate={this.props.value.endDate}
            onChange={date => this.handleChangeEnd(date)}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            popperPlacement="bottom"
            scrollableYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={100}
            locale={this.props.locale}
            dateFormat={dateFormat}
            excludeDates={this.props.onlyWorkDays ? getWorkdaysInYear() : null}
          />
        </Col>
      </Container>
    )
  }
}

DateRangePicker.propTypes = {
  value: PropTypes.shape({
    startDate: DateType.isRequired,
    endDate: DateType.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([DateType, PropTypes.string]),
  maxDate: PropTypes.oneOfType([DateType, PropTypes.string]),
  locale: PropTypes.string,
  onlyWorkDays: PropTypes.bool,
  activeLanguage: PropTypes.shape({ code: PropTypes.string }),
}
