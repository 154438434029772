import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
`
export const Tick = styled.img`
  width: 15px;
  height: 15px;
  &:hover {
    cursor: pointer;
  }
`

export const ActualDate = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  min-width: 165px;
`
