import styled from 'styled-components/macro'
import { H1 } from '../../../components/styles'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const TitleRow = styled(Row)`
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${({ theme }) => theme.media.mobile} {
    width: initial;
    justify-content: center;
  }
`

export const Title = styled(H1)`
  @media ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`

export const Header = styled(Row)`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.media.mobile} {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 0;
    margin-top: 25px;
  }
`

export const FilterRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - 80px);
  grid-gap: 20px;
`

export const Filters = styled.div`
  margin-top: 45px;

  @media ${({ theme }) => theme.media.mobile} {
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 15px auto 0;
  }
`
