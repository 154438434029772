import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavHashLink } from 'react-router-hash-link'
import { useSelector } from 'react-redux'

import CompanyRegistration from '../../../../components/CompanyRegistration'
import { Spacer } from '../../../../components/styles/Spacer'
import Button from '../../../../components/Button'

import { TextV as Text } from '../../../../components/styles'
import { selectActiveLanguage } from '../../../../redux/language-selector/language-selector.selector'

const features = [
  {
    id: 'article1',
    image: {
      en: 'img1.png',
      hu: 'img1_hu.png',
    },
    title: 'Landing.Features.1.Title',
    description: 'Landing.Features.1.Description',
    gridColumn: ['span 1', 'span 3'],
  },
  {
    id: 'article2',
    image: {
      en: 'img2.png',
      hu: 'img2_hu.png',
    },
    title: 'Landing.Features.2.Title',
    description: 'Landing.Features.2.Description',
    gridColumn: ['span 1', 'span 3'],
  },
  {
    id: 'article3',
    image: {
      en: 'img3.png',
      hu: 'img3_hu.png',
    },
    title: 'Landing.Features.3.Title',
    description: 'Landing.Features.3.Description',
    gridColumn: ['span 1', 'span 2'],
  },
  {
    id: 'article4',
    image: {
      en: 'img4.png',
      hu: 'img4_hu.png',
    },
    title: 'Landing.Features.4.Title',
    description: 'Landing.Features.4.Description',
    gridColumn: ['span 1', 'span 2'],
  },
  {
    id: 'article5',
    image: {
      en: 'img5.png',
      hu: 'img5_hu.png',
    },
    title: 'Landing.Features.5.Title',
    description: 'Landing.Features.5.Description',
    gridColumn: ['span 1', 'span 2'],
  },
]

const LandingView = () => {
  const lang = useSelector(selectActiveLanguage)
  return (
    <>
      <Spacer
        id="try-now"
        backgroundImage={['none', "url('img/landing-page-header.svg')"]}
        backgroundPosition={['left bottom']}
        backgroundRepeat="no-repeat"
        backgroundSize={['cover']}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="75vh"
        mx="auto"
        px={['sm', 0]}
        textAlign="center"
      >
        <Spacer mb={[30, 60]} maxWidth={760} mx="auto">
          <Text variant="pageTitle">
            <FormattedMessage id="Landing.CompanyReg.Title" />
          </Text>
        </Spacer>
        <CompanyRegistration key={lang.code} />
      </Spacer>
      <Spacer
        id="why-freebees"
        textAlign="center"
        mt={[70, 140]}
        mb={[50, 100]}
        maxWidth={904}
        mx="auto"
      >
        <Text variant="title">
          <FormattedMessage id="Landing.Features.Title" />
        </Text>
      </Spacer>
      <Spacer
        display="grid"
        gridColumnGap={40}
        gridRowGap={[50, 100]}
        gridTemplateColumns={['1fr', 'repeat(6, 1fr)']}
        maxWidth={1240}
        mx="auto"
        px={10}
      >
        {features.map(feature => (
          <Spacer
            key={feature.id}
            gridColumn={feature.gridColumn}
            display="flex"
            flexDirection="column"
          >
            <img
              src={`img/${feature.image[lang.code]}`}
              alt="artice"
              style={{
                objectFit: 'contain',
                width: '100%',
                borderRadius: '2px',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
              }}
            />
            <Spacer mt={[20, 60]} mb={[10, 20]}>
              <Text variant="sectionTitle">
                <FormattedMessage id={feature.title} />
              </Text>
            </Spacer>
            <Text variant="description" textAlign="justify">
              <FormattedMessage id={feature.description} />
            </Text>
          </Spacer>
        ))}
      </Spacer>
      <Spacer
        backgroundImage={["url('img/landing-page-footer.svg')"]}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={['center bottom']}
      >
        <Spacer
          maxWidth={1240}
          textAlign="center"
          mx="auto"
          pt={[90, 180]}
          display="flex"
          flexDirection="column"
        >
          <Text variant="modalTitle">
            <FormattedMessage id="Landing.TryIt.Title" />
          </Text>
          <Spacer mt={40} mb={75}>
            <NavHashLink to="/#try-now" smooth>
              <Button narrow reversed id="close-modal" type="button" primary>
                <FormattedMessage id="Landing.Page.Button.Try" />
              </Button>
            </NavHashLink>
          </Spacer>
        </Spacer>
      </Spacer>
    </>
  )
}

LandingView.propTypes = {}

export default LandingView
