import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ProfileImage from '../ProfileImage'
import {
  DropdownWrapper,
  UserProfileWrapper,
  DropdownTitle,
  DropdownTitleText,
  DropdownList,
  ProfileImageWrapper,
  DropDownToggleIcon,
} from './styles'
import { cutText } from '../../common/utils/text'
import { useClickOutside } from '../../hooks/useClickOutside'

export default function Dropdown({
  HeaderRenderer,
  ItemRenderer,
  profileImage,
  name,
}) {
  const [listOpen, setListOpen] = useState(false)
  const ref = useClickOutside(() => setListOpen(false))

  function handleTitleClick() {
    setListOpen(isOpen => !isOpen)
  }

  return (
    <DropdownWrapper ref={ref}>
      <DropdownTitle id="dropdown-header-title" onClick={handleTitleClick}>
        {HeaderRenderer ? (
          HeaderRenderer()
        ) : (
          <UserProfileWrapper>
            <ProfileImageWrapper>
              <ProfileImage src={profileImage} />
            </ProfileImageWrapper>
            <DropdownTitleText>{cutText(`${name}`, 22)}</DropdownTitleText>
          </UserProfileWrapper>
        )}
        <DropDownToggleIcon
          Open={listOpen}
          src="/assets/img/dropdownToggle.svg"
          alt="toggle"
        />
      </DropdownTitle>
      <DropdownList
        show={listOpen}
        id="dropdown-list"
        onClick={handleTitleClick}
      >
        {ItemRenderer()}
      </DropdownList>
    </DropdownWrapper>
  )
}

Dropdown.propTypes = {
  ItemRenderer: PropTypes.func,
  HeaderRenderer: PropTypes.func,
  name: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
}
