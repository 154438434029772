import styled from 'styled-components/macro'
import { Form } from '../styles'
import { dimensions } from '../../../theme/freebees'

export const StyledForm = styled(Form)`
  width: 343px;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    width: 100%;
  }
`
