import styled from 'styled-components/macro'

export const ModalHeaderWrapper = styled.div`
  height: 60px;
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
`

export const ModalCloseIcon = styled.img`
  margin-top: 31px;
  margin-right: 30px;

  cursor: pointer;
  width: 12px;
  height: 12px;
`
export const TitleWrapper = styled.div`
  height: 105px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Description = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;

  margin-top: 30px;

  text-align: center;

  color: #111111;
`

export const ContentWrapper = styled.div`
  margin-bottom: 25px;
`
