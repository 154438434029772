import React from 'react'
import {
  string,
  bool,
  func,
  shape,
  arrayOf,
  oneOfType,
  number,
} from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import DatePicker, { registerLocale } from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB'

import Tooltip from '../Tooltip'
import { IntlType, DateType } from '../../common/prop-types'
import { Spacer } from '../styles/Spacer'
import ErrorMessage from '../ErrorMessage'
import { StyledInput, InputLabel } from './styles'
import SelectorInput from '../SelectorInput'
import DateRangePicker from '../DateRangePicker'
import { TextArea } from '../styles/TextArea'
import { getDateFormat } from '../DateField/DateField'
import { selectActiveLanguage } from '../../redux/language-selector/language-selector.selector'

registerLocale('en-GB', enGB)

const Input = ({
  dateFormatCalendar,
  disabled,
  defaultValue,
  filterOptions,
  error,
  hideErrors,
  id,
  intl,
  isSearchable,
  label,
  maxDate,
  maxLength,
  maxHeight,
  minDate,
  onChange,
  onlyWorkDays,
  options,
  placeholder,
  scrollableYearDropdown,
  selected,
  selectorStyle,
  showDisabledMonthNavigation,
  showYearDropdown,
  type,
  value,
  required,
  yearDropdownItemNumber,
  tooltip,
  withBorder,
  hideLabel,
  ...props
}) => {
  let inputType = 'StyledInput'

  const { code } = useSelector(selectActiveLanguage)

  const dateFormat = getDateFormat(code)

  if (type === 'selector') inputType = 'SelectorInput'
  if (type === 'date-range') inputType = 'DateRangePicker'
  if (type === 'date') inputType = 'DatePicker'
  if (type === 'text-area') inputType = 'TextArea'

  const commonProps = {
    value,
    onChange,
    disabled,
  }

  return (
    <div className="input-container">
      {!props.hidden && !hideLabel && label && (
        <InputLabel id={`${id}-label`}>
          <FormattedMessage id={label} />
          {required && '*'}
          {tooltip && (
            <Tooltip
              content={tooltip}
              translate
              styles={{
                cursor: 'pointer',
                marginLeft: '5px',
              }}
            >
              <img src="/assets/icons/info.svg" alt="info" />
            </Tooltip>
          )}
        </InputLabel>
      )}

      {inputType === 'StyledInput' && (
        <StyledInput
          id={`${id}-input`}
          {...commonProps}
          placeholder={
            placeholder
              ? intl.formatMessage({
                  id: placeholder,
                })
              : null
          }
          type={type}
          {...props}
          maxLength={maxLength}
          onChange={event => {
            if (event.target.value !== ' ') onChange(event)
          }}
        />
      )}

      {inputType === 'SelectorInput' && (
        <SelectorInput
          id={`${id}-selector`}
          options={options}
          {...commonProps}
          label={
            label
              ? intl.formatMessage({
                  id: label,
                })
              : null
          }
          isSearchable={isSearchable}
          selectorStyle={selectorStyle}
          defaultValue={defaultValue}
          filterOptions={filterOptions}
          withBorder={withBorder}
        />
      )}

      {inputType === 'DateRangePicker' && (
        <DateRangePicker
          id={`${id}-date-range-picker`}
          {...commonProps}
          minDate={minDate}
          maxDate={maxDate}
          locale={intl.locale}
          onlyWorkDays={onlyWorkDays}
        />
      )}

      {inputType === 'DatePicker' && (
        <DatePicker
          id={`${id}-date-picker`}
          {...commonProps}
          minDate={minDate}
          showYearDropdown={showYearDropdown}
          scrollableYearDropdown={scrollableYearDropdown}
          dateFormatCalendar={dateFormatCalendar}
          yearDropdownItemNumber={yearDropdownItemNumber}
          showDisabledMonthNavigation={showDisabledMonthNavigation}
          selected={selected}
          maxDate={maxDate}
          locale={intl.locale}
          onlyWorkDays={onlyWorkDays}
          popperPlacement="bottom-end"
          dateFormat={dateFormat}
        />
      )}

      {inputType === 'TextArea' && (
        <TextArea
          id={`${id}-text-area`}
          {...commonProps}
          maxLength={maxLength}
          maxHeight={maxHeight}
        />
      )}

      {!hideErrors && !props.hidden && (
        <Spacer minHeight={25} mt="5px">
          {error && error.length > 0 && (
            <ErrorMessage error={error[0]} id={`${id}-error`} />
          )}
        </Spacer>
      )}
    </div>
  )
}

Input.propTypes = {
  dateFormatCalendar: string,
  defaultValue: shape({}),
  disabled: bool,
  error: arrayOf(string),
  filterOptions: func,
  hidden: bool,
  hideErrors: bool,
  id: string,
  intl: IntlType.isRequired,
  isSearchable: bool,
  label: string,
  maxDate: DateType,
  maxHeight: number,
  maxLength: number,
  minDate: DateType,
  onChange: func,
  onlyWorkDays: bool,
  options: arrayOf(shape({})),
  placeholder: string,
  required: bool,
  scrollableYearDropdown: bool,
  selected: DateType,
  selectorStyle: string,
  showDisabledMonthNavigation: bool,
  showYearDropdown: bool,
  tooltip: string,
  type: string,
  value: oneOfType([string, shape({})]),
  yearDropdownItemNumber: number,
  withBorder: bool,
  hideLabel: bool,
}

Input.defaultProps = {
  id: 'input',
  type: 'text',
}

Input.displayName = 'Input'

export default Input
