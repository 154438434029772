import styled from 'styled-components/macro'
import { space, width, maxWidth } from 'styled-system'
import { Spacer } from '../components/styles/Spacer'

export * from './Inputs'
export * from './Modal'

export const PaletteColor = styled.div`
  width: 88px;
  height: 63px;
  background-color: ${({ color }) => color};
  display: inline-block;
  margin: 5px;
  border: 1px solid black;
`

export const PaletteColorText = styled.div`
  border-radius: 4px;
  background-color: grey;
  color: #fff;
  font-size: 12px;
  text-align: center;
`

export const modalStyle = {
  content: {
    width: '465px',
    bottom: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
}

export const Elevated = styled.div`
  ${space};
  ${width};
  ${maxWidth};
  background-color: ${({ theme }) => theme.colors.orangeLightGrayish};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
`

export const Container = styled.span`
  display: flex;
`

export const RightControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .control {
    padding: 20px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  @media ${({ theme }) => theme.media.mobile} {
    justify-content: center;

    .control:last-of-type {
      padding: 0;
    }
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 127px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
`

export const PageContainer = styled(Spacer)`
  padding-top: 60px;
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.dimensions.pageWidth}px`};
`
