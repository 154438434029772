import styled from 'styled-components'
import { space, textAlign } from 'styled-system'
import { selectColor } from '../../../../../../components/styles'

export const Title = styled.div`
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
`

export const TitleWrapper = styled.div`
  display: flex;

  @media ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`

export const H2 = styled.h2`
  ${space};
  ${textAlign};
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  font-size: 2.6rem;
  color: ${selectColor};
`

export const TitleContent = styled.div``

export const Padding = styled.div`
  width: 100%;
  height: 30px;
`
