import styled from 'styled-components/macro'
import { space, height, maxHeight, minHeight, overflowY } from 'styled-system'

export * from './Padding'
export * from './Margin'
export * from './Text'
export * from './Grid'
export * from './ItemList'

export const Module = styled.div`
  ${space};
  ${height};
  ${maxHeight};
  ${minHeight};
  ${overflowY};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.22);
  @media ${({ theme }) => theme.media.mobile} {
    border-radius: 0;
  }
`

export const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayVeryLight};
  width: 100%;
`

export const Reveal = styled.span`
  transition: all 0.5s;
  opacity: ${({ ready }) => (ready ? 1 : 0)};
`
