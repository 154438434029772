import styled from 'styled-components/macro'

export const Image = styled.img`
  cursor: pointer;
  padding-right: 15px;
  padding-left: 80px;

  height: ${type => {
    if (type === 'success') return '9px'
    return '10px'
  }};
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme, type }) => {
    if (type === 'success') return theme.colors.greenLime
    if (type === 'error') return theme.colors.rejectRed
    return theme.colors.grayDark
  }};
  padding-right: 80px;
`

export const ToastContainer = styled.div`
  min-height: 60px;
  background: ${({ theme, type }) => {
    if (type === 'success') return theme.colors.greenVeryLight
    if (type === 'error') return theme.colors.redLightGrayish
    return theme.colors.white
  }};
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  visibility: visible;
  max-width: 600px;
  width: max-content;

  &.styled_toast {
    padding: 0px !important;
    margin: 0px !important;
    width: max-content !important;
    min-height: 60px !important;
    border-radius: 4px !important;
  }

  .toast_styled_container {
    top: 80px !important;
    right: 140px !important;
    padding: 0 !important;
    margin: 0 !important;
    width: max-content !important;
  }

  .toast_styled_body {
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--default-font-family);
    margin: 0 !important;
    display: grid;
    align-items: center;
  }
`
