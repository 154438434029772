import React, { Component } from 'react'
import { shape, func, instanceOf, number } from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { EmployeeListType } from '../../../../common/prop-types'
import notify from '../../../../common/services/notification'
import { Spacer } from '../../../../components/styles/Spacer'
import { Text } from '../../../../components/styles'
import LoadingOverlay from '../../../../components/LoadingOverlay'

class EmployeeListModule extends Component {
  state = {
    sortBy: {
      field: '',
      order: '',
    },
    searchValue: '',
  }

  handleSortOrder = ({ field, order }) => {
    const { refetch } = this.props

    const variables = {
      ...this.props.variables,
      orders: {
        orderBy: field,
        order,
      },
    }

    this.setState(
      {
        sortBy: { field, order },
      },
      async () => {
        try {
          await refetch(variables)
        } catch (error) {
          notify('error', 'Notification.Action.Failed')
        }
      }
    )
  }

  handleSearchChange = e => {
    const { refetch } = this.props

    const variables = {
      ...this.props.variables,
      filter: { ...this.props.variables.filter, search: e.target.value },
    }

    this.setState(
      {
        searchValue: e.target.value,
      },
      async () => {
        try {
          await refetch(variables)
        } catch (error) {
          notify('error', 'Notification.Action.Failed')
        }
      }
    )
  }

  render() {
    const {
      data: { employees = [] },
      networkStatus,
      renderEmployees,
    } = this.props

    const { sortBy, searchValue } = this.state

    const showEmtyListIndicator = networkStatus === 7 && !employees.length

    return (
      <Spacer>
        {renderEmployees({
          employees,
          sortBy,
          searchValue,
          onSearchChange: this.handleSearchChange,
          onSortChange: this.handleSortOrder,
        })}

        {showEmtyListIndicator && (
          <Spacer mt={40}>
            <Text fontSize={16} color="gray" align="left">
              <FormattedMessage id="Company.EmployeeList.Empty" />
            </Text>
          </Spacer>
        )}
        <LoadingOverlay networkStatus={networkStatus} />
      </Spacer>
    )
  }
}

EmployeeListModule.propTypes = {
  data: shape({
    employees: EmployeeListType,
  }).isRequired,
  refetch: func,
  networkStatus: number.isRequired,
  renderEmployees: func.isRequired,
  variables: instanceOf(Object),
}

export default EmployeeListModule
