import { connect } from 'react-redux'

import RequestEdit from './RequestEdit'
import { openHolidayModal } from '../../../redux/holiday-modal/holiday-modal.reducer'

const mapDispatchToProps = {
  openHolidayModal,
}

export default connect(null, mapDispatchToProps)(RequestEdit)
