import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  TutorialSteps,
  Step,
  Content,
  TutorialContent,
  TutorialTextContent,
  Image,
  ModalCloseIcon,
} from './styles'
import InlineLink from '../../../../components/InlineLink'
import { Elevated } from '../../../../elements'
import { H2, Text } from '../../../../components/styles'
import { selectIsTutorialShown } from '../../../../redux/auth/auth.selector'
import { setShowTutorial as setShowTutorialAction } from '../../../../redux/auth/auth.reducer'
import { openAddUserModal } from '../../../../redux/holiday-modal/holiday-modal.reducer'
import { InviteLink } from '../InviteBanner/styles'

export default function Tutorial() {
  const showTutorial = useSelector(selectIsTutorialShown)
  const dispatch = useDispatch()
  const history = useHistory()

  function setShowTutorial(nextShowTutorial) {
    dispatch(setShowTutorialAction(nextShowTutorial))
  }

  const steps = [
    {
      key: 'Tutorial.Action.1',
      component: (
        <InviteLink
          type="button"
          onClick={() => {
            history.push('/company')
            dispatch(openAddUserModal())
          }}
        >
          <FormattedMessage id="Tutorial.Action.1" />
        </InviteLink>
      ),
    },
    {
      key: 'Tutorial.Action.2',
      component: <FormattedMessage id="Tutorial.Action.2" />,
    },
    {
      key: 'Tutorial.Action.3',
      component: <FormattedMessage id="Tutorial.Action.3" />,
    },
  ]

  return (
    showTutorial && (
      <Elevated mb="medium">
        <Content>
          <TutorialContent>
            <ModalCloseIcon
              id="close-modal-icon"
              src="/img/closeIcon.svg"
              alt="close"
              onClick={() => {
                setShowTutorial(false)
              }}
            />
            <Image display={['none', 'block']} src="assets/img/tutorial.svg" />
            <TutorialTextContent>
              <H2>
                <FormattedMessage id="Tutorial.Title" />
              </H2>
              <TutorialSteps mx={30} my={20}>
                {steps.map(step => (
                  <Step key={step.key}>{step.component}</Step>
                ))}
              </TutorialSteps>
              <Text>
                <FormattedMessage
                  id="Tutorial.Message"
                  values={{
                    email: (
                      <InviteLink href="mailto:support@freebees.io">
                        support@freebees.io
                      </InviteLink>
                    ),
                    faq: (
                      <InlineLink
                        labelTKey="Tutorial.Message.FAQ"
                        path="/faq"
                      />
                    ),
                  }}
                />
              </Text>
            </TutorialTextContent>
          </TutorialContent>
        </Content>
      </Elevated>
    )
  )
}

Tutorial.propTypes = {}
