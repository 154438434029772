import styled from 'styled-components/macro'

export const Locale = styled.div`
  display: inline-block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 32px;
  letter-spacing: -0.25px;
  text-transform: uppercase;
  color: #0b0b0b;
`
