import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  width: 65px;

  @media ${({ theme }) => theme.media.mobile} {
    width: 55px;
  }
`

export const OptionContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.5s;
  pointer-events: ${({ visible }) => (visible ? 'inherit' : 'none')};
  z-index: ${({ theme }) => theme.zIndex.modal};

  button {
    margin: 7px 0;
    padding: 5px;
  }
`
