function compileHolidayList(companyHolidays, nationalHolidays) {
  const uniqueNationalHolidays = filterCompanyHolidaysFromNationalHolidays(
    nationalHolidays,
    companyHolidays
  )

  const holidays = uniqueNationalHolidays
    .map(mapNationalHolidayToHoliday)
    .concat(companyHolidays)
    .filter(isCurrentOrFutureYear)

  return holidays
}

function filterCompanyHolidaysFromNationalHolidays(
  nationalHolidays,
  companyHolidays
) {
  const allCompanyHolidayDates = companyHolidays.map(holiday => holiday.date)

  return nationalHolidays.filter(
    holiday => !isInCompanyHolidays(holiday.date, allCompanyHolidayDates)
  )
}

function isInCompanyHolidays(date, allCompanyHolidayDates) {
  return allCompanyHolidayDates.includes(date)
}

function mapNationalHolidayToHoliday(holiday) {
  return {
    id: holiday.id,
    date: holiday.date,
    description: holiday.name,
    deletable: holiday.deletable || false,
  }
}

function isCurrentOrFutureYear(holiday) {
  return currentYear <= new Date(holiday.date).getFullYear()
}

const currentYear = new Date().getFullYear()

export default compileHolidayList
