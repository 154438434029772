import React from 'react'
import { string, func, shape, bool, instanceOf } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import CompanyNameForm from './CompanyNameForm'
import UserRegistrationForm from './UserRegistrationForm'
import { BackIcon } from './styles'
import { Text } from '../styles/Text'
import EmailVerificationForm from './EmailVerificationForm'
import {
  CompanyRegistrationType,
  UserRegistrationFormType,
  VerificationFormType,
} from '../../common/prop-types'
import { ButtonText } from '../styles/Button'
import { Spacer } from '../styles/Spacer'
import { Elevated } from '../../elements'

const CompanyRegistrationView = ({
  companyFormData,
  currentStep,
  onBackPress,
  onCompanyDataSubmit,
  onCompanyDataChange,
  onUserRegistrationChange,
  onUserRegistrationSubmit,
  onVerificationCodeChange,
  onVerificationCodeSubmit,
  steps,
  userFormData,
  verificationFormData,
  pending,
}) => {
  const companyNameProps = {
    formData: companyFormData,
    onInputChange: onCompanyDataChange,
    onSubmit: onCompanyDataSubmit,
  }

  const userRegProps = {
    formData: userFormData,
    onInputChange: onUserRegistrationChange,
    onSubmit: onUserRegistrationSubmit,
    pending,
  }

  const emailVerificationProps = {
    formData: verificationFormData,
    onInputChange: onVerificationCodeChange,
    onSubmit: onVerificationCodeSubmit,
  }
  return (
    <Elevated p={20} mx="auto" width={['100%', 620]}>
      <Spacer height={22} mb={28} alignItems="center" display="flex">
        {currentStep.showBack && (
          <ButtonText
            id={`${currentStep.name}-back-button`}
            onClick={() => onBackPress(currentStep)}
            disabled={pending}
          >
            <BackIcon src="/assets/img/step-left.svg" alt="back" />
            <Text semiBold>
              <FormattedMessage id="ComanyReg.Button.Back" />
            </Text>
          </ButtonText>
        )}
      </Spacer>
      <>
        {currentStep.name === steps.companyName.name && (
          <CompanyNameForm {...companyNameProps} />
        )}

        {currentStep.name === steps.userReg.name && (
          <UserRegistrationForm {...userRegProps} />
        )}

        {currentStep.name === steps.emailVerification.name && (
          <EmailVerificationForm {...emailVerificationProps} />
        )}
      </>
    </Elevated>
  )
}

CompanyRegistrationView.propTypes = {
  currentStep: shape({ name: string.isRequired, showBack: bool }).isRequired,
  steps: instanceOf(Object),
  companyFormData: CompanyRegistrationType.isRequired,
  verificationFormData: VerificationFormType.isRequired,
  userFormData: UserRegistrationFormType.isRequired,
  onBackPress: func.isRequired,
  onCompanyDataSubmit: func.isRequired,
  onCompanyDataChange: func.isRequired,
  onUserRegistrationSubmit: func.isRequired,
  onUserRegistrationChange: func.isRequired,
  onVerificationCodeSubmit: func.isRequired,
  onVerificationCodeChange: func.isRequired,
  pending: bool.isRequired,
}
export default CompanyRegistrationView
