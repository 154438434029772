import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { TextLink, TextV as Text } from '../styles/Text'
import { Spacer } from '../styles/Spacer'
import { InviteLink } from '../../modules/dashboard/components/InviteBanner/styles'

const Footer = () => (
  <Spacer boxShadow="footer" background="white">
    <Spacer
      minHeight={[150]}
      display="flex"
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      alignItems={['center']}
      maxWidth={1000}
      mx="auto"
      p={20}
    >
      <Spacer
        display="flex"
        flexDirection={['column', 'row']}
        alignItems={['center']}
      >
        <Spacer
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems={['center', 'flex-start']}
          mr={[0, 100]}
          my={[10, 0]}
          height={80}
        >
          <Text variant="small">
            <FormattedMessage id="Faq.Footer.CreatedBy" />
          </Text>
          <a
            href="https://www.digitalnatives.hu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Spacer>
              <img
                src="/assets/img/DiNaLogo.svg"
                alt="Digital Natives Logo"
                style={{ height: '25px' }}
              />
            </Spacer>
          </a>
        </Spacer>

        <Spacer
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          textAlign={['center', 'left']}
          my={[10, 0]}
          height={80}
        >
          <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
            <TextLink fontSize={[8, 12]}>
              <FormattedMessage id="Footer.Links.Privacy" />
            </TextLink>
          </Link>
          <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }}>
            <TextLink fontSize={[8, 12]}>
              <FormattedMessage id="Footer.Links.Terms" />
            </TextLink>
          </Link>
        </Spacer>
      </Spacer>

      <Spacer
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        textAlign={['center', 'end']}
        my={[10, 0]}
        height={80}
      >
        <Text variant="description">
          <FormattedMessage id="Faq.Footer.DoYouHaveQuestion" />
        </Text>
        <InviteLink href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#102;&#114;&#101;&#101;&#098;&#101;&#101;&#115;&#046;&#105;&#111;">
          support@freebees.io
        </InviteLink>
      </Spacer>
    </Spacer>
  </Spacer>
)

export default Footer
