import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { createStructuredSelector } from 'reselect'

import CREATE_HOLIDAY_REQUEST from '../../../graphql/mutations/createHolidayRequest'
import UPDATE_HOLIDAY_REQUEST from '../../../graphql/mutations/updateHolidayRequest'
import CREATE_COMPANY_HOLIDAY from '../../../graphql/mutations/createCompanyHoliday'
import {
  selectHolidayModalData,
  selectIsHolidayModalOpen,
} from '../../../redux/holiday-modal/holiday-modal.selector'
import { selectActiveEmployees } from '../../../redux/company/company.selector'
import { closeHolidayModal } from '../../../redux/holiday-modal/holiday-modal.reducer'
import Wrapper from './Wrapper'
import { selectCurrentUserData } from '../../../redux/auth/auth.selector'
import { selectActiveLanguage } from '../../../redux/language-selector/language-selector.selector'

const mapStateToProps = createStructuredSelector({
  modalData: selectHolidayModalData,
  activeEmployees: selectActiveEmployees,
  isHolidayModalOpen: selectIsHolidayModalOpen,
  currentUserData: selectCurrentUserData,
  activeLanguage: selectActiveLanguage,
})

const mapDispatchToProps = {
  closeHolidayModal,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(CREATE_HOLIDAY_REQUEST, { name: 'CreateHoliday' }),
  graphql(CREATE_COMPANY_HOLIDAY, { name: 'CreateCompanyHoliday' }),
  graphql(UPDATE_HOLIDAY_REQUEST, { name: 'UpdateHoliday' })
)(Wrapper)
