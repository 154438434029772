import styled from 'styled-components/macro'
import { Text } from '../styles/Text'

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  cursor: pointer;
  width: 10px;
  height: 10px;
  display: ${({ active }) => (active ? 'none' : 'block')};
  position: relative;
`

export const SortIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  height: 20px;
  padding-top: ${({ active }) => (active ? '3' : '0')}px;
`

export const StyledText = styled(Text)`
  font-size: 12px;
`
