import React from 'react'
import { shape, string } from 'prop-types'

import { Spacer } from '../../../../components/styles/Spacer'
import ChangePassword from '../../../../components/ChangePassword'
import { usePageTracking } from '../../../../hooks/usePageTracking'

const ChangePasswordPage = ({ match }) => {
  const {
    params: { code, email },
  } = match

  usePageTracking('ChangePassword')

  return (
    <Spacer mt={100}>
      <ChangePassword code={code} email={email} />
    </Spacer>
  )
}

ChangePasswordPage.propTypes = {
  match: shape({ params: shape({ code: string, email: string }) }).isRequired,
}

export default ChangePasswordPage
