import styled from 'styled-components/macro'
import { StyledInput } from '../../components/Input/styles'
import { dimensions } from '../../theme/freebees'

export const InputGroup = styled.div`
  width: ${({ narrow = false, block = false }) => {
    if (narrow) return '173px'
    if (block) return '100%'
    return '212px'
  }};

  margin: 0 7.5px;
  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    &:last-of-type,
    &:first-of-type {
      margin: 0;
    }
  }
`

export const Select = styled.select`
  background: #ffffff;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  padding: 15px;
  &:focus {
    outline-width: 0;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const Option = styled.option``

export const VerificationInput = styled(StyledInput)`
  height: 52px;
  width: 200px;
  margin: 0 10px 20px;
  text-align: center;
`
