import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMinutes,
  isToday,
} from 'date-fns'
import { filterNotWorkingDays } from '../services/workdays'

export const getDaysOfSelectedMonth = date => {
  const start = startOfMonth(date)
  const end = endOfMonth(date)
  return eachDayOfInterval({ start, end })
}

export const fixTimezoneOffset = date =>
  addMinutes(date, -date.getTimezoneOffset())

export const fixDate = date => {
  const fixedTimezone = fixTimezoneOffset(date)
  return fixedTimezone.toISOString()
}

export const getCalendarData = (employees, datesOfSelectedMonth) =>
  employees.map(employee => ({
    employee,
    dates: datesOfSelectedMonth.map(date => {
      return {
        date,
        isItToday: isToday(date),
        cellId: `c${employee.id}-${date.getTime()}`,
        isWorkingDay: filterNotWorkingDays(date),
      }
    }),
  }))
