import { createActions, handleActions } from 'redux-actions'

const defaultState = {
  data: null,
  isAuthenticated: false,
  isRegistrationInProgress: false,
  isTutorialShown: false,
}

export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_REGISTRATION_IN_PROGRESS = 'SET_REGISTRATION_IN_PROGRESS'
export const SET_SHOW_TUTORIAL = 'SET_SHOW_TUTORIAL'

export const {
  setRegistrationInProgress,
  setAuthenticated,
  setUserData,
  setShowTutorial,
} = createActions(
  {},
  SET_USER_DATA,
  SET_AUTHENTICATED,
  SET_REGISTRATION_IN_PROGRESS,
  SET_SHOW_TUTORIAL
)

export default handleActions(
  {
    [SET_AUTHENTICATED]: (state, { payload }) => ({
      ...state,
      isAuthenticated: payload,
    }),
    [SET_USER_DATA]: (state, { payload }) => ({
      ...state,
      data: payload,
      isTutorialShown: payload.role.name === 'ADMIN',
    }),
    [SET_REGISTRATION_IN_PROGRESS]: (state, { payload }) => ({
      ...state,
      isRegistrationInProgress: payload,
    }),
    [SET_SHOW_TUTORIAL]: (state, { payload }) => ({
      ...state,
      isTutorialShown: payload,
    }),
  },
  defaultState
)
