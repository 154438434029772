import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'

import * as Sentry from '@sentry/browser'

import { AuthService } from '../common/services/auth'
import { isFeatureEnabled, features } from '../common/constants/feature-flags'

const uri = process.env.REACT_APP_GRAPHQL_URL

const httpLink = new HttpLink({ uri })

const authLink = setContext(async (_, { headers }) => {
  const token = await AuthService.getToken()
  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      const { message, locations, path, errorInfo } = error

      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )

      Sentry.captureException(error)

      if (
        errorInfo &&
        errorInfo.code &&
        errorInfo.code === 'Auth.EmployeeDeactivated'
      ) {
        AuthService.signOut()
      }

      if (message === 'connect ETIMEDOUT') {
        AuthService.signOut()
      }
    })
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`)
  }
})

const link = ApolloLink.from([errorLink, authLink, httpLink])
const cache = new InMemoryCache()

let persistor = null

if (isFeatureEnabled(features.persistApolloCache)) {
  persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  })
}

const client = new ApolloClient({
  link,
  cache,
})

export const apolloPersistor = persistor

export default client
