import { injectIntl } from 'react-intl'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'

import EmployeeStatusEditor from './EmployeeStatusEditor'
import ACTIVATE_EMPLOYEE from '../../../graphql/mutations/activateEmployee'
import DEACTIVATE_EMPLOYEE from '../../../graphql/mutations/deactivateEmployee'

export default compose(
  injectIntl,
  graphql(ACTIVATE_EMPLOYEE, { name: 'ActivateEmployee' }),
  graphql(DEACTIVATE_EMPLOYEE, { name: 'DeactivateEmployee' })
)(EmployeeStatusEditor)
