import React from 'react'
import PropTypes from 'prop-types'

import { HolidayRequestType } from '../../../common/prop-types'
import { ButtonIcon } from '../../styles/Button'

const RequestEdit = ({ holidayRequest, openHolidayModal }) => {
  const handleEditRequest = () => {
    let requestType = holidayRequest.type
    if (requestType === 'VACATION' && holidayRequest.status === 'PLANNED') {
      requestType = 'PLANNED'
    }
    openHolidayModal({
      requestType,
      holidayRequest,
    })
  }

  return (
    <ButtonIcon onClick={handleEditRequest} narrow>
      <img src="/assets/icons/edit.svg" alt="edit" />
    </ButtonIcon>
  )
}

RequestEdit.propTypes = {
  holidayRequest: HolidayRequestType.isRequired,
  openHolidayModal: PropTypes.func.isRequired,
}

export default RequestEdit
