import { createGlobalStyle } from 'styled-components/macro'
import reset from 'styled-reset'
import 'react-perfect-scrollbar/dist/css/styles.css'

export const GlobalStyle = createGlobalStyle`
  ${reset}

  /* Fonts downloaded from https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin,latin-ext */

  @font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/open-sans-v28-latin-ext_latin-regular.ttf') format('woff2'),
      url('/assets/fonts/open-sans-v28-latin-ext_latin-regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Open Sans Bold';
    src: url('/assets/fonts/open-sans-v28-latin-ext_latin-700.ttf') format('woff2'),
      url('/assets/fonts/open-sans-v28-latin-ext_latin-700.woff') format('woff');
  }

  @font-face {
    font-family: 'Open Sans SemiBold';
    src: url('/assets/fonts/open-sans-v28-latin-ext_latin-600.ttf') format('woff2'),
      url('/assets/fonts/open-sans-v28-latin-ext_latin-600.woff') format('woff');
  }

  @font-face {
    font-family: 'Open Sans ExtraBold';
    src: url('/assets/fonts/open-sans-v28-latin-ext_latin-800.ttf') format('woff2'),
      url('/assets/fonts/open-sans-v28-latin-ext_latin-800.woff') format('woff');
  }

  html {
    font-family: ${({ theme }) => theme.fontFamily.default} !important;
    box-sizing: border-box;
    font-size: 62.5%;
    @media ${({ theme }) => theme.media.mobile} {
      font-size: 50%;
    }
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    font-size: 16px;
    padding: 0;
    margin: 0;
  }

  .ReactModal__Content,
  .ReactModal__Content--after-open {
    overflow: visible;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    z-index: 2000;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: visible;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
`
