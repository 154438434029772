import styled from 'styled-components/macro'
import { height } from 'styled-system'
import { colors } from '../../../../theme/freebees'

const getCellBackgroundColor = ({
  theme,
  isWorkingDay,
  isItToday,
  isHoliday,
  type,
}) => {
  if (type === 'employee') {
    return theme.colors.white
  }
  if (isHoliday && isItToday) {
    return colors.calendarHolidayTodayBackgroundColor
  }
  if (isHoliday) {
    return colors.calendarHolidayBackgroundColor
  }
  if (isWorkingDay !== undefined && !isWorkingDay) {
    return colors.calendarWeekendBackgroundColor
  }
  if (isItToday) {
    return colors.calendarTodayBackgroundColor
  }
  return theme.colors.white
}

const getDayColor = (
  { theme, isItToday, isHoliday, isWorkingDay },
  defaultColor
) => {
  if (isHoliday) {
    return theme.colors.calendarHeaderGreen
  }
  if (isItToday) {
    return theme.colors.calendarHeaderOrange
  }
  if (!isWorkingDay) {
    return theme.colors.calendarHeaderGray
  }
  return defaultColor
}

const getBorderColor = ({
  theme,
  isWorkingDay,
  isItToday,
  isPreviousDayWorkingDay,
}) => {
  if (isWorkingDay && !isPreviousDayWorkingDay && !isItToday) {
    return theme.colors.calendarBorderColorDark
  }
  return theme.colors.calendarBorderColor
}

export const CalendarCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  height: ${({ type }) => {
    if (type === 'company') return '60px'
    if (type === 'employee') return '32px'
    return '60px'
  }};
  background-color: ${getCellBackgroundColor};
  position: relative;
`

export const CalendarContainer = styled.div`
  display: grid;
  flex-direction: column;
  grid-template-rows: 40px 1fr;
  width: 100%;
  user-select: none;
  overflow-y: hidden;
`

export const CalendarBody = styled.div`
  display: grid;
  grid-template-columns: 242px repeat(${({ columns }) => columns}, 1fr);
  grid-template-rows: auto;
  align-items: flex-start;
`

export const CalendarHeader = styled(CalendarBody)`
  overflow: hidden;
`

const CellBase = styled.div.attrs(props => ({
  style: {
    gridColumnStart: `${props.column}`,
    gridRowStart: `${props.row}`,
  },
}))`
  border-left: 1px solid ${getBorderColor};
  border-bottom: 1px solid ${({ theme }) => theme.colors.calendarBorderColor};
  grid-column-end: span 1;
  grid-row-end: span 1;
  height: 60px;
  min-width: 30px;
  ${height};
`

export const Cell = styled(CellBase)`
  background-color: ${getCellBackgroundColor};
  position: relative;
  pointer-events: ${({ canCreateHoliday }) =>
    canCreateHoliday ? 'initial' : 'none'};
  display: flex;
  align-items: center;

  cursor: ${({ canCreateHoliday }) =>
    canCreateHoliday ? 'pointer' : 'initial'};

  &:hover {
    .inner {
      display: ${({ canCreateHoliday }) =>
        canCreateHoliday ? 'initial' : 'none'};
    }
  }

  .inner {
    display: ${({ showIndicator }) => (showIndicator ? 'block' : 'none')};
    height: 20px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.calendarRequestedColor};
    border-radius: 4px;
    opacity: 0.75;
  }
`

export const EmployeeCell = styled(CellBase)`
  padding-left: 20px;
  border-left: none;
`

export const HeaderCell = styled(CalendarCell)`
  font-size: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .day-number {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: ${props => getDayColor(props, colors.grayVeryDark)};
    font-family: ${({ isItToday }) => isItToday && 'Open Sans ExtraBold'};
  }
`

export const DayName = styled.div`
  text-transform: uppercase;
  font-size: 8px;
  line-height: 14px;
  letter-spacing: 1px;
  color: ${props => getDayColor(props, colors.grayDark)};
  font-family: ${({ isItToday }) => isItToday && 'Open Sans Bold'};
`
