import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HolidayRequestType } from '../../common/prop-types'
import { ModalContainer, ModalHeader } from '../../elements'
import { useClickOutside } from '../../hooks/useClickOutside'
import { localeFormat } from '../DateField/DateField'
import ProfileImage from '../ProfileImage'
import RequestStatusControl from '../RequestStatusControl'
import { Spacer } from '../styles/Spacer'
import { H2, Text } from '../styles/Text'
import { Actions, Info, ModalBody, ModalCloseIcon } from './styles'

const getTitle = hr => {
  if (hr.type === 'SICK_LEAVE') {
    if (hr.status === 'REQUESTED')
      return 'Calendar.HolidayPopup.Title.SickLeave.Requested'
    if (hr.status === 'APPROVED')
      return 'Calendar.HolidayPopup.Title.SickLeave.Approved'
  }

  if (hr.type === 'VACATION') {
    if (hr.status === 'PLANNED')
      return 'Calendar.HolidayPopup.Title.Vacation.Planned'
    if (hr.status === 'REQUESTED')
      return 'Calendar.HolidayPopup.Title.Vacation.Requested'
    if (hr.status === 'APPROVED')
      return 'Calendar.HolidayPopup.Title.Vacation.Approved'
    if (hr.status === 'REJECTED')
      return 'Calendar.HolidayPopup.Title.Vacation.Rejected'
  }
  return 'Calendar.HolidayPopup.Title.Request'
}

export default function HolidayDetailsModal({
  onClose,
  holidayRequest,
  locale,
}) {
  const ref = useClickOutside(() => onClose())

  const dateInfo = `${localeFormat(
    holidayRequest.startDate,
    locale.code
  )} - ${localeFormat(holidayRequest.endDate, locale.code)}`
  const title = getTitle(holidayRequest)

  return (
    <ModalContainer shadow px={30} pt={30} pb={20} ref={ref}>
      <ModalCloseIcon
        id="close-modal"
        src="/img/closeIcon.svg"
        alt="close"
        onClick={onClose}
      />
      <div>
        <ModalHeader>
          <H2 textAlign="left">
            <FormattedMessage id={title} />
          </H2>
        </ModalHeader>

        <Spacer pb={20}>
          <ModalBody>
            <ProfileImage src={holidayRequest.employee.avatarURL} />
            <Spacer ml={14}>
              <Info>
                <Spacer mb={20}>
                  <Text fontSize={16}>
                    {`${holidayRequest.employee.lastName} ${holidayRequest.employee.firstName}`}
                  </Text>
                </Spacer>
                <Text>{dateInfo}</Text>
                {holidayRequest.description && (
                  <div>
                    <Text color="grayLight">{holidayRequest.description}</Text>
                  </div>
                )}
              </Info>
            </Spacer>
          </ModalBody>
        </Spacer>

        <Actions>
          <RequestStatusControl
            holidayRequest={holidayRequest}
            showApprove
            showDelete
            showEdit
            showReject
            showTransform
            iconsFirst
          />
        </Actions>
      </div>
    </ModalContainer>
  )
}

HolidayDetailsModal.propTypes = {
  onClose: PropTypes.func,
  holidayRequest: HolidayRequestType.isRequired,
  locale: PropTypes.shape({ code: PropTypes.string }),
}
