import styled from 'styled-components/macro'
import { Spacer } from '../../../../components/styles/Spacer'

export const StyledSpacer = styled(Spacer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoadingImage = styled.img`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  animation: spin 1s linear infinite;
`

export const DownloadLink = styled.a`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.orangeLight};
`
