import { getYear } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { EmployeeType } from '../../../../common/prop-types'
import { calculateHolidayCountersForYear } from '../../../../common/utils/calculations'
import { selectSelectedDate } from '../../../../redux/profile/profile.selector'
import {
  Container,
  CounterContainer,
  CounterNumber,
  CounterTitle,
} from './styles'

export default function EmployeeTimeOffHeader({ employee }) {
  const selectedDate = useSelector(selectSelectedDate)

  const counters = calculateHolidayCountersForYear(
    employee,
    getYear(selectedDate)
  )

  return (
    <Container>
      <Counter
        number={counters.availableHolidays}
        messageId="EmployeeProfile.Header.HolidaysAvailble"
      />
      <Counter
        number={counters.plannedHolidays}
        messageId="EmployeeProfile.Header.HolidaysPlanned"
      />
      <Counter
        number={counters.allowedHolidays}
        messageId="EmployeeProfile.Header.HolidaysAllowance"
      />
      <Counter
        number={counters.sickLeaves}
        messageId="EmployeeProfile.Header.SickLeavesTaken"
      />
    </Container>
  )
}

EmployeeTimeOffHeader.propTypes = {
  employee: EmployeeType.isRequired,
}

function Counter({ number, messageId }) {
  return (
    <CounterContainer>
      <CounterNumber>{number}</CounterNumber>
      <CounterTitle>
        <FormattedMessage id={messageId} />
      </CounterTitle>
    </CounterContainer>
  )
}

Counter.propTypes = {
  number: PropTypes.number.isRequired,
  messageId: PropTypes.string.isRequired,
}
