import styled from 'styled-components/macro'

export const VerticalInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RemoveButtonContainer = styled.div`
  text-align: right;
`

export const RemoveChildIcon = styled.img`
  position: relative;
  top: 3px;
  right: 8px;
`
