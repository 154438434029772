import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EmployeeTeamMembershipView from './EmployeeTeamMembershipView'
import notify from '../../../../../../common/services/notification'
import { refetchQueries } from '../../../../../../common/constants/refetchQueries'
import {
  GqlDataType,
  EmployeeType,
  HistoryType,
} from '../../../../../../common/prop-types'

class EmployeeTeamMembership extends Component {
  state = {
    isTeamModalOpen: false,
    selectedTeams: this.props.employee.teams,
  }

  assignTeams = async ({ ids, employeeId }) => {
    try {
      await this.props.assignTeams({
        variables: { ids, employeeId },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  handleTeamSelectSubmit = async (employee, newTeams) => {
    this.setState(prevValue => ({ ...prevValue, isTeamModalOpen: false }))

    const teamIds = newTeams.map(team => team.id)
    const input = { ids: teamIds, employeeId: employee.id }
    const { success } = await this.assignTeams(input)

    if (success) {
      this.setState({ selectedTeams: newTeams })
      notify('success', 'Notification.Employee.TeamMembership.Update.Success')
    } else {
      notify('error', 'Notification.Employee.TeamMembership.Update.Failed')
    }
  }

  handleTeamSelectDeselection = (employee, deselectedTeam) => {
    const remainingTeams = employee.teams.filter(
      team => team.id !== deselectedTeam.id
    )

    this.handleTeamSelectSubmit(employee, remainingTeams)
  }

  handleGoToProfile = () => {
    this.props.history.push('/company')
  }

  render() {
    const {
      CurrentCompany: { currentCompany = {} },
      employee,
      isAdmin,
    } = this.props
    const { isTeamModalOpen, selectedTeams } = this.state

    return (
      <EmployeeTeamMembershipView
        isAdmin={isAdmin}
        isTeamSelectOpen={isTeamModalOpen}
        onTeamSelectModalClose={() =>
          this.setState(prevValue => ({ ...prevValue, isTeamModalOpen: false }))
        }
        openTeamSelectModal={() =>
          this.setState(prevValue => ({ ...prevValue, isTeamModalOpen: true }))
        }
        onGoToProfile={this.handleGoToProfile}
        onTeamSelectModalSubmit={newTeams =>
          this.handleTeamSelectSubmit(employee, newTeams)
        }
        onTeamDeselected={deselectedTeam =>
          this.handleTeamSelectDeselection(employee, deselectedTeam)
        }
        availableTeams={currentCompany.teams}
        selectedTeams={selectedTeams}
      />
    )
  }
}

EmployeeTeamMembership.propTypes = {
  CurrentCompany: GqlDataType.isRequired,
  employee: EmployeeType.isRequired,
  assignTeams: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  history: HistoryType.isRequired,
}

export default EmployeeTeamMembership
