import React, { Component } from 'react'
import { func, bool } from 'prop-types'
import DropdownList from '../DropdownList'
import DropDownRedirectLink from '../DropdownList/DropDownRedirectLink'
import DropDownCallbackLink from '../DropdownList/DropDownCallbackLink'
import { AuthService } from '../../common/services/auth'
import notify from '../../common/services/notification/notification'
import { trackLogout } from '../../common/services/analytics'
import { Separator } from '../styles'

class UserDropdownList extends Component {
  signOut = async () => {
    try {
      await AuthService.signOut()
      trackLogout()
      notify('success', 'Notification.LogOut.Successful')
    } catch (error) {
      notify('error', error.message)
    }
  }

  setShowTutorial = () => {
    this.props.setShowTutorial(true)
  }

  ItemRenderer = () => [
    <DropDownRedirectLink
      key="item-0"
      to="/me/info"
      title="UserDropDown.MyProfile"
    />,
    <DropDownRedirectLink
      key="item-faq"
      to="/faq"
      title="UserNavigationBar.Item.Faq"
    />,
    <DropDownRedirectLink
      key="item-terms"
      to="/terms-and-conditions"
      title="UserNavigationBar.Item.Terms"
    />,
    <DropDownRedirectLink
      key="item-privacy"
      to="/privacy-policy"
      title="UserNavigationBar.Item.Privacy"
    />,
    <DropDownCallbackLink
      show={!this.props.isTutorialShown && this.props.isAdmin}
      key="open-tutorial"
      title="UserNavigationBar.Item.OpenTutorial"
      callback={this.setShowTutorial}
    />,
    <Separator key="separator" />,
    <DropDownCallbackLink
      key="item-1"
      title="UserDropDown.SignOut"
      callback={this.signOut}
    />,
  ]

  render() {
    return <DropdownList ItemRenderer={this.ItemRenderer} />
  }
}

UserDropdownList.propTypes = {
  isTutorialShown: bool.isRequired,
  isAdmin: bool.isRequired,
  setShowTutorial: func.isRequired,
}

export default UserDropdownList
