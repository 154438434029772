import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EmployeeType } from '../../../../common/prop-types'

import { refetchQueries } from '../../../../common/constants/refetchQueries'
import notify from '../../../../common/services/notification'
import EditableField from '../../../../components/EditableField'
import { getEmployeeUpdateRequestFromInput } from './utils'

class EmployeeEditableField extends Component {
  state = {
    isLoading: false,
  }

  handleValueChange = async value => {
    const { field } = this.props
    const variables = { [field]: value }
    this.setState({ isLoading: true })

    const { success } = await this.updateEmployee(variables)

    if (success) {
      notify('success', 'Notification.Employee.Updated')
    } else {
      notify('error', 'Notification.Updated.Failed')
    }

    this.setState({ isLoading: false })
  }

  updateEmployee = async input => {
    // eslint-disable-next-line prefer-destructuring
    const id = this.props.employee.id

    const request = getEmployeeUpdateRequestFromInput(
      input,
      this.props.type,
      this.props.field
    )

    try {
      await this.props.UpdateEmployee({
        variables: { input: { ...request, id } },
        refetchQueries,
      })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  render() {
    const { employee, field, ...otherProps } = this.props
    const { isLoading } = this.state
    if (!employee) {
      return null
    }

    const value = this.props.value || employee[field]

    return (
      <EditableField
        id="employee-editable-text-field"
        isLoading={isLoading}
        value={value}
        onChange={v => this.handleValueChange(v)}
        {...otherProps}
        isNegativeAllowed={false}
      />
    )
  }
}

EmployeeEditableField.propTypes = {
  UpdateEmployee: PropTypes.func.isRequired,
  employee: EmployeeType.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number,
  isNegativeAllowed: PropTypes.bool,
}

export default EmployeeEditableField
