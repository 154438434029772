import React from 'react'
import PropTypes from 'prop-types'

import { IntlType } from '../../common/prop-types'
import { FreeBeesTooltip } from './styles'

const Tooltip = ({ intl, translate, children, content, ...props }) => (
  <FreeBeesTooltip
    className="freebees-tooltip"
    {...props}
    content={translate ? intl.formatMessage({ id: content }) : content}
  >
    <div className="inner">{children}</div>
  </FreeBeesTooltip>
)

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
  intl: IntlType.isRequired,
  translate: PropTypes.bool,
}

export default Tooltip
