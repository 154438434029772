import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Container } from './styles'
import { DropdownIndicator } from './DropdownIndicator'
import { colors } from '../../theme/freebees'

const roleSelector = {
  control: (base, state) =>
    state.selectProps.withBorder
      ? {
          ...base,
          lineHeight: 2,
          '&:hover': { borderColor: colors.orangeLight },
          width: 'inherit',
          height: 52,
          paddingRight: 21,
          paddingLeft: 12,
          margin: 0,
          outline: 'none',
          boxShadow: 'none',
          borderColor: colors.orangeLight,
          borderRadius: 4,
          border:
            state.isFocused || state.menuIsOpen
              ? `1px solid ${colors.orangeLight}`
              : `1px solid ${colors.inputBorderGray}`,
        }
      : {
          ...base,
          lineHeight: 2,
          '&:hover': { borderColor: 'none' },
          width: 'inherit',
          height: 52,
          paddingRight: 21,
          paddingLeft: 12,
          margin: 0,
          outline: 'none',
          boxShadow: 'none',
          borderColor: 'none',
          borderRadius: 4,
          border: 'none',
        },
  dropdownIndicator: (base, state) => ({
    ...base,
    display: state.isDisabled ? 'none' : 'initial',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  option: () => ({
    padding: '10px 10px 10px 20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    color: '#1f1f1f',
    margin: 0,
    border: 'none',
    '&:hover': {
      textDecoration: 'none',
      background: '#eeeeee',
    },
  }),
  menuList: () => ({
    maxHeight: 310,
    overflowY: 'visible',
    paddingBottom: 0,
    paddingTop: 0,
    position: 'relative',
    boxSizing: 'border-box',
    padding: 0,
    width: '100%',
    whiteSpace: 'nowrap',
  }),
  menu: base => ({
    ...base,
    paddingRight: 0,
    background: 'white',
    border: 'none',
    overflow: 'auto',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.14)',
    width: '100%',
  }),
  valueContainer: base => ({
    ...base,
    outline: 'none',
    height: '100%',
  }),
  singleValue: base => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
}

const userProfileStyle = {
  control: (base, state) =>
    state.selectProps.withBorder
      ? {
          ...base,
          lineHeight: 2,
          '&:hover': { borderColor: colors.orangeLight },
          height: 34,
          paddingRight: 10,
          paddingLeft: 0,
          margin: 0,
          width: 'fit-content',
          outline: 'none',
          boxShadow: 'none',
          borderColor: colors.orangeLight,
          borderRadius: 4,
          border:
            state.isFocused || state.menuIsOpen
              ? `1px solid ${colors.orangeLight}`
              : `1px solid ${colors.inputBorderGray}`,
        }
      : {
          ...base,
          lineHeight: 2,
          '&:hover': { borderColor: 'none', cursor: 'pointer' },
          width: 'fit-content',
          height: 34,
          paddingRight: 10,
          paddingLeft: 0,
          margin: 0,
          outline: 'none',
          boxShadow: 'none',
          borderColor: 'none',
          borderRadius: 4,
          border: 'none',
          backgroundColor: 'transparent',
        },
  dropdownIndicator: base => ({
    ...base,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  menuList: () => ({
    maxHeight: 310,
    overflowY: 'visible',
    paddingBottom: 0,
    paddingTop: 0,
    position: 'relative',
    boxSizing: 'border-box',
    padding: 0,
    // width: '100%',
    whiteSpace: 'nowrap',
  }),
  menu: base => ({
    ...base,
    paddingRight: 0,
    width: 'auto',
    background: 'white',
    border: 'none',
    overflow: 'auto',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.14)',
  }),
  valueContainer: base => ({
    ...base,
    outline: 'none',
    height: '100%',
    padding: 0,
    display: 'contents',
    div: {
      display: 'contents',
    },
  }),
  option: () => ({
    padding: '10px 20px 10px 20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    color: '#1f1f1f',
    margin: 0,
    border: 'none',
    '&:hover': {
      textDecoration: 'none',
      background: '#eeeeee',
      cursor: 'pointer',
    },
  }),
}

class SelectorInput extends Component {
  getStyle = () => {
    if (this.props.selectorStyle === 'userProfile') return userProfileStyle

    return roleSelector
  }

  render() {
    return (
      <Container>
        <Select
          id="selectorInput"
          components={{ DropdownIndicator }}
          filterOption={this.props.filterOptions}
          defaultValue={this.props.defaultValue}
          options={this.props.options}
          placeholder={this.props.label}
          styles={this.getStyle()}
          customArrow={this.arrowRenderer}
          isSearchable={this.props.isSearchable}
          value={this.props.value}
          onChange={this.props.onChange}
          withBorder={this.props.withBorder}
          isDisabled={this.props.disabled}
          captureMenuScroll={false}
        />
      </Container>
    )
  }
}

SelectorInput.defaultProps = {
  label: 'Select an option',
  withBorder: true,
  isSearchable: false,
}

SelectorInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  isSearchable: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  withBorder: PropTypes.bool,
  selectorStyle: PropTypes.string,
  filterOptions: PropTypes.func,
  defaultValue: PropTypes.shape({}),
}

export default SelectorInput
