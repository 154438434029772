import React, { Component } from 'react'
import { shape } from 'prop-types'
import EmployeeProfileView from './EmployeeProfileView'
import { EmployeeListType, HistoryType } from '../../../../common/prop-types'
import { Spacer } from '../../../../components/styles/Spacer'
import Loading from '../../../../components/Loading'

const tabsItems = [
  { id: 'timeoff', title: 'EmployeeProfile.TabTitle.TimeOff' },
  { id: 'info', title: 'EmployeeProfile.TabTitle.PersonalInfo' },
]

class EmployeeProfile extends Component {
  state = {
    tabs: tabsItems,
  }

  handleTabChange = tab => {
    const { history, match } = this.props
    history.push(`${match.url}/${tab.id}`)
  }

  render() {
    const { tabs } = this.state
    const {
      data: { employees },
      location,
      match,
    } = this.props

    const activeTab = location.pathname.includes('/info')
      ? tabsItems[1]
      : tabsItems[0]

    const redirectUrl = `${match.url}/timeoff/list`

    if (!employees) {
      return (
        <Spacer height="100vh">
          <Loading />
        </Spacer>
      )
    }

    return (
      <EmployeeProfileView
        match={match}
        employee={employees[0]}
        onTabChange={tab => this.handleTabChange(tab)}
        activeTab={activeTab}
        tabs={tabs}
        redirectUrl={redirectUrl}
      />
    )
  }
}
EmployeeProfile.propTypes = {
  history: HistoryType.isRequired,
  location: shape({}),
  match: shape({}),
  data: shape({
    employees: EmployeeListType,
  }).isRequired,
}
export default EmployeeProfile
