import styled from 'styled-components/macro'

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.25s;
  pointer-events: none;
`
