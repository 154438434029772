import React from 'react'
import OnHolidayModule from '../components/OnHolidayModule'

import {
  Grid,
  GridColumnWrapper,
  Module,
  Separator,
} from '../../../components/styles'
import WithQuery from '../../../components/WithQuery'
import { Spacer } from '../../../components/styles/Spacer'
import {
  QueryRequestedHolidays,
  QueryRequestedSickLeaves,
} from '../../../graphql/queries/holidayRequests'
import RequestStatusControl from '../../../components/RequestStatusControl'

import PageHeader from '../components/PageHeader'
import PendingRequestModule from '../components/PendingRequestModule'
import AddNewUsersModal from '../components/AddNewUsersModal'
import MadeItModalModal from '../components/MadeItModal'
import Tutorial from '../components/Tutorial'

import { ReactComponent as EmptyHolidayRequests } from '../assets/images/img_holiday_request.svg'
import { ReactComponent as EmptySickLeaves } from '../assets/images/img_sickleaves.svg'
import InviteBanner from '../components/InviteBanner'

const PendingRequestModuleWithQuery = WithQuery(PendingRequestModule)

const requestedHolidaysVariables = {
  filter: {
    statusesByType: [
      {
        type: 'VACATION',
        statuses: ['REQUESTED'],
      },
    ],
  },
}

const requestedSickLeavesVariables = {
  filter: {
    statusesByType: [
      {
        type: 'SICK_LEAVE',
        statuses: ['REQUESTED'],
      },
    ],
  },
}

const DashboardPageContent = () => (
  <Spacer mb={100}>
    <InviteBanner />
    <AddNewUsersModal />
    <MadeItModalModal />
    <Spacer mt={[20, 45]} mb={[20, 40]} ml={[10, 0]}>
      <PageHeader />
    </Spacer>

    <Grid>
      <GridColumnWrapper column={1}>
        <Module>
          <PendingRequestModuleWithQuery
            query={QueryRequestedHolidays}
            variables={requestedHolidaysVariables}
            showWhenLoading
            showErrorMessage
            titleId="PendginRequests.Vacation.Title"
            placeholderImage={EmptyHolidayRequests}
            placeholderTextId="PendginRequests.Vacation.Empty"
            renderStatusControl={({ holidayRequest }) => (
              <RequestStatusControl
                holidayRequest={holidayRequest}
                iconsFirst
                showDelete={false}
                showEdit={false}
                showApprove
                showReject
              />
            )}
          />

          <Separator />

          <PendingRequestModuleWithQuery
            query={QueryRequestedSickLeaves}
            variables={requestedSickLeavesVariables}
            showWhenLoading
            showErrorMessage
            titleId="PendginRequests.Sick.Title"
            placeholderImage={EmptySickLeaves}
            placeholderTextId="PendginRequests.Sick.Empty"
            renderStatusControl={({ holidayRequest }) => (
              <RequestStatusControl
                holidayRequest={holidayRequest}
                iconsFirst
                showApprove
                showDelete={false}
                showEdit
                showReject={false}
              />
            )}
          />
        </Module>
      </GridColumnWrapper>
      <GridColumnWrapper column={2}>
        <Tutorial />

        <Module p={[10, 40]} mt={[10, 0]}>
          <OnHolidayModule holidayRequestType="VACATION" />
        </Module>

        <Module p={[10, 40]} mt={[10, 40]}>
          <OnHolidayModule holidayRequestType="SICK_LEAVE" />
        </Module>
      </GridColumnWrapper>
    </Grid>
  </Spacer>
)

DashboardPageContent.propTypes = {}

export default DashboardPageContent
