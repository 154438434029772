import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`

export const BaseValue = styled.div`
  width: 100%;
  padding-right: 10px;
`

export const SmallValue = styled(BaseValue)`
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`

export const BaseInput = styled.input`
  width: 100%;
  margin-right: 20px;
  border-width: 1px;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-color: ${({ theme }) => theme.colors.orangeLight};
`

export const BigInput = styled(BaseInput)`
  font-size: 36px;
`

export const SmallInput = styled(BaseInput)`
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid ${({ theme }) => theme.colors.orangeLight};
  height: 38px;
  width: 78px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
`

export const EditImageBig = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 20px;
`
export const EditImageSmall = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 73px;
`
