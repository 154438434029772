import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { TextInfo } from '../styles'

export default function RemainingHolidaysIndicator({
  holidayCounters: { remainingDays, year, availableHolidays },
}) {
  const noMoreHolidays = remainingDays < 0

  return (
    <TextInfo warning={noMoreHolidays}>
      {noMoreHolidays ? (
        <FormattedMessage
          id="RequestHoliday.NoMoreHolidaysLeft"
          values={{ days: availableHolidays, year }}
        />
      ) : (
        <FormattedMessage
          id="RequestHoliday.HolidaysLeft"
          values={{ days: remainingDays, year }}
        />
      )}
    </TextInfo>
  )
}

RemainingHolidaysIndicator.propTypes = {
  holidayCounters: PropTypes.shape({
    remainingDays: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    availableHolidays: PropTypes.number.isRequired,
  }).isRequired,
}
