import React from 'react'

import CompanyEditableField from '../components/CompanyEditableField'
import CompanyInfo from '../components/CompanyInfo'
import EmployeeInfo from '../components/EmployeeInfo'
import { Grid, Module, GridColumnWrapper } from '../../../components/styles'
import { Spacer } from '../../../components/styles/Spacer'
import { usePageTracking } from '../../../hooks/usePageTracking'
import InviteBanner from '../../dashboard/components/InviteBanner'

const Company = () => {
  usePageTracking('CompanyProfile')

  return (
    <Spacer mb={100}>
      <InviteBanner />
      <Spacer mt={45} mb={40} width={600} height={36}>
        <CompanyEditableField field="name" big maxLength={50} />
      </Spacer>
      <Grid>
        <GridColumnWrapper column={1}>
          <Module>
            <CompanyInfo />
          </Module>
        </GridColumnWrapper>
        <GridColumnWrapper column={2}>
          <Module>
            <EmployeeInfo />
          </Module>
        </GridColumnWrapper>
      </Grid>
    </Spacer>
  )
}

export default Company
