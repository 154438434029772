import styled from 'styled-components/macro'
import Button from '../../../../components/Button'

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 165px 52px;

  justify-items: center;
`

export const CenterImage = styled.img`
  grid-row: 1;
  margin-top: 22px;
  width: 160px;
  height: 97px;
  justify-content: center;
`

export const SubmitButton = styled(Button)`
  grid-row: 2;
  width: 288px;
`
