import React from 'react'
import { Helmet } from 'react-helmet'

import PricingPageContent from './PricingPageContent'
import { usePageTracking } from '../../../../hooks/usePageTracking'
import { IntlType } from '../../../../common/prop-types'

const PricingPage = ({ intl }) => {
  usePageTracking('Pricing')

  const title = intl.formatMessage({ id: 'App.Meta.Title.Pricing' })
  const description = intl.formatMessage({ id: 'App.Meta.Description.Pricing' })

  return (
    <>
      <Helmet>
        <meta name="description" content={description} />
        <title>{title}</title>
      </Helmet>
      <PricingPageContent />
    </>
  )
}

PricingPage.propTypes = {
  intl: IntlType.isRequired,
}

export default PricingPage
