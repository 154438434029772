import styled from 'styled-components/macro'
import { Elevated } from '../../../../elements'
import { dimensions } from '../../../../theme/freebees'

export const PageWrapper = styled.div`
  padding: 100px;
  display: flex;
  justify-content: center;
`

export const StyledElevated = styled(Elevated)`
  width: 520px;
  margin-top: 100px;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    width: 100%;
    margin-top: 10px;
  }
`

export const Content = styled.div`
  padding: 20px 80px;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    padding: 10px;
  }
`
