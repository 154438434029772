import styled from 'styled-components/macro'

import { Spacer } from '../../styles/Spacer'
import { Text, H2 } from '../../styles/Text'
import { colors, dimensions } from '../../../theme/freebees'

export const CenterAlignedSpacer = styled(Spacer)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledErrorText = styled(Text)`
  color: ${colors.rejectRed};
`

export const TextLink = styled(Text)`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: ${colors.orangeLight};
  display: flex;
  align-items: center;
`

export const StyledH2 = styled(H2)`
  padding: 0 150px;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    padding: 0;
  }
`
