import { arrayOf, bool, func, object } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { cutText } from '../../../../../common/utils/text'
import Button from '../../../../../components/Button'
import DateField from '../../../../../components/DateField'
import { Text } from '../../../../../components/styles'
import { Spacer } from '../../../../../components/styles/Spacer'
import UserName from '../../../../../components/UserName'
import {
  CenterAlignedSpacer,
  FieldContainer,
  SuccessImageIndicator,
  SuccessText,
} from './styles'

const getFieldColumns = width => (
  <FieldContainer key="user-table-record" minWidth={width}>
    <Text>
      <FormattedMessage id="Bulk.Upload.Success.Email.Field.Text" />
    </Text>
    <Text>
      <FormattedMessage id="Bulk.Upload.Success.DateOfBirth.Field.Text" />
    </Text>
    <Text>
      <FormattedMessage id="Bulk.Upload.Success.EmployeeFrom.Field.Text" />
    </Text>
  </FieldContainer>
)

const getEmployeeFields = (employee, width) => (
  <FieldContainer key={`user-table-record-${employee.email}`} minWidth={width}>
    <Text>{cutText(employee.email, 21)}</Text>
    <Text>
      <DateField date={employee.birthDate} />
    </Text>
    <Text>
      <DateField date={employee.hireDate} />
    </Text>
  </FieldContainer>
)

const getHeaders = (data, leftMargin, width) => {
  const maxDisplayNumber = Math.min(3, data.length)
  const displayEmployees = data.slice(0, maxDisplayNumber)

  return (
    <Spacer ml={leftMargin}>
      <Spacer display="flex" width={width}>
        {displayEmployees.map(employee => (
          <UserName
            textLength={13}
            key={`user-table-header-${employee.email}`}
            suppressRedirection
            employee={{ ...employee, id: '', active: false }}
          />
        ))}
      </Spacer>
    </Spacer>
  )
}

const getRecords = (data, columnSize, width) => {
  const maxDisplayNumber = Math.min(3, data.length)
  const displayEmployees = data.slice(0, maxDisplayNumber)

  return (
    <Spacer display="flex">
      {getFieldColumns(columnSize)}
      {displayEmployees.map(employee => getEmployeeFields(employee, width))}
    </Spacer>
  )
}

const BulkUploadSuccessDisplay = ({ data, isLoading, onUploadUsers }) => (
  <Spacer>
    <CenterAlignedSpacer height={30}>
      <Spacer display="flex">
        <Spacer display="flex" alignItems="center">
          <SuccessImageIndicator
            src="/assets/img/success-toast.png"
            alt="indicator"
          />
          <SuccessText>
            <FormattedMessage id="Bulk.Upload.Success.Text" />
          </SuccessText>
        </Spacer>
        &nbsp;
        <Text>{data.length}</Text>
        &nbsp;
        <Text>
          <FormattedMessage id="Bulk.Upload.Success.Text.Detail" />
        </Text>
      </Spacer>
    </CenterAlignedSpacer>

    <Spacer mt={18} style={{ overflowX: 'auto' }}>
      {getHeaders(data, 210, 222)}
      {getRecords(data, 210, 222)}
    </Spacer>

    <CenterAlignedSpacer mt={60}>
      <Text>
        <FormattedMessage id="Bulk.Upload.Success.Text.Extra" />
      </Text>
    </CenterAlignedSpacer>

    <CenterAlignedSpacer mt={20} height={52}>
      <Button
        width={256}
        primary
        id="upload-button"
        loading={isLoading}
        onClick={onUploadUsers}
      >
        <FormattedMessage id="Bulk.Upload.Button.Upload.Users.Text" />
      </Button>
    </CenterAlignedSpacer>
  </Spacer>
)

BulkUploadSuccessDisplay.propTypes = {
  isLoading: bool.isRequired,
  data: arrayOf(object).isRequired,
  onUploadUsers: func.isRequired,
}

export default BulkUploadSuccessDisplay
