import React from 'react'
import Report from '../components/Report'
import { usePageTracking } from '../../../hooks/usePageTracking'

const ReportPage = () => {
  usePageTracking('Report')

  return <Report />
}

export default ReportPage
