import { FormattedMessage } from 'react-intl'
import { func, arrayOf, instanceOf, shape, string, PropTypes } from 'prop-types'
import React, { Component } from 'react'
import { Button } from '../../../../components/styles/Button'
import {
  SectionTitleContainer,
  SectionContent,
  InformationContainer,
  Chip,
  HolidayContainer,
} from './style'
import ConfirmActionModal from '../../../../components/Modals/ConfirmActionModal'
import notify from '../../../../common/services/notification'
import { H2, Text } from '../../../../components/styles'
import { throwCustomError } from '../../../../common/constants/errorCodes'
import { HolidayMarker } from '../CompanyHolidayList/styles'

export class CompanyDictionaryEditor extends Component {
  state = {
    isAddItemModalOpen: false,
    isConfirmModalOpen: false,
    isLoading: false,
    subject: null,
  }

  handleDeleteItem = async item => {
    this.setState({ isConfirmModalOpen: true, subject: item })
  }

  handleCloseDeleteItemModal = () => {
    this.setState({ isConfirmModalOpen: false })
  }

  handleDeleteItemSubmit = async ({ subject }) => {
    this.setState({ isLoading: true })

    const { success } = await this.deleteItem(subject)

    if (success) {
      notify('success', 'Notification.Item.Deleted')
      await this.props.updateItems()
    } else {
      notify('error', 'Notification.Item.Deletion.Failed')
    }

    this.setState({ isConfirmModalOpen: false, isLoading: false })
  }

  handleAddItemSubmit = async item => {
    this.setState({ isLoading: true })
    const { success, error } = await this.createItem(item)

    if (success) {
      notify('success', 'Notification.Item.Creation')
      await this.props.updateItems()
      this.closeAddItemModal()
    } else {
      notify('error', error.message)
    }

    this.setState({ isLoading: false })
  }

  createItem = async newItem => {
    try {
      const variables = {
        name: newItem,
      }
      const result = await this.props.createItem({
        variables: { input: variables },
        errorPolicy: 'all',
      })

      throwCustomError(result, 'Notification.Item.Creation.Failed')

      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  deleteItem = async ({ id }) => {
    try {
      const variables = {
        id,
      }
      await this.props.deleteItem({ variables })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  openAddItemModal = () => {
    if (this.props.onAddItemClick) {
      this.props.onAddItemClick()
    } else {
      this.setState({
        isAddItemModalOpen: true,
      })
    }
  }

  closeAddItemModal = () => {
    this.setState({
      isAddItemModalOpen: false,
    })
  }

  render() {
    const {
      RenderAddItemModal,
      renderList,
      copy,
      items,
      emptyListTextId,
      isHolidaySection,
    } = this.props
    const { isAddItemModalOpen, isConfirmModalOpen, isLoading, subject } =
      this.state

    return (
      <>
        {isConfirmModalOpen && (
          <ConfirmActionModal
            isOpen={isConfirmModalOpen}
            onClose={this.handleCloseDeleteItemModal}
            onSubmit={this.handleDeleteItemSubmit}
            question={copy.deleteModal.question}
            title={copy.deleteModal.title}
            subject={subject}
            isLoading={isLoading}
          />
        )}

        {isAddItemModalOpen && (
          <RenderAddItemModal
            copy={copy.createModal}
            isLoading={isLoading}
            isOpen={isAddItemModalOpen}
            items={[items]}
            onClose={this.closeAddItemModal}
            onSubmit={this.handleAddItemSubmit}
          />
        )}

        <SectionTitleContainer>
          <H2>
            <FormattedMessage id={copy.title} />
          </H2>
          <Button
            secondary
            bordered
            id="add-item-button"
            narrow
            onClick={this.openAddItemModal}
            orange
            type="button"
          >
            <FormattedMessage id="Button.AddNew" />
          </Button>
        </SectionTitleContainer>
        {isHolidaySection && (
          <InformationContainer>
            <Chip>
              <HolidayMarker isCompanyHoliday={false} />
              <FormattedMessage id="National.Holiday" />
            </Chip>
            <Chip>
              <HolidayMarker isCompanyHoliday />
              <FormattedMessage id="Company.Holiday" />
            </Chip>
          </InformationContainer>
        )}
        <HolidayContainer isHolidayList={isHolidaySection}>
          <SectionContent>
            {items.length ? (
              <>{renderList({ items, onDelete: this.handleDeleteItem })}</>
            ) : (
              <Text fontSize={16} color="gray" align="left">
                <FormattedMessage id={emptyListTextId} />
              </Text>
            )}
          </SectionContent>
        </HolidayContainer>
      </>
    )
  }
}

CompanyDictionaryEditor.propTypes = {
  copy: shape({
    createModal: shape({
      title: string.isRequired,
      currentListLabel: string.isRequired,
      inputPlaceholder: string.isRequired,
      newItemLabel: string.isRequired,
    }).isRequired,
    deleteModal: shape({
      question: string.isRequired,
      title: string.isRequired,
    }).isRequired,
    title: string.isRequired,
  }).isRequired,
  createItem: func.isRequired,
  deleteItem: func.isRequired,
  onAddItemClick: func,
  items: arrayOf(instanceOf(Object)).isRequired,
  RenderAddItemModal: func,
  renderList: func.isRequired,
  updateItems: func.isRequired,
  emptyListTextId: string,
  isHolidaySection: PropTypes.bool.isRequired,
}

CompanyDictionaryEditor.defaultProps = {
  emptyListTextId: 'Company.Items.Empty',
}

export default CompanyDictionaryEditor
