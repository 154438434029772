import React from 'react'
import {
  areIntervalsOverlapping,
  endOfDay,
  isSameDay,
  startOfDay,
} from 'date-fns'
import { Day } from './Day'

import { DateType, HolidayRequestListType } from '../../../../common/prop-types'
import { filterNotWorkingDays } from '../../../../common/services/workdays'
import { daysOfWeek } from '../../../../common/utils/dateUtils'
import { Container } from './style'

export const Week = ({ date, month, holidayRequests }) => (
  <div key="days">
    <Container>
      {daysOfWeek(date).map(day => {
        const isWorkingDay = filterNotWorkingDays(day)

        const holiday = holidayRequests.find(
          ({ startDate, endDate }) =>
            areIntervalsOverlapping(
              { start: startOfDay(day), end: endOfDay(day) },
              { start: startDate, end: endDate },
              { inclusive: true }
            ) && isWorkingDay
        )
        const firstDay = holiday && isSameDay(day, holiday.startDate)
        const lastDay = holiday && isSameDay(day, holiday.endDate)
        return (
          <Day
            key={`day-${day.toUTCString()}`}
            date={day}
            month={month}
            holiday={holiday}
            isWorkingDay={isWorkingDay}
            firstDay={firstDay}
            lastDay={lastDay}
          />
        )
      })}
    </Container>
  </div>
)

Week.propTypes = {
  month: DateType.isRequired,
  date: DateType.isRequired,
  holidayRequests: HolidayRequestListType.isRequired,
}

export default Week
