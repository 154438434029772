import React, { useEffect } from 'react'
import { shape, string, arrayOf } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { GqlDataType, FunctionType } from '../../common/prop-types'
import {
  transformEmployee,
  transformCompanyHolidays,
  transformCompanyData,
} from '../../common/utils/responseTransformer'
import {
  setCompensationDays,
  setCompanyHolidays,
  setNationalHolidays,
} from '../../common/services/workdays'
import { setCurrentUser } from '../../common/services/analytics'
import { AdminRoutes, EmployeeRoutes } from '../../routes'
import Loading from '../Loading'
import { selectCurrentUserData } from '../../redux/auth/auth.selector'
import { PageContainer } from '../../elements'
import { Spacer } from '../styles/Spacer'
import { selectLocale } from '../../redux/language-selector/language-selector.reducer'

const shouldUpdateEmployeeData = (oldData, newData) => {
  const newName = `${newData.firstName} ${newData.lastName}`
  const oldName = `${oldData.firstName} ${oldData.lastName}`

  const nameChanged = oldName !== newName
  const roleChanged = oldData.role.id !== newData.role.id
  const languageChanged = oldData.selectedLang !== newData.selectedLang

  return nameChanged || roleChanged || languageChanged
}

const UserDataLoader = ({
  currentCompanyQuery: { currentCompany } = {},
  nationalHolidaysQuery: { nationalHolidays },
  compensationDaysQuery: { compensationDays },
  employeeQuery: { currentEmployee } = {},
  setUserData,
  setCompanyData,
}) => {
  const currentUserData = useSelector(selectCurrentUserData)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentEmployee) {
      const transformedEmployee = transformEmployee(currentEmployee)
      setCurrentUser(transformedEmployee)

      if (currentUserData) {
        if (shouldUpdateEmployeeData(currentUserData, transformedEmployee)) {
          setUserData(transformedEmployee)
        }
        dispatch(selectLocale(transformedEmployee.selectedLang))
      } else {
        setUserData(transformedEmployee)
      }
    }
  }, [currentEmployee, currentUserData, setUserData, dispatch])

  useEffect(() => {
    if (currentCompany) {
      const transformedData = transformCompanyData(currentCompany)
      setCompanyData(transformedData)
      setCompanyHolidays(transformedData.companyHolidays)
    }
  }, [currentCompany, setCompanyData])

  useEffect(() => {
    if (compensationDays) {
      const transformedData = transformCompanyHolidays(compensationDays)

      setCompensationDays(transformedData)
    }
  }, [compensationDays])

  useEffect(() => {
    if (nationalHolidays) {
      const transformedData = transformCompanyHolidays(nationalHolidays)

      setNationalHolidays(transformedData)
    }
  }, [nationalHolidays])

  if (
    !currentUserData ||
    !currentEmployee ||
    !currentCompany ||
    !compensationDays ||
    !nationalHolidays
  )
    return (
      <Spacer height="100vh" mt={60}>
        <Loading />
      </Spacer>
    )

  const Routes = currentUserData.isAdmin ? AdminRoutes : EmployeeRoutes

  return (
    <PageContainer>
      <Routes />
    </PageContainer>
  )
}

UserDataLoader.propTypes = {
  employeeQuery: GqlDataType,
  setUserData: FunctionType.isRequired,
  setCompanyData: FunctionType.isRequired,
  nationalHolidaysQuery: shape({
    nationalHolidays: arrayOf(shape({ id: string, date: string })),
  }),
  compensationDaysQuery: shape({
    compensationDays: arrayOf(shape({ id: string, date: string })),
  }),
  currentCompanyQuery: shape({}),
}

export default UserDataLoader
