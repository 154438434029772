import ReactModal from 'react-modal'
import styled from 'styled-components/macro'
import { space, width } from 'styled-system'
import { dimensions } from '../../theme/freebees'
import { Text } from '../styles'

export const ModalContainer = styled.div`
  ${space};
  ${width};
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.modal};
  border-radius: 4px;
  padding: 30px 40px 40px;
  width: fit-content;
  margin: 0 auto;
  pointer-events: all;
  margin-bottom: '40px';
  @media ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const ModalTitle = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  line-height: 32px;
`

export const ModalContent = styled.div``

export const ModalInputContainer = styled.div`
  padding-bottom: 30px;
  padding-top: 10px;
`

export const ModalVerticalInputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${dimensions.pageWidth}px) {
    flex-direction: column;
  }
`

export const ModalHeader = styled.div`
  text-align: center;
  margin-bottom: 10px;
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ModalControls = styled.div`
  text-align: right;
  height: 30px;
  svg {
    transition: all 0.4s;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
`

export const ModalCloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`

export const customStyles = {
  content: {
    bottom: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    padding: '0px',
    border: '0px',
    boxShadow: '0px 10px 36px rgba(17, 14, 10, 0.27)',
    overflowX: 'scroll',
  },
}

export const CustomReactModal = styled(ReactModal)`
  position: relative;
  background: transparent;
  padding: 40px;
  top: inherit;
  left: inherit;
  bottom: inherit;
  right: inherit;
  margin: 0 auto;
  border: none;
  overflow: hidden;
  width: fit-content;
  outline: none;

  @media ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: 40px 20px;
  }
`
