import React from 'react'
import PropTypes from 'prop-types'
import { Image } from './styles'

const ProfileImage = ({ src, size }) => (
  <Image
    alt="User profile"
    src={src || `/assets/img/avatar-placeholder-${size}.svg`}
    size={size}
  />
)

ProfileImage.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
}

ProfileImage.defaultProps = {
  size: 'small',
}

export default ProfileImage
