import React from 'react'
import { bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { HolidayRequestType } from '../../common/prop-types'

import RequestStatusReject from './RequestStatusReject'
import RequestStatusApprove from './RequestStatusApprove'
import RequestDelete from './RequestDelete'
import RequestEdit from './RequestEdit'
import { Container, Control } from './styles'
import RequestStatusTransform from './RequestStatusTransform'
import Button from '../Button'
import { Spacer } from '../styles/Spacer'
import { selectIsAdmin } from '../../redux/auth/auth.selector'

const RequestStatusControl = ({
  holidayRequest,
  iconsFirst,
  showApprove,
  showDelete,
  showEdit,
  showReject,
  showTransform,
}) => {
  const isAdmin = useSelector(selectIsAdmin)
  const approvable = showApprove && holidayRequest.approvable
  const rejectable = showReject && holidayRequest.rejectable
  const editable = showEdit && holidayRequest.editable
  const deletable = showDelete && holidayRequest.deletable
  const transformable = showTransform && holidayRequest.editable

  const icons = (
    <Spacer mx={10} display="flex">
      {editable && (
        <Control type="icon">
          <RequestEdit holidayRequest={holidayRequest} />
        </Control>
      )}

      {deletable && (
        <Control type="icon">
          <RequestDelete holidayRequest={holidayRequest} />
        </Control>
      )}
    </Spacer>
  )

  const transform = holidayRequest.status === 'PLANNED' && transformable && (
    <Control type="button">
      <RequestStatusTransform
        holidayRequest={holidayRequest}
        renderTrigger={({ onClick, isLoading }) => (
          <Button
            width={230}
            secondary
            narrow
            color="orange"
            id="approve-request-button"
            onClick={onClick}
            disabled={isLoading}
          >
            <FormattedMessage
              id={`Button.TransformRequest${isAdmin ? '.Admin' : ''}`}
            />
          </Button>
        )}
      />
    </Control>
  )

  return (
    <Container justifyContent={['flex-start', 'flex-end']}>
      {iconsFirst && icons}

      {approvable && (
        <Control type="button">
          <RequestStatusApprove secondary holidayRequest={holidayRequest} />
        </Control>
      )}

      {rejectable && (
        <Control type="button">
          <RequestStatusReject holidayRequest={holidayRequest} />
        </Control>
      )}

      {transform}

      {!iconsFirst && icons}
    </Container>
  )
}

RequestStatusControl.propTypes = {
  holidayRequest: HolidayRequestType,
  iconsFirst: bool,
  showApprove: bool,
  showReject: bool,
  showDelete: bool,
  showEdit: bool,
  showTransform: bool,
}

export default RequestStatusControl
