import React from 'react'
import { oneOf, func } from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Spacer } from '../../../../components/styles/Spacer'
import { Text } from '../../../../components/styles'
import ButtonSelector from '../../../../components/ButtonSelector'

const ReportDownloadSection = ({
  selectedDownloadType,
  onSelectionChanged,
}) => (
  <Spacer display="flex" alignItems="baseline">
    <Spacer pr={25}>
      <Text>
        <FormattedMessage id="Report.Select.DownloadFormat" />
      </Text>
    </Spacer>
    <Spacer>
      <ButtonSelector
        width="70px"
        id="download-type-selector"
        options={[
          {
            value: 'Report.FormatSelector.PDF',
            key: 'PDF',
          },
          { key: 'XLS', value: 'Report.FormatSelector.XLS' },
          { key: 'CSV', value: 'Report.FormatSelector.CSV' },
        ]}
        value={selectedDownloadType}
        onChange={value => {
          onSelectionChanged(value)
        }}
      />
    </Spacer>
  </Spacer>
)

ReportDownloadSection.propTypes = {
  selectedDownloadType: oneOf(['PDF', 'XLS', 'CSV']).isRequired,
  onSelectionChanged: func.isRequired,
}

export default ReportDownloadSection
