const cognitoErrorCodes = {
  UserNotFoundException: 'Cognito.ValidationErrors.UserNotFound',
  UsernameExistsException: 'Cognito.ValidationErrors.UsernameExists',
  UserNotConfirmedException: 'Cognito.ValidationErrors.UserNotConfirmed',
  PasswordResetRequiredException:
    'Cognito.ValidationErrors.PasswordResetRequired',
  NotAuthorizedException: 'Cognito.ValidationErrors.NotAuthorized',
  InvalidPasswordException: 'Cognito.ValidationErrors.InvalidPassword',
}

export const getCognitoMessage = (
  error = {},
  defaultMessage = 'Notifictaion.Action.Failed'
) => {
  const { code } = error
  let { message = defaultMessage } = error

  message = cognitoErrorCodes[code] || message

  return message
}

export const throwCustomError = (
  response,
  defaultMessage = 'Notification.Action.Failed'
) => {
  const thereAreErrors =
    response && Array.isArray(response.errors) && response.errors.length

  if (thereAreErrors) {
    let firstCustomErrorCode = defaultMessage
    const firstError = response.errors[0]

    // NOTE: this is necessary because of the backend's inconsistent error messages
    const thereAreValidationErrors =
      Array.isArray(firstError.errorInfo) && firstError.errorInfo.length

    const logicErrorCode = firstError.errorInfo && firstError.errorInfo.code

    if (thereAreValidationErrors) {
      const [{ errors: [{ message = defaultMessage } = {}] = [] } = {}] =
        firstError.errorInfo
      firstCustomErrorCode = message
    }

    if (logicErrorCode) {
      firstCustomErrorCode = logicErrorCode
    }

    throw new Error(firstCustomErrorCode)
  }
}
