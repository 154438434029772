import React from 'react'
import styled from 'styled-components'
import { injectIntl, intlShape } from 'react-intl'

const StyledChatButton = styled.button`
  background-color: ${({ theme }) => theme.colors.orangeLight};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }

  img {
    width: 24px;
    height: 24px;
  }
`

const ChatButton = ({ intl }) => {
  const buttonTitle = intl.formatMessage({
    id: 'Chat.Title',
    defaultMessage: 'Have any questions? Let us know here',
  })

  return (
    <StyledChatButton
      title={buttonTitle}
      onClick={() => {
        const encodedEmail =
          '&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#102;&#114;&#101;&#101;&#098;&#101;&#101;&#115;&#046;&#105;&#111;'
        const decodeEmail = encoded => {
          const textArea = document.createElement('textarea')
          textArea.innerHTML = encoded
          return textArea.value
        }

        const email = decodeEmail(encodedEmail)
        window.location.href = `mailto:${email}`
      }}
    >
      <img src="/assets/icons/chat.svg" alt="Chat Icon" />
    </StyledChatButton>
  )
}

ChatButton.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(ChatButton)
