import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider, injectIntl } from 'react-intl'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { PublicRoutes } from '../../routes'
import i18nService from '../../common/services/i18n'
import UserDataLoader from '../UserDataLoader'
import ScrollToTop from '../ScrollToTop'
import AppHeader from '../AppHeader'
import AddHolidayModal from '../Modals/AddHolidayModal'
import { selectActiveLanguage } from '../../redux/language-selector/language-selector.selector'
import { selectIsAuthenticated } from '../../redux/auth/auth.selector'
import Footer from '../Footer'
import { Spacer } from '../styles/Spacer'
import ChatButton from './styles'

const Meta = injectIntl(({ intl }) => {
  const title = intl.formatMessage({ id: 'App.Meta.Title' })
  const description = intl.formatMessage({ id: 'App.Meta.Description' })
  return (
    <Helmet>
      <meta name="description" content={description} />
      <title>{title}</title>
    </Helmet>
  )
})

export const App = () => {
  const selectedLocale = useSelector(selectActiveLanguage)
  const isAuthenticated = useSelector(selectIsAuthenticated)

  return (
    <IntlProvider
      locale={selectedLocale.code}
      messages={i18nService.getMessages(selectedLocale.code)}
    >
      <>
        <ChatButton />
        <ToastContainer
          autoClose={8000}
          className="toast_styled_container"
          bodyClassName="toast_styled_body"
        />

        <AddHolidayModal />
        <Meta />
        <Router>
          <ScrollToTop>
            <>
              {isAuthenticated ? (
                <UserDataLoader />
              ) : (
                <>
                  <Spacer minHeight="calc(100vh - 60px - 150px)" mt={60}>
                    <PublicRoutes />
                  </Spacer>
                  <Footer />
                </>
              )}
            </>
            <AppHeader />
          </ScrollToTop>
        </Router>
      </>
    </IntlProvider>
  )
}

App.propTypes = {}
