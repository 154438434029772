import gql from 'graphql-tag'

const DISCONNECT_FROM_GOOGLE = gql`
  mutation disconnectEmployeeFromGoogle(
    $input: DisconnectEmployeeFromGoogleInput!
  ) {
    disconnectEmployeeFromGoogle(input: $input) {
      message
    }
  }
`

export default DISCONNECT_FROM_GOOGLE
