import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { openAddUserModal } from '../../../../redux/holiday-modal/holiday-modal.reducer'
import { selectAreEmployeesAdded } from '../../../../redux/company/company.selector'
import { selectIsAdmin } from '../../../../redux/auth/auth.selector'
import { Banner, InviteLink } from './styles'

function InviteBanner() {
  const areEmployeesAdded = useSelector(selectAreEmployeesAdded)
  const isAdmin = useSelector(selectIsAdmin)
  const dispatch = useDispatch()
  const history = useHistory()

  if (areEmployeesAdded || !isAdmin) {
    return null
  }

  return (
    <Banner>
      <FormattedMessage id="InviteBanner.Message" />
      <InviteLink
        type="button"
        onClick={() => {
          history.push('/company')
          dispatch(openAddUserModal())
        }}
      >
        <FormattedMessage id="InviteBanner.Link" />
      </InviteLink>
    </Banner>
  )
}

export default InviteBanner
