import { subYears } from 'date-fns'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import isLength from 'validator/lib/isLength'

const hasValue = (field, values) =>
  !!values[field] &&
  (values[field] instanceof Object || !isEmpty(values[field]))

const isAtLeastSixteen = (birthDateStr, hireDateStr) => {
  if (!birthDateStr || !hireDateStr) {
    return false
  }
  const birthDate = new Date(birthDateStr)
  const hireDate = new Date(hireDateStr)
  return subYears(hireDate, 16) >= birthDate
}

export default rules => fields => {
  let error = false

  const messages = rules.reduce((results, ruleDef) => {
    const [field, ...userRuleDefs] = ruleDef

    const fieldErrors = userRuleDefs.reduce((fErrors, r) => {
      let rule
      let msg

      if (typeof r === 'object') {
        ;({ rule, msg } = r)
      } else {
        rule = r
        msg = undefined
      }

      if (rule === 'required' && !hasValue(field, fields)) {
        fErrors.push(msg || 'This field is required')
      } else if (
        rule === 'email' &&
        (!hasValue(field, fields) || !isEmail(fields[field]))
      ) {
        fErrors.push(msg || 'This is not a valid email address')
      } else if (
        rule === 'firstName' &&
        (!hasValue(field, fields) || !isLength(fields[field], { min: 3 }))
      ) {
        fErrors.push(msg || 'This is not a valid first name')
      } else if (rule === 'at-least-16') {
        if (!isAtLeastSixteen(fields.birthDate, fields.hireDate)) {
          fErrors.push(msg || 'The employee must be at least 16 years old.')
        }
      } else if (typeof rule === 'function' && !rule(fields[field], fields)) {
        fErrors.push(msg || 'This field is invalid')
      }

      return fErrors
    }, [])

    if (fieldErrors.length > 0) {
      error = true
    }

    return { ...results, [field]: fieldErrors }
  }, {})

  return {
    error,
    messages,
  }
}
