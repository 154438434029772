import React from 'react'
import { func } from 'prop-types'
import { Image } from './style'
import { EmployeeType, IntlType } from '../../../common/prop-types'
import EntityControl from '../../EntityControl'
import { refetchQueries } from '../../../common/constants/refetchQueries'

const EmployeeActivate = ({ mutate, employee, intl }) => (
  <EntityControl
    mutate={mutate}
    entity={employee}
    question="EmloyeeActivateModal.Question"
    title="EmloyeeActivateModal.Title"
    refetchQueries={refetchQueries}
    renderTrigger={({ onClick }) => (
      <Image
        title={intl.formatMessage({ id: 'Employee.Activate.Tooltip' })}
        id="activate-user"
        onClick={onClick}
        src="/assets/icons/user-activate.svg"
      />
    )}
  />
)

EmployeeActivate.propTypes = {
  employee: EmployeeType.isRequired,
  mutate: func.isRequired,
  intl: IntlType.isRequired,
}

export default EmployeeActivate
