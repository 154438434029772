import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { HolidayCellContainer } from '../HolidayCell/styles'

export const Selection = styled(HolidayCellContainer)`
  z-index: 1;
  pointer-events: none;
  opacity: 0.75;
  align-self: center;
`

const HolidaySelection = React.forwardRef(
  ({ row, startCell, endCell }, ref) => {
    return (
      <Selection
        ref={ref}
        row={row}
        startCell={startCell}
        endCell={endCell}
        type="SELECTION"
      >
        <div className="inner" />
      </Selection>
    )
  }
)

HolidaySelection.propTypes = {
  row: PropTypes.number.isRequired,
  startCell: PropTypes.number.isRequired,
  endCell: PropTypes.number.isRequired,
}

export default HolidaySelection
