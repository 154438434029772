import styled from 'styled-components/macro'
import { colors } from '../../../../../theme/freebees'
import { Spacer } from '../../../../../components/styles/Spacer'
import { Text } from '../../../../../components/styles'

export const CenterAlignedSpacer = styled(Spacer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SuccessText = styled(Text)`
  color: ${colors.greenLime};
`

export const SuccessImageIndicator = styled.img`
  padding-right: 11px;
  height: 9px;
`

export const FieldContainer = styled(Spacer)`
  height: 100px;
  min-width: ${props => `${props.minWidth}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`
