import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function useRedirectTo(location) {
  const history = useHistory()

  useEffect(() => {
    window.open(location, '_blank')
    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      history.replace('/')
    }
  }, [location, history])

  return null
}
