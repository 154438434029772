import React, { PureComponent } from 'react'
import { string, shape, func } from 'prop-types'
import CompanyNameFormView from './CompanyNameFormView'
import validation from '../../../common/services/validation'

class CompanyNameForm extends PureComponent {
  state = { validationErrors: { companyName: [] } }

  isValid = validation([['companyName', 'required']])

  validation = () => {
    const { error, messages } = this.isValid(this.props.formData)
    this.setState({ validationErrors: messages })
    return { error, messages }
  }

  handleInputChange = change => {
    this.setState(change, () => {
      this.props.onInputChange(change)
    })
  }

  handleSubmit = async event => {
    const { companyName } = this.state

    const { error } = this.validation()

    event.persist()
    event.preventDefault()

    if (!error) {
      this.props.onSubmit({ companyName })
    }
  }

  render() {
    const {
      formData: { companyName },
    } = this.props
    const { validationErrors } = this.state

    const props = {
      companyName,
      validationErrors,
      onSubmit: this.handleSubmit,
      onChange: this.handleInputChange,
    }
    return <CompanyNameFormView {...props} />
  }
}

CompanyNameForm.propTypes = {
  onSubmit: func.isRequired,
  onInputChange: func.isRequired,
  formData: shape({
    companyName: string.isRequired,
  }).isRequired,
}
export default CompanyNameForm
