import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { ButtonGroup } from '../../../../components/styles/Button'

import { InputSection } from './styles'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form } from '../../../../components/styles/Form'
import Modal from '../../../../components/Modal'
import { Spacer } from '../../../../components/styles/Spacer'

export class AddTagModal extends Component {
  state = {
    newTag: undefined,
  }

  onTextChanged = newTag => {
    this.setState({ newTag })
  }

  render() {
    const { onClose, copy, isOpen, onSubmit, isLoading } = this.props
    const { newTag } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleKey={copy.title}
        content={() => (
          <Spacer>
            <Form
              onSubmit={e => {
                e.preventDefault()
                onSubmit(this.state.newTag)
              }}
            >
              <InputSection>
                <Input
                  autoFocus
                  id="tag"
                  label={copy.newItemLabel}
                  placeholder={copy.inputPlaceholder}
                  maxLength={50}
                  onChange={event => this.onTextChanged(event.target.value)}
                  hideErrors
                />
              </InputSection>

              <ButtonGroup>
                <Button
                  primary
                  bordered
                  narrow
                  id="close-modal"
                  type="button"
                  onClick={onClose}
                >
                  <FormattedMessage id="Button.Cancel" />
                </Button>
                <Button
                  primary
                  narrow
                  disabled={!newTag}
                  id="submit-button"
                  type="submit"
                  loading={isLoading}
                >
                  <FormattedMessage id="Button.Add" />
                </Button>
              </ButtonGroup>
            </Form>
          </Spacer>
        )}
      />
    )
  }
}

AddTagModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  copy: PropTypes.shape({
    title: PropTypes.string,
    currentListLabel: PropTypes.string,
    newItemLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
  }),
}

export default AddTagModal
